import React, { useState } from 'react';
import { RootState, useAppDispatch, useAppSelector } from '../../store/store';
import { Container } from 'react-bootstrap';
import {
    clearApplication,
    newApplicationStart,
} from '../../store/slices/applicationDetail/applicationSlice';
import { useNavigate } from 'react-router-dom';
import { ApiData } from '../../store/storeApiUtils';
import { ApplicationProcess } from '../../services/entities/ApplicationProcess';
import { LoadingIndicator } from '../../components/loadingIndicator/LoadingIndicator';
import ApplicationTable from './appTable/ApplicationTable';
import Header from '../common/header/Header';
import { PlusCircle } from 'react-bootstrap-icons';
import MainContent from '../common/content/MainContent';
import { hasIntersection, matchPattern } from '../../utils/utils';
import { APPLICANT_ROLES, UserRole } from '../../services/entities/User';
import MultiRequestDialog from './MultiRequestDialog';
import { IS_KOOP } from '../../services/constants';

function ApplicationListPage() {
    const process: ApiData<ApplicationProcess> = useAppSelector(
        (state: RootState) => state.applicationDetail.application.process
    );
    const user = useAppSelector((s: RootState) => s.user);
    const dispatch = useAppDispatch();

    const navigate = useNavigate();
    const [showMultiRequestDialog, setShowMultiRequestDialog] = useState(false);
    const handleNewApplication = () => {
        if (
            IS_KOOP &&
            hasIntersection([UserRole.zadavatel_hromzad], user.roles) &&
            user.roles.length === 2
        ) {
            dispatch(clearApplication());
            dispatch(newApplicationStart({ navigate, multiApplication: true }));
        } else if (
            (IS_KOOP &&
                hasIntersection(
                    [UserRole.administrator, UserRole.spravce],
                    user.roles
                )) ||
            (hasIntersection([UserRole.zadavatel_hromzad], user.roles) &&
                matchPattern(user.roles, 'zadavatel'))
        ) {
            setShowMultiRequestDialog(!showMultiRequestDialog);
        } else {
            dispatch(clearApplication());
            dispatch(
                newApplicationStart({ navigate, multiApplication: false })
            );
        }
    };

    return (
        <div className="position-relative">
            {process.pending ? <LoadingIndicator /> : null}
            <Header
                actions={
                    hasIntersection(user.roles, APPLICANT_ROLES)
                        ? [
                              {
                                  title: 'Nová žádost',
                                  icon: <PlusCircle />,
                                  action: handleNewApplication,
                              },
                          ]
                        : []
                }
                className="mb-3"
            />
            <MultiRequestDialog
                open={showMultiRequestDialog}
                onHide={() => setShowMultiRequestDialog(false)}
            />
            <MainContent>
                <Container fluid="xxl">
                    <h3 className="mb-3">Žádosti o mimořádné provize</h3>

                    {process.error ? (
                        <div className="d-flex align-items-center">
                            <h6 className="text-danger">
                                Chyba při startování procesu: {process.error}
                            </h6>
                        </div>
                    ) : null}

                    <ApplicationTable />
                </Container>
            </MainContent>
        </div>
    );
}

export default ApplicationListPage;
