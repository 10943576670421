import React from 'react'
import {UseFormReturn} from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import {FormError} from '../../../components/form/FormError';
import {ProductTypeNames} from '../../../services/entities/Payout';
import {Col, Row} from 'react-bootstrap';
import {TextItem} from '../../../components/textItem/TextItem';
import {formatAmount, formatDate} from '../../../utils/formatUtils';
import clsx from 'clsx';
import {dateAfter, dateBefore} from "../../../components/form/formValidations";
import {PayoutDetailDataPayout} from '../../../services/entities/PayoutDetailWrapper';

//https://www.npmjs.com/package/react-year-picker
//https://www.npmjs.com/package/react-month-picker

type PayoutDetailFormProps = {
    payout: PayoutDetailDataPayout,
    formContext: UseFormReturn<PayoutDetailDataPayout, object>,
    editable: boolean,
    className?: string
}
function PayoutDetailForm({className, payout, formContext, editable}: PayoutDetailFormProps) {

    const {register, watch, formState: { errors } } = formContext

    const fromDateWatch = watch('fromDate')
    const toDateWatch = watch('toDate')

    return (
        <Form
            id="PayoutDetailForm"
            className={clsx('PayoutDetailForm', className)}
        >
            <Row className="mb-3">
                <TextItem
                    title="Titul (číslo smlouvy)"
                    value={payout?.title?.evidenceNumber}
                />
                <TextItem
                    title="Získatelské číslo"
                    value={payout?.recipientId}
                />
                <TextItem title="Částka" value={formatAmount(payout?.amount)} />
                <TextItem
                    title="Kmen"
                    value={`${payout?.productType} (${
                        ProductTypeNames[payout?.productType]
                    })`}
                />
                <TextItem
                    title="Nevyplatit před"
                    value={formatDate(payout?.dueDate)}
                />
                <TextItem title="DB ID výplaty" value={payout?.id} />
            </Row>
            {editable && (
                <Row>
                    <Col>
                        <Form.Group className="me-3">
                            <Form.Label>Období od</Form.Label>
                            <Form.Control
                                type="date"
                                max={toDateWatch?.toString()}
                                placeholder="Zadejte datum"
                                {...register('fromDate', {
                                    validate: {
                                        ...dateBefore(
                                            toDateWatch,
                                            '"Období od" musí být dříve než "Období do".'
                                        ),
                                    },
                                })}
                            />
                            <FormError errors={errors} name="fromDate" />
                        </Form.Group>
                    </Col>

                    <Col>
                        <Form.Group className="me-5">
                            <Form.Label>Období do</Form.Label>
                            <Form.Control
                                type="date"
                                min={fromDateWatch?.toString()}
                                placeholder="Zadejte datum"
                                {...register('toDate', {
                                    validate: {
                                        ...dateAfter(
                                            fromDateWatch,
                                            '"Období do" musí být později než "Období od".'
                                        ),
                                    },
                                })}
                            />
                            <FormError errors={errors} name="toDate" />
                        </Form.Group>
                    </Col>
                    <Col />
                    <Col />
                </Row>
            )}
        </Form>
    );
}

export default PayoutDetailForm;
