import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getIdToken } from '../../../pages/common/authProvider/authProviderUtils';
import {
    DataImportConfirmType,
    DataImportType,
    fetchTitleRegisterImport,
    fetchTitleRegisterImportConfirm,
    FetchTitleRegisterImportType,
} from '../../../services/api/titleRegisterImportApi';
import parseErrors from '../../../utils/parseErrors';

export type TitleRegisterImportState = {
    data: FetchTitleRegisterImportType;
    loading: boolean;
    errors: Array<string>;
};

const initialState: TitleRegisterImportState = {
    data: {
        status: '',
        message: '',
        rows: [],
        numberOfRowsInDB: null,
        numberOfRowsInFile: null,
        titleStructureType: '',
        referenceUUID: ''
    },
    loading: false,
    errors: [],
};

export const titleRegisterImportFetch = createAsyncThunk<
    FetchTitleRegisterImportType,
    DataImportType
>(
    'titleRegisterImport/titleRegisterImportFetch',
    async (data: DataImportType) => {
        return fetchTitleRegisterImport(data, await getIdToken());
    }
);

export const titleRegisterImportConfirmFetch = createAsyncThunk<
    FetchTitleRegisterImportType,
    DataImportConfirmType
>(
    'titleRegisterImport/titleRegisterImportConfirmFetch',
    async (data: DataImportConfirmType) => {
        return fetchTitleRegisterImportConfirm(data, await getIdToken());
    }
);

const titleRegisterImportSlice = createSlice({
    name: 'titleRegisterImportSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(titleRegisterImportFetch.pending, (s) => {
            s.loading = true;
        });
        builder.addCase(titleRegisterImportFetch.fulfilled, (s, a) => {
            s.data = a.payload;
            s.errors = parseErrors(a.payload);
            s.loading = false;
        });
        builder.addCase(titleRegisterImportFetch.rejected, (s) => {
            s.loading = false;
        });
        builder.addCase(titleRegisterImportConfirmFetch.pending, (s) => {
            s.loading = true;
        });
        builder.addCase(titleRegisterImportConfirmFetch.fulfilled, (s, a) => {
            s.data = a.payload;
            s.errors = parseErrors(a.payload);
            s.loading = false;
        });
        builder.addCase(titleRegisterImportConfirmFetch.rejected, (s) => {
            s.loading = false;
        });
    },
});

export default titleRegisterImportSlice.reducer;
