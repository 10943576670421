import dayjs from 'dayjs';
import { Variant } from 'react-bootstrap/esm/types';
import {
    ApplicationPayoutStateEnum,
    ApplicationStateEnum,
} from '../services/entities/Application';
import { PayoutStateEnum } from '../services/entities/Payout';
import { TaskEscalation } from '../services/entities/ApplicationProcess';
import { CamundaUser, UserId } from '../services/entities/User';
import { TokenError } from '../pages/common/authProvider/authProviderUtils';
import { PayoutsTasks } from '../services/entities/PayoutsList';

// export const month2DateStr = (month: string): string => {
//     return month ? dayjs(month, 'YYYY-MM').format('YYYY-MM-DD') : ''
// }

// export const date2month = (date: Date | string): any => {
//     return date ? dayjs(date).format('YYYY-MM') : ''
// }

export const isValidDate = (text: string) =>
    dayjs(text.trim(), 'DD.MM.YYYY').isValid();

export const reformatDate = (text: string) =>
    dayjs(text.trim(), 'DD.MM.YYYY').format('YYYY-MM-DD');

export const dateToInput = (date: Date | string) =>
    dayjs(date).format('YYYY-MM-DD') as unknown as Date;

export const formatDate = (date: Date | string) =>
    dayjs(date).format('DD.MM.YYYY');

export const formatServerDate = (date: Date | string): string =>
    dayjs(date).format('YYYY-MM-DD');

export const formatDateAsMonth = (date: Date | string): string =>
    dayjs(date).format('MM/YYYY');

export const formatDateTime = (date: Date): string =>
    dayjs(date).format('DD.MM.YYYY HH:mm:ss');

export const formatDateTimeStamp = (date: Date): string =>
    dayjs(date).format('DD-MM-YYYY_HH-mm');

// 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light'

export const appStateBg = (state: ApplicationStateEnum): Variant => {
    switch (state) {
        case ApplicationStateEnum.APPROVED:
            return 'success';
        case ApplicationStateEnum.CANCELLED:
            return 'danger';
        case ApplicationStateEnum.DISAPPROVED:
            return 'danger';
        case ApplicationStateEnum.FOR_APPROVAL:
            return 'info';
        case ApplicationStateEnum.NEW:
            return 'info';
        case ApplicationStateEnum.RETURNED:
            return 'warning';
    }
};

export const appPayoutStateBg = (
    state: ApplicationPayoutStateEnum
): Variant => {
    switch (state) {
        case ApplicationPayoutStateEnum.PAID:
            return 'success';
        case ApplicationPayoutStateEnum.UNPAID:
            return 'danger';
        case ApplicationPayoutStateEnum.WITHOUT_PAYMENT:
            return 'warning';
    }
    return '';
};

export const formatAppPayoutState = (
    state: ApplicationPayoutStateEnum
): string => {
    switch (state) {
        case ApplicationPayoutStateEnum.PAID:
            return 'Vyplaceno';
        case ApplicationPayoutStateEnum.UNPAID:
            return 'Nevyplaceno';
        case ApplicationPayoutStateEnum.WITHOUT_PAYMENT:
            return 'Bez výplaty';
    }
    return '';
};

export const formatPayoutState = (state: PayoutStateEnum): string => {
    switch (state) {
        case PayoutStateEnum.PENDING:
            return 'Čekající';
        case PayoutStateEnum.PAID:
            return 'Vyplaceno';
        case PayoutStateEnum.DISPATCH:
            return 'Zadáno';
        case PayoutStateEnum.ERROR:
            return 'Chyba';
        case PayoutStateEnum.ERROR_RESOLVED:
            return 'Chyba vyřešena';
    }
    return '';
};
export const formatPayoutTasks = (state: PayoutsTasks): string => {
    switch (state) {
        case PayoutsTasks.ERROR_RESOLVED:
            return 'Vyřešení chyby';
    }
    return '';
};

export const formatEscalationButton = (
    esc: TaskEscalation,
    defaultValue: string
): string => {
    const escCode = esc?.escalation?.escalationCode;

    switch (escCode) {
        case 'application-cancel':
            return 'Zrušit žádost';
        default:
            return defaultValue;
    }
};

export const formatAppState = (state: ApplicationStateEnum): string => {
    switch (state) {
        case ApplicationStateEnum.NEW:
            return 'Nová';
        case ApplicationStateEnum.RETURNED:
            return 'Vrácena k dopracování';
        case ApplicationStateEnum.APPROVED:
            return 'Schválena';
        case ApplicationStateEnum.CANCELLED:
            return 'Zrušena';
        case ApplicationStateEnum.FOR_APPROVAL:
            return 'Ke schválení';
        case ApplicationStateEnum.DISAPPROVED:
            return 'Neschválena';
        case ApplicationStateEnum.FOR_APPROVAL_RETURNED:
            return 'Ke schválení po opravě';
    }
};

export const formatAmount = (value: number) => {
    if (!value) return '';
    const parts = value.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return parts.join(',') + ' Kč';
};

export const formatCamundaUserId = (
    value: UserId,
    camundaUserMap: { [key: UserId]: CamundaUser }
) => {
    const user = camundaUserMap[value];
    return formatCamundaUser(user, value);
};

export const formatCamundaUser = (
    user: CamundaUser,
    defaultValue: string = '',
    useId: boolean = false
) => {
    return user
        ? `${user.firstName} ${user.lastName}${useId ? ` (${user.id})` : ''}`
        : defaultValue;
};

export const formatTokenError = (err: TokenError): string => {
    switch (err) {
        case TokenError.CANT_GET_TOKENS:
            return 'Problém při načítání přihlašovacích údajů z ID Portu.';
        case TokenError.REFRESH_EXPIRED:
            return 'Vypršela platnost uložených přihlašovacích údajů.';
        case TokenError.NO_ID_TOKEN:
            return 'Neobdržen id token.';
        case TokenError.NO_REFRESH_TOKEN:
            return 'Neobdržen refresh token.';
        case TokenError.NO_USERNAME:
            return 'Neobdržen login uživatele z id portu.';
        case TokenError.NO_ID_EXP:
            return 'Id token neobsahuje data o expiraci.';
        case TokenError.NO_REFRESH_EXP:
            return 'Refresh token neobsahuje data o expiraci.';
        case TokenError.WRONG_STORED_FORMAT:
            return 'Došlo ke změně formátu a uložené přihlašovací údaje neodpovídají aktuální struktuře, prosím přihlaste se znovu.';
    }
};
export const TrueFalseFormat = (status) => {
    return status ? 'Ano' : 'Ne';
};

window['formatAmount'] = formatAmount;
