import React from 'react'
import './ErrorBoundary.scss'
import { Collapse } from 'react-bootstrap';
import {MyButton} from '../button/MyButton';

type ErrorBoundaryState = {
    error: Error,
    open: boolean
}
class ErrorBoundary extends React.Component<{children: React.ReactNode}, ErrorBoundaryState> {
    constructor(props) {
        super(props);
        this.state = { error: null, open: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { error };
    }

    // componentDidCatch(error, errorInfo) {
    //     // You can also log the error to an error reporting service
    // }

    render() {
        if (this.state.error) {
            // You can render any custom fallback UI
            return (
                <div className="ErrorBoundary p-3">
                        <h5 className="text-danger">V aplikaci došlo k neočekávané chybě. </h5>
                        Prosím kontaktujte podporu. <br />
                        <MyButton
                            size="sm"
                            onClick={() => this.setState({open: !this.state.open})}
                            aria-controls="example-collapse-text"
                            aria-expanded={this.state.open}>
                            Detail
                        </MyButton>
                        <Collapse in={this.state.open}>
                            <p>
                                {this.state.error.stack}
                            </p>
                        </Collapse>
                </div>
            )
        }

        return this.props.children;
    }
}

export default ErrorBoundary;