import React from 'react';
import {Col, Container, Form, Row} from 'react-bootstrap';
import Header from '../../common/header/Header';
import {ArrowRightCircle, Backspace} from 'react-bootstrap-icons';
import MainContent from '../../common/content/MainContent';
import {TEXTS} from '../../../constants/texts';
import {NAV} from '../../../RouteList';
import {RequiredLabel} from '../../../components/form/RequiredLabel';
import {FormError} from '../../../components/form/FormError';
import {useForm} from 'react-hook-form';
import {MyButton} from '../../../components/button/MyButton';
import {required} from '../../../components/form/formValidations';
import {RootState, useAppDispatch, useAppSelector} from '../../../store/store';
import {processInstancesModify} from '../../../store/slices/administration/administrationSlice';
import {ProcessInstanceModifyInstruction} from '../../../services/entities/ProcessInstanceModifyInput';
import {LoadingIndicator} from '../../../components/loadingIndicator/LoadingIndicator';
import {addNotification} from '../../../store/slices/common/notificationsSlice';

type ProcessInstanceModificationFormData = {
    ids2remove: string,
    ids2add: string,
    processInstanceIds: string
}

const defaultFormData = {
    processInstanceIds: "",
    ids2remove: "",
    ids2add: ""
}

function ProcessInstanceModificationPage() {
    const dispatch = useAppDispatch()
    const modifyState = useAppSelector((s: RootState) => s.administration.modify)
    const {register, reset, handleSubmit, formState: {errors}} = useForm<ProcessInstanceModificationFormData>({
        defaultValues: defaultFormData
    });

    const onSubmit = (values: ProcessInstanceModificationFormData) => {
        const items2add: ProcessInstanceModifyInstruction[] = values.ids2add.split(',').map(el => ({
            type: "STARTBEFOREACTIVITY",
            activityId: el.trim(),
        }))
        const items2remove: ProcessInstanceModifyInstruction[] = values.ids2remove.split(',').map(el => ({
            type: "CANCEL",
            activityId: el.trim()
        }))
        const processInstanceIds = values.processInstanceIds.split(',').map(el => el.trim())

        dispatch(processInstancesModify({
            processInstanceIds,
            skipCustomListeners: true,
            skipIoMappings: true,
            instructions: [
                ...items2add,
                ...items2remove
            ]
        })).then(() => {
            reset(defaultFormData)
            dispatch(addNotification({
                text: 'Modifikace procesních instancí úspěšně provedena.',
                variant: 'success',
                timeout: 10000
            }))
        }, () => {
            dispatch(addNotification({
                text: 'Vyskytl se problém při modifikaci procesních instancí.',
                variant: 'danger',
                timeout: 10000
            }))
        })
    }

    return (
        <div>
            <Header className="mb-3"
                    actions={[
                        {
                            title: TEXTS.back,
                            icon: <Backspace/>,
                            to: NAV.INDEX
                        }
                    ]}/>

            <MainContent className="pt-5">

                {
                    modifyState.pending && <LoadingIndicator />
                }

                <Container fluid="xxl">
                    <Form className="ApplicationForm" onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group className="mb-3">
                            <RequiredLabel>Id procesních instancí</RequiredLabel>
                            <Form.Control className="mw-100" as="textarea"  rows={5}
                                          {...register("processInstanceIds", {...required()})}
                                          placeholder="Zadejte id procesních instancí oddělených čárkou,
                                                    mezery a nové řádky se ignorují"/>
                            <FormError errors={errors} name="processInstanceIds"/>
                        </Form.Group>

                        <Row className="mb-3">
                            <Col xs={12} sm={6} className="mb-sm-0 mb-3">

                                <Form.Group className="me-3">
                                    <RequiredLabel>Aktivity, ze kterých bude token odstraněn</RequiredLabel>
                                    <Form.Control as="textarea"
                                                  rows={5}  {...register("ids2remove", {...required()})}
                                                  placeholder="Zadejte id aktivit oddělených čárkou,
                                                    mezery a nové řádky se ignorují"/>
                                    <FormError errors={errors} name="ids2remove"/>
                                </Form.Group>


                            </Col>
                            <Col xs={12} sm={6}>
                                <Form.Group>
                                    <RequiredLabel>Aktivity, před které bude token vložen</RequiredLabel>
                                    <Form.Control as="textarea" rows={5}  {...register("ids2add", {...required()})}
                                                  placeholder="Zadejte id aktivit oddělených čárkou,
                                                    mezery a nové řádky se ignorují"/>
                                    <FormError errors={errors} name="ids2add"/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col align="end">
                                <MyButton type="submit">
                                    <ArrowRightCircle className="me-2"/>Modifikovat
                                </MyButton>
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </MainContent>
        </div>
    );
}

export default ProcessInstanceModificationPage;
