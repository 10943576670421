import './services/init';

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import RouteList from "./RouteList";
import reportWebVitals from './reportWebVitals';
import dayjs from 'dayjs';
import {store} from './store/store';
import { Provider } from 'react-redux';

//config dayjs - todo into separate file
const customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <RouteList />
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
