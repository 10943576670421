import './MainContent.scss'
import React from 'react'
import clsx from 'clsx'

export type MainContentProps = {
    children: React.ReactNode,
    className?: string
}
function MainContent({children, className}: MainContentProps) {
    return (
        <div className={clsx('MainContent position-relative h-100', className)}>
            {children}
        </div>
    )


}

export default MainContent;
