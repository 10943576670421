import { ApplicationTask } from './ApplicationProcess';

export const MAX_PAYOUT_AMOUNT = 999999999;

export const getDuplicates = (payouts: PayoutInternal[]): PayoutInternal[] => {
    return payouts && payouts.filter((p) => p.hasDuplicate);
};

export type PayoutId = string | number;

export type KODZI = number;

export type PayoutDuplicate = {
    payoutId: number;
    applicationId: number;
};
export type PayoutWrapper = {
    payout: Payout;
    tasks?: ApplicationTask[];
    hasDuplicate?: boolean;
    golemError?: string;
    duplicates?: PayoutDuplicate[];
};

export interface Payout {
    id?: PayoutId;
    recipientId: KODZI;
    amount: number | string;
    fromDate: string;
    toDate: string;
    productType: ProductTypeEnum;
    dueDate?: Date;
    payoutProcessInstanceId?: string;
    state?: PayoutStateEnum;
    recipientName: string;
    paymentType: string;
    businessType: number;
    businessDescription: string;
    brokerValid: boolean;
    brokerExists: boolean;
    brokerFetchError: boolean;
    //hromadna zadost
    titleId?: string;
    agreementNumber?: string;
    title?: {
        id?: string;

        agreementNumber?: string;
    };
    deposit?: boolean;
    filterByCode?: string;
    filterByName?: string;
}

export interface PayoutInternal extends Payout{
    isNew?: boolean,
    tasks?: ApplicationTask[],
    hasDuplicate?: boolean,
    golemError?: string,
    duplicates?: PayoutDuplicate[]
}

export enum ProductTypeEnum {
    N = 'N',
    Z = 'Z',
    O = 'O',
    H = 'H',
    F = 'F',
    M = 'M',
    P = 'P',
    C = 'C',
    R = 'R',
    B = 'B',
    I = 'I',
}
export const ProductTypeNames = {
    [ProductTypeEnum.N]: 'N = neživot (vše)',
    [ProductTypeEnum.Z]: 'Z = život (vše)',
    [ProductTypeEnum.O]: 'O = auta (POV)',
    [ProductTypeEnum.H]: 'H = auta (HAV)',
    [ProductTypeEnum.F]: 'F = auta (vše)',
    [ProductTypeEnum.M]: 'M = majetek',
    [ProductTypeEnum.P]: 'P = podnikatel',
    [ProductTypeEnum.C]: 'C = cestovní',
    [ProductTypeEnum.R]: 'R = retail neživot',
    [ProductTypeEnum.B]: 'B = standardní podnikatelé (PMJ)',
    [ProductTypeEnum.I]: 'I = riziko osob',
};

export enum PayoutStateEnum {
    PENDING = 'PENDING',
    DISPATCH = 'DISPATCH',
    PAID = 'PAID',
    ERROR = 'ERROR',
    ERROR_RESOLVED = 'ERROR_RESOLVED',
}

export type TribesData = {
    code: string;
    description: string;
    validity: boolean;
};
