import React from "react";
import {Popover, OverlayTrigger, Button} from 'react-bootstrap';

export type ConfirmationProps = {
    title: string,
    message: React.ReactNode,
    confirmText: string,
    cancelText?: string,
    onConfirm: (confirmParams: any) => void,
    children,
    confirmParams?: any,
    className?: string,
    disabled?: boolean
}
export default function Confirmation({
                                         title, message, confirmText, className, cancelText = 'Zrušit',
                                         onConfirm, children, disabled, confirmParams
                                     }: ConfirmationProps) {
    const handleConfirm = () => {
        onConfirm && onConfirm(confirmParams)
        handleClose()
    }

    const handleClose = () => document.body.click();

    const popover = (
        <Popover id="popover-confirmation" className={className}>
            <Popover.Header as="h3">{title}</Popover.Header>
            <Popover.Body>
                {message}
                <div className='d-flex flex-column gap-2 mt-3 w-100'>
                    <Button
                        variant='warning'
                        onClick={handleConfirm}>
                        {confirmText}
                    </Button>

                    <Button
                        variant='secondary'
                        onClick={handleClose}>
                        {cancelText}
                    </Button>
                </div>
            </Popover.Body>
        </Popover>
    );

    return disabled ? children :
        (
            <OverlayTrigger
                trigger="click" placement="auto"
                overlay={popover}
                rootClose>
                <div className="d-inline-block">
                    {children}
                </div>
            </OverlayTrigger>
        )
}