import {CUSTOM_SERVICE_API_URL, SERVICE_API_URL} from '../constants';
import {
    ApplicationProcess,
    ApplicationTask,
    DiagramData,
    IdentityLink,
    ProcessDefinitionId,
    ProcessInstanceId,
    TaskEscalation,
    TaskId
} from '../entities/ApplicationProcess';
import {apiConfig, takeLatestController} from './apiUtils';
import {CamundaUser, UserId} from "../entities/User";
import {formatCamundaUser} from "../../utils/formatUtils";
import {fetchMethods} from "../fetchUtils";

const takeLatest = takeLatestController()

export const createApplication = (
    token,
    multiApplication
): Promise<ApplicationProcess> => {
    const url = `${SERVICE_API_URL}/process-definition/key/mp-application/start`;
    return fetchMethods.post(
        url,
        {
            variables: {
                multiApplication: { value: multiApplication, type: 'Boolean' },
            },
        },
        token
    );
};

export const getApplicationTasks = (processInstanceId: string, token: string): Promise<ApplicationTask[]> => {
    const url = `${SERVICE_API_URL}/task?processInstanceId=${processInstanceId}`;
    let signal = takeLatest();
    return fetchMethods.get(url, token, undefined, signal).then((response) => {
        return response || [];
    });
}

export const getProcessDefinitionId = (processInstanceId: string, token: string): Promise<ProcessDefinitionId> => {
    const url = `${SERVICE_API_URL}/process-instance/${processInstanceId}`;
    return fetchMethods.get(url, token).then((response) => {
        return response.definitionId
    });
}

export const finishTask = (taskId: string, token: string): Promise<any> => {
    const url = `${SERVICE_API_URL}/task/${taskId}/complete`;
    return fetchMethods.post(url, '', token)
}

export const finishEscalation = (taskId: string, escalationCode: string, token: string): Promise<any> => {
    const url = `${SERVICE_API_URL}/task/${taskId}/bpmnEscalation`;
    const body = {escalationCode}
    return fetchMethods.post(url, body, token)
}

export const getAppTaskActions = (processDefinitionId: string,
                                  elementId: string,
                                  token: string): Promise<TaskEscalation[]> => {
    const url = `${CUSTOM_SERVICE_API_URL}/${processDefinitionId}/${elementId}/actions`;
    return fetchMethods.get(url, token)
}

export const claimTask = (taskId: string, userId: string, token: string) => {
    const url = `${SERVICE_API_URL}/task/${taskId}/claim`;
    const body = {userId}
    return fetchMethods.post(url, body, token)
}

export const unClaimTask = (taskId: string, token: string) => {
    const url = `${SERVICE_API_URL}/task/${taskId}/unclaim`;
    return fetchMethods.post(url, '', token)
}

export const fetchAppProcessDiagram = (token: string): Promise<DiagramData> => {
    const url = `${SERVICE_API_URL}/process-definition/key/mp-application/xml`;
    return fetchMethods.get(url, token) //todo response type
}

export const fetchAppProcessDiagrams = (process: string, token: string): Promise<DiagramData> => {
    const url = `${SERVICE_API_URL}/process-definition/key/${process}/xml`;
    return fetchMethods.get(url, token) //todo response type
}

export const fetchProcessTasks = (processInstanceId: ProcessInstanceId, token: string): Promise<ApplicationTask[]> => {
    const url = `${SERVICE_API_URL}/task?processInstanceId=${processInstanceId}`;
    return fetchMethods.get(url, token)
}

export const fetchProcessTaskById = (taskId: TaskId, token: string): Promise<ApplicationTask[]> => {
    const url = `${SERVICE_API_URL}/task?taskId=${taskId}`;
    return fetchMethods.get(url, token)
}

export const fetchTaskIdentityLinks = (taskId: TaskId, token: string): Promise<IdentityLink[]> => {
    const url = `${SERVICE_API_URL}/task/${taskId}/identity-links`;
    // return fetchMethods.get(url, token)

    return fetch(url, {
        method: 'GET',
        ...apiConfig(token)
    }).then(r => {
        try {
            return r.json()
        } catch(e) {
            return []
        }
    })
}

export const fetchCamundaUsersByIds = (userIds: UserId[], token: string): Promise<CamundaUser[]> => {
    const url = `${SERVICE_API_URL}/user/?idIn=${userIds.join(',')}`;
    return fetchMethods.get(url, token)
}

export const fetchCamundaUserByLastname = (query: string, token: string): Promise<CamundaUser[]> => {
    const url = `${SERVICE_API_URL}/user/?lastNameLike=${query}`;
    return fetchMethods.get(url, token).then((response) => {
        return response?.map(el => ({...el, label: formatCamundaUser(el, '', true)}))
    });
}