import {Form} from 'react-bootstrap';
import {useForm, useWatch} from 'react-hook-form';
import clsx from 'clsx';
import {Payout, TribesData} from '../../../services/entities/Payout';
import {RequiredLabel} from '../../../components/form/RequiredLabel';
import {FormError} from '../../../components/form/FormError';
import { useContext, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import {
    ApplicationTypeEnumKoop,
    ApplicationWrapper,
} from '../../../services/entities/Application';
import { dateToInput, formatServerDate } from '../../../utils/formatUtils';
// import * as yup from 'yup';
import {
    minLength,
    required,
    validateKodzi,
    validatePayoutAmount,
} from '../../../components/form/formValidations';
import { AppFormContext } from '../application-detail';
import { validateDate } from '../../../utils/validateDate';
import {
    setBrokerCodes,
    setTribe,
    setSelectedType,
    titleRegisterListTitleFilterFetch,
} from '../../../store/slices/applicationDetail/applicationSlice';
import { ApiData } from '../../../store/storeApiUtils';
import { MpTitle } from '../../../services/entities/TitleRegister';
import { LoadingIndicator } from '../../../components/loadingIndicator/LoadingIndicator';

type NewPayoutProps = {
    onSave: (payout: Payout) => void;
    payout?: Payout;
};

function NewPayoutFormKoop({ onSave, payout }: NewPayoutProps) {
    const dispatch = useAppDispatch();
    const multiApplication = useAppSelector(
        (state) =>
            state?.applicationDetail?.application?.application?.data
                ?.application?.multiApplication
    );

    const application: ApiData<ApplicationWrapper> = useAppSelector(
        (s) => s.applicationDetail.application.application
    );
    const { brokerCodes, tribe } = useAppSelector(
        (s) => s.applicationDetail.application
    );

    const appFormLogic = useContext(AppFormContext);
    const appType: ApplicationTypeEnumKoop = useWatch({
        name: 'type',
        control: appFormLogic.control,
    }) as ApplicationTypeEnumKoop;
    const {
        register,
        handleSubmit,
        control,
        reset,
        formState,
        getFieldState,
        setValue,
        formState: { errors },
    } = useForm<Payout>({
        mode: 'onChange',
        defaultValues: {
            dueDate: dateToInput(application.data.application.created),
        },
    });
    const amount = useWatch({ name: 'amount', control });
    const amountProperNumFormat = Number(amount?.toString().replace(',', '.'));

    const onSubmit = (data: Payout) => {
        //pro hromande zadosti
        if (multiApplication && appType !== ApplicationTypeEnumKoop.OST) {
            dispatch(titleRegisterListTitleFilterFetch({ titleType: appType }));
        }
        //vzdy
        onSave({ ...data, amount: amountProperNumFormat });
    };
    const { tribes } = useAppSelector(
        (state) => state.applicationDetail.payouts
    );
    useEffect(() => {
        if (payout) reset(payout);
    }, [reset, payout]);

    //pro hromadne zadosti

    const applicationTitle = application.data.application.title;

    const { titleRegisterDataFilter, recordCount, pagination, loading } =
        useAppSelector(
            (state) => state.applicationDetail.application.mpTitlesData
        );

    const filterByCodeWatch = useWatch({ name: 'filterByCode', control });
    const filterByNameWatch = useWatch({ name: 'filterByName', control });

    const handleFilterByCode = (e) => {
        if (multiApplication && appType !== ApplicationTypeEnumKoop.OST) {
            setValue('filterByCode', e.target.value);
            setTimeout(() => {
                if (appType && !errors.filterByCode) {
                    dispatch(
                        titleRegisterListTitleFilterFetch({
                            titleType: appType,
                            brokerCode: e.target.value,
                            name: filterByNameWatch,
                        })
                    );
                }
            }, 500);
        }
    };
    useEffect(() => {
        if (
            multiApplication &&
            appType !== ApplicationTypeEnumKoop.OST &&
            appType
        )
            dispatch(titleRegisterListTitleFilterFetch({ titleType: appType }));
    }, [dispatch, appType, multiApplication]);

    const titlesAll = recordCount;
    const titlesShown = Math.min(pagination.pageSize, recordCount);
    const handleFilterByName = (e) => {
        if (multiApplication) {
            setValue('filterByName', e.target.value);
            setTimeout(() => {
                if (appType) {
                    dispatch(
                        titleRegisterListTitleFilterFetch({
                            titleType: appType,
                            brokerCode: filterByCodeWatch,
                            name: e.target.value,
                        })
                    );
                }
            }, 500);
        }
    };

    const titleIdWatch = useWatch({
        name: 'agreementNumber',
        control,
    });

    const selectedTitle: MpTitle = titleRegisterDataFilter?.find(
        (title) =>
            title.agreementNumber === titleIdWatch ||
            title.agreementNumber === payout?.agreementNumber ||
            title.id === +payout?.title?.id
    );

    const titleSelectInputDefault =
        (!recordCount || recordCount === 0) && appType
            ? 'Nenalezeny žádné tituly'
            : !appType
            ? 'Nejprve vyberte typ provize'
            : 'Vyberte titul';

    const filterByCodeFieldState = getFieldState('filterByCode', formState);
    const filterByNameFieldState = getFieldState('filterByName', formState);
    const dirtyFieldsCheck =
        filterByCodeFieldState.isDirty || filterByNameFieldState.isDirty;
    const titleSelectInputStored = `${applicationTitle?.agreementNumber} (${applicationTitle?.evidenceNumber}`;

    useEffect(() => {
        if (multiApplication && appType !== ApplicationTypeEnumKoop.OST) {
            dispatch(setSelectedType(appType));
            dispatch(setBrokerCodes(selectedTitle?.brokerCode));

            dispatch(setTribe(selectedTitle?.tribe));
        }
    }, [
        dispatch,
        selectedTitle?.brokerCode,
        appType,
        selectedTitle?.tribe,
        multiApplication,
    ]);

    useEffect(() => {
        if (
            multiApplication &&
            appType !== ApplicationTypeEnumKoop.OST &&
            payout
        ) {
            setValue('filterByName', payout?.agreementNumber);
            setTimeout(() => {
                if (appType) {
                    dispatch(
                        titleRegisterListTitleFilterFetch({
                            titleType: appType,
                            brokerCode: filterByCodeWatch,
                            name: payout?.agreementNumber,
                        })
                    ).then(() => {
                        setValue('recipientId', payout?.recipientId);
                        setValue('productType', payout?.productType);
                    });
                }
            }, 500);
        }
    }, [
        payout,
        appType,
        dispatch,
        filterByCodeWatch,
        multiApplication,
        setValue,
    ]);

    useEffect(() => {
        if (multiApplication && appType !== ApplicationTypeEnumKoop.OST) {
            setValue(
                'agreementNumber',
                selectedTitle?.agreementNumber.toString()
            );
        }
    }, [appType, setValue, selectedTitle?.agreementNumber, multiApplication]);

    useEffect(() => {
        if (amount) setValue('amount', amount.toString().replace('.', ','));
    }, [amount, setValue]);

    return (
        <Form id="NewPayoutForm" onSubmit={handleSubmit(onSubmit)}>
            {loading && <LoadingIndicator />}
            <Form.Group className="d-flex flex-row mb-3 row">
                <Form.Group className="d-flex gap-3 mb-3 col">
                    {multiApplication &&
                        appType !== ApplicationTypeEnumKoop.OST && (
                            <div>
                                <Form.Group>
                                    <RequiredLabel>Titul</RequiredLabel>
                                    <Form.Label className="ms-2">
                                        {appType && recordCount
                                            ? `(Zobrazeno: ${titlesShown} z celkem: ${titlesAll})`
                                            : ''}
                                    </Form.Label>
                                    <Form.Select
                                        {...register('agreementNumber', {
                                            ...required(true),
                                        })}
                                        defaultValue=""
                                    >
                                        <option
                                            hidden
                                            value={
                                                applicationTitle &&
                                                !dirtyFieldsCheck
                                                    ? applicationTitle?.agreementNumber?.toString()
                                                    : ''
                                            }
                                        >
                                            {applicationTitle &&
                                            !dirtyFieldsCheck
                                                ? titleSelectInputStored
                                                : titleSelectInputDefault}
                                        </option>
                                        {appType &&
                                            titleRegisterDataFilter?.map(
                                                (title, i) => {
                                                    if (
                                                        i >= 0 &&
                                                        i < pagination.pageSize
                                                    ) {
                                                        return (
                                                            <option
                                                                key={
                                                                    title.agreementNumber
                                                                }
                                                                value={
                                                                    title.agreementNumber
                                                                }
                                                            >{`${title.agreementNumber} (${title.evidenceNumber})`}</option>
                                                        );
                                                    } else if (
                                                        i ===
                                                        pagination.pageSize
                                                    ) {
                                                        return (
                                                            <option
                                                                disabled
                                                            >{`+ dalších ${
                                                                recordCount -
                                                                pagination.pageSize
                                                            } titulů`}</option>
                                                        );
                                                    }
                                                    return null;
                                                }
                                            )}
                                    </Form.Select>
                                    <FormError
                                        errors={errors}
                                        name="agreementNumber"
                                    />
                                </Form.Group>
                            </div>
                        )}
                    {multiApplication &&
                        appType !== ApplicationTypeEnumKoop.OST && (
                            <div className="ms-3">
                                <Form.Label>Filtrovat tituly podle:</Form.Label>
                                <div className="d-flex">
                                    {appType !== ApplicationTypeEnumKoop.STZ &&
                                        appType !==
                                            ApplicationTypeEnumKoop.USP && (
                                            <Form.Group className="pb-2">
                                                <Form.Control
                                                    placeholder="Čísla získatele"
                                                    type="text"
                                                    {...register(
                                                        'filterByCode',
                                                        {
                                                            ...required(false),
                                                            onChange: (
                                                                event
                                                            ) => {
                                                                handleFilterByCode(
                                                                    event
                                                                );
                                                            },
                                                            ...minLength(3),
                                                        }
                                                    )}
                                                />
                                                <FormError
                                                    errors={errors}
                                                    name="filterByCode"
                                                />
                                            </Form.Group>
                                        )}
                                    <Form.Group className="pb-2 ms-1">
                                        <Form.Control
                                            placeholder="Čísla smlouvy (Titulu ID)"
                                            type="text"
                                            {...register('filterByName', {
                                                onChange: (event) => {
                                                    handleFilterByName(event);
                                                },
                                            })}
                                        />
                                    </Form.Group>
                                </div>
                            </div>
                        )}
                </Form.Group>
                <div className="row">
                    <Form.Group className="me-3 col">
                        <RequiredLabel>Získatelské číslo</RequiredLabel>
                        {brokerCodes === null ||
                        appType === ApplicationTypeEnumKoop.OST ? (
                            <div>
                                <Form.Control
                                    className="small-input"
                                    size="sm"
                                    placeholder="Zadejte hodnotu"
                                    disabled={
                                        multiApplication &&
                                        !titleIdWatch &&
                                        appType !== ApplicationTypeEnumKoop.OST
                                    }
                                    {...register('recipientId', {
                                        validate: {
                                            ...validateKodzi(),
                                        },
                                        ...required(),
                                    })}
                                />
                                <FormError
                                    className="small-input"
                                    errors={errors}
                                    name="recipientId"
                                />
                            </div>
                        ) : (
                            <div>
                                <Form.Select
                                    {...register('recipientId', {
                                        ...required(),
                                    })}
                                    size="sm"
                                    defaultValue={null}
                                    className={clsx('small-input')}
                                    disabled={multiApplication && !titleIdWatch}
                                >
                                    <option value="" hidden>
                                        Vyberte číslo získatele
                                    </option>
                                    {brokerCodes
                                        ?.split(';')
                                        ?.map((code, index) => (
                                            <option key={index} value={code}>
                                                {code}
                                            </option>
                                        ))}
                                </Form.Select>
                                <FormError errors={errors} name="recipientId" />
                            </div>
                        )}
                    </Form.Group>

                    <Form.Group className="me-3 col">
                        <RequiredLabel>Částka</RequiredLabel>
                        <Form.Control
                            className="small-input no-input-arrows"
                            size="sm"
                            type="text"
                            step="any"
                            placeholder="Zadejte hodnotu"
                            disabled={
                                multiApplication &&
                                !titleIdWatch &&
                                appType !== ApplicationTypeEnumKoop.OST
                            }
                            {...register('amount', {
                                valueAsNumber: false,
                                ...required(),

                                validate: {
                                    ...validatePayoutAmount(),
                                },
                            })}
                        />
                        <FormError errors={errors} name="amount" />
                    </Form.Group>

                    <Form.Group className="me-3 col">
                        <RequiredLabel>Kmen</RequiredLabel>
                        <Form.Select
                            {...register('productType', { ...required() })}
                            size="sm"
                            defaultValue={null}
                            className={clsx('small-input')}
                            disabled={
                                multiApplication &&
                                !titleIdWatch &&
                                appType !== ApplicationTypeEnumKoop.OST
                            }
                        >
                            <option value="" hidden>
                                Vyberte kmen
                            </option>
                            {appType === ApplicationTypeEnumKoop.OST ||
                            appType === ApplicationTypeEnumKoop.USP
                                ? tribes.map((entry) => (
                                      <option
                                          key={entry.code}
                                          value={entry.code}
                                      >
                                          {entry.code} = {entry.description}
                                      </option>
                                  ))
                                : tribes
                                      .filter((el: TribesData) => {
                                          return tribe?.split(';').some((e) => {
                                              return el.code === e;
                                          });
                                      })
                                      .map((tribe) => {
                                          return (
                                              <option
                                                  key={tribe.code}
                                                  value={tribe.code}
                                              >
                                                  {tribe.code} ={' '}
                                                  {tribe.description}
                                              </option>
                                          );
                                      })}
                        </Form.Select>
                        <FormError errors={errors} name="productType" />
                    </Form.Group>

                    <Form.Group className="col">
                        <RequiredLabel>Nevyplatit před</RequiredLabel>
                        <Form.Control
                            size="sm"
                            className="small-input"
                            type="date"
                            placeholder="Zadejte datum"
                            min={formatServerDate(new Date())}
                            {...register('dueDate', {
                                ...required(),
                                validate: validateDate,
                            })}
                        />
                        <FormError errors={errors} name="dueDate" />
                    </Form.Group>
                </div>
            </Form.Group>
        </Form>
    );
}

export default NewPayoutFormKoop;
