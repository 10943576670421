import { useEffect } from 'react';
import { Alert, Form, Modal } from 'react-bootstrap';
import { useForm, useWatch } from 'react-hook-form';
import { TEXTS } from '../../../constants/texts';
import { MyButton } from '../../../components/button/MyButton';
import { required } from '../../../components/form/formValidations';
import TitleRegisterImportErrorAlert from './title-register-import-error-alert';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { addSuccess } from '../../../store/slices/common/notificationsSlice';
import { FormError } from '../../../components/form/FormError';
import { titleRegisterImportFetch } from '../../../store/slices/titleRegister/titleRegisterImportSlice';
import { DataImportType } from '../../../services/api/titleRegisterImportApi';
import { LoadingIndicator } from '../../../components/loadingIndicator/LoadingIndicator';
import {
    setSelectedTitleRegister,
    titleRegisterListFetch,
} from '../../../store/slices/titleRegister/titleRegisterSlice';
import {
    initialValues,
    TitleStructureEnum,
} from '../../../services/entities/TitleRegister';
import { separateExtension } from '../../../utils/fileUtils';

type ImportFormProps = {
    show: boolean;
    onHide: () => void;
};

function ImportFormModal({ show, onHide }: ImportFormProps) {
    const dispatch = useAppDispatch();
    // const [nonCsv, setNonCsv] = useState(false);
    const {
        data,
        loading,
        errors: errorsData,
    } = useAppSelector((state) => state.titleRegisterImport);
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        getValues,
    } = useForm({ mode: 'onChange' });

    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        if (data?.status === 'SUCCESS') {
            dispatch(addSuccess('Import titulů proběhl úspěšně'));
            dispatch(
                setSelectedTitleRegister(
                    data.titleStructureType === 'MOT'
                        ? 'STZ'
                        : data.titleStructureType
                )
            );

            dispatch(
                titleRegisterListFetch({
                    type: TitleStructureEnum.DMP,
                    data: { ...initialValues[TitleStructureEnum.DMP] },
                })
            );

            dispatch(
                titleRegisterListFetch({
                    type: TitleStructureEnum.STZ,
                    data: { ...initialValues[TitleStructureEnum.STZ] },
                })
            );

            dispatch(
                titleRegisterListFetch({
                    type: TitleStructureEnum.USP,
                    data: { ...initialValues[TitleStructureEnum.USP] },
                })
            );
        } else {
            setShowAlert(true);
        }
    }, [data, dispatch]);

    const watchFileList: FileList = useWatch({ control, name: 'file' });
    const file = watchFileList?.length ? watchFileList[0] : null;
    const [extensionValid, setExtensionValid] = useState(true);
    // const validateCsv = (e: any) => {
    //     if (!isCsv(e.target.value.split('\\').pop())) {
    //         setNonCsv(true);
    //     } else {
    //         setNonCsv(false);
    //     }
    // };
    const validateExtension = (extension) => {
        return extension && /^(csv)$/i.test(extension);
    };

    useEffect(() => {
        if (file) {
            const parts = separateExtension(file.name);
            setExtensionValid(validateExtension(parts[1]));
        } else {
            setExtensionValid(false);
        }
    }, [file]);

    useEffect(() => {
        setExtensionValid(true);
    }, []);

    const onSubmit = () => {
        if (!extensionValid) return;
        const payload: DataImportType = {
            file: getValues('file'),
        };
        dispatch(titleRegisterImportFetch(payload));
        onHide();
    };

    const onClose = () => {
        onHide();
        setExtensionValid(true);
    };

    if (loading) return <LoadingIndicator />;
    return (
        <div>
            {data?.status === 'WARNING' ? (
                <TitleRegisterImportErrorAlert
                    variant="warning"
                    title={'Varování při importu souboru titulů'}
                    content={[data.message]}
                    show={showAlert}
                    setShowAlert={setShowAlert}
                    file={getValues('file')}
                />
            ) : (
                data?.status === 'ERROR' && (
                    <TitleRegisterImportErrorAlert
                        variant="danger"
                        title={'Chyba při importu souboru titulů'}
                        content={errorsData}
                        show={showAlert}
                        setShowAlert={setShowAlert}
                    />
                )
            )}
            <Modal show={show} onHide={onClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title as="h5">Importovat tituly</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p className="mb-4">
                        Pro aktualizaci evidence titulů prosím nahrajte soubor
                        ve stanovené struktuře a formátu CSV UTF-8. Upozornění:
                        Veškerá data v aktuální evidenci budou přepsána.
                    </p>
                    <Form id="ImportForm" onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group className="mb-3">
                            <Form.Control
                                size="sm"
                                type="file"
                                accept=".csv"
                                placeholder="Vyberte soubor"
                                {...register('file', { ...required() })}
                                // onChange={(e) => {
                                //     validateCsv(e);
                                // }}
                            />
                            <FormError errors={errors} name="file" />
                            {!extensionValid && (
                                <Alert
                                    variant="danger"
                                    className="w-100 p-2 my-2"
                                >
                                    <Alert.Heading>
                                        Chyby při načítání/zpracování souboru.
                                    </Alert.Heading>
                                    <p>
                                        <strong>
                                            {' '}
                                            Dokud nedojde k opravě, tak nebude
                                            umožněn import souboru.
                                        </strong>
                                    </p>
                                    Importovaný soubor musí mít příponu .csv
                                </Alert>
                            )}
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <MyButton variant="secondary" onClick={onClose}>
                        {TEXTS.close}
                    </MyButton>
                    <MyButton
                        type="submit"
                        form="ImportForm"
                        variant="primary"
                        disabled={!extensionValid}
                    >
                        {TEXTS.send}
                    </MyButton>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ImportFormModal;
