import {
    configureStore,
    isRejected,
    Middleware,
    MiddlewareAPI,
} from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import applicationListSlice, {
    ApplicationListState,
} from './slices/applicationList/applicationListSlice';
import payoutsSlice, {
    PayoutsState,
} from './slices/applicationDetail/payoutsSlice';
import applicationSlice, {
    ApplicationState,
} from './slices/applicationDetail/applicationSlice';
import logger from 'redux-logger';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import userSlice, { UserState } from './slices/common/userSlice';
import commentsSlice, {
    CommentsState,
} from './slices/applicationDetail/commentsSlice';
import attachmentsSlice, {
    AttachmentsState,
} from './slices/applicationDetail/attachmentsSlice';
import applicationListFilterSlice, {
    ApplicationCustomFilterState,
} from './slices/applicationList/applicationListFilterSlice';
import notificationsSlice, {
    addDanger,
    NotificationsState,
} from './slices/common/notificationsSlice';
import { useCallback } from 'react';
import { isPromise } from '../utils/utils';
import { AxiosError } from 'axios';
import diagramSlice, { DiagramState } from './slices/diagram/diagramSlice';
import applicationLogsSlice, {
    ApplicationLogsState,
} from './slices/applicationDetail/applicationLogsSlice';
import administrationSlice, {
    AdministrationState,
} from './slices/administration/administrationSlice';
import brokerSlice, {
    BrokerState,
} from './slices/applicationDetail/brokerSlice';
import payoutDetailSlice, {
    PayoutDetailState,
} from './slices/payoutDetail/payoutDetailSlice';
import camundaUsersSlice, {
    CamundaUsersState,
} from './slices/applicationDetail/camundaUsersSlice';
import titleRegisterSlice, {
    TitleRegisterState,
} from './slices/titleRegister/titleRegisterSlice';
import titleRegisterFilterSlice, {
    TitleRegisterFilterState,
} from './slices/titleRegister/titleRegisterFilterSlice';
import titleRegisterImportSlice, {
    TitleRegisterImportState,
} from './slices/titleRegister/titleRegisterImportSlice';
import applicationNewHistorySlice, { NewHistoryState } from './slices/applicationDetail/applicationNewHistorySlice';
import payoutsListSlice, {
    PayoutsListState,
} from './slices/payoutsList/payoutsListSlice';
import payoutsListFilterSlice, {
    PayoutsListFilterState,
} from './slices/payoutsList/payoutsListFilterSlice';

//TODO does to much things - move reducer content to another file (index?)

export const getServerErrorMsg = (error) => {
    return error.stack || error.message || error.toString();
};
const rtkQueryErrorLogger: Middleware =
    (api: MiddlewareAPI<AppDispatch>) => (next) => (action) => {
        if (
            isRejected(action) &&
            action.error instanceof AxiosError &&
            action.error.code !== AxiosError.ERR_CANCELED
        ) {
            api.dispatch(
                addDanger(
                    `Došlo k chybě, obraťte se na administrátora aplikace. \nDetail: \n${getServerErrorMsg(
                        action.error
                    )}`
                )
            );
        }
        return next(action);
    };

export const store = configureStore({
    reducer: combineReducers({
        user: userSlice,
        notifications: notificationsSlice,
        applicationList: applicationListSlice,
        applicationListFilter: applicationListFilterSlice,
        applicationDetail: combineReducers({
            payouts: payoutsSlice,
            application: applicationSlice,
            newHistory: applicationNewHistorySlice,
            comments: commentsSlice,
            attachments: attachmentsSlice,
            diagram: diagramSlice,
            logs: applicationLogsSlice,
            camundaUsers: camundaUsersSlice,
        }),
        broker: brokerSlice,
        administration: administrationSlice,
        payouDetail: payoutDetailSlice,
        payoutsList: payoutsListSlice,
        payoutsListFilter: payoutsListFilterSlice,
        titleRegister: titleRegisterSlice,
        titleRegisterFilter: titleRegisterFilterSlice,
        titleRegisterImport: titleRegisterImportSlice,
    }),
    middleware: (getDefaultMiddleware) => {
        const result = getDefaultMiddleware();

        if (process.env.NODE_ENV === 'development') result.push(logger);

        result.push(rtkQueryErrorLogger);

        return result;
    },
});

export type RootState = {
    user: UserState;
    notifications: NotificationsState;
    applicationList: ApplicationListState;
    applicationListFilter: ApplicationCustomFilterState;
    applicationDetail: {
        payouts: PayoutsState;
        application: ApplicationState;
        newHistory: NewHistoryState;
        comments: CommentsState;
        attachments: AttachmentsState;
        diagram: DiagramState;
        logs: ApplicationLogsState;
        camundaUsers: CamundaUsersState;
    };
    broker: BrokerState;
    administration: AdministrationState;
    payouDetail: PayoutDetailState;
    payoutsList: PayoutsListState;
    payoutsListFilter: PayoutsListFilterState;
    titleRegister: TitleRegisterState;
    titleRegisterFilter: TitleRegisterFilterState;
    titleRegisterImport: TitleRegisterImportState;
};

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = () => {
    const dispatch = useDispatch();
    return useCallback(
        (action) => {
            const result = dispatch(action);
            if (isPromise(result)) {
                return result.then((resp) => {
                    if (isRejected(resp)) throw new Error(resp.error.message);
                    return resp;
                });
            }
            return result;
        },
        [dispatch]
    );
};

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
