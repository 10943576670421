import './CommentForm.scss';
import {Form} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {useEffect, useMemo} from 'react';
import {FormError} from '../../../components/form/FormError';
import {TEXTS} from '../../../constants/texts';
import {required} from '../../../components/form/formValidations';
import {isCommentRequired, RequiredCommentData} from '../../../services/entities/ApplicationProcess';
import {RequiredLabel} from '../../../components/form/RequiredLabel';

/*
TODO
 Povinné bude pouze v těchto případech:

V procesním kroku "Schválení žádosti", kdy Schvalovatel vrací žádost o MP zpět Zadavateli k opravě.
V procesním kroku "Schválení žádosti", kdy Schvalovatel neschválil žádost o MP.
V procesním kroku "Zajištění vrácení vyplacené, ale neschválené MP", kdy Zadavatel musí okomentovat jakým způsobem bylo zajištěno vrácení vyplacené, ale neschválené MP.
V procesním kroku "Vyřešení chyby zpracování výplaty", kdy Správce výplaty musí okomentovat způsob vyřešení chyby při zpracování výplaty v GOLEM.
 */

export type CommentFormData = {comment: string}
export type CommentFormProps = {
    title?: string,
    defaultData?: CommentFormData,
    requiredCommentData?: RequiredCommentData,
    onSave: (FormData) => void
}
function CommentForm({onSave, defaultData, requiredCommentData, title = 'Komentář k úkolu'}: CommentFormProps) {
    const {register, handleSubmit, reset, formState: { errors } } = useForm<CommentFormData>({
        defaultValues: defaultData
    });
    const requiredComment = useMemo(() => isCommentRequired(requiredCommentData), [requiredCommentData])


    useEffect(() => {
        reset(defaultData)
    }, [reset, defaultData])

    const titleEl = <>Komentář k akci <strong>{title}</strong></>

    return (
        <Form id='CommentForm' onSubmit={handleSubmit(onSave)}>
            <Form.Group className="mb-3">
                {
                    requiredComment ?
                        <RequiredLabel>{titleEl}</RequiredLabel> :
                        <Form.Label>{titleEl}</Form.Label>
                }
                <Form.Control as="textarea" rows={5}
                              {...register("comment", {...required(requiredComment) })}
                              placeholder={TEXTS.comment} />
                <FormError errors={errors} name="comment" />
            </Form.Group>
        </Form>
    )
}

export default CommentForm;
