import {ApiData, defaultApiData, setCustomData, setDataFail, setDataPending, setDataSuccess} from '../../storeApiUtils';
import {ApplicationTask, ProcessDefinitionId, TaskId} from '../../../services/entities/ApplicationProcess';
import {fetchProcessTaskById, getProcessDefinitionId} from '../../../services/api/applicationProcessApi';
import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ProcessInstanceModifyInput} from '../../../services/entities/ProcessInstanceModifyInput';
import {changeTaskAssignee, migrateProcessInstances, modifyProcessInstances} from '../../../services/api/camundaApi';
import {ApplicationId} from '../../../services/entities/Application';
import {camundaUsersFetch} from "../applicationDetail/camundaUsersSlice";
import {getIdToken} from "../../../pages/common/authProvider/authProviderUtils";

export type AdministrationState  = {
    modify: ApiData<void>,
    migrate: ApiData<void>,
    assign: ApiData<void>,
    tasks: ApiData<ApplicationTask[]>
}
const initialState: AdministrationState = {
    modify: defaultApiData(),
    migrate: defaultApiData(),
    assign: defaultApiData(),
    tasks: defaultApiData([])
}
export const processInstancesModify = createAsyncThunk<void, ProcessInstanceModifyInput>(
    'administration/processInstancesModify',
    async (input) => {
        const token = await getIdToken()
        const processDefinitionId = await getProcessDefinitionId(input.processInstanceIds[0], token)
        return await modifyProcessInstances(input, processDefinitionId, token)
    }
)

export type ProcessInstanceMigrateInput = {
    sourceDefinitionId: ProcessDefinitionId,
    targetDefinitionId: ProcessDefinitionId
}
export const processInstancesMigrate = createAsyncThunk<void, ProcessInstanceMigrateInput>(
    'administration/processInstancesMigrate',
    async ({sourceDefinitionId, targetDefinitionId}) => {
        return await migrateProcessInstances(
            sourceDefinitionId,
            targetDefinitionId,
            await getIdToken()
        )
    }
)

export const assignTasksFetch = createAsyncThunk<ApplicationTask[], TaskId>(
    'administration/assignTasksFetch',
    async (input, thunkApi) => {
        const result =  await fetchProcessTaskById(input, await getIdToken())
        thunkApi.dispatch(camundaUsersFetch(result.map(el => el.assignee)))
        return result
    }
)

export type TaskAssigneeChangeInput = {
    applicationId: ApplicationId,
    taskId:TaskId,
    userId: string
}
export const taskAssigneeChange = createAsyncThunk<void, TaskAssigneeChangeInput>(
    'administration/taskAssigneeChange',
    async ({taskId, applicationId, userId}, thunkApi) => {
        return await changeTaskAssignee(applicationId, taskId, userId, await getIdToken())
            .then(() => {
                thunkApi.dispatch(setTaskAssignee({taskId, assignee: userId}))
                thunkApi.dispatch(camundaUsersFetch([userId]))
            })
    }
)

type SetTaskAssigneeInput = {
    taskId: TaskId,
    assignee: string
}

const administrationSlice = createSlice({
    name: 'administration',
    initialState,
    reducers: {
        'setTaskAssignee': (state, action: PayloadAction<SetTaskAssigneeInput>) => {
            const element = state.tasks.data.find(el => el.id === action.payload.taskId);
            element.assignee = action.payload.assignee
        },
        'clearTaskAssigneeAdministrationData': (state) => {
            setCustomData(state.tasks, [])
        }
    },
    extraReducers: builder => {
        builder.addCase(processInstancesModify.pending, s => {
            setDataPending(s.modify)
        })
        builder.addCase(processInstancesModify.fulfilled, (s, a) => {
            setDataSuccess(s.modify, a)
        })
        builder.addCase(processInstancesModify.rejected, (s, a) => {
            setDataFail(s.modify, a)
        })

        builder.addCase(processInstancesMigrate.pending, s => {
            setDataPending(s.migrate)
        })
        builder.addCase(processInstancesMigrate.fulfilled, (s, a) => {
            setDataSuccess(s.migrate, a)
        })
        builder.addCase(processInstancesMigrate.rejected, (s, a) => {
            setDataFail(s.migrate, a)
        })

        builder.addCase(assignTasksFetch.pending, s => {
            setDataPending(s.tasks)
        })
        builder.addCase(assignTasksFetch.fulfilled, (s, a) => {
            setDataSuccess(s.tasks, a)
        })
        builder.addCase(assignTasksFetch.rejected, (s, a) => {
            setDataFail(s.tasks, a)
        })

        builder.addCase(taskAssigneeChange.pending, s => {
            setDataPending(s.assign)
        })
        builder.addCase(taskAssigneeChange.fulfilled, (s, a) => {
            setDataSuccess(s.assign, a)
        })
        builder.addCase(taskAssigneeChange.rejected, (s, a) => {
            setDataFail(s.assign, a)
        })
    }
})

export const {setTaskAssignee, clearTaskAssigneeAdministrationData} = administrationSlice.actions
export default administrationSlice.reducer