import {
    MAX_PAYOUT_AMOUNT,
    TribesData,
} from '../../../services/entities/Payout';
import Papa from 'papaparse';
import { isValidDate, reformatDate } from '../../../utils/formatUtils';
import { validatePayoutAmount } from '../../../components/form/formValidations';
import { IS_KOOP } from '../../../services/constants';
import { MpTitle } from '../../../services/entities/TitleRegister';
const HEADER = 'KODZI;Částka;Kmen;Nevyplatit před';
const MULTIAPLICATION_HEADER = 'Titul;KODZI;Částka;Kmen;Nevyplatit před';

const HEADER_NAMES = ['recipientId', 'amount', 'productType', 'dueDate'];
const MULTIAPLICATION_HEADER_NAMES = [
    'agreementNumber',
    'recipientId',
    'amount',
    'productType',
    'dueDate',
];

type TranformResult = {
    value: any;
    error?: string;
};

let selectedTitle;
const transformFn =
    (
        defaultDue: Date,
        tribe: string,
        selectedType: string,
        tribesCodes: TribesData[],
        brokerCodes: string,
        multiApplication: boolean,
        titleRegisterDataFilter: MpTitle[]
    ) =>
    (value, row, header): TranformResult => {
        switch (header) {
            case 'agreementNumber':
                if (multiApplication && selectedType !== 'OST') {
                    selectedTitle = titleRegisterDataFilter?.find(
                        (title) => title.agreementNumber === value
                    );

                    if (selectedTitle?.agreementNumber) {
                        return {
                            value,
                            error: null,
                        };
                    } else {
                        return {
                            value,
                            error: `Neznámý titul na řádku: ${row}`,
                        };
                    }
                } else {
                    break;
                }

            case 'recipientId':
                if (!IS_KOOP) {
                    return { value: Number(value) };
                }

                if (
                    multiApplication &&
                    selectedType !== 'OST' &&
                    !selectedTitle?.agreementNumber
                ) {
                    return {
                        value: value,
                        error: `Kód získatele na řádku ${row} není možné ověřit z důvodu neznámého titulu`,
                    };
                }
                if (/^[0-9]{1,10}$/.test(value)) {
                    if (
                        !multiApplication &&
                        (brokerCodes === null ||
                            brokerCodes === undefined ||
                            selectedType === 'OST')
                    ) {
                        return {
                            value,
                            error: null,
                        };
                    }

                    //todo co kdyz nebude aggnumber

                    if (
                        (multiApplication &&
                            ((selectedTitle?.agreementNumber !== undefined &&
                                selectedTitle?.brokerCode === null) ||
                                selectedTitle?.brokerCode === undefined)) ||
                        selectedType === 'OST'
                    ) {
                        return {
                            value,
                            error: null,
                        };
                    }

                    const bCode =
                        value && multiApplication && selectedType !== 'OST'
                            ? selectedTitle?.brokerCode
                                  .split(';')
                                  .filter((b) => b === value)
                            : value &&
                              (!multiApplication || selectedType === 'OST')
                            ? brokerCodes.split(';').filter((b) => b === value)
                            : [];

                    return {
                        value: bCode.join(''),
                        error:
                            bCode.length !== 0
                                ? null
                                : `Neznámý kód získatele na řádku: ${row}`,
                    };
                } else {
                    return {
                        value,
                        error: `Získatelské číslo musí obsahovat minimálně 1 a maximálně 10 číslic na řádku: ${row}`,
                    };
                }

            case 'amount':
                let error = validatePayoutAmount().validatePayoutAmount(value);
                if (error) error += ` Částka ${value} na řádku: ${row}`;

                if (!error) {
                    value = Number(value.replace(',', '.'));
                    if (value > MAX_PAYOUT_AMOUNT)
                        error = `Výplata s částkou ${value} překročila maximální povolenou částku ${MAX_PAYOUT_AMOUNT} na řádku: ${row}`;
                }

                return {
                    value,
                    error,
                };
            case 'productType':
                let res = null;
                if (
                    IS_KOOP &&
                    multiApplication &&
                    selectedType !== 'OST' &&
                    !selectedTitle?.agreementNumber
                ) {
                    return {
                        value: value,
                        error: `Kmen výplaty na řádku ${row} není možné ověřit z důvodu neznámého titulu`,
                    };
                }
                if (
                    (IS_KOOP &&
                        (selectedType === 'OST' || selectedType === 'USP')) ||
                    !IS_KOOP
                ) {
                    res = tribesCodes
                        .map((t) => t.code)
                        .find((t) => t === value?.toUpperCase());
                } else if (
                    IS_KOOP &&
                    (tribe === value?.toUpperCase() ||
                        selectedTitle?.tribe?.toLocaleUpperCase() ===
                            value?.toUpperCase())
                ) {
                    res = value;
                }
                // else if (!IS_KOOP) {
                //     const resCPP = value
                //         ? ProductTypeEnum[value.toUpperCase()]
                //         : null;
                //     return {
                //         value: resCPP,
                //         error: resCPP
                //             ? null
                //             : `Neznámý kmen výplaty na řádku: ${row}`,
                //     };
                // }

                return {
                    value: res,
                    error: res ? null : `Neznámý kmen výplaty na řádku: ${row}`,
                };

            case 'dueDate':
                return {
                    value: isValidDate(value)
                        ? reformatDate(value)
                        : defaultDue,
                };
        }
        return {
            value: null,
            error: 'Neplatný formát souboru.',
        };
    };

type Err = {
    type: 'Quotes' | 'Delimiter' | 'FieldMismatch'; // A generalization of the error
    code:
        | 'MissingQuotes'
        | 'UndetectableDelimiter'
        | 'TooFewFields'
        | 'TooManyFields'; // Standardized error code
    message: string; // Human-readable details
    row: number; // Row index of parsed data where error is
};
const translateError = (err: Err): string => {
    const row = err.row + 2;

    switch (err.code) {
        case 'MissingQuotes':
            return `Chybějící uvozovky na řádku: ${row}`;
        case 'UndetectableDelimiter':
            return `Nelze nalézt oddělovač na řádku: ${row}`;
        case 'TooFewFields':
            return `Příliš málo hodnot na řádku: ${row}`;
        case 'TooManyFields':
            return `Příliš mnoho hodnot na řádku: ${row}`;
    }
};

const createErrorMsg = (errors: Err[]): React.ReactNode[] => {
    return errors.map(translateError);
};

export type ImportResult = {
    data: any[];
    errors: React.ReactNode[];
};

export const importPayouts = async (
    file: File,
    defaultDue: Date,
    tribe: string,
    selectedType: string,
    tribeCodes: Array<TribesData>,
    brokerCodes: string,
    multiApplication: boolean,
    titleRegisterDataFilter: MpTitle[]
): Promise<ImportResult> => {
    const transformer = transformFn(
        defaultDue,
        tribe,
        selectedType,
        tribeCodes,
        brokerCodes,
        multiApplication,
        titleRegisterDataFilter
    );
    const customErrors = [];

    let countValues = 0;
    return new Promise((resolve, reject) => {
        try {
            Papa.parse(file, {
                header: true,
                skipEmptyLines: 'greedy',
                transformHeader: (header, index) => {
                    if (multiApplication && selectedType !== 'OST') {
                        return MULTIAPLICATION_HEADER_NAMES[index];
                    } else {
                        return HEADER_NAMES[index];
                    }
                },
                beforeFirstChunk: (chunk) => {
                    const documentNumberOfColumns = chunk
                        ?.split('\n')[0]
                        ?.split(';')?.length;

                    if (
                        (!multiApplication || selectedType === 'OST') &&
                        documentNumberOfColumns !== 4
                    ) {
                        reject({
                            message: `Importní soubor není ve správném formátu! Požadovaný počet sloupců v importním souboru pro ${
                                selectedType === 'OST' && multiApplication
                                    ? 'nestandardní žádost'
                                    : 'individuální žádost'
                            } je 4, ale aktuální počet sloupců v importním souboru je ${documentNumberOfColumns}`,
                        });
                    }
                    if (
                        multiApplication &&
                        selectedType !== 'OST' &&
                        documentNumberOfColumns !== 5
                    ) {
                        reject({
                            message: `Importní soubor není ve správném formátu! Požadovaný počet sloupců v importním souboru pro hromadnou žádost je 5, ale aktuální počet sloupců v importním souboru je ${documentNumberOfColumns}`,
                        });
                    }

                    const index = chunk.indexOf(';');
                    const item = chunk.substring(0, index);

                    const haveHeader =
                        multiApplication && selectedType !== 'OST'
                            ? item?.toLowerCase() === 'titul'
                            : isNaN(Number(item));

                    if (!haveHeader)
                        chunk =
                            multiApplication && selectedType !== 'OST'
                                ? MULTIAPLICATION_HEADER + '\n' + chunk
                                : HEADER + '\n' + chunk;

                    return chunk;
                },
                transform: (value, header) => {
                    try {
                        const result = transformer(
                            value,

                            multiApplication && selectedType !== 'OST'
                                ? ~~(
                                      countValues++ /
                                      MULTIAPLICATION_HEADER_NAMES.length
                                  ) + 1
                                : ~~(countValues++ / HEADER_NAMES.length) + 1,
                            header
                        );

                        if (result.error) customErrors.push(result.error);

                        return result.value;
                    } catch (e) {
                        reject({ message: 'Neplatný formát souboru.' });
                        throw e;
                    }
                },
                complete: (results) => {
                    const errorElements = createErrorMsg(results.errors);
                    resolve({
                        data: results.data,
                        errors: errorElements.concat(customErrors),
                    });
                },
                error: (error) => {
                    reject(error);
                },
            });
        } catch (err) {
            console.error(err);
            reject(err);
        }
    });
};
