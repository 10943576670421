import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {UserId, UserProfile, UserRole} from '../../../services/entities/User';
import {fetchUserProfile, fetchUserRoles} from '../../../services/api/userApi';
import {getIdToken, TokenError} from "../../../pages/common/authProvider/authProviderUtils";

export interface UserState {
    login: UserId,
    name: string,
    roles: UserRole[],
    email: string,
    mocked: boolean,
    pendingData: boolean,
    tokenError: TokenError
}

const initialState: UserState = {
    login: null,
    name: null,
    roles: [],
    email: '',
    mocked: false,
    pendingData: false,
    tokenError: null
}

export type SetUserAndLoadData = {
    login: string,
    mocked?: boolean
}
type SetUserAndLoadDataResult = {
    profile: UserProfile,
    roles: UserRole[],
    mocked?: boolean
}
export const setUserAndLoadData = createAsyncThunk<SetUserAndLoadDataResult, SetUserAndLoadData>(
    'user/setUserAndLoadData',
    async (input) => {
        const results = await Promise.all([
            fetchUserProfile(input.login, await getIdToken()),
            fetchUserRoles(input.login, await getIdToken())
        ])

        const profile = results[0]
        const roles = results[1]

        return {
            profile,
            roles,
            mocked: input.mocked
        }
    }
)

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        'clearUser': (state) => {
            state.name = initialState.name
            state.roles = initialState.roles
            state.login = initialState.login
            state.tokenError = null
        },
        'setTokenError': (state, action: PayloadAction<TokenError>) => {
            state.tokenError = action.payload
        }
    },
    extraReducers: builder => {
        builder.addCase(setUserAndLoadData.pending, (s, a) => {
            s.login = a.meta.arg.login
            s.mocked = a.meta.arg.mocked
            s.pendingData = true
        })
        builder.addCase(setUserAndLoadData.fulfilled, (s, a) => {
            const payload = a.payload
            s.pendingData = false
            s.name = `${payload.profile.firstName} ${payload.profile.lastName}`
            s.email = payload.profile.email
            s.roles = payload.roles
            s.mocked = payload.mocked
        })
        builder.addCase(setUserAndLoadData.rejected, (s) => {
            s.pendingData = false
        })
    }
})

export const {clearUser, setTokenError} = userSlice.actions
export default userSlice.reducer