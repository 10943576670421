import React, { useEffect, useMemo, useState } from 'react';
import { Container } from 'react-bootstrap';
import ApplicationFormKoop from './appForm/ApplicationFormKoop';
import MyTabContainer from '../../components/tabContainer/MyTabContainer';
import Tab from 'react-bootstrap/Tab';
import Payouts from './payouts/Payouts';
import { TEXTS } from '../../constants/texts';
import { NAV } from '../../RouteList';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../common/header/Header';
import { Backspace } from 'react-bootstrap-icons';
import AppOverview from '../common/appOverview/AppOverview';
import MainContent from '../common/content/MainContent';
import {
    applicationAndPayoutSaveFinal,
    applicationAndPayoutsSave,
    applicationFetch,
    applicationTaskFetch,
    hideErrorAlert,
    setAppDirty,
    showErrorAlert,
} from '../../store/slices/applicationDetail/applicationSlice';
import { RootState, useAppDispatch, useAppSelector } from '../../store/store';
import {
    ApplicationTask,
    TASK_DEF_APP_EDIT,
    TASK_DEFS_USE_CONFIRM_LEAVE,
    TaskEscalation,
} from '../../services/entities/ApplicationProcess';
import AppComments from './comments/application/AppComments';
import {
    payoutsFetch,
    tribesFetch,
} from '../../store/slices/applicationDetail/payoutsSlice';
import {
    CLAIMED_TASKS,
    resolveTaskState,
    TaskStateEnum,
} from '../common/resolveTaskState';
import ClaimTask from '../common/claimTask/ClaimTask';
import {
    Application,
    ValidationResult,
} from '../../services/entities/Application';
import { useForm, UseFormReturn, useWatch } from 'react-hook-form';
import { LoadingIndicator } from '../../components/loadingIndicator/LoadingIndicator';
import {
    getDuplicates,
    Payout,
    PayoutInternal,
} from '../../services/entities/Payout';
import DuplicatedPayoutsMessage from './payouts/DuplicatedPayoutsMessage';
import { TaskActionBar2 } from '../common/taskActions2/TaskActionBar2';
import Attachments from './attachments/Attachments';
import { addNotification } from '../../store/slices/common/notificationsSlice';
import {
    commentSave,
    commentsFetch,
} from '../../store/slices/applicationDetail/commentsSlice';
import { Comment } from '../../services/entities/Comment';
import { useLazyLoading } from './hooks/useLazyLoading';
import {
    attachmentsFetch,
    getRequiredAttachmentsRules,
} from '../../store/slices/applicationDetail/attachmentsSlice';
import ApplicationLogs from './logs/ApplicationLogs';
import { appLogsFetch } from '../../store/slices/applicationDetail/applicationLogsSlice';
import Confirmation from '../../components/confirmation/Confirmation';
import { MyButton } from '../../components/button/MyButton';
import useNavigationPrompt from '../../components/navigatonPrompt/useNavigationPrompt';
import { dateAfterCheck } from '../../components/form/formValidations';
import { AttachmentKoop } from '../../services/entities/Attachment';
import ValidationAlert from './validationAlert/ValidationAlert';
import ProcessDiagrams from './diagrams/ProcessDiagrams';
import ProcessHistoryTableTabs from './history/ProcessHistoryTableTabs';
import { IS_KOOP } from '../../services/constants';
import ApplicationFormCpp from './appForm/ApplicationFormCpp';
import { appNewHistoryFetch } from '../../store/slices/applicationDetail/applicationNewHistorySlice';
import { validateRequiredAttachmentsKoop } from './attachments/validateRequiredAttachmentsKoop';

export type DuplicatedPayoutsMsgState = {
    duplicates?: PayoutInternal[];
    handler?: () => void;
};

//context for form that is used across more components
export const AppFormContext =
    React.createContext<UseFormReturn<Application>>(null);

export type ApplicationDetailPageProps = {
    isPayouts?: boolean;
};

function ApplicationDetailPage({
    isPayouts = false,
}: ApplicationDetailPageProps) {
    const { applicationId, taskId } = useParams();

    const appFormLogic = useForm<Application>({ mode: 'onChange' });
    const fromDateWatch = useWatch({
        name: 'fromDate',
        control: appFormLogic.control,
    });
    const toDateWatch = useWatch({
        name: 'toDate',
        control: appFormLogic.control,
    });
    const withoutPaymentWatch = useWatch({
        name: 'withoutPayment',
        control: appFormLogic.control,
    });

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [showDiagram, setShowDiagram] = useState(false);

    const tasksData = useAppSelector(
        (s) => s.applicationDetail.application.tasks
    );
    const task = useMemo(() => {
        if (!tasksData.data.length) return null;

        if (taskId) return tasksData.data.find((el) => el.id === taskId);
        else return tasksData.data[0];
    }, [taskId, tasksData]);

    const user = useAppSelector((s: RootState) => s.user);
    const finishingApp = useAppSelector(
        (s: RootState) => s.applicationDetail.application.finishingApp
    );
    const saveComment = useAppSelector(
        (s: RootState) => s.applicationDetail.comments
    );

    useEffect(() => {
        dispatch(setAppDirty(false));
    }, [dispatch]);

    const state = useMemo(
        () =>
            resolveTaskState(
                user?.login,
                task?.assignee,
                task?.taskDefinitionKey
            ),
        [user?.login, task?.assignee, task?.taskDefinitionKey]
    );

    const editable = state === TaskStateEnum.CLAIMED_EDITABLE_TASK;
    const claimed = CLAIMED_TASKS.includes(state);

    const applicationState = useAppSelector(
        (s) => s.applicationDetail.application
    );
    const { application, validationErrors: validationErrorsMsgs } =
        applicationState;
    const payouts = useAppSelector(
        (s: RootState) => s.applicationDetail.payouts
    );
    const expectedPayoutSumValid = useAppSelector(
        (s: RootState) => s.applicationDetail.application.expectedPayoutSumValid
    );
    const comments = useAppSelector(
        (s: RootState) => s.applicationDetail.comments
    );
    const isDirty = useAppSelector(
        (s) => s.applicationDetail.application.dirty
    );

    const attachmentsData = useAppSelector(
        (state) => state.applicationDetail.attachments.data
    );

    const { requiredAttachmentsRules } = useAppSelector(
        (s: RootState) => s.applicationDetail.attachments
    );

    const disableSave =
        appFormLogic.formState?.errors?.fromDate?.type === 'validateDate' ||
        appFormLogic.formState?.errors?.toDate?.type === 'validateDate';

    useEffect(() => {
        if (IS_KOOP) dispatch(getRequiredAttachmentsRules());
    }, [dispatch]);

    useEffect(() => {
        application?.data?.application?.id &&
            dispatch(attachmentsFetch(application?.data?.application?.id));
    }, [dispatch, application?.data?.application?.id]);

    useEffect(() => {
        if (validationErrorsMsgs.result === ValidationResult.ERROR) {
            dispatch(showErrorAlert(application?.data?.application?.id));
        }
    }, [
        validationErrorsMsgs.result,
        application?.data?.application?.id,
        dispatch,
    ]);

    useEffect(() => {
        if (!validationErrorsMsgs?.errorMsgId) dispatch(hideErrorAlert());
    }, [
        validationErrorsMsgs?.errorMsgId,
        application?.data?.application?.id,
        dispatch,
    ]);

    useNavigationPrompt(
        'Máte neuložená data žádosti/výplat o která přijdete pokud teď opustíte stránku. ' +
            '(Data je možné uložit pomocí tlačítka "Uložit") ' +
            'Přejete si přesto opustit stránku?',
        isDirty
    );
    useEffect(() => {
        dispatch(tribesFetch());
    }, [dispatch]);

    useLazyLoading({
        param: applicationId,
        actionFn: applicationFetch,
        errFn: (err) => {
            const msg = err.message;
            if (msg && (msg.includes('401') || msg.includes('403'))) {
                dispatch(
                    addNotification({
                        variant: 'danger',
                        text: 'Nemáte oprávnění ke zobrazení této žádosti.',
                    })
                );
                navigate(NAV.INDEX);
            }
        },
    });

    useEffect(() => {
        let payload = {
            superProcessInstance:
                application?.data?.application?.processInstanceId,
            processDefinitionKeys: [],
        };
        if (payload.superProcessInstance) dispatch(appNewHistoryFetch(payload));
    }, [dispatch, application?.data?.application?.processInstanceId]);

    const refreshTask = useLazyLoading({
        param: application.data?.tasks?.length
            ? taskId
                ? application.data?.tasks.find((el) => el.id === taskId)
                      .processInstanceId
                : application.data?.tasks[0]?.processInstanceId
            : application.data?.application.processInstanceId,
        actionFn: applicationTaskFetch,
    });

    useEffect(() => {
        if (application.data?.application?.id) {
            dispatch(
                payoutsFetch({
                    validityDate:
                        application.data?.application?.fromDate ||
                        application.data?.application?.created,
                    data: application.data?.application?.id,
                })
            );
        }
    }, [
        application.data?.application?.fromDate,
        application.data?.application?.created,
        application.data?.application?.id,
        dispatch,
    ]);

    useLazyLoading({
        param: application.data?.application?.id,
        actionFn: commentsFetch,
    });

    useLazyLoading({
        param: application.data?.application?.id,
        actionFn: appLogsFetch,
    });

    const validApp = appFormLogic.formState.isValid;

    const validationErrors = useMemo(() => {
        return claimed && editable
            ? (() => {
                  const errors = [];

                  if (!payouts.data?.length && !withoutPaymentWatch) {
                      errors.push('Je třeba zadat alespoň jednu výplatu');
                  }

                  if (payouts.data?.length > 1 && !expectedPayoutSumValid) {
                      errors.push(
                          'Očekávaná suma výplat a skutečná suma částek výplat musí být shodné.'
                      );
                  }

                  if (!validApp || !dateAfterCheck(toDateWatch, fromDateWatch))
                      errors.push(
                          'Je třeba správně vyplnit formulář parametrů žádosti'
                      );
                  const validateAt = validateRequiredAttachmentsKoop(
                      applicationState,
                      requiredAttachmentsRules,
                      payouts.data,
                      attachmentsData as AttachmentKoop[]
                  );

                  if (IS_KOOP && !validateAt)
                      errors.push('Je třeba přidat povinné přílohy');

                  return errors.join('\n');
              })()
            : '';
    }, [
        claimed,
        editable,
        payouts.data,
        expectedPayoutSumValid,
        validApp,
        toDateWatch,
        fromDateWatch,
        applicationState,
        requiredAttachmentsRules,
        attachmentsData,
        withoutPaymentWatch
    ]);

    const [duplicatedPayoutsMsg, setDuplicatedPayoutsMsg] =
        useState<DuplicatedPayoutsMsgState>({});

    const handleSave = () => {
        appFormLogic.handleSubmit(() => {})();

        // formRef && formRef.current && formRef.current.submit() //save apps
        const appFormValues: object = appFormLogic.getValues();
        const applicationData = {
            ...application.data.application,
            ...appFormValues,
        };
        dispatch(setAppDirty(false));

        dispatch(applicationAndPayoutsSave(applicationData)).then(
            (value: any) => {
                //save payouts
                const duplicates = getDuplicates(value.payload.payouts);
                if (duplicates.length)
                    dispatch(
                        addNotification({
                            text: `V žádosti došlo k nalezení ${duplicates.length} duplicitních výplat.`,
                            timeout: 10000,
                            variant: 'warning',
                        })
                    );
            }
        ); //save payoutsN
    };

    const handleFinalSave = async (esc: TaskEscalation): Promise<boolean> => {
        if (!editable || esc?.escalation) return true;

        const applicationData = { ...application, ...appFormLogic.getValues() };

        const res: any = await dispatch(
            applicationAndPayoutSaveFinal(applicationData)
        );

        if (res.payload.result === ValidationResult.ERROR) return false;

        dispatch(setAppDirty(false));

        const duplicatedPayouts = getDuplicates(res.payload.payouts);
        if (duplicatedPayouts.length) {
            await handleDuplicatedPayoutsForFinalSave(duplicatedPayouts);
        }

        return true;
    };

    const handleDuplicatedPayoutsForFinalSave = (
        duplicatedPayouts: Payout[]
    ): Promise<void> => {
        return new Promise((res) => {
            setDuplicatedPayoutsMsg({
                duplicates: duplicatedPayouts,
                handler: () => {
                    setDuplicatedPayoutsMsg({});
                    res();
                },
            });
        });
    };

    const afterFinish = (task: ApplicationTask, esc?: TaskEscalation) => {
        navigate(NAV.INDEX);
        dispatch(
            addNotification({
                text: `Akce ${
                    esc?.extensionElements?.actionLabel || task.name
                } provedena.`,
            })
        );
    };

    const handleSaveComment = (comment: Comment) => {
        return dispatch(
            commentSave({
                comment,
                appId: application.data?.application?.id,
            })
        );
    };
    const handleDiagram = () => {
        setShowDiagram(true);
    };
    const useConfirmForBack =
        task && TASK_DEFS_USE_CONFIRM_LEAVE.includes(task.taskDefinitionKey);

    const saveValidationMessage = isDirty
        ? 'V aplikaci jsou neuložená data (detailu žádosti nebo výplaty).'
        : '';
        
    return (
        <div className="position-relative">
            {/* <Modal
                show={showDiagram}
                onHide={() => setShowDiagram(false)}
                fullscreen={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Procesní diagram</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="w-100 h-100" id="bpmnjsContainer"/>
                </Modal.Body>
            </Modal> */}

            {finishingApp.pending || saveComment.pending ? (
                <LoadingIndicator />
            ) : null}

            <DuplicatedPayoutsMessage
                open={!!duplicatedPayoutsMsg.handler}
                handleClose={() => setDuplicatedPayoutsMsg({})}
                handleConfirm={duplicatedPayoutsMsg.handler}
                duplicates={duplicatedPayoutsMsg.duplicates}
            />

            <Header
                className="mb-3"
                useConfirmForBack={useConfirmForBack}
                actions={[
                    {
                        title: TEXTS.back,
                        componentOverride: (
                            <Confirmation
                                className="large-confirmation"
                                title="Opustit žádost"
                                confirmText={TEXTS.yes}
                                cancelText={TEXTS.no}
                                message={TEXTS.leaveAppDetailConfirmation}
                                disabled={!useConfirmForBack}
                                onConfirm={() => navigate(NAV.INDEX)}
                            >
                                <MyButton
                                    noYMargin
                                    className="button-action"
                                    onClick={
                                        useConfirmForBack
                                            ? null
                                            : () => navigate(NAV.INDEX)
                                    }
                                >
                                    <div className="d-flex align-items-center">
                                        <Backspace />
                                        &nbsp;
                                        {TEXTS.back}
                                    </div>
                                </MyButton>
                            </Confirmation>
                        ),
                    },
                ]}
            />

            {state !== TaskStateEnum.NO_TASK ? (
                <Container fluid="xxl" className="mb-3">
                    <ClaimTask
                        state={state}
                        task={task}
                        onAfterAction={() => refreshTask.refresh()}
                    />
                </Container>
            ) : null}

            <AppOverview
                className="mb-3"
                readonly={state === TaskStateEnum.NO_TASK}
                application={application.data}
                task={task}
                pending={application.pending || tasksData.pending}
            />

            {state !== TaskStateEnum.NO_TASK ? (
                <>
                    <ValidationAlert
                        show={validationErrorsMsgs.showErrorAlert}
                        onClose={() => {
                            dispatch(hideErrorAlert());
                        }}
                        dismissible={true}
                        variant={'danger'}
                        errors={validationErrorsMsgs?.errors}
                    />
                    <TaskActionBar2
                        task={task}
                        claimed={claimed}
                        editable={editable}
                        afterFinish={afterFinish}
                        handleFinalSave={handleFinalSave}
                        triggerValidations={appFormLogic.trigger}
                        handleSave={handleSave}
                        pending={tasksData.pending}
                        handleSaveComment={handleSaveComment}
                        saveValidationMessage={saveValidationMessage}
                        finishValidationMessage={validationErrors}
                        disableSave={disableSave}
                    />
                </>
            ) : (
                <Container fluid="xxl" className="mt-3">
                    <hr />
                </Container>
            )}

            <MainContent>
                <AppFormContext.Provider value={appFormLogic}>
                    <MyTabContainer
                        items={[
                            { label: 'Parametry' },
                            { label: 'Výplaty', count: payouts.data?.length },
                            {
                                label: 'Přílohy',
                                count: attachmentsData?.length,
                            },
                            {
                                label: 'Komentáře',
                                count: comments.data?.length,
                            },
                            { label: 'Historie žádosti' },
                            // { label: "Historie žádosti2" },
                            { label: 'Logy' },
                            {
                                label: 'Procesní diagramy',
                                action: handleDiagram,
                            },
                        ]}
                        selectedEventKey={isPayouts && 'Výplaty'}
                    >
                        <Tab.Pane eventKey="Parametry">
                            {IS_KOOP ? (
                                <ApplicationFormKoop disabled={!editable} />
                            ) : (
                                <ApplicationFormCpp disabled={!editable} />
                            )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="Výplaty">
                            <Payouts
                                disabled={
                                    !application.data ||
                                    !editable ||
                                    (application.data.application
                                        ?.immediatePayout &&
                                        task.taskDefinitionKey ===
                                            TASK_DEF_APP_EDIT)
                                }
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="Přílohy">
                            <Attachments
                                applicationId={
                                    application.data?.application?.id
                                }
                                disabled={!editable}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="Komentáře">
                            <AppComments />
                        </Tab.Pane>
                        {/*{*/}
                        {/*    <Tab.Pane eventKey="Historie žádosti">*/}
                        {/*        <ApplicationHistory />*/}
                        {/*    </Tab.Pane>*/}
                        {/*}*/}
                        {
                            <Tab.Pane eventKey="Historie žádosti">
                                <ProcessHistoryTableTabs />
                            </Tab.Pane>
                        }
                        <Tab.Pane eventKey="Logy">
                            <ApplicationLogs />
                        </Tab.Pane>
                        <Tab.Pane eventKey="Procesní diagramy">
                            <ProcessDiagrams
                                showDiagram={showDiagram}
                                setShowDiagram={setShowDiagram}
                            />
                        </Tab.Pane>
                    </MyTabContainer>
                </AppFormContext.Provider>
            </MainContent>
        </div>
    );
}

export default ApplicationDetailPage;
