import {Tab, Tabs} from "react-bootstrap";
import {LoadingIndicator} from "../../../components/loadingIndicator/LoadingIndicator";
import {TaskDefinitionKey} from "../../../services/entities/ApplicationProcess";
import {RootState, useAppSelector} from "../../../store/store";

import NewHistoryTable from "./NewHistoryTable";
import {useMemo} from 'react';
import {IS_KOOP} from '../../../services/constants';

enum ProcessIdEnum {
    MP_APPLICATION = "mp-application",
    MP_APPROVAL_KOOP = "mp_approval-koop",
    MP_APPROVAL_CPP = "mp_approval-cpp",
    MP_APPROVAL_SUB = "mp_approval_subprocess",
    MP_PAYOUT_WRAPPER = "mp-payout-wrapper",
    MP_PAYOUT_GOLEM = "mp-payout-golem",
    MP_PAYOUT_SAPHR = "mp-payout-saphr",
}

const ProcessIdNames = {
    [ProcessIdEnum.MP_APPLICATION]: "Hlavní proces",
    [ProcessIdEnum.MP_APPROVAL_KOOP]: "Schvalovací proces",
    [ProcessIdEnum.MP_APPROVAL_CPP]: "Schvalovací proces",
    [ProcessIdEnum.MP_PAYOUT_WRAPPER]: "Proces na zpracování výplat",
    [ProcessIdEnum.MP_PAYOUT_GOLEM]: 'Zpracování výplat v GOLEM"',
    [ProcessIdEnum.MP_PAYOUT_SAPHR]: "Zpracování výplaty v SAP HR)",
};

export const MP_APPROVAL_ADDITION = ProcessIdEnum.MP_APPROVAL_SUB
export const MP_APPROVAL_ID = IS_KOOP ? ProcessIdEnum.MP_APPROVAL_KOOP : ProcessIdEnum.MP_APPROVAL_CPP

export const processDefinitionKeys: TaskDefinitionKey[] = [
    ProcessIdEnum.MP_APPLICATION, MP_APPROVAL_ID, ProcessIdEnum.MP_PAYOUT_WRAPPER
]

const ProcessHistoryTableTabs = () => {
    const historyData = useAppSelector(
        (s: RootState) => s.applicationDetail.newHistory
    );

    const uniqueProcessDefinitionKeys: TaskDefinitionKey[]  = useMemo(() => {
        const result: Set<TaskDefinitionKey> =  new Set()
        historyData.data?.forEach(process => result.add(process.processDefinitionKey))
        return Array.from(result)
    }, [historyData.data])

    if (historyData.pending) return <LoadingIndicator/>;

    return (
        <div>
            <Tabs className="mb-3">
                {uniqueProcessDefinitionKeys.map((processKey) => {
                    return (
                        processDefinitionKeys.includes(processKey) && (
                            <Tab
                                key={processKey}
                                eventKey={processKey}
                                title={ProcessIdNames[processKey]}
                            >
                                <NewHistoryTable
                                    processKey={processKey}
                                    historyData={historyData}
                                />
                            </Tab>
                        )
                    );
                })}
            </Tabs>
        </div>
    );
};

export default ProcessHistoryTableTabs;
