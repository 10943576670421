import React from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { MyButton } from '../../components/button/MyButton';
import {
    clearApplication,
    newApplicationStart,
} from '../../store/slices/applicationDetail/applicationSlice';
import { useAppDispatch } from '../../store/store';

type MassRequestDialogProps = {
    onHide: () => void;
    open: boolean;
};

const MultiRequestDialog = ({ onHide, open }: MassRequestDialogProps) => {
    const navigate = useNavigate();

    const dispatch = useAppDispatch();
    const handleIndividualRequest = () => {
        dispatch(clearApplication());
        dispatch(newApplicationStart({ navigate, multiApplication: false }));
    };
    const handleMassRequest = () => {
        dispatch(clearApplication());
        dispatch(newApplicationStart({ navigate, multiApplication: true }));
    };
    return (
        <Modal show={open} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title as="h5">Nová žádost</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                S ohledem na Vaše oprávnění není možné jednoznačně určit typ
                nové žádosti, prosíme o upřesnění volbou z těchto typů:
            </Modal.Body>
            <Modal.Footer>
                <MyButton
                    variant="outline-primary"
                    onClick={handleIndividualRequest}
                >
                    Individuální žádost
                </MyButton>
                <MyButton variant="outline-primary" onClick={handleMassRequest}>
                    Hromadná žádost
                </MyButton>
            </Modal.Footer>
        </Modal>
    );
};

export default MultiRequestDialog;
