import './MyTabContainer.scss'
import React, {createContext, useEffect, useMemo, useState} from 'react';
import {Container, TabPaneProps} from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import {MyTabItem, MyTabs} from './MyTabs';
import clsx from 'clsx';
import {generateId} from '../../utils/generateId';

type MyTabContainerProps = {
    items: [MyTabItem, ...MyTabItem[]],
    className?: string,
    selectedEventKey?: string,
    children: Array<React.ReactElement<TabPaneProps>>,
    onSelect?: (string) => void
}

//TOOD use npm boostrap 5 useBreakpoints

export const MyTabContainerContext = createContext(null)

function MyTabContainer({onSelect, items, children, className, selectedEventKey}: MyTabContainerProps) {

    const actualItems = items.filter(el => el)
    const [selectedKey, setSelectedKey] = useState(selectedEventKey || actualItems[0].label)

    useEffect(() => {
        if (selectedEventKey)
            setSelectedKey(selectedEventKey)
    }, [selectedEventKey])

    const id = useMemo(() => generateId().toString(), [])

    return (
        <MyTabContainerContext.Provider value={[selectedKey, setSelectedKey]}>
            <Container  fluid="xxl" className={clsx('MyTabContainer pb-3 h-100', className)}>
                <Tab.Container onSelect={onSelect} defaultActiveKey={selectedKey}
                               id={id}
                               activeKey={selectedKey}>
                    <Row className="h-100 pt-3">
                        <Col sm={10}>
                            <Tab.Content className="pe-3 pe-xxl-5">
                                { children }
                            </Tab.Content>
                        </Col>
                        <Col sm={2}>
                            <MyTabs items={actualItems} />
                        </Col>
                    </Row>
                </Tab.Container>
            </Container>
        </MyTabContainerContext.Provider>
    )


}

export default MyTabContainer;
