import React from 'react';
import './ProcessHistory.scss';
import {TableColumn} from 'react-data-table-component';
import {MyTable} from '../../../components/table/MyTable';
import {formatCamundaUserId, formatDateTime} from '../../../utils/formatUtils';
import {ProcessHistory, USER_TASK_ACTIVITY_TYPE} from '../../../services/entities/ProcessHistory';
import {GearFill, PersonFill} from 'react-bootstrap-icons';
import {CamundaUser, UserId} from "../../../services/entities/User";

export type ProcessHistoryTableProps = {
    data: ProcessHistory[],
    pending: boolean,
    camundaUsersMap: {[key: UserId]: CamundaUser},
    subHeaderComponent?: React.ReactNode
}

function ProcessHistoryTable({ data, pending, subHeaderComponent, camundaUsersMap }: ProcessHistoryTableProps) {

    const columns: TableColumn<ProcessHistory>[] = [
        {
            name: 'Procesní krok',
            selector: row => row.activityName,
            cell: row => <span>{row.activityName} <br /><small className="text-muted">({row.activityId})</small></span>,
            sortable: true
        },
        {
            name: 'Jméno',
            selector: row => row.assignee,
            cell: row => <span>
                {row.activityType === USER_TASK_ACTIVITY_TYPE && <PersonFill className="me-1" />}
                {row.activityType === USER_TASK_ACTIVITY_TYPE && formatCamundaUserId(row.assignee, camundaUsersMap)}
                {row.activityType === USER_TASK_ACTIVITY_TYPE && !row.assignee && 'Nepřiřazeno'}
                {row.activityType !== USER_TASK_ACTIVITY_TYPE && <GearFill className="me-1" />}
                {row.activityType !== USER_TASK_ACTIVITY_TYPE && 'Systém'}
            </span>,
            sortable: true
        },
        {
            name: 'Začátek',
            selector: row => row.startTime?.toString(),
            cell: row => formatDateTime(row.startTime),
            sortable: true
        },
        {
            name: 'Konec',
            selector: row => row.endTime?.toString(),
            cell: row => row.endTime ? formatDateTime(row.endTime) : 'Nedokončeno',
            sortable: true
        }
    ];

    return (
        <div className="ProcessHistoryTable position-relative">
            <MyTable columns={columns}
                     defaultSortFieldId="startTime"
                     progressPending={pending}
                     data={data}
                     subHeader={!!subHeaderComponent}
                     subHeaderComponent={subHeaderComponent}
                     pagination/>
        </div>
    )
}

export default ProcessHistoryTable;
