import React from 'react';
import { Container } from 'react-bootstrap';

import { PAYOUTS_LIST_ROLES } from '../../services/entities/User';
import { RootState, useAppSelector } from '../../store/store';
import { hasIntersection } from '../../utils/utils';
import MainContent from '../common/content/MainContent';
import Header from '../common/header/Header';
import PayoutsTable from './payoutsTable/PayoutsTable';

type Props = {};

const PayoutsList = (props: Props) => {
    const user = useAppSelector((s: RootState) => s.user);

    return (
        <div className="position-relative">
            <Header className="mb-3" />
            {hasIntersection(user.roles, PAYOUTS_LIST_ROLES) && (
                <MainContent>
                    <Container fluid="xxl">
                        <h3 className="mb-3">Výplaty</h3>

                        <PayoutsTable />
                    </Container>
                </MainContent>
            )}
        </div>
    );
};

export default PayoutsList;
