import {Form} from 'react-bootstrap';
import {useForm, useWatch} from 'react-hook-form';
import clsx from 'clsx';
import { Payout } from '../../../services/entities/Payout';
import {RequiredLabel} from '../../../components/form/RequiredLabel';
import {FormError} from '../../../components/form/FormError';
import {useContext, useEffect} from 'react';
import {RootState, useAppSelector} from '../../../store/store';
import {ApplicationTypeEnumCpp, ApplicationWrapper} from '../../../services/entities/Application';
import {dateToInput, formatServerDate} from '../../../utils/formatUtils';
// import * as yup from 'yup';
import {max, required, validateKodzi, validatePayoutAmount} from '../../../components/form/formValidations';
import {AppFormContext} from '../application-detail';

type NewPayoutProps = {
    onSave: (Payout) => void,
    payout?: Payout
}

function NewPayoutFormCpp({onSave, payout}: NewPayoutProps) {
    const application: ApplicationWrapper = useAppSelector((s: RootState) => s.applicationDetail.application.application.data)
    const appFormLogic = useContext(AppFormContext)
    const appType: ApplicationTypeEnumCpp = useWatch({name: 'type', control: appFormLogic.control}) as ApplicationTypeEnumCpp
    const {register, handleSubmit, control, reset, formState: {errors}} = useForm<Payout>({
        mode: 'onChange',
        defaultValues: {
            dueDate: dateToInput(application.application.created)
        }
    });
    const amount = useWatch({name: 'amount', control})
    const amountProperNumFormat = Number(amount?.toString().replace(',','.'));
    const { tribes } = useAppSelector(
        (state) => state.applicationDetail.payouts
    );

    const onSubmit = (data: Payout) =>
        onSave({ ...data, amount: amountProperNumFormat });

    useEffect(() => {
        if (payout) reset(payout);
    }, [reset, payout]);

    return (
        <Form id="NewPayoutForm" onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="d-flex flex-row mb-3">
                <Form.Group className="me-3">
                    <RequiredLabel>Získatelské číslo</RequiredLabel>
                    <Form.Control
                        className="small-input"
                        size="sm"
                        placeholder="Zadejte hodnotu"
                        {...register('recipientId', {
                            validate: {
                                ...validateKodzi(),
                            },
                            ...required(),
                        })}
                    />
                    <FormError
                        className="small-input"
                        errors={errors}
                        name="recipientId"
                    />
                </Form.Group>

                <Form.Group className="me-3">
                    <RequiredLabel>Částka</RequiredLabel>
                    <Form.Control
                        className="small-input no-input-arrows"
                        size="sm"
                        type="text"
                        placeholder="Zadejte hodnotu"
                        {...register('amount', {
                            valueAsNumber: false,
                            ...required(),
                            ...max(999999999),
                            validate: {
                                ...validatePayoutAmount(),
                            },
                        })}
                    />
                    <FormError errors={errors} name="amount" />
                </Form.Group>

                <Form.Group className="me-3">
                    <RequiredLabel>Kmen</RequiredLabel>
                    <Form.Select
                        {...register('productType', { ...required() })}
                        size="sm"
                        defaultValue={null}
                        className={clsx('small-input')}
                    >
                        <option value="" hidden>
                            Vyberte kmen
                        </option>
                        {tribes.map((entry) => (
                            <option key={entry.code} value={entry.code}>
                                {entry.code} = {entry.description}
                            </option>
                        ))}
                        {/* {Object.entries(ProductTypeNames).map((entry) => (
                            <option key={entry[0]} value={entry[0]}>
                                {entry[1]}
                            </option>
                        ))} */}
                    </Form.Select>
                    <FormError errors={errors} name="productType" />
                </Form.Group>

                <Form.Group>
                    <RequiredLabel>Nevyplatit před</RequiredLabel>
                    <Form.Control
                        size="sm"
                        className="small-input"
                        type="date"
                        disabled={
                            appType ===
                                ApplicationTypeEnumCpp.INSURANCE_OFFICE ||
                            appType === ApplicationTypeEnumCpp.OUT_OF_TERM
                        }
                        placeholder="Zadejte datum"
                        min={formatServerDate(new Date())}
                        {...register('dueDate', { ...required() })}
                    />
                    <FormError errors={errors} name="dueDate" />
                </Form.Group>
            </Form.Group>
        </Form>
    );
}

export default NewPayoutFormCpp;
