import { formatDateTimeStamp } from '../../utils/formatUtils';
import { DATA_API_URL } from '../constants';
import {
    PagedPayoutsList,
    PayoutsListFetchInput,
} from '../entities/PayoutsList';
import { fetchMethods } from '../fetchUtils';
import { AuthToken } from './applicationApi';

export const fetchPayoutsList = (
    input: PayoutsListFetchInput,
    token: AuthToken
): Promise<PagedPayoutsList> => {
    const url = `${DATA_API_URL}/payouts/`;
    const body = input;
    return fetchMethods.post(url, body, token);
};

export const exportPayoutsList = (
    input: PayoutsListFetchInput,
    token: AuthToken
): Promise<any> => {
    const url = `${DATA_API_URL}/payouts/csv`;

    const body = input;
    return fetchMethods.post(url, body, token).then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `Vyplaty_${formatDateTimeStamp(new Date())}.csv`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    });
};
