import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {PayoutId} from '../../../services/entities/Payout';
import {ApplicationTask, ProcessInstanceId} from '../../../services/entities/ApplicationProcess';
import {ApplicationId} from '../../../services/entities/Application';
import {savePayoutDates} from '../../../services/api/payoutApi';
import {fetchPayoutCommentsForPayoutDetail, savePayoutComment} from '../../../services/api/payoutCommentApi';
import {Comment} from '../../../services/entities/Comment';
import {ApiData, defaultApiData, setDataFail, setDataPending, setDataSuccess} from '../../storeApiUtils';
import {getApplicationTasks} from '../../../services/api/applicationProcessApi';
import {ProcessHistory} from '../../../services/entities/ProcessHistory';
import {camundaUsersFetch} from "../applicationDetail/camundaUsersSlice";
import {fetchPayoutHistoryForPayoutDetail} from "../../../services/api/newHistoryApi";
import {getIdToken} from "../../../pages/common/authProvider/authProviderUtils";
import {PayoutDetailWrapper} from '../../../services/entities/PayoutDetailWrapper';
import {fetchPayoutDetailData} from '../../../services/api/payoutDetailApi';

export interface PayoutDetailState {
    payoutDetailData: ApiData<PayoutDetailWrapper>;
    task: ApplicationTask;
    pendingSave: boolean;
    commentSave: ApiData<void>;
    history: ApiData<ProcessHistory[]>;
    comments: ApiData<Comment[]>;
    fromPayout: boolean;
}

const initialState: PayoutDetailState = {
    payoutDetailData: defaultApiData(null),
    task: null,
    pendingSave: false,
    history: defaultApiData([]),
    comments: defaultApiData([]),
    commentSave: defaultApiData(),
    fromPayout: false,
};

export type PayoutDetailDataFetchInput = {
    applicationId: ApplicationId,
    payoutId: PayoutId
}
export const payoutDetailDataFetch = createAsyncThunk<PayoutDetailWrapper, PayoutDetailDataFetchInput>(
    'payoutDetail/payoutDetailDataFetch',
    async (input) => {
        return await fetchPayoutDetailData(input.applicationId, input.payoutId, await getIdToken())
    }
)

export type PayoutHistoryFetchInput = {
    applicationId: ApplicationId,
    payoutId: PayoutId
}
export const payoutHistoryFetch = createAsyncThunk<ProcessHistory[], PayoutHistoryFetchInput>(
    'payoutDetail/payoutHistoryFetch',
    async (input, thunkApi) => {
        const result = await fetchPayoutHistoryForPayoutDetail(input.applicationId, input.payoutId, await getIdToken())

        const userIds = result.map(el => el.assignee);
        thunkApi.dispatch(camundaUsersFetch(userIds))

        return result
    }
)

export type PayoutsCommentsFetchInput = {
    applicationId: ApplicationId,
    payoutId: PayoutId
}
export const payoutsCommentsFetch = createAsyncThunk<Comment[], PayoutsCommentsFetchInput>(
    'payoutDetail/payoutsCommentsFetch',
    async ({applicationId, payoutId}, thunkApi) => {
        const result = await fetchPayoutCommentsForPayoutDetail(
            applicationId,
            payoutId,
            await getIdToken())

        const ids = result.map(el => el.userName)
        thunkApi.dispatch(camundaUsersFetch(ids))

        return result
    }
)

export type SavePayoutDatesInput = {
    fromDate: Date,
    toDate: Date,
    payout: { id: PayoutId },
    applicationId: ApplicationId
}
type SavePayoutDatesResult = {
    fromDate: Date,
    toDate: Date
}
export const payoutDatesSave = createAsyncThunk<SavePayoutDatesResult, SavePayoutDatesInput>(
    'payoutDetail/payoutDatesSave',
    async ({applicationId, fromDate, toDate, payout}) => {
        await savePayoutDates(applicationId, payout.id, fromDate, toDate, await getIdToken())
        return {
            fromDate,
            toDate
        }
    }
)

export type PayoutCommentSaveInput = {
    payout: { id: PayoutId },
    applicationId: ApplicationId,
    comment: Comment
}
export const payoutCommentSave = createAsyncThunk<void, PayoutCommentSaveInput>(
    'payoutDetail/payoutCommentSave',
    async ({applicationId, comment, payout}) => {
        await savePayoutComment(applicationId, payout.id, comment, await getIdToken())
        return
    }
)

export const refreshPayoutErrorTask = createAsyncThunk<ApplicationTask, ProcessInstanceId>(
    'payoutDetail/refreshPayoutErrorTask',
    async (processInstanceId, thunkApi) => {
        const tasks = await getApplicationTasks(processInstanceId, await getIdToken())
        const result = tasks.length ? tasks[0] : null
        if (result)
            thunkApi.dispatch(camundaUsersFetch([result.assignee]))
        return result
    }
)

const payoutDetailSlice = createSlice({
    name: 'payoutDetail',
    initialState,
    reducers: {
        setFromPayout: (s, a) => {
            s.fromPayout = a.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(payoutDatesSave.pending, (s) => {
            s.pendingSave = true;
        });
        builder.addCase(payoutDatesSave.fulfilled, (s, a) => {
            s.payoutDetailData = {
                ...s.payoutDetailData,
                data: {
                    ...s.payoutDetailData.data,
                    payoutWrapperLite: {
                        ...s.payoutDetailData.data?.payoutWrapperLite,
                        payoutLite: {
                            ...s.payoutDetailData.data?.payoutWrapperLite
                                .payoutLite,
                            ...a.payload,
                        },
                    },
                },
            };
            s.pendingSave = false;
        });
        builder.addCase(payoutDatesSave.rejected, (s) => {
            s.pendingSave = false;
        });

        builder.addCase(payoutCommentSave.pending, (s) => {
            s.pendingSave = true;
        });
        builder.addCase(payoutCommentSave.fulfilled, (s) => {
            s.pendingSave = false;
        });
        builder.addCase(payoutCommentSave.rejected, (s) => {
            s.pendingSave = false;
        });

        builder.addCase(refreshPayoutErrorTask.pending, (s) => {
            s.pendingSave = true;
        });
        builder.addCase(refreshPayoutErrorTask.fulfilled, (s, a) => {
            s.task = a.payload;
            s.pendingSave = false;
        });
        builder.addCase(refreshPayoutErrorTask.rejected, (s) => {
            s.pendingSave = false;
        });

        builder.addCase(payoutHistoryFetch.pending, (s, a) =>
            setDataPending(s.history, a)
        );
        builder.addCase(payoutHistoryFetch.fulfilled, (s, a) =>
            setDataSuccess(s.history, a)
        );
        builder.addCase(payoutHistoryFetch.rejected, (s, a) =>
            setDataFail(s.history, a)
        );

        builder.addCase(payoutsCommentsFetch.pending, (s, a) =>
            setDataPending(s.comments, a)
        );
        builder.addCase(payoutsCommentsFetch.fulfilled, (s, a) =>
            setDataSuccess(s.comments, a)
        );
        builder.addCase(payoutsCommentsFetch.rejected, (s, a) =>
            setDataFail(s.comments, a)
        );

        builder.addCase(payoutDetailDataFetch.pending, (s, a) => {
            s.payoutDetailData.data = initialState.payoutDetailData.data;
            setDataPending(s.payoutDetailData, a);
        });
        builder.addCase(payoutDetailDataFetch.fulfilled, (s, a) => {
            setDataSuccess(s.payoutDetailData, a);
            s.task = a.payload.payoutWrapperLite?.tasks?.length
                ? a.payload.payoutWrapperLite.tasks[0]
                : null;
        });
        builder.addCase(payoutDetailDataFetch.rejected, (s, a) => {
            setDataFail(s.payoutDetailData, a);
        });
    },
});

export const { setFromPayout } = payoutDetailSlice.actions;
export default payoutDetailSlice.reducer