import Big from "big.js";
import { ApplicationTask, ProcessInstanceId } from "./ApplicationProcess";
import { PayoutInternal } from "./Payout";
import { MpTitle } from './TitleRegister';

export type ApplicationFilter = {
    pageNumber?: number;
    pageSize?: number;

    baseFilter?: ApplicationBaseFilterTypeEnum;
    creatorName?: string;
    recipientName?: string;
    applicationID?: string;
    applicationNumber?: string;
    states?: string;
    payoutStates?: string;
    types?: string;

    creationDateFrom?: Date;
    creationDateTo?: Date;
    golemReleaseDate?: Date;

    payoutAmountFrom?: Big;
    payoutAmountTo?: Big;

    sortList?: string;
    sortOrder?: string;

    tasks?: string;
    approvers?: string;

    lastApproverName?: string;

    fromToDate?: Date;
};

export enum ApplicationBaseFilterTypeEnum {
    ACTION = "ACTION",
    OWNER = "OWNER",
    ALL = "ALL",
}

export type ApplicationId = string | number;

export interface PagedApplications {
    applicationWrapperList: ApplicationWrapper[];
    pageNumber: number;
    pageSize: number;
    recordCount: number;
}

export interface ApplicationWrapper {
    result: string,
    application: Application,
    tasks: ApplicationTask[],
    payouts?: PayoutInternal[],
    realPayoutSum: number,
    errorPayout: boolean,
    errors?: Array<string>
}

export enum ValidationResult {
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR'
}

export type ValidationErrors = {
    result: string
    errors: Array<string>
    showErrorAlert: boolean
    errorMsgId: string
}

export enum ApplicationPayoutStateEnum {
    UNPAID = "UNPAID",
    PAID = "PAID",
    WITHOUT_PAYMENT = "WITHOUT_PAYMENT",
}
export type ObjectType = {
    custom: boolean;
    defaultValue: string;
    description: string;
    golemPrefix: string;
    name: ApplicationTypeEnumKoop;
    numberPrefix: ApplicationTypeEnumKoop;
};

export interface Application {
    created: Date;
    description: string;
    fromDate: Date;
    id: ApplicationId;
    approvers: string;
    lastApproverName?: string;
    immediatePayout: boolean;
    toDate: Date;
    number: number;
    payoutState: ApplicationPayoutStateEnum;
    payoutSum: Big;
    processInstanceId: ProcessInstanceId;
    state: ApplicationStateEnum;
    type: ApplicationTypeEnumCpp | ApplicationTypeEnumKoop;
    typeObject: ObjectType;
    typeValue: string;
    mpgroup?: string;
    creatorName?: string;
    creatorUsername?: string;
    recipientName?: string;
    withoutPayment?: boolean;
    deposit?: boolean;
    businessDecision?: boolean;
    titleId?: string;
    filterByCode?: string;
    filterByName?: string;
    title?: MpTitle;
    multiApplication: boolean;
}

export enum ApplicationTypeEnumKoop {
    DMP = 'DMP',
    MOP = 'MOP',
    BON = 'BON',
    STZ = 'STZ',
    ODM = 'ODM',
    DMM = 'DMM',
    USP = 'USP',
    OST = 'OST',
    MOT = 'MOT',
}

export enum ApplicationTypeEnumCpp {
    INSURANCE_OFFICE = 'INSURANCE_OFFICE',
    OUT_OF_TERM = 'OUT_OF_TERM',
    SUPER_COMISSION = 'SUPER_COMISSION',
    COMPETITION_HEADQUATERS = 'COMPETITION_HEADQUATERS',
    COMPETITION_REGION = 'COMPETITION_REGION',
}

export enum ApplicationTaskEnum {
    applicationCreate = 'Vyplnění žádosti',
    applicationEdit = 'Úprava žádosti',
    approve_application_task = 'Schválení žádosti',
    UserTask_ZajisteniVraceniVyplaceneProvize = 'Zajištění vrácení vyplacené, ale neschválené MP',
    errorPayout = 'Chyba výplaty',
}

export const ApplicationTypeNames = {
    // koop
    [ApplicationTypeEnumKoop.DMP]: 'Dohoda o mimořádné provizi',
    [ApplicationTypeEnumKoop.MOP]: 'Mimořádný obchodní případ',
    [ApplicationTypeEnumKoop.BON]: 'Množstevní bonus',
    [ApplicationTypeEnumKoop.MOT]: 'Motivace',
    [ApplicationTypeEnumKoop.STZ]: 'Mimořádná provize za soutěže',
    [ApplicationTypeEnumKoop.ODM]: 'Mimořádná odměna',
    [ApplicationTypeEnumKoop.DMM]: 'Mimořádná odměna – makléři',
    [ApplicationTypeEnumKoop.USP]: 'Úspory z provizí',
    [ApplicationTypeEnumKoop.OST]: 'Nestandardní žádost',
    // cpp
    [ApplicationTypeEnumCpp.INSURANCE_OFFICE]: 'Pojišťovací kancelář   ',
    [ApplicationTypeEnumCpp.OUT_OF_TERM]: 'Mimo termín',
    [ApplicationTypeEnumCpp.SUPER_COMISSION]: 'Superprovize',
    [ApplicationTypeEnumCpp.COMPETITION_HEADQUATERS]:
        'Soutěž / Motivační akce na generálním ředitelství',
    [ApplicationTypeEnumCpp.COMPETITION_REGION]:
        'Soutěž / Motivační akce na regionu',
};

export const ApplicationTypeValues = {
    // koop
    [ApplicationTypeEnumKoop.DMP]: {
        enabled: false,
        value: 'DMP',
        typeValue: 'PX_DMP',
    },
    [ApplicationTypeEnumKoop.MOP]: {
        enabled: false,
        value: 'MOP',
        typeValue: 'PX_MOP',
    },
    [ApplicationTypeEnumKoop.BON]: {
        enabled: false,
        value: 'BON',
        typeValue: 'PX_BON',
    },
    [ApplicationTypeEnumKoop.MOT]: {
        enabled: false,
        value: 'MOT',
        typeValue: 'PX_MOT',
    },
    [ApplicationTypeEnumKoop.STZ]: {
        enabled: false,
        value: 'STZ',
        typeValue: 'PX_STZ',
    },
    [ApplicationTypeEnumKoop.ODM]: {
        enabled: false,
        value: 'ODM',
        typeValue: 'PX_ODM',
    },
    [ApplicationTypeEnumKoop.DMM]: {
        enabled: false,
        value: 'DMM',
        typeValue: 'PX_DMM',
    },
    [ApplicationTypeEnumKoop.USP]: {
        enabled: false,
        value: 'USP',
        typeValue: 'PX_USP',
    },
    [ApplicationTypeEnumKoop.OST]: {
        enabled: false,
        value: 'OST',
        typeValue: 'PX_OST',
    },
    // cpp
    [ApplicationTypeEnumCpp.INSURANCE_OFFICE]: { enabled: false, value: 'PPK' },
    [ApplicationTypeEnumCpp.OUT_OF_TERM]: { enabled: false, value: 'PMT' },
    [ApplicationTypeEnumCpp.SUPER_COMISSION]: { enabled: false, value: 'PSP' },
    [ApplicationTypeEnumCpp.COMPETITION_HEADQUATERS]: {
        enabled: true,
        value: 'STZ',
    },
    [ApplicationTypeEnumCpp.COMPETITION_REGION]: {
        enabled: true,
        value: 'STZ',
    },
};

export enum ApplicationStateEnum {
    NEW = "NEW",
    FOR_APPROVAL = "FOR_APPROVAL",
    APPROVED = "APPROVED",
    DISAPPROVED = "DISAPPROVED",
    RETURNED = "RETURNED",
    CANCELLED = "CANCELLED",
    FOR_APPROVAL_RETURNED = "FOR_APPROVAL_RETURNED",
}

export type ApplicationNewHistoryBody = {
    superProcessInstance: string;
    processDefinitionKeys: string[];
};
