import React, {useEffect, useState} from 'react';
import {Container, Form} from 'react-bootstrap';
import Header from '../../common/header/Header';
import {Backspace, PersonPlus} from 'react-bootstrap-icons';
import MainContent from '../../common/content/MainContent';
import {TEXTS} from '../../../constants/texts';
import {NAV} from '../../../RouteList';
import {RequiredLabel} from '../../../components/form/RequiredLabel';
import {required} from '../../../components/form/formValidations';
import {FormError} from '../../../components/form/FormError';
import {MyButton} from '../../../components/button/MyButton';
import {useForm} from 'react-hook-form';
import {MyTable} from '../../../components/table/MyTable';
import {RootState, useAppDispatch, useAppSelector} from '../../../store/store';
import {
    assignTasksFetch,
    taskAssigneeChange,
    clearTaskAssigneeAdministrationData
} from '../../../store/slices/administration/administrationSlice';
import {TableColumn} from 'react-data-table-component';
import {ApplicationTask} from '../../../services/entities/ApplicationProcess';
import TaskAssignModalForm from './TaskAssignModalForm';
import {formatCamundaUserId} from "../../../utils/formatUtils";
import {CamundaUser, UserId} from "../../../services/entities/User";

type AssigneeCellProps = {
    task: ApplicationTask,
    userMap: { [key: UserId]: CamundaUser },
    onEdit: (task: ApplicationTask) => void
}
const AssigneeCell = ({task, onEdit, userMap}: AssigneeCellProps) => {
    return <MyButton variant="link" role='button'
                     tooltip="Změnit přiřazení"
                     noYMargin className="px-0"
                     onClick={() => onEdit(task)}>
        {
            task.assignee ? formatCamundaUserId(task.assignee, userMap) : 'Nepřiřazeno'
        }
        <PersonPlus className="ms-2"/>
    </MyButton>
}

function TaskAssignnPage() {

    const dispatch = useAppDispatch()
    const {tasks, assign} = useAppSelector((s: RootState) => s.administration)
    const {register, handleSubmit, formState: {errors}} = useForm();
    const [modalTask, setModalTask] = useState<ApplicationTask>(null)
    const [applicationId, setApplicationId] = useState(null)
    const camundaUserMap = useAppSelector(s => s.applicationDetail.camundaUsers.map)

    useEffect(() => {
        dispatch(clearTaskAssigneeAdministrationData())
    }, [dispatch])

    const columns: TableColumn<ApplicationTask>[] = [
        {
            name: 'Id',
            selector: row => row.id,
            sortable: true
        },
        {
            name: 'Název',
            selector: row => row.name,
            sortable: true
        },
        {
            name: 'Řešitel',
            selector: row => row.assignee,
            cell: row => <AssigneeCell userMap={camundaUserMap}
                                       task={row} onEdit={(task) => setModalTask(task)}/>,
            sortable: true
        },
    ]

    const onSearch = (values) => {
        setApplicationId(values.applicationId)
        dispatch(assignTasksFetch(values.taskId))
    }

    const handleAssigneeConfirm = (assignee: string) => {
        dispatch(taskAssigneeChange({applicationId, taskId: modalTask.id, userId: assignee}))
            .then(() => {
                setModalTask(null)
            })
    }

    return (
        <div>
            <TaskAssignModalForm
                pending={tasks.pending || assign.pending}
                assignee={modalTask?.assignee}
                assigneeLabel={formatCamundaUserId(modalTask?.assignee, camundaUserMap)}
                onHide={() => setModalTask(null)}
                open={!!modalTask}
                onAssigneeConfirm={handleAssigneeConfirm}/>

            <Header className="mb-3"
                    actions={[
                        {
                            title: TEXTS.back,
                            icon: <Backspace/>,
                            to: NAV.INDEX
                        }
                    ]}/>

            <MainContent className="pt-5">
                <Container fluid="xxl">
                    <Form className="ApplicationForm mb-3" onSubmit={handleSubmit(onSearch)}>

                        <Form.Group className="mb-3">
                            <RequiredLabel>Db ID žádosti</RequiredLabel>
                            <Form.Control className="mw-100"
                                          {...register('applicationId', {...required()})}
                                          placeholder="Zadejte id žádosti"/>
                            <FormError errors={errors} name="applicationId"/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredLabel>Task Id</RequiredLabel>
                            <Form.Control className="mw-100"
                                          {...register('taskId', {...required()})}
                                          placeholder="Zadejte id úkolu"/>
                            <FormError errors={errors} name="taskId"/>
                        </Form.Group>

                        <div className="d-flex justify-content-end">
                            <MyButton variant="primary" type="submit" noYMargin>
                                Vyhledat úkol
                            </MyButton>
                        </div>
                    </Form>

                    <MyTable data={tasks.data}
                             className="mt-3"
                             progressPending={tasks.pending || assign.pending}
                             noDataComponent="Žádné úkoly"
                             columns={columns}/>

                </Container>
            </MainContent>
        </div>
    );
}

export default TaskAssignnPage;
