import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {PagedApplications} from '../../../services/entities/Application';
import {AppListFetchInput, exportAppList, fetchAppList} from '../../../services/api/applicationApi';
import {ApiData, defaultApiData, setDataFail, setDataPending, setDataSuccess} from '../../storeApiUtils';
import {getIdToken} from "../../../pages/common/authProvider/authProviderUtils";

export type ApplicationListState = ApiData<PagedApplications>
const initialState: ApplicationListState = defaultApiData(null)

export const appListFetch = createAsyncThunk<PagedApplications, AppListFetchInput>(
    'applicationList/fetchAppList',
    async (input) => {
        return fetchAppList(input, await getIdToken())
    })

export const appListExport = createAsyncThunk<void, AppListFetchInput>(
    'applicationList/appListExport',
    async (input) => {
        return await exportAppList(input, await getIdToken())
    }
)

const applicationListSlice = createSlice({
    name: 'applicationList',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(appListFetch.pending, s => setDataPending(s))
        builder.addCase(appListFetch.fulfilled, (s, a) => {
            setDataSuccess(s, a)
        })
        builder.addCase(appListFetch.rejected, setDataFail)
    }
})

export default applicationListSlice.reducer