import './ServerErrorMessage.scss'
import {AxiosError} from "axios";
import {OverlayTrigger, Tooltip} from "react-bootstrap"

export type ServerErrorMessageProps = {
    error: Error | AxiosError | string
}
export function ServerErrorMessage({error}: ServerErrorMessageProps) {

    let detailMessage: string
    if (error instanceof AxiosError) {
        detailMessage = error.request?.responseText
    } else if (error instanceof Error) {
        detailMessage = error.message
    } else {
        detailMessage = error
    }

    return (
        <OverlayTrigger overlay={<Tooltip>{detailMessage}</Tooltip>}>
            <div> Došlo k chybě při načítání či zpracování dat.</div>
        </OverlayTrigger>
    )
}