import {
    AttachmentKoop,
    DokuType,
    RequiredAttachmentsForTypes,
    RequiredSingleAttachment
} from '../../../services/entities/Attachment';
import { PayoutInternal } from '../../../services/entities/Payout';
import { ApplicationState } from '../../../store/slices/applicationDetail/applicationSlice';

export const validateRequiredAttachmentsKoop = (
    application: ApplicationState,
    requiredAttachments: RequiredAttachmentsForTypes,
    payouts: PayoutInternal[],
    attachments: AttachmentKoop[]): boolean => {

    if (!application.application.data || !requiredAttachments || !attachments)
        return false;

    return application.application.data?.application.multiApplication ?
        validateRequiredAttachmentsMulti(application, requiredAttachments, payouts, attachments) :
        validateRequiredAttachmentsSimple(application, requiredAttachments, attachments);
};

const selectDokuTypeByTribe = (file: RequiredSingleAttachment, tribe: string): DokuType => {
    return file?.dokuTypes.find(d => d.tribe === tribe) ||
        file?.dokuTypes.find(d => d.tribe === null);
};

const validateRequiredAttachmentsSimple = (
    application: ApplicationState,
    requiredAttachments: RequiredAttachmentsForTypes,
    attachments: AttachmentKoop[]): boolean => {

    //get required items by app type
    const requiredSingleAttachments = requiredAttachments
        .filter(r => r.applicationType === application.selectedType)
        .flatMap(el => el.items.filter(i => i.required));

    let allOnBoard = true;

    for (let i = 0; i < requiredSingleAttachments.length; i++) {
        const dokuType = selectDokuTypeByTribe(requiredSingleAttachments[i], application.tribe);

        if (!attachments.find(a => a.dokuType === dokuType?.dokuType)) {
            allOnBoard = false;
            break;
        }
    }

    return allOnBoard;
};

type TitleAndTribe = {
    titleId: string,
    tribe: string
}

const validateRequiredAttachmentsMulti = (
    application: ApplicationState,
    requiredAttachments: RequiredAttachmentsForTypes,
    payouts: PayoutInternal[],
    attachments: AttachmentKoop[]
): boolean => {
    if (!payouts || payouts.length === 0) {
        return false;
    }

    //get required items by app type
    const requiredSingleAttachments = requiredAttachments
        .filter((r) => r.applicationType === application.selectedType)
        .flatMap((el) => el.items.filter((i) => i.required));

    let allOnBoard = true;
    for (let i = 0; i < requiredSingleAttachments.length; i++) {
        const requiredSingleAttachment = requiredSingleAttachments[i];

        if (requiredSingleAttachment.forTitle) {
            allOnBoard = validateRequiredAttachmentsForTitle(
                requiredSingleAttachment,
                payouts,
                attachments
            );
            if (!allOnBoard) break;
        } else {
            const dokuType = selectDokuTypeByTribe(
                requiredSingleAttachment,
                application.tribe
            );

            if (!attachments.find((a) => a.dokuType === dokuType?.dokuType)) {
                allOnBoard = false;
                break;
            }
        }
    }

    return allOnBoard;
};

const validateRequiredAttachmentsForTitle = (
    requiredForType: RequiredSingleAttachment,
    payouts: PayoutInternal[],
    attachments: AttachmentKoop[]
): boolean => {
    const titlesAndTribes: TitleAndTribe[] = payouts.map((p) => ({
        titleId: p.title ? p.title.agreementNumber : p.agreementNumber,
        tribe: p.productType,
    }));

    let allOnBoard = true;
    for (let i = 0; i < titlesAndTribes.length; i++) {
        const t = titlesAndTribes[i];
        const dokuType = selectDokuTypeByTribe(requiredForType, t.tribe);
        if (dokuType) {
            if (
                !attachments.find(
                    (a) =>
                        a.dokuType === dokuType.dokuType &&
                        a.title?.agreementNumber === t.titleId
                )
            ) {
                allOnBoard = false;
                break;
            }
        }
    }

    return allOnBoard;
};
