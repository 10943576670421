export const takeLatestController = () => {
    let controller = new AbortController();
    return () => {
        controller.abort()
        controller = new AbortController()
        return controller.signal;
    }
}
export const authHeader = (token) => {
    return {
        'Authorization': `Bearer ${token}`
    }
}
export const apiConfig = (token: string, otherHeaders?: object, other?: object) => {
    return {
        headers: {...authHeader(token), ...otherHeaders},
        ...other
    }
}
