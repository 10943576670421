import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
    ApplicationTask,
    RequiredCommentData,
    TASK_DEF_NOT_USE_COMMENT,
    TaskEscalation
} from '../../../services/entities/ApplicationProcess';
import {Alert, Col, Container, Modal, OverlayTrigger, Row, Tooltip} from 'react-bootstrap';
import clsx from 'clsx';
import {MyButton} from '../../../components/button/MyButton';
import {Asterisk, ExclamationTriangle, Save} from 'react-bootstrap-icons';
import {Variant} from 'react-bootstrap/esm/types';
import {TEXTS} from '../../../constants/texts';
import {LoadingIndicator} from '../../../components/loadingIndicator/LoadingIndicator';
import CommentForm, {CommentFormData} from '../../ApplicationDetail/commentForm/CommentForm';
import {RootState, useAppDispatch, useAppSelector} from '../../../store/store';
import {
    setAppDirty,
    taskFinish,
} from '../../../store/slices/applicationDetail/applicationSlice';
import { getAppTaskActions } from '../../../services/api/applicationProcessApi';
import { ServerErrorMessage } from '../serverErrorMessage/ServerErrorMessage';
import { Comment } from '../../../services/entities/Comment';
import { formatEscalationButton } from '../../../utils/formatUtils';
import WithTooltip from '../../../components/withPopover/WithTooltip';
import { getIdToken } from '../authProvider/authProviderUtils';
import { UseFormTrigger } from 'react-hook-form';

type ActionProps = {
    description: string;
    label: string;
    validationMsg?: string;
    validationMsgInfo?: string;
    icon?: React.ReactNode;
    handleClick: () => void;
    validationIcon?: any;
    variant?: Variant;
    disable?: boolean;
};

function Action({
    validationIcon,
    description,
    label,
    validationMsg,
    validationMsgInfo,
    variant,
    icon,
    handleClick,
    disable,
}: ActionProps) {
    const tooltipMsg = validationMsg || validationMsgInfo;

    const IconEl = validationIcon || ExclamationTriangle;

    return (
        <div className="d-inline-block ms-4">
            <WithTooltip tooltip={description} inline>
                <MyButton
                    noYMargin
                    onClick={handleClick}
                    disabled={disable}
                    variant={variant}
                >
                    {icon && icon}
                    {icon && <>&nbsp;</>}
                    {label}
                </MyButton>
            </WithTooltip>

            {tooltipMsg && (
                <OverlayTrigger
                    overlay={
                        <Tooltip>
                            {tooltipMsg.split('\n').map((el, i) => (
                                <div key={i}>{el}</div>
                            ))}
                        </Tooltip>
                    }
                >
                    <IconEl
                        className={clsx(
                            'h4 mb-0 ms-1',
                            validationMsg ? 'text-danger' : 'text-warning'
                        )}
                    />
                </OverlayTrigger>
            )}
        </div>
    );
}

export type TaskActions2Props = {
    handleFinalSave: (esc?: TaskEscalation) => Promise<boolean>;
    handleSaveComment: (comment: Comment) => Promise<any>;
    afterFinish: (task: ApplicationTask, esc?: TaskEscalation) => void;
    task: ApplicationTask;
    handleSave?: () => void;
    finishValidationMessage?: string;
    saveValidationMessage?: string;
    error?: string;
    pending?: boolean;
    icons?: object;
    claimed: boolean;
    editable: boolean;
    triggerValidations?: UseFormTrigger<any>;
    disableSave?: boolean;
};

export function TaskActionBar2({
    task,
    handleSave,
    icons,
    pending,
    error,
    finishValidationMessage,
    saveValidationMessage,
    handleFinalSave,
    afterFinish,
    handleSaveComment,
    claimed,
    editable,
    triggerValidations,
    disableSave,
}: TaskActions2Props) {
    const dispatch = useAppDispatch();
    const user = useAppSelector((s: RootState) => s.user);

    const [taskModalHandler, setTaskModalhandler] = useState<{
        handler: (data: CommentFormData) => void;
        title: string;
        data: CommentFormData;
        esc: TaskEscalation;
    }>(null);
    const [requiredCommentData, setRequiredCommentData] =
        useState<RequiredCommentData>(null);
    const [serverError, setServerError] = useState<Error | string>(null);
    const [commentData, setCommentData] = useState<CommentFormData>({
        comment: '',
    });

    //TODO check - experimental - fetch data inside component
    const escalationsPending = useRef(false);
    const [escalations, setEscalations] = useState<{
        data: TaskEscalation[];
        pending: boolean;
    }>({ data: [], pending: false });

    const getAppTaskActionsCallback = useCallback(async () => {
        getAppTaskActions(
            task.processDefinitionId,
            task.taskDefinitionKey,
            await getIdToken()
        ).then(
            (data) => {
                escalationsPending.current = false;
                setEscalations({ data, pending: false });
            },
            (err) => {
                escalationsPending.current = false;
                setEscalations((esc) => ({ ...esc, pending: false }));
                setServerError(err);
            }
        );
    }, [task.processDefinitionId, task.taskDefinitionKey]);

    useEffect(() => {
        if (
            claimed &&
            task?.processDefinitionId &&
            !escalationsPending.current
        ) {
            escalationsPending.current = true;
            setEscalations({ data: [], pending: true });
            void getAppTaskActionsCallback();
        }
    }, [task?.processDefinitionId, claimed, getAppTaskActionsCallback]);

    //show comment -> save data & save comment -> finish task -> navigate

    const saveTaskComment = (
        task: ApplicationTask,
        data: CommentFormData
    ): Promise<any> => {
        if (data.comment) {
            return handleSaveComment({
                text: data.comment,
                taskId: task.id,
                taskName: task.name,
                userName: user.name,
            });
        } else {
            return Promise.resolve();
        }
    };

    const sendFinishTask = (
        task: ApplicationTask,
        escalation?: TaskEscalation
    ): Promise<any> => {
        return dispatch(
            taskFinish({
                escalationCode: escalation?.escalation?.escalationCode,
                taskId: task.id,
            })
        );
    };

    const finishTaskChain = async (
        task: ApplicationTask,
        esc: TaskEscalation
    ) => {
        try {
            await triggerValidations();

            if (esc.escalation || !finishValidationMessage) {
                const commentData =
                    await getCommentDataFromModalFormWhenFinishingTask(
                        task,
                        esc
                    );
                setCommentData(commentData);

                const handleFinalSaveResult = await handleFinalSave(esc);

                if (handleFinalSaveResult) {
                    await saveTaskComment(task, commentData);
                    await sendFinishTask(task, esc);
                    await afterFinish(task, esc);
                    setCommentData({ comment: '' });
                } else return;
            }
        } catch (err: any) {
            setServerError(err);
        }
    };

    const getCommentDataFromModalFormWhenFinishingTask = (
        task: ApplicationTask,
        esc: TaskEscalation
    ): Promise<CommentFormData> => {
        return new Promise((res) => {
            if (TASK_DEF_NOT_USE_COMMENT.includes(task.taskDefinitionKey)) {
                res({ comment: '' });
                return;
            }

            setRequiredCommentData({
                taskDefName: task.taskDefinitionKey,
                escalationCode: esc.escalation
                    ? esc.escalation.escalationCode
                    : null,
            });

            const handler = (data: CommentFormData) => {
                res(data);
                setRequiredCommentData(null);
                setTaskModalhandler(null);
            };

            const title = esc ? esc.extensionElements.actionLabel : task.name;

            setTaskModalhandler({ handler, title, data: commentData, esc });
        });
    };

    const handleTaskModalHide = () => {
        setRequiredCommentData(null);
        setTaskModalhandler(null);
    };

    const hasError = serverError || error;
    const message = escalations.data[0]?.documentation;
    const errEl = serverError || error;

    if (!claimed) {
        return (
            <Alert className={clsx('ActionBar')} variant="info">
                <Container fluid="xxl">
                    Nemáte nárokován tento úkol a nemůžete na něm provádět akce
                </Container>
            </Alert>
        );
    }

    return (
        <div className="position-relative">
            {(escalations.pending || pending) && <LoadingIndicator />}

            <Modal
                show={!!taskModalHandler}
                onHide={handleTaskModalHide}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title as="h5">{taskModalHandler?.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CommentForm
                        title={taskModalHandler?.title}
                        defaultData={taskModalHandler?.data}
                        requiredCommentData={requiredCommentData}
                        onSave={taskModalHandler?.handler}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <MyButton variant="secondary" onClick={handleTaskModalHide}>
                        {TEXTS.close}
                    </MyButton>
                    <MyButton
                        type="submit"
                        form="CommentForm"
                        variant="primary"
                    >
                        {formatEscalationButton(
                            taskModalHandler?.esc,
                            taskModalHandler?.title || TEXTS.send
                        )}
                    </MyButton>
                </Modal.Footer>
            </Modal>

            <Alert
                className={clsx('ActionBar')}
                variant={!!hasError ? 'danger' : 'light'}
            >
                <Container>
                    <Row className="align-items-center">
                        <Col>
                            {hasError ? (
                                <ServerErrorMessage error={errEl} />
                            ) : (
                                message
                            )}
                        </Col>
                        <Col>
                            <div className="d-flex justify-content-end">
                                {handleSave && editable ? (
                                    <Action
                                        description={TEXTS.save}
                                        label={TEXTS.save}
                                        variant="outline-primary"
                                        handleClick={handleSave}
                                        validationMsgInfo={
                                            saveValidationMessage
                                        }
                                        validationIcon={Asterisk}
                                        disable={disableSave}
                                        icon={<Save />}
                                    />
                                ) : null}
                                {escalations.data.map((el, i) => (
                                    <Action
                                        key={i}
                                        handleClick={() => {
                                            dispatch(setAppDirty(false));
                                            finishTaskChain(task, el);
                                        }}
                                        description={
                                            el.extensionElements
                                                .actionDescription
                                        }
                                        label={el.extensionElements.actionLabel}
                                        variant={
                                            i === 0 ? 'primary' : 'secondary'
                                        }
                                        validationMsg={
                                            i === 0 && finishValidationMessage
                                        }
                                        icon={icons && icons[i]}
                                    />
                                ))}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Alert>
        </div>
    );
}