import '../customFilter/CustomTitleFilterForm.scss';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { useForm } from 'react-hook-form';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { TEXTS, TITLE_FILTER_TEXTS } from '../../../constants/texts';
import { MyButton } from '../../../components/button/MyButton';
import {
    clearTitleRegisterFilter,
    setTitleRegisterFilter,
    TitleRegisterFilterState,
} from '../../../store/slices/titleRegister/titleRegisterFilterSlice';
import { useCallback, useEffect } from 'react';
import {
    initialValues,
    TitleRegisterFilter,
    TitleStructureEnum,
} from '../../../services/entities/TitleRegister';
import { FormError } from '../../../components/form/FormError';
import {
    setPagination,
    titleRegisterListFetch,
} from '../../../store/slices/titleRegister/titleRegisterSlice';
import { validateDate } from '../../../utils/validateDate';

type TitleRegisterFilterFormProps = {
    show: boolean;
    onHide: () => void;
    selectedTable: TitleStructureEnum;
};

export let initialState: TitleRegisterFilterState = {
    filterData: {
        [TitleStructureEnum.DMP]: initialValues[TitleStructureEnum.DMP],
        [TitleStructureEnum.STZ]: initialValues[TitleStructureEnum.STZ],
        [TitleStructureEnum.USP]: initialValues[TitleStructureEnum.USP],
    },
    currentFiltersTypeSet: [],
    clean: true,
};

// const resolver = (data: TitleRegisterFilter) => {
//     return {
//         values: {
//             ...data,
//             isEstimatedItem:
//                 data.isEstimatedItem === 'true'
//                     ? true
//                     : data.isEstimatedItem === 'false'
//                     ? false
//                     : null,
//             depositAllowed:
//                 data.depositAllowed === 'true'
//                     ? true
//                     : data.depositAllowed === 'false'
//                     ? false
//                     : null,
//             leftToPay:
//                 data.leftToPay === 'true'
//                     ? true
//                     : data.leftToPay === 'false'
//                     ? false
//                     : null,
//             titleType: data.titleType.toString().replaceAll(',', ';'),
//         },
//         errors: {},
//     };
// };

const TitleRegisterFilterForm = ({
    show,
    onHide,
    selectedTable,
}: TitleRegisterFilterFormProps) => {
    const filterData = useAppSelector(
        (state) => state.titleRegisterFilter.filterData[selectedTable]
    );
    const { currentFiltersTypeSet } = useAppSelector(
        (state) => state.titleRegisterFilter
    );
    const dispatch = useAppDispatch();
    const { register, handleSubmit, reset, formState, setValue } =
        useForm<TitleRegisterFilter>({
            mode: 'onChange',
            defaultValues: initialValues[selectedTable],
        });
    const { pagination } = useAppSelector((state) => {
        return state.titleRegister[state.titleRegister.selectedTitleRegister];
    });

    const checkCurrentFilterSet = useCallback(() => {
        return currentFiltersTypeSet.includes(selectedTable);
    }, [selectedTable, currentFiltersTypeSet]);

    useEffect(() => {
        if (!checkCurrentFilterSet()) {
            reset(initialValues[selectedTable]);
        } else {
            reset(filterData);
        }
    }, [selectedTable, reset, checkCurrentFilterSet, filterData]);

    const onSubmit = async (values) => {
        values = {
            ...values,
            titleType: values.titleType.toString().replaceAll(',', ';'),
            isEstimatedItem:
                values?.isEstimatedItem === 'Vybrat'
                    ? setValue('isEstimatedItem', '')
                    : values?.isEstimatedItem,
            depositAllowed:
                values?.depositAllowed === 'Vybrat'
                    ? setValue('depositAllowed', '')
                    : values?.depositAllowed,
            leftToPay:
                values?.leftToPay === 'Vybrat'
                    ? setValue('leftToPay', '')
                    : values?.leftToPay,
        };

        dispatch(
            setTitleRegisterFilter({
                titleStructureType: selectedTable,
                data: values,
            })
        );

        dispatch(
            setPagination({
                type: selectedTable,
                data: { pageSize: pagination.pageSize, pageNumber: 1 },
            })
        );

        dispatch(
            titleRegisterListFetch({ type: selectedTable, data: { ...values } })
        );
        onHide();
    };

    const handleClear = () => {
        dispatch(clearTitleRegisterFilter(selectedTable));
        dispatch(titleRegisterListFetch({ type: selectedTable }));
    };

    return (
        <Modal
            show={show}
            onHide={onHide}
            dialogClassName="CustomTitleFilterForm"
        >
            <Modal.Header closeButton>
                <Modal.Title as="h5">Vlastní filtr pro tituly</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form
                    id="CustomTitleFilterForm"
                    onSubmit={handleSubmit(onSubmit)}
                    noValidate
                >
                    <Row className="mb-2">
                        <Col sm={12} md={12} lg={6}>
                            <Form.Group className="pb-2">
                                <Form.Label>
                                    {TITLE_FILTER_TEXTS.titleType}
                                </Form.Label>
                                <Form.Select
                                    {...register('titleType')}
                                    htmlSize={
                                        initialValues[
                                            selectedTable
                                        ].titleType.split(';').length
                                    }
                                    multiple
                                >
                                    {initialValues[selectedTable].titleType
                                        .split(';')
                                        .map((type) => {
                                            return (
                                                <option key={type} value={type}>
                                                    {type}
                                                </option>
                                            );
                                        })}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="pb-2">
                                <Form.Label>
                                    {TITLE_FILTER_TEXTS.evidenceNumber}
                                </Form.Label>
                                <Form.Control {...register('evidenceNumber')} />
                            </Form.Group>
                            <Form.Group className="pb-2">
                                <Form.Label>
                                    {TITLE_FILTER_TEXTS.agreementNumber}
                                </Form.Label>
                                <Form.Control
                                    {...register('agreementNumber')}
                                />
                            </Form.Group>
                            <Form.Group className="pb-2">
                                <Form.Label>
                                    {TITLE_FILTER_TEXTS.agency}
                                </Form.Label>
                                <Form.Control {...register('agency')} />
                            </Form.Group>
                            <Form.Group className="pb-2">
                                <Form.Label>
                                    {TITLE_FILTER_TEXTS.broker}
                                </Form.Label>
                                <Form.Control {...register('broker')} />
                            </Form.Group>
                            <Form.Group className="pb-2">
                                <Form.Label>
                                    {TITLE_FILTER_TEXTS.brokerCode}
                                </Form.Label>
                                <Form.Control {...register('brokerCode')} />
                            </Form.Group>

                            <Form.Group className="pb-2">
                                <Form.Label>
                                    {TITLE_FILTER_TEXTS.brokerType}
                                </Form.Label>
                                <Form.Control {...register('brokerType')} />
                            </Form.Group>
                        </Col>

                        <Col sm={12} md={12} lg={6}>
                            <Form.Group as={Row} className="pb-2">
                                <Form.Group as={Col}>
                                    <Form.Label>
                                        {TITLE_FILTER_TEXTS.validFrom}
                                    </Form.Label>
                                    <Form.Control
                                        type="date"
                                        {...register('validFrom', {
                                            validate: validateDate,
                                        })}
                                    />

                                    <FormError
                                        errors={formState.errors}
                                        name="validFrom"
                                    />
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Label>
                                        {TITLE_FILTER_TEXTS.validTo}
                                    </Form.Label>
                                    <Form.Control
                                        type="date"
                                        {...register('validTo', {
                                            validate: validateDate,
                                        })}
                                    />
                                    <FormError
                                        errors={formState.errors}
                                        name="validTo"
                                    />
                                </Form.Group>
                            </Form.Group>

                            <Form.Group as={Row} className="pb-2">
                                <Form.Group as={Col}>
                                    <Form.Label>
                                        {TITLE_FILTER_TEXTS.titleMaxValueFrom}
                                    </Form.Label>
                                    <Form.Control
                                        type="number"
                                        {...register('titleMaxValueFrom')}
                                    />
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Label>
                                        {TITLE_FILTER_TEXTS.titleMaxValueTo}
                                    </Form.Label>
                                    <Form.Control
                                        type="number"
                                        {...register('titleMaxValueTo')}
                                    />
                                </Form.Group>
                            </Form.Group>

                            <Form.Group as={Row} className="pb-2">
                                <Form.Group as={Col}>
                                    <Form.Label>
                                        {TITLE_FILTER_TEXTS.paidOutFrom}
                                    </Form.Label>
                                    <Form.Control
                                        type="number"
                                        {...register('paidOutFrom')}
                                    />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>
                                        {TITLE_FILTER_TEXTS.paidOutTo}
                                    </Form.Label>
                                    <Form.Control
                                        type="number"
                                        {...register('paidOutTo')}
                                    />
                                </Form.Group>
                            </Form.Group>

                            <Form.Group className="pb-2 d-flex justify-content-between">
                                <Form.Group>
                                    <Form.Label>
                                        {TITLE_FILTER_TEXTS.isEstimatedItem}
                                    </Form.Label>
                                    <Form.Select
                                        defaultValue={null}
                                        {...register('isEstimatedItem')}
                                    >
                                        <option hidden value="">
                                            Vybrat
                                        </option>
                                        <option value={null}>Vybrat</option>
                                        <option value={'true'}>Ano</option>
                                        <option value={'false'}>Ne</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>
                                        {TITLE_FILTER_TEXTS.depositAllowed}
                                    </Form.Label>
                                    <Form.Select
                                        defaultValue=""
                                        {...register('depositAllowed')}
                                    >
                                        <option hidden value="">
                                            Vybrat
                                        </option>
                                        <option value={null}>Vybrat</option>
                                        <option value={'true'}>Ano</option>
                                        <option value={'false'}>Ne</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>
                                        {TITLE_FILTER_TEXTS.leftToPay}
                                    </Form.Label>
                                    <Form.Select
                                        defaultValue=""
                                        {...register('leftToPay')}
                                    >
                                        <option hidden value="">
                                            Vybrat
                                        </option>
                                        <option value={null}>Vybrat</option>
                                        <option value={'true'}>Ano</option>
                                        <option value={'false'}>Ne</option>
                                    </Form.Select>
                                </Form.Group>
                            </Form.Group>
                            <Form.Group className="pb-2">
                                <Form.Label>
                                    {TITLE_FILTER_TEXTS.incentiveEvent}
                                </Form.Label>
                                <Form.Control {...register('incentiveEvent')} />
                            </Form.Group>

                            <Form.Group className="pb-2">
                                <Form.Label>
                                    {TITLE_FILTER_TEXTS.typeOfEvent}
                                </Form.Label>
                                <Form.Control {...register('typeOfEvent')} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <MyButton
                    variant="warning"
                    onClick={handleClear}
                    className="me-3"
                >
                    {TEXTS.clear}
                </MyButton>
                <MyButton variant="secondary" onClick={onHide}>
                    {TEXTS.close}
                </MyButton>
                <MyButton
                    type="submit"
                    form="CustomTitleFilterForm"
                    disabled={!Object.keys(formState.dirtyFields).length}
                    variant="primary"
                >
                    Filtrovat
                </MyButton>
            </Modal.Footer>
        </Modal>
    );
};

export default TitleRegisterFilterForm;
