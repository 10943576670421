import {DATA_API_URL} from '../constants';
import {ApplicationId} from '../entities/Application';
import {AuthToken} from './applicationApi';
import {Comment} from '../entities/Comment';
import {PayoutId} from '../entities/Payout';
import {fetchMethods} from "../fetchUtils";

export const fetchPayoutComments = (appId: ApplicationId, payoutId: PayoutId, token: AuthToken): Promise<Comment[]> => {
    const url = `${DATA_API_URL}/applications/${appId}/payouts/${payoutId}/comments/`;
    return fetchMethods.get(url, token)
}

export const fetchPayoutCommentsForPayoutDetail = (appId: ApplicationId, payoutId: PayoutId, token: AuthToken): Promise<Comment[]> => {
    ///payouts/{idApplication}/{idPayout}/comments
    const url = `${DATA_API_URL}/payouts/${appId}/${payoutId}/comments/`;
    return fetchMethods.get(url, token)
}

export const savePayoutComment = (appId: ApplicationId, payoutId: PayoutId, comment: Comment, token: string): Promise<Comment[]> => {
    const url = `${DATA_API_URL}/applications/${appId}/payouts/${payoutId}/comments/`;
    const body = [comment]
    return fetchMethods.put(url, body, token)
}