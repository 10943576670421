import {Container, Modal} from 'react-bootstrap';
import {MyButton} from '../../../components/button/MyButton';
import {RootState, useAppDispatch, useAppSelector,} from '../../../store/store';
import {diagramsFetch} from '../../../store/slices/diagram/diagramSlice';
import React, {useEffect, useMemo, useState} from 'react';
import Viewer from 'bpmn-js/lib/NavigatedViewer';
import {addNotification} from '../../../store/slices/common/notificationsSlice';
import {LoadingIndicator} from '../../../components/loadingIndicator/LoadingIndicator';
import {COMPANIES, COMPANY} from '../../../services/constants';

export type ProcessDiagramProps = {
    showDiagram: boolean;
    setShowDiagram: React.Dispatch<React.SetStateAction<boolean>>;
};

enum ProcessIdEnum {
    MP_APPLICATION = 'mp-application',
    MP_APPROVAL_KOOP = 'mp_approval-koop',
    MP_APPROVAL_CPP = 'mp_approval-cpp',
    MP_PAYOUT_WRAPPER = 'mp-payout-wrapper',
    MP_PAYOUT_GOLEM = 'mp-payout-golem',
    MP_PAYOUT_SAPHR = 'mp-payout-saphr',
}

const translateProcessName = (process) => {
    switch (process) {
        case ProcessIdEnum.MP_APPLICATION:
            return 'Hlavní proces';
        case ProcessIdEnum.MP_APPROVAL_KOOP:
            return 'Schvalovací proces';
        case ProcessIdEnum.MP_APPROVAL_CPP:
            return 'Schvalovací proces';
        case ProcessIdEnum.MP_PAYOUT_WRAPPER:
            return 'Proces na zpracování výplat';
        case ProcessIdEnum.MP_PAYOUT_GOLEM:
            return 'Zpracování výplat v GOLEM';
        case ProcessIdEnum.MP_PAYOUT_SAPHR:
            return 'Zpracování výplaty v SAP HR';
    }
};

const ProcessDiagrams = ({
    showDiagram,
    setShowDiagram,
}: ProcessDiagramProps) => {
    const dispatch = useAppDispatch();
    const { diagram, pending } = useAppSelector(
        (s: RootState) => s.applicationDetail.diagram
    );

    const history = useAppSelector((s) => s.applicationDetail.newHistory);

    const [selectedProcess, setSelectedProcess] = useState(
        translateProcessName(ProcessIdEnum.MP_APPLICATION)
    );
    const [processId, setProcessId] = useState('');

    const currentActivities = useMemo(() => {
        if (history.data) {
            const unfinishedIds = history.data
                .filter((el) => !el.endTime)
                .filter((el) => el.activityType !== 'multiInstanceBody')
                .map((el) => {
                    return {
                        activityId: el?.activityId,
                        processKey: el.processDefinitionKey,
                    };
                });
            const result = {
                'mp-application': {},
                'mp_approval-koop': {},
                'mp_approval-cpp': {},
                'mp-payout-wrapper': {},
                'mp-payout-golem': {},
                'mp-payout-saphr': {},
                // 'mp-payout': {},
            };

            unfinishedIds.forEach(
                (obj) => {
                    if (result[obj.processKey]) {
                        obj.activityId = obj.activityId.split('#')[0];
                        result[obj.processKey][obj.activityId] =
                            (result[obj.processKey][obj.activityId] || 0) + 1;
                    }
                }

                // obj.activityId = obj.activityId.split('#')[0];
            );
            return result;
        }
    }, [history]);

    const viewer = useMemo(() => {
        return new Viewer();
    }, []);

    useEffect(() => {
        if (
            !diagram.pending &&
            viewer &&
            showDiagram &&
            currentActivities[processId]
        ) {

            // console.log('SHOWING', diagram.data.bpmn20Xml)
            viewer
                .importXML(diagram.data.bpmn20Xml)
                .then(() => {
                    viewer.attachTo('#bpmnjsContainer');
                    viewer.get('canvas').zoom('fit-viewport');

                    const overlays = viewer.get('overlays');
                    Object.entries(currentActivities[processId]).forEach(
                        (entry) => {
                            overlays.add(entry[0], {
                                position: {
                                    left: 5,
                                    bottom: 0,
                                },
                                scale: { min: 0.5 },
                                html: `<div class="badge bg-primary fs-6">${entry[1]}</div>`,
                            });
                        }
                    );
                })
                .catch(function (err) {
                    const { warnings, message } = err;
                    dispatch(
                        addNotification({
                            text: `Problems with opening diagram: ${warnings}, ${message}`,
                            variant: 'warning',
                            timeout: 10000,
                        })
                    );
                });
        } else if (viewer) {
            viewer.detach();
        }
    }, [
        currentActivities,
        showDiagram,
        viewer,
        diagram,
        dispatch,
        selectedProcess,
        processId,
    ]);

    const handleDiagram = (processId: string) => {
        setSelectedProcess(translateProcessName(processId));
        console.log('selected process', translateProcessName(processId))
        setProcessId(processId);
        return dispatch(diagramsFetch(processId));
    };

    useEffect(() => {
        setProcessId(ProcessIdEnum.MP_APPLICATION);
        dispatch(diagramsFetch(ProcessIdEnum.MP_APPLICATION));
    }, [dispatch]);

    return (
        <div>
            <Modal
                show={showDiagram}
                onHide={() => {
                    setShowDiagram(false);
                }}
                fullscreen={true}
            >
                <Modal.Header closeButton>
                    <MyButton
                        variant={`${
                            selectedProcess !==
                            translateProcessName(ProcessIdEnum.MP_APPLICATION)
                                ? 'link'
                                : 'primary'
                        } `}
                        size="sm"
                        onClick={() =>
                            handleDiagram(ProcessIdEnum.MP_APPLICATION)
                        }
                    >
                        {translateProcessName(ProcessIdEnum.MP_APPLICATION)}
                    </MyButton>

                    <MyButton
                        variant={`${
                            selectedProcess !==
                            translateProcessName(
                                COMPANY === COMPANIES.KOOP
                                    ? ProcessIdEnum.MP_APPROVAL_KOOP
                                    : ProcessIdEnum.MP_APPROVAL_CPP
                            )
                                ? 'link'
                                : 'primary'
                        } `}
                        size="sm"
                        onClick={() =>
                            handleDiagram(
                                COMPANY === COMPANIES.KOOP
                                    ? ProcessIdEnum.MP_APPROVAL_KOOP
                                    : ProcessIdEnum.MP_APPROVAL_CPP
                            )
                        }
                    >
                        {translateProcessName(
                            COMPANY === COMPANIES.KOOP
                                ? ProcessIdEnum.MP_APPROVAL_KOOP
                                : ProcessIdEnum.MP_APPROVAL_CPP
                        )}
                    </MyButton>

                    <MyButton
                        variant={`${
                            selectedProcess !==
                            translateProcessName(
                                ProcessIdEnum.MP_PAYOUT_WRAPPER
                            )
                                ? 'link'
                                : 'primary'
                        } `}
                        size="sm"
                        onClick={() =>
                            handleDiagram(ProcessIdEnum.MP_PAYOUT_WRAPPER)
                        }
                    >
                        {translateProcessName(ProcessIdEnum.MP_PAYOUT_WRAPPER)}
                    </MyButton>

                    <MyButton
                        variant={`${
                            selectedProcess !==
                            translateProcessName(ProcessIdEnum.MP_PAYOUT_GOLEM)
                                ? 'link'
                                : 'primary'
                        } `}
                        size="sm"
                        onClick={() =>
                            handleDiagram(ProcessIdEnum.MP_PAYOUT_GOLEM)
                        }
                    >
                        {translateProcessName(ProcessIdEnum.MP_PAYOUT_GOLEM)}
                    </MyButton>

                    <MyButton
                        variant={`${
                            selectedProcess !==
                            translateProcessName(ProcessIdEnum.MP_PAYOUT_SAPHR)
                                ? 'link'
                                : 'primary'
                        } `}
                        size="sm"
                        onClick={() =>
                            handleDiagram(ProcessIdEnum.MP_PAYOUT_SAPHR)
                        }
                    >
                        {translateProcessName(ProcessIdEnum.MP_PAYOUT_SAPHR)}
                    </MyButton>
                </Modal.Header>
                <Modal.Body>
                    {pending && <LoadingIndicator />}
                    <div className="w-100 h-100" id="bpmnjsContainer" />
                </Modal.Body>
            </Modal>

            <Container>
                <div className="mt-4 col text-center gy-3">
                    <div>
                        <MyButton
                            className="w-25"
                            onClick={() =>
                                handleDiagram(ProcessIdEnum.MP_APPLICATION)
                            }
                        >
                            {translateProcessName(ProcessIdEnum.MP_APPLICATION)}
                        </MyButton>
                    </div>
                    <div>
                        <MyButton
                            className="w-25"
                            onClick={() =>
                                handleDiagram(
                                    COMPANY === COMPANIES.KOOP
                                        ? ProcessIdEnum.MP_APPROVAL_KOOP
                                        : ProcessIdEnum.MP_APPROVAL_CPP
                                )
                            }
                        >
                            {translateProcessName(
                                COMPANY === COMPANIES.KOOP
                                    ? ProcessIdEnum.MP_APPROVAL_KOOP
                                    : ProcessIdEnum.MP_APPROVAL_CPP
                            )}
                        </MyButton>
                    </div>
                    <div>
                        <MyButton
                            className="w-25"
                            onClick={() =>
                                handleDiagram(ProcessIdEnum.MP_PAYOUT_WRAPPER)
                            }
                        >
                            {translateProcessName(
                                ProcessIdEnum.MP_PAYOUT_WRAPPER
                            )}
                        </MyButton>
                    </div>
                    <div>
                        <MyButton
                            className="w-25"
                            onClick={() =>
                                handleDiagram(ProcessIdEnum.MP_PAYOUT_GOLEM)
                            }
                        >
                            {translateProcessName(
                                ProcessIdEnum.MP_PAYOUT_GOLEM
                            )}
                        </MyButton>
                    </div>
                    <div>
                        <MyButton
                            className="w-25"
                            onClick={() =>
                                handleDiagram(ProcessIdEnum.MP_PAYOUT_SAPHR)
                            }
                        >
                            {translateProcessName(
                                ProcessIdEnum.MP_PAYOUT_SAPHR
                            )}
                        </MyButton>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default ProcessDiagrams;
