import React from 'react';
import './Payouts.scss'
import {MyButton} from '../../../components/button/MyButton';
import {Alert, Modal} from 'react-bootstrap';
import {TEXTS} from '../../../constants/texts';
import {PayoutInternal} from '../../../services/entities/Payout';

export type DuplicatedPayoutsMessageProps = {
    open: boolean,
    handleClose: () => void,
    handleConfirm: () => void,
    duplicates: PayoutInternal[]
}

function DuplicatedPayoutsMessage({open, handleClose, duplicates, handleConfirm}: DuplicatedPayoutsMessageProps) {

    return (
        <div className="ImportPayoutForm">
            <Modal show={open} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title as="h5" >Duplicitní výplaty</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Alert variant='warning'>
                        V žádosti došlo k nalezení {duplicates?.length} duplicitních výplat. <br />
                        Odstranění duplicit není podmínkou pro odeslání žádosti k dalšímu zpracování. <br />
                        Chcete přesto žádost odeslat k dalšímu zpracování?
                    </Alert>
                </Modal.Body>
                <Modal.Footer className="justify-content-end">
                        <div className="d-flex">
                            <MyButton variant="secondary" onClick={handleClose} className="me-2">
                                {TEXTS.no}
                            </MyButton>
                            <MyButton variant="primary" onClick={handleConfirm}>
                                {TEXTS.yes}
                            </MyButton>
                        </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default DuplicatedPayoutsMessage
