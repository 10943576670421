import { DATA_API_URL } from "../constants";
import {ApplicationId, ApplicationNewHistoryBody} from "../entities/Application";
import { NewHistory } from "../entities/NewHistory";
import { fetchMethods } from "../fetchUtils";
import {PayoutId} from "../entities/Payout";
import {ProcessHistory} from "../entities/ProcessHistory";

export const fetchAppNewHistory = (
    applicationNewHistoryBody: ApplicationNewHistoryBody,
    token: string
): Promise<NewHistory[]> => {
    const url = `${DATA_API_URL}/applications/history/process-activity-instance`;
    return fetchMethods.post(url, applicationNewHistoryBody, token);
};

export const fetchPayoutHistory = (applicationId: ApplicationId, payoutId: PayoutId, token: string): Promise<ProcessHistory[]> => {
    const url = `${DATA_API_URL}/applications/${applicationId}/payouts/${payoutId}/history/activity-instance`;
    return fetchMethods.get(url, token).then((response) => {
        return response || [];
    });
}

export const fetchPayoutHistoryForPayoutDetail = (applicationId: ApplicationId, payoutId: PayoutId, token: string): Promise<ProcessHistory[]> => {
    ///payouts/{idApplication}/{idPayout}/history/activity-instance
    const url = `${DATA_API_URL}/payouts/${applicationId}/${payoutId}/history/activity-instance`;
    return fetchMethods.get(url, token).then((response) => {
        return response || [];
    });
}