import {AxiosError} from 'axios';

export const defaultApiData = <D>(defaultValue?: D):ApiData<D> => {
    return {
        error: "",
        data: defaultValue || null,
        actionRequestId: null,
        pending: false
    }
}

export interface ApiData<D> {
    error?: string,
    data: D,
    actionRequestId?: string,
    pending: boolean
}

export const setDataPending = (state: ApiData<any>, action?: { meta: { requestId: string } }) => {
    state.error = ""
    state.pending = true
    state.actionRequestId = action?.meta.requestId
}
export const setCustomData = (state: ApiData<any>, data) => {
    state.pending = false
    state.error = ""
    state.data = data
}
export const setDataSuccess = (state: ApiData<any>, action, lazy: boolean = false) => {
    if (!lazy || state.actionRequestId === action.meta.requestId)
        setCustomData(state, action.payload)
}
export const setDataFail = (state: ApiData<any>, action) => {
    state.pending = false;
    state.error = action.error.code === AxiosError.ERR_CANCELED ? "" : action.error.stack;
}