import {Form, Modal, Stack} from 'react-bootstrap';
import {useForm, useWatch} from 'react-hook-form';
import {RequiredLabel} from '../../../components/form/RequiredLabel';
import {FormError} from '../../../components/form/FormError';
import {
    AttachmentCpp,
    DocumentTypeEnumCpp,
    MAX_ATTACHMENT_SIZE,
    MAX_ATTACHMENT_SIZE_MB,
    translateDocumentType
} from '../../../services/entities/Attachment';
import {TEXTS} from '../../../constants/texts';
import {MyButton} from '../../../components/button/MyButton';
import {useEffect, useState} from 'react';
import {pattern, required} from '../../../components/form/formValidations';
import {separateExtension} from '../../../utils/fileUtils';

type AttachmentFormProps = {
    onSave: (attachment: AttachmentCpp) => Promise<any>,
    show: boolean,
    onHide: () => void,
    attachment?: AttachmentCpp
}
type AttachmentFormData = {
    description: string,
    fileName: string,
    file: FileList,
    dokuType: DocumentTypeEnumCpp
}

function AttachmentFormModalCpp({attachment, show, onHide, onSave}: AttachmentFormProps) {
    const {register, handleSubmit, control, setValue, reset, formState: {errors}}
        = useForm<AttachmentFormData>({mode: 'onChange'});
    const watchFileList = useWatch({control, name: 'file'})
    const file = watchFileList?.length ? watchFileList[0] : null

    const [pending, setPending] = useState(false)
    const [extensionValid, setExtensionValid] = useState(true)
    const [sizeValid, setSizeValid] = useState(true)

    useEffect(() => {
        if (!show)
            reset({
                file: null,
                description: '',
                fileName: '',
                dokuType: null
            })
    }, [show, reset])

    useEffect(() => {
        if (file) {
            const parts = separateExtension(file.name)
            setExtensionValid(validateExtension(parts[1]))
            const size = file.size
            setSizeValid(size <= MAX_ATTACHMENT_SIZE)
            setValue('fileName', parts[0])
        } else {
            setExtensionValid(false)
        }
    }, [file, setValue, setSizeValid])

    const validateExtension = (extension) => {
        console.log('validateExtension', extension, /^doc|docx|xls|xlsx|jpeg|jpg|gif|png|pdf|ppt|pptx$/i.test(extension))
        return extension && /^(doc|docx|xls|xlsx|jpeg|jpg|gif|png|pdf|ppt|pptx)$/i.test(extension)
    }

    const onSubmit = (values: AttachmentFormData) => {
        if (!extensionValid || !sizeValid)
            return

        setPending(true)

        const description = values.description
        const fileList: FileList = values.file
        const file = fileList[0]
        const extension = separateExtension(file.name)[1]
        const fileName = values.fileName ? `${values.fileName}.${extension}` : file.name

        onSave({
            fileName,
            file,
            mimeType: 'application/octet-stream',
            description,
            dokuType: values.dokuType
        }).then(() => {
            setPending(false)
        })
    }

    return (
        <Modal show={show} onHide={onHide} size="lg">
            <Modal.Header closeButton>
                <Modal.Title as="h5">Nová příloha</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id='AttachmentForm'
                      onSubmit={handleSubmit(onSubmit)}>

                    <Stack className="mb-3" direction="horizontal" gap={3}>
                        {
                            !attachment ?
                                <Form.Group>
                                    <RequiredLabel>Příloha</RequiredLabel>
                                    <Form.Control className="small-input" size="sm" type="file"
                                                  disabled={!!attachment}
                                                  accept=".doc,.docx,.xls,.xlsx,.jpeg,.jpg,.gif,.png,.pdf,.ppt, .pptx"
                                                  placeholder="Vyberte soubor"
                                                  {...register("file", {...required()})} />
                                    <FormError className="small-input" errors={errors} name="file"/>
                                </Form.Group> : null
                        }

                        <Form.Group>
                            <RequiredLabel>Název</RequiredLabel>
                            <Form.Control className="small-input" size="sm"
                                          placeholder="Nové jméno"
                                          {...register('fileName', {
                                              ...required(),
                                              ...pattern(/^[\w\-.]+$/,
                                                  'Název souboru smí obsahovat jen: písmena (bez diakritiky), čísla, pomlčky, tečky.')
                                          })} />
                            <FormError className="small-input" errors={errors} name="fileName"/>
                        </Form.Group>

                        <Form.Group>
                            <RequiredLabel>Typ dokumentu</RequiredLabel>
                            <Form.Select {...register("dokuType", {...required()})} size="sm">
                                <option value="" hidden>
                                    Vyberte typ dokumentu
                                </option>
                                {
                                    Object.keys(DocumentTypeEnumCpp).map(key => (
                                        <option key={key}
                                                value={key}>{translateDocumentType(DocumentTypeEnumCpp[key])}</option>
                                    ))
                                }
                            </Form.Select>
                            <FormError className="small-input" errors={errors} name="docType"/>
                        </Form.Group>
                    </Stack>

                    {
                        file && !extensionValid &&
                        <div className="mb-3">
                            <Form.Text className="text-danger">
                                Soubor musí mít být v jednom z těchto formátů:
                                .doc,.docx,.xls,.xlsx,.jpeg,.jpg,.gif,.png,.pdf,.ppt, .pptx
                                <br/>
                            </Form.Text>
                        </div>
                    }

                    {
                        file && !sizeValid &&
                        <div className="mb-3">
                            <Form.Text className="text-danger">
                                Maximální povolená velikost souboru je {MAX_ATTACHMENT_SIZE_MB} MB.
                                <br/>
                            </Form.Text>
                        </div>
                    }

                    <Form.Group>
                        <Form.Label>{TEXTS.description}</Form.Label>
                        <Form.Control as="textarea" rows={5}
                                      {...register('description')}
                                      placeholder={TEXTS.description}/>
                        <FormError className="small-input" errors={errors} name="description"/>
                    </Form.Group>

                </Form>
            </Modal.Body>
            <Modal.Footer>
                <MyButton variant="secondary" onClick={onHide}>
                    {TEXTS.close}
                </MyButton>
                <MyButton type="submit" form="AttachmentForm"
                          disabled={pending}
                          variant="primary">
                    {TEXTS.save}
                </MyButton>
            </Modal.Footer>
        </Modal>
    )
}

export default AttachmentFormModalCpp;
