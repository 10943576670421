import React, {useMemo, useState} from "react";

import {USER_TASK_ACTIVITY_TYPE} from "../../../services/entities/ProcessHistory";
import {Container, Form, Row} from "react-bootstrap";
import {TextItem} from "../../../components/textItem/TextItem";
import WithTooltip from "../../../components/withPopover/WithTooltip";
import {RootState, useAppSelector,} from "../../../store/store";
import {MyTable} from "../../../components/table/MyTable";
import {GearFill, PersonFill} from "react-bootstrap-icons";
import {formatCamundaUserId, formatDateTime,} from "../../../utils/formatUtils";

import {ProcessInstanceId} from "../../../services/entities/ApplicationProcess";
import {NewHistoryState} from "../../../store/slices/applicationDetail/applicationNewHistorySlice";
import {MP_APPROVAL_ADDITION, MP_APPROVAL_ID} from './ProcessHistoryTableTabs';

export type NewHistoryTableProps = {
    processKey?: string;
    historyData: NewHistoryState;
};

function NewHistoryTable({processKey, historyData}: NewHistoryTableProps) {
    const {data} = useAppSelector(
        (s: RootState) => s.applicationDetail.newHistory
    );
    const camundaUsersMap = useAppSelector(
        (s: RootState) => s.applicationDetail.camundaUsers.map
    );

    const [onlyUserTasks, setOnlyUserTasks] = useState(true);

    const filteredTable = data.filter(
        (d) => d.processDefinitionKey === processKey
                         //https://jira.vig.cz/browse/UNICORN-377
                         || (processKey === MP_APPROVAL_ID
                             && d.processDefinitionKey === MP_APPROVAL_ADDITION
                             && d.activityType === USER_TASK_ACTIVITY_TYPE)
            );

    const filteredData = useMemo(() => {
        if (historyData.pending) return [];

        if (!onlyUserTasks) return filteredTable;

        return filteredTable.filter(
            (el) => el.activityType === USER_TASK_ACTIVITY_TYPE
        );
    }, [filteredTable, onlyUserTasks, historyData.pending]);

const superProcessInstance: ProcessInstanceId = useMemo(() => {
    return filteredTable.length ? filteredTable[0].processInstanceId : '';
}, [filteredTable]);

const subHeaderComponentMemo = React.useMemo(() => {
    const handleChange = () => {
        setOnlyUserTasks((value) => !value);
    };

    return (
        <div className="d-flex">
            <WithTooltip tooltip="Filtrovat seznam položek historie">
                <Form.Check
                    checked={onlyUserTasks}
                    type="switch"
                    onChange={handleChange}
                    label="Pouze uživatelské kroky"
                />
            </WithTooltip>
        </div>
    );
}, [onlyUserTasks]);

const columns = useMemo(
    () => [
        {
            name: 'Procesní krok',
            selector: (row) => row.activityName,
            cell: (row) => (
                <span>
                    {row.activityName} <br />
                    <small className="text-muted">({row.activityId})</small>
                </span>
            ),
            sortable: true,
        },
        {
            name: 'Jméno',
            selector: (row) => row.assignee,
            cell: (row) => (
                <span>
                    {row.activityType === USER_TASK_ACTIVITY_TYPE && (
                        <PersonFill className="me-1" />
                    )}
                    {row.activityType === USER_TASK_ACTIVITY_TYPE &&
                        formatCamundaUserId(row.assignee, camundaUsersMap)}
                    {row.activityType === USER_TASK_ACTIVITY_TYPE &&
                        !row.assignee &&
                        'Nepřiřazeno'}
                    {row.activityType !== USER_TASK_ACTIVITY_TYPE && (
                        <GearFill className="me-1" />
                    )}
                    {row.activityType !== USER_TASK_ACTIVITY_TYPE && 'Systém'}
                </span>
            ),
            sortable: true,
        },
        {
            name: 'Začátek',
            id: 'startTime',
            selector: (row) => row.startTime?.toString(),
            cell: (row) => formatDateTime(row.startTime),
            sortable: true,
        },
        {
            name: 'Konec',
            selector: (row) => row.endTime?.toString(),
            cell: (row) =>
                row.endTime ? formatDateTime(row.endTime) : 'Nedokončeno',
            sortable: true,
        },
    ],
    [camundaUsersMap]
);

return (
    <div>
        <Container fluid="xxl" className="mb-3">
            <Row>
                <TextItem
                    title="ID procesní instance"
                    value={superProcessInstance}
                />
            </Row>
        </Container>
        <div className="ProcessHistoryTable position-relative">
            <MyTable
                columns={columns}
                defaultSortFieldId="startTime"
                defaultSortAsc={false}
                data={filteredData}
                progressPending={historyData.pending}
                subHeader={!!subHeaderComponentMemo}
                subHeaderComponent={subHeaderComponentMemo}
                pagination
            />
        </div>
    </div>
);
}

export default NewHistoryTable;
