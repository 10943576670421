import React from 'react';
import { Alert, Modal } from 'react-bootstrap';
import { MyButton } from '../../../components/button/MyButton';
import { TEXTS } from '../../../constants/texts';

export type PayoutsExistMessageProps = {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    handleRemovePayouts: () => void;
    multiApplication: boolean;
};

const PayoutsExistMessage = ({
    open,
    setOpen,
    handleRemovePayouts,
    multiApplication,
}: PayoutsExistMessageProps) => {
    return (
        <div>
            <Modal
                show={open}
                onHide={() => {
                    setOpen(false);
                }}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title as="h5">Existující výplaty</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Alert variant="warning">
                        {multiApplication
                            ? 'Změna typu mimořádné provize '
                            : '  Změna titulu '}
                        může mít dopad na validnost již přítomných výplat
                        žádosti (titul může např. limitovat získatele nebo
                        kmeny, pro které je možné výplatu zadat). <br /> Přejete
                        si vymazat všechny existující výplaty? <br /> Pokud ne,
                        zrevidujte a případně upravte seznam výplat
                        individuálně. Nebude možné odeslat žádost s výplatami,
                        které nebudou korespondovat se zvoleným titulem.
                    </Alert>
                </Modal.Body>
                <Modal.Footer className="justify-content-end">
                    <div className="d-flex">
                        <MyButton
                            variant="secondary"
                            onClick={() => {
                                setOpen(false);
                            }}
                            className="me-2"
                        >
                            {TEXTS.no}
                        </MyButton>
                        <MyButton
                            variant="primary"
                            onClick={() => {
                                handleRemovePayouts();
                                setOpen(false);
                            }}
                        >
                            {TEXTS.yes}
                        </MyButton>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default PayoutsExistMessage;
