import React, {useEffect, useMemo, useState} from 'react';
import {Container} from 'react-bootstrap';
import Header from '../common/header/Header';
import {Backspace} from 'react-bootstrap-icons';
import MainContent from '../common/content/MainContent';
import {NAV} from '../../RouteList';
import { useNavigate, useParams } from 'react-router-dom';
import {RootState, useAppDispatch, useAppSelector} from '../../store/store';
import AppOverview from '../common/appOverview/AppOverview';
import {CLAIMED_TASKS, resolveTaskState, TaskStateEnum} from '../common/resolveTaskState';
import ClaimTask from '../common/claimTask/ClaimTask';
import {useForm} from 'react-hook-form';
import {TaskActionBar2} from '../common/taskActions2/TaskActionBar2';
import {ApplicationTask, TaskEscalation} from '../../services/entities/ApplicationProcess';
import {addNotification} from '../../store/slices/common/notificationsSlice';
import {Comment} from '../../services/entities/Comment';
import {LoadingIndicator} from '../../components/loadingIndicator/LoadingIndicator';
import PayoutHistoryAndComments from '../ApplicationDetail/history/payout/PayoutHistoryAndComments';
import {
    payoutCommentSave,
    payoutDatesSave,
    payoutDetailDataFetch,
    refreshPayoutErrorTask
} from '../../store/slices/payoutDetail/payoutDetailSlice';
import PayoutDetailForm from './payoutDetailForm/PayoutDetailForm';
import clsx from 'clsx';
import {clearApplication} from '../../store/slices/applicationDetail/applicationSlice';
import { PayoutDetailDataPayout } from '../../services/entities/PayoutDetailWrapper';
import { hasIntersection } from '../../utils/utils';
import { MP_APPLICATION_ROLES } from '../../services/entities/User';

function PayoutDetailPage() {
    const params = useParams();
    const dispatch = useAppDispatch();
    const { payoutDetailData, task, pendingSave, fromPayout } = useAppSelector(
        (s: RootState) => s.payouDetail
    );
    const pending = payoutDetailData.pending;
    const payoutWrapper = payoutDetailData.data?.payoutWrapperLite;
    const applicationWrapper = payoutDetailData.data?.applicationWrapper;
    const payout = payoutWrapper?.payoutLite;
    const application = applicationWrapper?.application;

    // const history: HistoryWrapper = useOutletContext();
    // const fromAppDetail = useMemo(() => {
    //     return history[history.length - 2]?.pathname.includes(
    //         'application/detail'
    //     );
    // }, [history]);

    useEffect(() => {
        if (params) {
            dispatch(
                payoutDetailDataFetch({
                    applicationId: params.applicationId,
                    payoutId: params.payoutId,
                })
            );
        }
    }, [params, dispatch]);

    const formContext = useForm<PayoutDetailDataPayout>({
        defaultValues: { ...payout },
    });
    const isValid = formContext.formState.isValid;

    const user = useAppSelector((s: RootState) => s.user);
    const state: TaskStateEnum = useMemo(() => {
        return resolveTaskState(
            user?.login,
            task?.assignee,
            task?.taskDefinitionKey
        );
    }, [user?.login, task?.assignee, task?.taskDefinitionKey]);

    const editable = state === TaskStateEnum.CLAIMED_EDITABLE_TASK;
    const claimed = CLAIMED_TASKS.includes(state);
    const navigate = useNavigate();

    const [disabledData, setDisabledData] = useState(false);

    const afterFinish = (task: ApplicationTask) => {
        dispatch(clearApplication());
        navigate(fromPayout ? NAV.PAYOUTS : NAV.INDEX);

        dispatch(
            addNotification({
                text: `Úkol ${task.name} splněn`,
            })
        );
    };

    useEffect(() => {
        if (payout) formContext.reset(payout);
    }, [formContext, payout]);

    const handleFinalSave = (esc: TaskEscalation): Promise<boolean> => {
        if (!esc) return Promise.resolve(true);

        const values = formContext.getValues();
        const fromDate = values.fromDate;
        const toDate = values.toDate;

        if (fromDate || toDate) {
            return dispatch(
                payoutDatesSave({
                    payout: payout,
                    applicationId: application?.id,
                    toDate,
                    fromDate,
                })
            ).then(
                () => {
                    setDisabledData(true);
                    return true;
                },
                () => null
            );
        }

        return Promise.resolve(true);
    };

    const handleSaveComment = (comment: Comment) => {
        return dispatch(
            payoutCommentSave({
                comment,
                applicationId: application?.id,
                payout: payout,
            })
        );
    };

    const handleAfterClaimingAction = () => {
        dispatch(refreshPayoutErrorTask(task.processInstanceId));
    };

    return (
        <div className="position-relative">
            {pending || pendingSave ? <LoadingIndicator /> : null}

            <Header
                className="mb-3"
                actions={[
                    {
                        title: 'Zpět',
                        icon: <Backspace />,
                        action: () => navigate(-1),
                    },
                ]}
            />

            {state !== TaskStateEnum.NO_TASK ? (
                <Container fluid="xxl" className="mb-3">
                    <ClaimTask
                        state={state}
                        task={task}
                        onAfterAction={handleAfterClaimingAction}
                    />
                </Container>
            ) : null}

            {payoutWrapper?.golemError && (
                <Container fluid="xxl" className="mb-3">
                    <div className="p-3 border border-warning">
                        <h5>
                            Chybová zpráva ze systému{' '}
                            {task?.processDefinitionId?.includes('saphr')
                                ? 'SAPHR'
                                : 'GOLEM'}
                        </h5>
                        <p>{payoutWrapper?.golemError}</p>
                    </div>
                </Container>
            )}

            <AppOverview
                className="mb-3"
                title={task ? `${task.name} (data žádosti)` : 'Data žádosti'}
                application={applicationWrapper}
                task={task}
                useLink={
                    hasIntersection(MP_APPLICATION_ROLES, user.roles) && true
                }
                pending={false}
            />

            {state !== TaskStateEnum.NO_TASK ? (
                <TaskActionBar2
                    task={task}
                    triggerValidations={formContext.trigger}
                    claimed={claimed}
                    editable={editable}
                    afterFinish={afterFinish}
                    handleFinalSave={handleFinalSave}
                    handleSaveComment={handleSaveComment}
                    finishValidationMessage={
                        !isValid && 'Zadejte validní hodnoty ve formuláři níže.'
                    }
                    pending={false}
                />
            ) : (
                <Container fluid="xxl" className="mt-3">
                    <hr />
                </Container>
            )}

            <MainContent
                className={clsx(state === TaskStateEnum.NO_TASK ? '' : 'pt-3')}
            >
                <Container fluid="xxl">
                    <h5 className="mb-3">Parametry výplaty</h5>
                    <PayoutDetailForm
                        editable={editable}
                        className="mb-3"
                        payout={payout}
                        formContext={formContext}
                    />
                    <hr className="mt-3 mb-3" />
                    {payout && (
                        <PayoutHistoryAndComments
                            disabled={disabledData}
                            applicationId={params.applicationId}
                            payoutId={params.payoutId}
                        />
                    )}
                </Container>
            </MainContent>
        </div>
    );
}

export default PayoutDetailPage;
