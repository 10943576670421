import { useLocation, Location } from "react-router-dom";
import {useEffect, useState} from 'react';

export type HistoryWrapper = Location[]
export function useListenHistory(): HistoryWrapper {
    const location: Location = useLocation();
    const [history, setHistory] = useState([location])

    useEffect(() => {
        setHistory(history =>  [...history, location])
    }, [location]);

    return history
}