import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { MyButton } from '../../components/button/MyButton';
import {
    login,
    setTokenMocked,
} from '../common/authProvider/authProviderUtils';
import { LoadingIndicator } from '../../components/loadingIndicator/LoadingIndicator';
import MockAuthProviderModal from '../common/authProvider/MockAuthProviderModal';
import { User } from '../../services/entities/User';
import { setUserAndLoadData } from '../../store/slices/common/userSlice';
import { useAppDispatch, useAppSelector } from '../../store/store';
// import { DoorOpen } from 'react-bootstrap-icons';
import { formatTokenError } from '../../utils/formatUtils';
// import { APP_ENV, ENVS } from '../../services/constants';

function LoginPage() {
    const [searchParams] = useSearchParams();
    const previousLocation = decodeURIComponent(
        searchParams.get('previousLocation')
    );
    const [showDevModal, setShowDevModal] = useState(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const tokenError = useAppSelector((s) => s.user.tokenError);
    const useDevLink =
        process.env.REACT_APP_DEVELOPMENT?.toLowerCase() === 'true';

    const [pending, setPending] = useState(false);

    const handleMockLogin = (user: User) => {
        setPending(true);
        setTokenMocked(user.token);
        dispatch(
            setUserAndLoadData({
                login: user.login,
                mocked: true,
            })
        ).then(() => {
            setPending(false);
            console.log('NAVIGATING', previousLocation || '/');
            navigate(previousLocation);
        });
    };

    // const handleLogin = () => {
    //     setPending(true);
    //     login(previousLocation || '').then(() => {
    //         setPending(false);
    //     });
    // };

    useEffect(() => {
        setPending(true);
        login(previousLocation || '').then(() => {
            setPending(false);
        });
    }, [previousLocation]);

    // const isNotProd = APP_ENV !== ENVS.PROD;

    return (
        <Container fluid="xxl" as="div" className="p-3 text-center">
            {/* {isNotProd && <h3 className="text-danger pb-5">! TEST !</h3>}

            <h3>Nepřihlášený uživatel</h3>

            <hr className="mb-4" /> */}

            {useDevLink && (
                <MockAuthProviderModal
                    show={showDevModal}
                    pending={pending}
                    onHide={() => setShowDevModal(false)}
                    onMockLogin={handleMockLogin}
                />
            )}

            {/* {pending && <LoadingIndicator title="Probíhá přihlašování" />} */}
            <div className="mb-5" />
            {pending && <LoadingIndicator title="Probíhá načítání" />}

            <div className="d-flex justify-content-between align-items-end">
                {/* <MyButton
                    onClick={handleLogin}
                    className="d-flex align-items-center"
                    size="lg"
                >
                    <DoorOpen className="me-2" />
                    Přihlásit se přes ID Port
                </MyButton> */}

                {useDevLink && (
                    <MyButton
                        className="ps-0"
                        size="sm"
                        variant="link"
                        onClick={() => setShowDevModal(true)}
                    >
                        <small>
                            (nebo použít mock přihlášení pro vývojáře)
                        </small>
                    </MyButton>
                )}
            </div>

            {tokenError && (
                <div className="pt-3">
                    <h5 className="text-danger">
                        Došlo k chybě při procesu přihlašování! <br />
                        Zkuste to prosím znovu, nebo kontaktujte podporu.
                    </h5>
                    <p>Detail: {formatTokenError(tokenError)}</p>
                </div>
            )}
            <div></div>
        </Container>
    );
}

export default LoginPage;
