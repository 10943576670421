import './CustomFilterForm.scss';
import {Col, Form, Modal, Row} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {useEffect} from 'react';
import {APP_TEXTS, TEXTS} from '../../../constants/texts';
import {MyButton} from '../../../components/button/MyButton';
import {RootState, useAppDispatch, useAppSelector} from '../../../store/store';
import {clearFilter, initialValues, setFilter} from '../../../store/slices/applicationList/applicationListFilterSlice';
import {formatAppPayoutState, formatAppState} from '../../../utils/formatUtils';
import {
    ApplicationFilter,
    ApplicationPayoutStateEnum,
    ApplicationStateEnum, ApplicationTaskEnum,
    ApplicationTypeNames
} from '../../../services/entities/Application';
import { IS_KOOP, TYPE_ENUM_FOR_COMPANY } from '../../../services/constants';

export type CustomFilterFormProps = {
    show: boolean,
    onHide: () => void
}

function CustomFilterForm({show, onHide}: CustomFilterFormProps) {

    const dispatch = useAppDispatch();
    const filter = useAppSelector((s: RootState) => s.applicationListFilter.filter)
    const {register, handleSubmit, reset, formState} = useForm<ApplicationFilter>({
        defaultValues: initialValues
    });

    useEffect(() => {
        if (filter)
            reset(filter)
    }, [reset, filter])

    const onSubmit = (values: ApplicationFilter) => {
        const data = {
            ...values,
            states: fromArray(values.states),
            payoutStates: fromArray(values.payoutStates),
            types: fromArray(values.types)
        }

        dispatch(setFilter(data))
        onHide()
    }

    const handleClear = () => {
        dispatch(clearFilter())
    }

    const fromArray = val => Array.isArray(val) ? val.join(',') : val

    return (
        <Modal show={show} onHide={onHide} dialogClassName="CustomFilterForm">
            <Modal.Header closeButton>
                <Modal.Title as="h5">Vlastní filtr</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="CustomFilterForm" onSubmit={handleSubmit(onSubmit)}>
                    <Row className="mb-2">
                        <Col sm={12} md={6} lg={3}>
                            <Form.Group className="pb-2">
                                <Form.Label>{APP_TEXTS.number}</Form.Label>
                                <Form.Control
                                    {...register('applicationNumber')}
                                />
                            </Form.Group>
                            <Form.Group className="pb-2">
                                <Form.Label>
                                    {APP_TEXTS.applicationID}
                                </Form.Label>
                                <Form.Control {...register('applicationID')} />
                            </Form.Group>
                            <Form.Group className="pb-2">
                                <Form.Label>{APP_TEXTS.creatorName}</Form.Label>
                                <Form.Control {...register('creatorName')} />
                            </Form.Group>
                            <Form.Group className="pb-2">
                                <Form.Label>Role schvalovatele</Form.Label>
                                <Form.Control {...register('approvers')} />
                            </Form.Group>
                            <Form.Group className="pb-2">
                                <Form.Label>Jméno schvalovatele</Form.Label>
                                <Form.Control
                                    {...register('lastApproverName')}
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={12} md={6} lg={3}>
                            <Form.Group className="pb-2">
                                <Form.Label>{APP_TEXTS.state}</Form.Label>
                                <Form.Select
                                    {...register('states')}
                                    multiple
                                    htmlSize={6}
                                >
                                    {Object.keys(ApplicationStateEnum).map(
                                        (key) => (
                                            <option key={key} value={key}>
                                                {formatAppState(
                                                    ApplicationStateEnum[key]
                                                )}
                                            </option>
                                        )
                                    )}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="pb-2">
                                <Form.Label>{APP_TEXTS.payoutState}</Form.Label>
                                <Form.Select
                                    {...register('payoutStates')}
                                    multiple
                                    htmlSize={3}
                                >
                                    {Object.keys(
                                        ApplicationPayoutStateEnum
                                    ).map((key) => (
                                        <option key={key} value={key}>
                                            {formatAppPayoutState(
                                                ApplicationPayoutStateEnum[key]
                                            )}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col sm={12} md={6} lg={3}>
                            <Form.Group className="pb-2">
                                <Form.Label>Úkol</Form.Label>
                                <Form.Select
                                    {...register('tasks')}
                                    multiple
                                    htmlSize={5}
                                >
                                    {Object.keys(ApplicationTaskEnum).map(
                                        (key) => (
                                            <option key={key} value={key}>
                                                {ApplicationTaskEnum[key]}
                                            </option>
                                        )
                                    )}
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="pb-2">
                                <Form.Label>{APP_TEXTS.type}</Form.Label>
                                <Form.Select
                                    {...register('types')}
                                    multiple
                                    htmlSize={5}
                                >
                                    {Object.keys(TYPE_ENUM_FOR_COMPANY).map(
                                        (key) => (
                                            <option key={key} value={key}>
                                                {
                                                    ApplicationTypeNames[
                                                        TYPE_ENUM_FOR_COMPANY[
                                                            key
                                                        ]
                                                    ]
                                                }{' '}
                                                {IS_KOOP && `(${key})`}
                                            </option>
                                        )
                                    )}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col sm={12} md={6} lg={3}>
                            <Form.Group as={Row} className="pb-2">
                                <Form.Group as={Col}>
                                    <Form.Label>
                                        {APP_TEXTS.creationDateFrom}
                                    </Form.Label>
                                    <Form.Control
                                        type="date"
                                        {...register('creationDateFrom')}
                                    />
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Label>
                                        {APP_TEXTS.creationDateTo}
                                    </Form.Label>
                                    <Form.Control
                                        type="date"
                                        {...register('creationDateTo')}
                                    />
                                </Form.Group>
                            </Form.Group>

                            <Form.Group className="pb-2">
                                <Form.Label>Období</Form.Label>
                                <Form.Control
                                    type="date"
                                    {...register('fromToDate')}
                                />
                            </Form.Group>

                            <Form.Group as={Row} className="pb-2">
                                <Form.Group as={Col}>
                                    <Form.Label>
                                        {APP_TEXTS.payoutAmountFrom}
                                    </Form.Label>
                                    <Form.Control
                                        type="number"
                                        {...register('payoutAmountFrom')}
                                    />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>
                                        {APP_TEXTS.payoutAmountTo}
                                    </Form.Label>
                                    <Form.Control
                                        type="number"
                                        {...register('payoutAmountTo')}
                                    />
                                </Form.Group>
                            </Form.Group>

                            <Form.Group className="pb-2">
                                <Form.Label>
                                    {APP_TEXTS.recipientName}
                                </Form.Label>
                                <Form.Control {...register('recipientName')} />
                            </Form.Group>

                            <Form.Group className="pb-2">
                                <Form.Label>
                                    {APP_TEXTS.golemReleaseDate}
                                </Form.Label>
                                <Form.Control
                                    type="date"
                                    {...register('golemReleaseDate')}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <MyButton
                    variant="warning"
                    onClick={handleClear}
                    className="me-3"
                >
                    {TEXTS.clear}
                </MyButton>
                <MyButton variant="secondary" onClick={onHide}>
                    {TEXTS.close}
                </MyButton>
                <MyButton
                    type="submit"
                    form="CustomFilterForm"
                    disabled={!formState.isDirty}
                    variant="primary"
                >
                    Filtrovat
                </MyButton>
            </Modal.Footer>
        </Modal>
    );
}

export default CustomFilterForm;
