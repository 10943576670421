import React, {useContext, useEffect, useMemo, useState} from 'react';
import './Payouts.scss';
import {
    ExpanderComponentProps,
    TableColumn,
} from 'react-data-table-component';
import {
    getDuplicates,
    Payout,
    PayoutInternal,
    ProductTypeNames,
} from '../../../services/entities/Payout';
import {
    formatAmount,
    formatDate,
    formatDateAsMonth,
    formatPayoutState,
} from '../../../utils/formatUtils';
import {MyButton} from '../../../components/button/MyButton';
import NewPayoutFormKoop from './NewPayoutFormKoop';
import {Alert, Col, Form, Modal, Row} from 'react-bootstrap';
import {TEXTS} from '../../../constants/texts';
import {
    RootState,
    useAppDispatch,
    useAppSelector,
} from '../../../store/store';
import {
    PayoutsState,
    putPayout,
    removePayout,
    setPayoutsPaginationSort,
} from '../../../store/slices/applicationDetail/payoutsSlice';
import {LoadingIndicator} from '../../../components/loadingIndicator/LoadingIndicator';
import {
    Binoculars,
    ExclamationTriangle,
    PencilSquare,
    Search,
    Trash3,
} from 'react-bootstrap-icons';
import {MyTable} from '../../../components/table/MyTable';
import ImportPayoutForm from './ImportPayoutForm';
import {
    ApplicationTypeEnumKoop,
    ApplicationWrapper,
} from '../../../services/entities/Application';
import {AppFormContext} from '../application-detail';
import clsx from 'clsx';
import {taskColumn} from '../../common/taskColumn/taskColumn';
import {NAV} from '../../../RouteList';
import Confirmation from '../../../components/confirmation/Confirmation';
import TableAction from '../../../components/table/TableAction';
import BrokerPopover from './BrokerPopover';
import WithPopover from '../../../components/withPopover/WithPopover';
import {RequiredLabel} from '../../../components/form/RequiredLabel';
import WithTooltip from '../../../components/withPopover/WithTooltip';
import {
    setPayoutSumValid,
    titleRegisterListTitleFilterFetch,
} from '../../../store/slices/applicationDetail/applicationSlice';
import {useForm, useWatch} from 'react-hook-form';
import {FormError} from '../../../components/form/FormError';
import {required} from '../../../components/form/formValidations';
import Big from 'big.js';
import {Link} from 'react-router-dom';
import {generateId} from '../../../utils/generateId';
import {addNotification} from '../../../store/slices/common/notificationsSlice';
import dayjs from 'dayjs';
import {IS_KOOP} from '../../../services/constants';
import NewPayoutFormCpp from './NewPayoutFormCpp';
import { setFromPayout } from '../../../store/slices/payoutDetail/payoutDetailSlice';

export type PayoutsProps = {
    disabled?: boolean;
};

function Payouts({disabled}: PayoutsProps) {
    const payouts: PayoutsState = useAppSelector(
        (state: RootState) => state.applicationDetail.payouts
    );
    const paginationSortAppId = payouts.paginationAndSort.applicationId;
    const sort = payouts.paginationAndSort.sort;
    const pagination = payouts.paginationAndSort.pagination;
    const [showFormModal, setShowFormModal] = useState(false);
    const [showImportModal, setShowImportModal] = useState(false);
    const [editPayout, setEditPayout] = useState(null);
    const application: ApplicationWrapper = useAppSelector(
        (s: RootState) => s.applicationDetail.application.application.data
    );
    const appFormContext = useContext(AppFormContext);
    const appFormFromDate = appFormContext.watch('fromDate');
    const appFormToDate = appFormContext.watch('toDate');
    const appFormPayoutSum = appFormContext.watch('payoutSum');

    // const { brokerCodes } = useAppSelector(
    //     (s) => s.applicationDetail.application
    // );

    const dispatch = useAppDispatch();

    const multiApplication = useAppSelector(
        (state) =>
            state?.applicationDetail?.application?.application?.data
                ?.application?.multiApplication
    );

    const {
        handleSubmit,
        setValue,
        register,
        reset,
        trigger,
        formState: { errors },
        control,
    } = useForm({ mode: 'onChange' });

    const payoutSumWatch = useWatch({ name: 'payoutSum', control });

    useEffect(() => {
        setValue('payoutSum', application?.application?.payoutSum);
    }, [application, setValue]);

    const handlePayoutSumChange = (e) => {
        appFormContext.setValue('payoutSum', e.target.value);
    };

    const realPayoutSum: Big = useMemo(() => {
        return payouts.data
            .map((p) => p.amount)
            .reduce((acc: Big, a) => acc.plus(a), new Big(0));
    }, [payouts]);

    useEffect(() => {
        appFormPayoutSum && reset({ payoutSum: appFormPayoutSum });
    }, [appFormPayoutSum, reset]);

    useEffect(() => {
        trigger && trigger('payoutSum');
    }, [trigger, payoutSumWatch, realPayoutSum]);

    useEffect(() => {
        const isValid = realPayoutSum.eq(payoutSumWatch || 0);
        dispatch(setPayoutSumValid(isValid));
    }, [dispatch, payoutSumWatch, realPayoutSum]);

    const validatePayoutSum = useMemo(() => {
        return {
            payoutSum: (val) =>
                realPayoutSum.eq(val) ||
                'Očekávaná suma výplat a skutečná suma částek výplat musí být shodné.',
        };
    }, [realPayoutSum]);
    const appFormLogic = useContext(AppFormContext);

    const appType: ApplicationTypeEnumKoop = useWatch({
        name: 'type',
        control: appFormLogic.control,
        disabled: !IS_KOOP,
    }) as ApplicationTypeEnumKoop;

    const formatPayoutDates = (row: PayoutInternal): React.ReactNode => {
        const hasDates = !!row.fromDate;
        const fromDate = hasDates ? row.fromDate : appFormFromDate;
        const toDate = hasDates ? row.toDate : appFormToDate;

        if (!fromDate || !toDate) return <span className="text-muted">--</span>;

        const msg = `${formatDateAsMonth(fromDate)} - ${formatDateAsMonth(
            toDate
        )}`;
        return <span className={clsx(!hasDates && 'text-muted')}>{msg}</span>;
    };

    function getProductTypeDescription(productType) {
        const payoutTribes = payouts.tribes.find(
            (obj) => obj.code === productType
        );
        if (payoutTribes) {
            return payoutTribes.description;
        } else {
            return ProductTypeNames[productType];
        }
    }

    const columns: TableColumn<PayoutInternal>[] = [
        {
            id: 'Detail',
            name: 'Detail',
            width: '45px',
            selector: (row) => (row.tasks ? row.tasks.length : 0),
            cell: (row) =>
                !row.isNew && (
                    <TableAction
                        tooltip="Zobrazit detail výplaty"
                        innerProps={{ 'data-id': row.id }}
                        large
                    >
                        <Link
                            to={NAV.PAYOUT_DETAIL(
                                application?.application.id,
                                row.id
                            )}
                        >
                            <Search />
                        </Link>
                    </TableAction>
                ),
        },
        {
            id: 'state',
            name: 'Stav',
            width: '90px',
            selector: (row) => row.state,
            format: (row) => formatPayoutState(row.state),
        },
        {
            name: 'Titul',
            selector: (row) =>
                !!row?.title
                    ? row?.title?.agreementNumber
                    : !row?.title && row?.agreementNumber,
            sortable: true,
            omit: (IS_KOOP && !multiApplication) || !IS_KOOP,
        },
        {
            id: 'recipientId',
            name: 'Získatelské číslo',
            selector: (row) => row.recipientId,
            cell: (row) => (
                <div className="d-flex">
                    <WithPopover
                        popover={
                            <BrokerPopover
                                payout={row}
                                validitydate={
                                    appFormFromDate ||
                                    application?.application?.created
                                }
                            />
                        }
                    >
                        <TableAction
                            tooltip="Zobrazit údaje o získateli"
                            innerProps={{ 'data-kodzi': row.recipientId }}
                        >
                            <Binoculars className="me-1" />
                            {row.recipientId}
                        </TableAction>
                    </WithPopover>
                </div>
            ),
            sortable: true,
        },
        {
            id: 'recipientName',
            name: 'Název získatele',
            selector: (row) => row.recipientName,
            cell: (row) =>
                row.recipientName ||
                (!row.brokerExists && (
                    <span className="text-danger">Nelze načíst</span>
                )),
            sortable: true,
        },
        {
            id: 'amount',
            name: 'Částka',
            selector: (row) => row.amount,
            format: (row) => formatAmount(row.amount as number),
            sortable: true,
        },
        {
            id: 'productType',
            name: 'Kmen',
            selector: (row) => row.productType,
            format: (row) =>
                `${row.productType} = ${getProductTypeDescription(
                    row.productType
                )}`,
            sortable: true,
        },
        {
            id: 'fromDate',
            name: 'Změna období',
            selector: (row) => row.fromDate?.toString(),
            format: (row) => formatPayoutDates(row),
            sortable: true,
        },
        {
            id: 'dueDate',
            name: 'Nevyplatit před',
            selector: (row) => dayjs(row.dueDate).toDate().getTime(),
            format: (row) => formatDate(row.dueDate),
            sortable: true,
        },
        {
            id: 'id',
            name: 'DB ID',
            selector: (row) => (row.isNew ? '--' : row.id),
            sortable: true,
        },
        taskColumn<PayoutInternal>({
            name: 'Úkoly',
            genIds: (row, task) => ({
                'data-id': row.id,
                'data-taskid': task.id,
            }),
            to: (row) => NAV.PAYOUT_DETAIL(application?.application.id, row.id),
            handleOpenTask: () => {
                dispatch(setFromPayout(false));
            },
        }),
    ];

    if (!disabled) {
        columns.push({
            name: 'Akce',
            cell: (row) => (
                <div className="d-flex">
                    <TableAction
                        onClick={handleEdit}
                        tooltip="Editovat výplatu"
                        innerProps={{ 'data-id': row.id }}
                        large
                    >
                        <PencilSquare />
                    </TableAction>

                    <Confirmation
                        title="Smazat výplatu"
                        message="Opravdu si přejete smazat výplatu?"
                        confirmText="Smazat"
                        confirmParams={row.id}
                        onConfirm={handleRemove}
                    >
                        <TableAction tooltip="Smazat výplatu" large second>
                            <Trash3 />
                        </TableAction>
                    </Confirmation>
                </div>
            ),
        });
    }

    const hasDuplicates =
        payouts.data && getDuplicates(payouts.data).length > 0;
    if (hasDuplicates) {
        columns.unshift({
            width: '80px',
            name: 'Chyba',
            cell: (row: PayoutInternal) =>
                row.hasDuplicate ? (
                    <MyButton
                        noYMargin
                        data-tag="allowRowEvents"
                        title="Klepněte pro zobrazení chyby"
                        variant="link"
                        data-id={row.id}
                        className="text-decoration-none"
                    >
                        <ExclamationTriangle
                            data-tag="allowRowEvents"
                            className="text-danger"
                        />
                    </MyButton>
                ) : null,
        });
    }

    const handleEdit = (event) => {
        const id: string = event.currentTarget.getAttribute('data-id');
        const payout = payouts.data.find((el) => el.id.toString() === id);
        setEditPayout(payout);
        setShowFormModal(true);
    };

    const handleRemove = (id) => {
        dispatch(removePayout(id));
    };

    const handleAddPayout = () => {
        setShowFormModal(true);
    };

    const handleSave = (payout: Payout) => {
        if (payout.id)
            dispatch(
                addNotification({
                    text: 'Data výplaty byla změněna.',
                })
            );
        dispatch(
            putPayout({
                validityDate:
                    appFormFromDate || application.application.created,
                data: {
                    ...payout,
                    id: payout.id || generateId(),
                },
            })
        );
        setEditPayout(null);
        setShowFormModal(false);
    };

    const handleModalHide = () => {
        setEditPayout(null);
        setShowFormModal(false);
    };

    const handleOpenImportModal = () => {
        if (
            IS_KOOP &&
            multiApplication &&
            appType !== ApplicationTypeEnumKoop.OST
        ) {
            dispatch(
                titleRegisterListTitleFilterFetch({
                    titleType: appType,
                })
            );
        }
        setShowImportModal(true);
    };

    // data provides access to your row data
    const ExpandedRow: React.FC<ExpanderComponentProps<PayoutInternal>> = ({
        data,
    }) => {
        return (
            <div>
                {data.duplicates && data.duplicates.length && (
                    <Alert variant="danger" className="small py-1">
                        <h6>Duplicitní s:</h6>
                        <ul className="pl-3">
                            {data.duplicates.map((d, i) => (
                                <li key={i}>
                                    ID žádosti:{' '}
                                    <a
                                        href={NAV.APPLICATION_DETAIL_PAYOUTS(
                                            d.applicationId
                                        )}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <strong>{d.applicationId}</strong>
                                    </a>
                                    , ID výplaty:{' '}
                                    <a
                                        href={NAV.PAYOUT_DETAIL(
                                            d.applicationId,
                                            d.payoutId
                                        )}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <strong>{d.payoutId}</strong>
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </Alert>
                )}
                {data.brokerFetchError && (
                    <Alert variant="danger" className="small py-1">
                        Nebylo možné navázat spojení se systémem GOLEM! Není tak
                        možné ověřit existenci/platnost zadaného získatele.
                    </Alert>
                )}
                {!data.brokerFetchError && !data.brokerExists && (
                    <Alert variant="danger" className="small py-1">
                        Neexistující získatelské číslo! Proveďte úpravu, jinak
                        nedojde ke zpracování této výplaty.
                    </Alert>
                )}
                {data.brokerExists && !data.brokerValid && (
                    <Alert variant="warning" className="small py-1">
                        Získatel je ve zvoleném období neplatný.
                    </Alert>
                )}
            </div>
        );
    };

    const rowWithoutExpander = (row: PayoutInternal) => {
        const duplicates = row.duplicates && row.duplicates.length;
        const kodziErr = !row.brokerExists || !row.brokerValid;

        return !duplicates && !kodziErr;
    };
    const preexpandedRows = (row: PayoutInternal) => {
        return !rowWithoutExpander(row);
    };

    const hasExpanded = payouts.data?.find((el) => !rowWithoutExpander(el));

    const handleSort = (selectedColumn, sortDirection: 'asc' | 'desc') => {
        dispatch(
            setPayoutsPaginationSort({
                pagination: pagination,
                sort: {
                    sortList: [selectedColumn.id],
                    sortOrder: sortDirection.toUpperCase() as 'ASC' | 'DESC',
                },
                applicationId: application?.application?.id,
            })
        );
    };

    const handlePageChange = (page) => {
        if (page !== pagination.page) {
            dispatch(
                setPayoutsPaginationSort({
                    pagination: { size: pagination.size, page },
                    sort,
                    applicationId: application?.application?.id,
                })
            );
        }
    };

    const handlePerRowsChange = async (newPageSize) => {
        if (newPageSize !== pagination.size) {
            dispatch(
                setPayoutsPaginationSort({
                    pagination: { size: newPageSize, page: 1 },
                    sort,
                    applicationId: application?.application?.id,
                })
            );
        }
    };

    return (
        <div className="Payouts position-relative">
            {(payouts.pending || payouts.kodzisPending) && <LoadingIndicator />}

            <Modal show={showFormModal} onHide={handleModalHide} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title as="h5">Nová výplata</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {IS_KOOP ? (
                        <NewPayoutFormKoop
                            onSave={handleSave}
                            payout={editPayout}
                        />
                    ) : (
                        <NewPayoutFormCpp
                            onSave={handleSave}
                            payout={editPayout}
                        />
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <MyButton variant="secondary" onClick={handleModalHide}>
                        {TEXTS.close}
                    </MyButton>
                    <MyButton
                        type="submit"
                        form="NewPayoutForm"
                        variant="primary"
                    >
                        {TEXTS.save}
                    </MyButton>
                </Modal.Footer>
            </Modal>
            <ImportPayoutForm
                fromDate={appFormFromDate}
                open={showImportModal}
                setOpen={setShowImportModal}
                application={application?.application}
            />

            {!disabled &&
            IS_KOOP &&
            !multiApplication &&
            !appFormLogic.getValues('titleId') &&
            appType !== ApplicationTypeEnumKoop.OST ? (
                <Alert className="text-danger" variant="light">
                    Dokud nebude v žádosti zvolen konkrétní titul (sekce
                    "Parametry"), nebude možné přidávat výplaty.
                </Alert>
            ) : (
                IS_KOOP &&
                multiApplication &&
                !appType && (
                    <Alert className="text-danger" variant="light">
                        Dokud nebude v žádosti zvolen konkrétní typ (sekce
                        "Parametry"), nebude možné přidávat výplaty.
                    </Alert>
                )
            )}

            <MyTable
                onSort={handleSort}
                paginationPerPage={pagination.size}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                paginationTotalRows={payouts.data?.length || 0}
                defaultSortFieldId={
                    paginationSortAppId === application?.application.id
                        ? sort.sortList[0]
                        : null
                }
                paginationDefaultPage={
                    paginationSortAppId === application?.application.id
                        ? pagination.page
                        : 1
                }
                defaultSortAsc={sort.sortOrder === 'ASC'}
                columns={columns}
                data={payouts.data}
                expandableRows={!!hasExpanded}
                expandOnRowClicked
                expandableRowsHideExpander
                expandableRowExpanded={preexpandedRows}
                expandableRowsComponent={ExpandedRow}
                expandableRowDisabled={rowWithoutExpander}
                noDataComponent="Žádné záznamy"
                pagination
            />
            <Row className="pt-3 pb-3">
                <Col>
                    {disabled ? (
                        ' '
                    ) : (
                        <>
                            {(!IS_KOOP ||
                                appFormLogic.getValues('titleId') ||
                                appType === ApplicationTypeEnumKoop.OST ||
                                (multiApplication && appType)) && (
                                <div>
                                    <MyButton
                                        onClick={handleAddPayout}
                                        noYMargin
                                        className="me-3"
                                    >
                                        Přidat
                                    </MyButton>

                                    <MyButton
                                        onClick={handleOpenImportModal}
                                        noYMargin
                                        variant="outline-primary"
                                    >
                                        Import seznamu výplat
                                    </MyButton>
                                </div>
                            )}
                        </>
                    )}
                </Col>
                <Col align="right">
                    {payouts.data.length > 1 ? (
                        <Form onSubmit={handleSubmit(null)}>
                            <WithTooltip tooltip="Zadejte očekávanou sumu výplat. Nebude možné žádost odeslat, pokud tato suma nebude souhlasit.">
                                <Form.Group>
                                    <RequiredLabel>
                                        Očekávaná suma všech výplat
                                    </RequiredLabel>
                                    <Form.Control
                                        type="number"
                                        disabled={disabled}
                                        {...register('payoutSum', {
                                            onChange: handlePayoutSumChange,
                                            ...required(),
                                            validate: {
                                                ...validatePayoutSum,
                                            },
                                        })}
                                        className="small-input no-input-arrows"
                                        placeholder="Zadejte hodnotu"
                                    />
                                    <FormError
                                        errors={errors}
                                        name="payoutSum"
                                    />
                                </Form.Group>
                            </WithTooltip>
                        </Form>
                    ) : null}
                </Col>
            </Row>
        </div>
    );
}

export default Payouts;
