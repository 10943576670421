import {Form, Modal, Stack} from 'react-bootstrap';
import {useForm, useWatch} from 'react-hook-form';
import {RequiredLabel} from '../../../components/form/RequiredLabel';
import {FormError} from '../../../components/form/FormError';
import {
    AttachmentKoop,
    MAX_ATTACHMENT_SIZE,
    MAX_ATTACHMENT_SIZE_MB,
} from '../../../services/entities/Attachment';
import {TEXTS} from '../../../constants/texts';
import {MyButton} from '../../../components/button/MyButton';
import { useContext, useEffect, useMemo, useState } from 'react';
import { pattern, required } from '../../../components/form/formValidations';
import { separateExtension } from '../../../utils/fileUtils';
import { RootState, useAppSelector } from '../../../store/store';
import { AttachmentsState } from '../../../store/slices/applicationDetail/attachmentsSlice';
import { getDokuType } from '../../../utils/getDokuType';
import { ApplicationTypeEnumKoop } from '../../../services/entities/Application';
import { AppFormContext } from '../application-detail';

type AttachmentFormProps = {
    onSave: (Attachment) => any;
    show: boolean;
    onHide: () => void;
    attachment?: AttachmentKoop;
    titleData?: any;
};
type AttachmentFormData = {
    description: string;
    fileName: string;
    file?: FileList;
    docType: string;
    id: string;
    titleID: string;
};

function AttachmentFormModalKoop({
    attachment,
    show,
    onHide,
    onSave,
    titleData,
}: AttachmentFormProps) {
    const {
        register,
        handleSubmit,
        control,
        setValue,
        reset,
        formState: { errors },
    } = useForm<AttachmentFormData>({ mode: 'onChange' });
    const { selectedType, tribe } = useAppSelector(
        (state) => state.applicationDetail.application
    );
    const attachments: AttachmentsState = useAppSelector(
        (s: RootState) => s.applicationDetail.attachments
    );

    const currentTypeAttachments = attachments.requiredAttachmentsRules.find(
        (att) => att.applicationType === selectedType
    );
    const watchFileList = useWatch({ control, name: 'file' });
    const file = watchFileList?.length ? watchFileList[0] : null;

    const [pending, setPending] = useState(false);
    const [extensionValid, setExtensionValid] = useState(true);
    const [sizeValid, setSizeValid] = useState(true);
    useEffect(() => {
        if (!show)
            reset({
                file: null,
                description: '',
                fileName: '',
                docType: null,
                titleID: '',
            });
    }, [show, reset]);

    useEffect(() => {
        if (file) {
            const parts = separateExtension(file.name);
            setExtensionValid(validateExtension(parts[1]));
            const size = file.size;
            setSizeValid(size <= MAX_ATTACHMENT_SIZE);
            setValue('fileName', parts[0]);
        } else {
            setExtensionValid(false);
        }
    }, [file, setValue, setSizeValid]);

    const validateExtension = (extension) => {
        console.log(
            'validateExtension',
            extension,
            /^doc|docx|xls|xlsx|jpeg|jpg|gif|png|pdf|ppt|pptx$/i.test(extension)
        );
        return (
            extension &&
            /^(doc|docx|xls|xlsx|jpeg|jpg|gif|png|pdf|ppt|pptx)$/i.test(
                extension
            )
        );
    };
    const docTypeWatch = useWatch({
        name: 'docType',
        control,
    });

    const disabledTitle = useMemo(() => {
        return !currentTypeAttachments?.items?.find((c) => {
            if (c.forTitle) {
                return c.id === +docTypeWatch;
            }
            return undefined;
        });
    }, [docTypeWatch, currentTypeAttachments]);

    const onSubmit = (values: AttachmentFormData) => {
        if (!extensionValid || !sizeValid) return;

        setPending(true);
        const description = values.description;
        const titleID = values.titleID;
        const fileList: FileList = values.file;
        const file = fileList[0];
        const extension = separateExtension(file.name)[1];
        const fileName = values.fileName
            ? `${values.fileName}.${extension}`
            : file.name;
        const requiredAttachmentsId = values.docType;

        const selectedDoc = currentTypeAttachments.items.find((i) => {
            return i.id.toString() === requiredAttachmentsId;
        });
        const currentSelectedTitle =
            multiApplication && appType !== ApplicationTypeEnumKoop.OST
                ? titleData.find((title) => {
                      return title.id.toString() === titleID;
                  })
                : titleRegisterDataFilter.find((title) => {
                      return title.id.toString() === titleID;
                  });

        onSave({
            fileName,
            file,
            mimeType: 'application/octet-stream',
            description,
            documentType: selectedDoc.documentType,
            dokuType: multiApplication
                ? getDokuType(
                      selectedDoc,
                      !disabledTitle ? currentSelectedTitle.tribe : tribe
                  )
                : getDokuType(selectedDoc, tribe),
            titleID,
        }).then(() => {
            setPending(false);
        });
    };

    // documentType: values.docType.split(';')[1],
    // dokuType: values.docType.split(';')[0],

    const multiApplication = useAppSelector(
        (state) =>
            state?.applicationDetail?.application?.application?.data
                ?.application?.multiApplication
    );
    const appFormLogic = useContext(AppFormContext);
    const appType: ApplicationTypeEnumKoop = useWatch({
        name: 'type',
        control: appFormLogic.control,
    }) as ApplicationTypeEnumKoop;

    const { titleRegisterDataFilter, recordCount, pagination } = useAppSelector(
        (state) => state.applicationDetail.application.mpTitlesData
    );

    // const availableTitle = useCallback(() => {
    //     if (multiApplication && appType !== ApplicationTypeEnumKoop.OST) {
    //         return payouts.map((payout) => {
    //             if (payout?.agreementNumber) {
    //                 return payout?.agreementNumber;
    //             } else {
    //                 return payout?.title?.agreementNumber;
    //             }
    //         });
    //     }
    // }, [appType, multiApplication, payouts]);

    // const [titleData, setTitleData] = useState([]);

    // useEffect(() => {
    //     if (multiApplication && appType !== ApplicationTypeEnumKoop.OST) {
    //         setTitleData([]);
    //         payouts?.map((payout) => {
    //             return dispatch(
    //                 titleRegisterListTitleFilterFetch({
    //                     titleType: appType,
    //                     name: payout?.agreementNumber,
    //                 })
    //             ).then((val: any) => {
    //                 setTitleData((oldArray) => {
    //                     if (
    //                         !oldArray?.find(
    //                             (o) => o?.id === val?.payload?.mpTitles[0]?.id
    //                         )
    //                     ) {
    //                         return [...oldArray, val?.payload?.mpTitles[0]];
    //                     } else {
    //                         return oldArray;
    //                     }
    //                 });
    //             });
    //         });
    //     }
    // }, [appType, dispatch, multiApplication, payouts]);

    // const filterAvailableTitle = () => {
    //     if (multiApplication && appType !== ApplicationTypeEnumKoop.OST) {
    //         const avTitles = availableTitle();
    //         return titleRegisterDataFilter.filter((title) => {
    //             return avTitles.some((avt) => avt === title.agreementNumber);
    //         });
    //     }
    // };

    // const FilteredTitle = filterAvailableTitle();

    // const notForTitle = () => {
    //     return currentTypeAttachments?.items?.map((c) => {
    //         if (!c.forTitle) {
    //             return c.id;
    //         }
    //         return undefined;
    //     });
    // };

    // const typeAttachments = notForTitle();

    //todo useMemo
    // const disabledTitle = typeAttachments?.includes(+docTypeWatch);
    // console.log(disabledTitle);
    // console.log(docTypeWatch);

    return (
        <Modal show={show} onHide={onHide} size="lg">
            <Modal.Header closeButton>
                <Modal.Title as="h5">Nová příloha</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="AttachmentForm" onSubmit={handleSubmit(onSubmit)}>
                    <Stack className="mb-3" direction="horizontal" gap={3}>
                        {!attachment ? (
                            <Form.Group>
                                <RequiredLabel>Příloha</RequiredLabel>
                                <Form.Control
                                    className="small-input"
                                    size="sm"
                                    type="file"
                                    disabled={!!attachment}
                                    accept=".doc,.docx,.xls,.xlsx,.jpeg,.jpg,.gif,.png,.pdf,.ppt, .pptx"
                                    placeholder="Vyberte soubor"
                                    {...register('file', { ...required() })}
                                />
                                <FormError
                                    className="small-input"
                                    errors={errors}
                                    name="file"
                                />
                            </Form.Group>
                        ) : null}

                        <Form.Group>
                            <RequiredLabel>Název</RequiredLabel>
                            <Form.Control
                                className="small-input"
                                size="sm"
                                placeholder="Nové jméno"
                                {...register('fileName', {
                                    ...required(),
                                    ...pattern(
                                        /^[\w\-.]+$/,
                                        'Název souboru smí obsahovat jen: písmena (bez diakritiky), čísla, pomlčky, tečky.'
                                    ),
                                })}
                            />
                            <FormError
                                className="small-input"
                                errors={errors}
                                name="fileName"
                            />
                        </Form.Group>

                        <Form.Group>
                            <RequiredLabel>Typ dokumentu</RequiredLabel>
                            <Form.Select
                                {...register('docType', { ...required() })}
                                size="sm"
                            >
                                <option value="" hidden>
                                    Vyberte typ dokumentu
                                </option>
                                {currentTypeAttachments?.items.map((file) => (
                                    <option key={file.id} value={file.id}>
                                        {file.documentType}
                                    </option>
                                ))}
                            </Form.Select>
                            <FormError
                                className="small-input"
                                errors={errors}
                                name="docType"
                            />
                        </Form.Group>

                        {multiApplication &&
                            appType !== ApplicationTypeEnumKoop.OST && (
                                <div>
                                    <Form.Group>
                                        <RequiredLabel>Titul</RequiredLabel>

                                        <Form.Select
                                            disabled={disabledTitle}
                                            {...register('titleID', {
                                                required: !disabledTitle
                                                    ? 'Pole je povinné'
                                                    : false,
                                            })}
                                            defaultValue=""
                                        >
                                            <option hidden value={''}>
                                                Vyberte konkrétní titul
                                            </option>

                                            {appType &&
                                                titleData?.map((title, i) => {
                                                    if (
                                                        i >= 0 &&
                                                        i < pagination.pageSize
                                                    ) {
                                                        return (
                                                            <option
                                                                key={title?.id}
                                                                value={
                                                                    title?.id
                                                                }
                                                            >{`${title?.agreementNumber} (${title?.evidenceNumber})`}</option>
                                                        );
                                                    } else if (
                                                        i ===
                                                        pagination.pageSize
                                                    ) {
                                                        return (
                                                            <option
                                                                key={title?.id}
                                                            >{`+ dalších ${
                                                                recordCount -
                                                                pagination.pageSize
                                                            } titulů`}</option>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                        </Form.Select>
                                        <FormError
                                            errors={errors}
                                            name="titleID"
                                        />
                                    </Form.Group>
                                </div>
                            )}
                    </Stack>

                    {file && !extensionValid && (
                        <div className="mb-3">
                            <Form.Text className="text-danger">
                                Soubor musí mít být v jednom z těchto formátů:
                                .doc,.docx,.xls,.xlsx,.jpeg,.jpg,.gif,.png,.pdf,.ppt,
                                .pptx
                                <br />
                            </Form.Text>
                        </div>
                    )}

                    {file && !sizeValid && (
                        <div className="mb-3">
                            <Form.Text className="text-danger">
                                Maximální povolená velikost souboru je{' '}
                                {MAX_ATTACHMENT_SIZE_MB} MB.
                                <br />
                            </Form.Text>
                        </div>
                    )}

                    <Form.Group>
                        <Form.Label>{TEXTS.description}</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={5}
                            {...register('description')}
                            placeholder={TEXTS.description}
                        />
                        <FormError
                            className="small-input"
                            errors={errors}
                            name="description"
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <MyButton variant="secondary" onClick={onHide}>
                    {TEXTS.close}
                </MyButton>
                <MyButton
                    type="submit"
                    form="AttachmentForm"
                    disabled={pending}
                    variant="primary"
                >
                    {TEXTS.save}
                </MyButton>
            </Modal.Footer>
        </Modal>
    );
}

export default AttachmentFormModalKoop;
