import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {ApiData, setDataFail, setDataPending, setDataSuccess} from '../../storeApiUtils';
import {AttachmentCpp, AttachmentKoop, RequiredAttachmentsForTypes} from '../../../services/entities/Attachment';
import {ApplicationId} from '../../../services/entities/Application';
import {
    downloadAttachment,
    fetchAttachments,
    fetchRequiredAttachmentsRules,
    removeAttachment,
    saveAttachment,
    updateAttachments,
} from '../../../services/api/attachmentsApi';
import {getIdToken} from '../../../pages/common/authProvider/authProviderUtils';

export type AttachmentAny = AttachmentKoop | AttachmentCpp
export type AttachmentsState = ApiData<AttachmentAny[]> & {
    applicationId: ApplicationId;
    requiredAttachmentsRules: RequiredAttachmentsForTypes;
};
const initialState: AttachmentsState = {
    data: [],
    pending: false,
    error: '',
    actionRequestId: null,
    applicationId: null,
    requiredAttachmentsRules: [],
};

export const attachmentsFetch = createAsyncThunk<AttachmentAny[], ApplicationId>(
    'attachments/attachmentsFetchLazy',

    async (appId: ApplicationId) => {
        return await fetchAttachments(appId, await getIdToken());
    }
);

export type AttachmentInput = {
    attachment: AttachmentAny;
    applicationId: ApplicationId;
    newDokuType?: string;
};

export const attachmentSave = createAsyncThunk<void, AttachmentInput>(
    'attachments/attachmentsSave',
    async (input, thunkApi) => {
        return await saveAttachment(
            input.applicationId,
            input.attachment as AttachmentAny,
            await getIdToken()
        ).then(() => {
            thunkApi.dispatch(attachmentsFetch(input.applicationId));
        });
    }
);

export const attachmentRemove = createAsyncThunk<void, AttachmentInput>(
    'attachments/attachmentRemove',
    async (input, thunkApi) => {
        return await removeAttachment(
            input.applicationId,
            input.attachment,
            await getIdToken()
        ).then(() => {
            thunkApi.dispatch(attachmentsFetch(input.applicationId));
        });
    }
);

export const attachmentDonwload = createAsyncThunk<void, AttachmentInput>(
    'attachments/attachmentDonwload',
    async (input, thunkApi) => {
        return await downloadAttachment(
            input.applicationId,
            input.attachment,
            await getIdToken()
        ).then(() => {
            thunkApi.dispatch(attachmentsFetch(input.applicationId));
        });
    }
);

export const getRequiredAttachmentsRules = createAsyncThunk<
    RequiredAttachmentsForTypes,
    void
>('attachments/getRequiredAttachmentsRules', async () => {
    return await fetchRequiredAttachmentsRules(await getIdToken());
});
export const attachmentUpdate = createAsyncThunk<void, AttachmentInput>(
    'attachments/attachmentsUpdate',
    async (input, thunkApi) => {
        return await updateAttachments(
            input.applicationId,
            input.attachment as AttachmentKoop,
            await getIdToken(),
            input.newDokuType
        ).then(() => {
            thunkApi.dispatch(attachmentsFetch(input.applicationId));
        });
    }
);

const attachmentsSlice = createSlice({
    name: 'attachments',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(attachmentsFetch.pending, (state, action) => {
            if (state.applicationId !== action.meta.arg) {
                state.applicationId = action.meta.arg;
                setDataPending(state, action);
            }
        });
        builder.addCase(attachmentsFetch.fulfilled, (state, action) => {
            setDataSuccess(state, action);
        });
        builder.addCase(attachmentsFetch.rejected, setDataFail);

        builder.addCase(attachmentSave.pending, (s) => setDataPending(s));
        builder.addCase(attachmentSave.fulfilled, (s) => {
            s.applicationId = null;
            s.pending = false
        });
        builder.addCase(attachmentSave.rejected, setDataFail);

        builder.addCase(attachmentRemove.pending, (s) => setDataPending(s));
        builder.addCase(attachmentRemove.fulfilled, (s) => {
            s.applicationId = null;
            s.pending = false
        });
        builder.addCase(attachmentRemove.rejected, setDataFail);

        builder.addCase(getRequiredAttachmentsRules.pending, (s) =>
            setDataPending(s)
        );
        builder.addCase(getRequiredAttachmentsRules.fulfilled, (s, action) => {
            s.requiredAttachmentsRules = action.payload;
        });
        builder.addCase(getRequiredAttachmentsRules.rejected, setDataFail);
        builder.addCase(attachmentUpdate.pending, (s) => setDataPending(s));
        builder.addCase(attachmentUpdate.fulfilled, (s) => {
            s.applicationId = null;
            s.pending = false
        });
        builder.addCase(attachmentUpdate.rejected, setDataFail);
    },
});

export default attachmentsSlice.reducer