import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from '../../store';
import {CamundaUser, UserId} from "../../../services/entities/User";
import {fetchCamundaUsersByIds} from "../../../services/api/applicationProcessApi";
import {getIdToken} from "../../../pages/common/authProvider/authProviderUtils";

export type CamundaUsersState = {
    map: {
        [key: UserId]: CamundaUser
    },
    pending: boolean
}
const initialState: CamundaUsersState = {
    map: {},
    pending: false
}

export const camundaUsersFetch = createAsyncThunk<CamundaUser[], UserId[]>(
    'camundaUsers/CamundaUsersFetch',
    async (ids: UserId[], thunkApi) => {
        const camundaUsersMap = (await thunkApi.getState() as RootState).applicationDetail.camundaUsers.map;
        ids = ids?.filter(el => el && !camundaUsersMap[el])
        return ids?.length ? fetchCamundaUsersByIds(ids, await getIdToken()) : []
    }
)


const CamundaUsersSlice = createSlice({
    name: 'camundaUsers',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(camundaUsersFetch.pending, (state) => {
            state.pending = true
        })
        builder.addCase(camundaUsersFetch.fulfilled, (state, action) => {
            const users = action.payload
            const newMap = {...state.map}
            users.forEach(usr => {
                newMap[usr.id] = usr
            })
            state.map = newMap
        })
        builder.addCase(camundaUsersFetch.rejected, state => {
            state.pending = false
        })
    }
})

export default CamundaUsersSlice.reducer