import React from 'react';
import {OverlayTrigger} from 'react-bootstrap';
import {OverlayChildren} from 'react-bootstrap/esm/Overlay';
import {OverlayTriggerType} from 'react-bootstrap/esm/OverlayTrigger';


type WithPopoverProps = {
    popover: OverlayChildren,
    popoverTrigger?: OverlayTriggerType | OverlayTriggerType[],
    disabled?: boolean,
    children: React.ReactNode
}
function WithPopover({children, disabled, popover, popoverTrigger}: WithPopoverProps) {

    if (disabled)
        return (<>{children}</>)

    return <OverlayTrigger
        trigger={popoverTrigger || 'click'}
        overlay={popover}  placement="auto"
        rootClose>
        <div className="d-inline-block">
            {children}
        </div>
    </OverlayTrigger>
}

export default WithPopover;