// noinspection JSNonASCIINames

import { ApplicationTypeEnumCpp, ApplicationTypeEnumKoop } from './Application';

export const MAX_ATTACHMENT_SIZE_MB = 15
export const MAX_ATTACHMENT_SIZE = MAX_ATTACHMENT_SIZE_MB * 1024 * 1024;

export interface AttachmentKoop {
    id?: string;
    extId?: string;
    file: File;
    fileName: string;
    mimeType: string;
    description: string;
    dokuType: string;
    documentType: string;
    title?: {
        evidenceNumber: string;
        agreementNumber: string;
    };
    titleID?: string;
}
export interface AttachmentCpp {
    id?: string;
    extId?: string;
    file: File;
    fileName: string;
    mimeType: string;
    description: string;
    dokuType: DocumentTypeEnumCpp;
    documentType?: string;
}

export type DokuType = {
    dokuType: string;
    id: number;
    tribe: string;
};

export type RequiredSingleAttachment = {
    id: number;
    documentName: string;
    documentType: string;
    required: boolean;
    forTitle: boolean;
    dokuTypes: DokuType[];
};

export type RequiredAttachmentsForType = {
    id: number,
    applicationType: ApplicationTypeEnumCpp | ApplicationTypeEnumKoop,
    items: Array<RequiredSingleAttachment>
}

export type RequiredAttachmentsForTypes = Array<RequiredAttachmentsForType>

export enum DocumentTypeEnumCpp {
    MP_PK = "MP_PK",
    MP_MT = "MP_MT",
    SP = "SP",
    MP_STM_GŘ ="MP_STM_GŘ",
    MP_STM_REG = "MP_STM_REG"
}

// todo-upravit docTypes až budou doplněny
export enum DocumentTypeEnumKoop {
    K038 = 'K038',
    K039 = 'K039',
    K040 = 'K040',
    K041 = 'K041',
    K042 = 'K042',
    K043 = 'K043',
    K044 = 'K044',
    K047 = 'K047',
    K048 = 'K048',
    K078 = 'K078',
    K163 = 'K163',
    K171 = 'K171',
    K172 = 'K172',
    K173 = 'K173',
}

export const translateDocumentType = (type: DocumentTypeEnumKoop | DocumentTypeEnumCpp) => {
    switch (type) {
        case DocumentTypeEnumKoop.K038:
            return 'Jednotný schvalovací formulář';
        case DocumentTypeEnumKoop.K039:
            return 'Záznam z jednání';
        case DocumentTypeEnumKoop.K040:
            return 'Dohoda';
        case DocumentTypeEnumKoop.K041:
            return 'Vyhodnocení';
        case DocumentTypeEnumKoop.K042:
            return 'Žádost';
        case DocumentTypeEnumKoop.K043:
            return 'Koncept';
        case DocumentTypeEnumKoop.K044:
            return 'Dodatek';
        case DocumentTypeEnumKoop.K047:
            return 'Ostatní';
        case DocumentTypeEnumKoop.K048:
            return 'Ostatní';
        case DocumentTypeEnumKoop.K078:
            return 'Výkon';
        case DocumentTypeEnumKoop.K163:
            return 'Jednotný schvalovací formulář';
        case DocumentTypeEnumCpp.MP_PK:
            return "MP PK – žádost, podklad pro výplatu"
        case DocumentTypeEnumCpp.MP_MT:
            return "MP MT – podklad pro výplatu"
        case DocumentTypeEnumCpp.SP:
            return "SP – podklad pro výplatu"
        case DocumentTypeEnumCpp.MP_STM_GŘ:
            return "MP STM GŘ - podklad pro výplatu"
        case DocumentTypeEnumCpp.MP_STM_REG:
            return "MP STM REG – propozice"
    }
};