import React, {useState} from 'react'
import './ApplicationTable.scss'
import {ButtonGroup, Form, ToggleButton} from 'react-bootstrap';
import {
    ApplicationBaseFilterTypeEnum,
    PagedApplications,
} from '../../../services/entities/Application';
import { MyButton } from '../../../components/button/MyButton';
import CustomFilterForm from '../customFilter/CustomFilterForm';
import { ArrowClockwise, XLg } from 'react-bootstrap-icons';
import { appListExport } from '../../../store/slices/applicationList/applicationListSlice';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import {
    clearFilter,
    resetPagination,
    setBaseFilter,
    setMyApprovalFilter,
} from '../../../store/slices/applicationList/applicationListFilterSlice';
import WithTooltip from '../../../components/withPopover/WithTooltip';
import clsx from 'clsx';
import { ApiData } from '../../../store/storeApiUtils';

function ApplicationTableHeader() {
    const dispatch = useAppDispatch();
    const { filter, clean } = useAppSelector(
        (state) => state.applicationListFilter
    );
    const [showCustomFModal, setShowCustomFModal] = useState(false);
    const baseFilter: ApplicationBaseFilterTypeEnum = useAppSelector(
        (s) => s.applicationListFilter.baseFilter
    );
    const myApprovalFilter = useAppSelector(
        (s) => s.applicationListFilter.myApprovalFilter
    );
    const sort = useAppSelector((s) => s.applicationListFilter.sort);
    const appData: ApiData<PagedApplications> = useAppSelector(
        (state) => state.applicationList
    );

    const getBaseFilterText = (type: ApplicationBaseFilterTypeEnum): string => {
        switch (type) {
            case ApplicationBaseFilterTypeEnum.ACTION:
                return 'Úkoly';
            case ApplicationBaseFilterTypeEnum.ALL:
                return 'Všechny žádosti';
            case ApplicationBaseFilterTypeEnum.OWNER:
                return 'Moje žádosti';
            // case ApplicationTableFilterType.CUSTOM:
            //     return 'Vlastní Filter'
        }
    };
    const handleBaseFilterChange = (event) => {
        const value = event.target.value;
        dispatch(setBaseFilter(value));
    };

    const handleMyApprovalFilterChange = (e) => {
        const value = e.currentTarget.checked;
        dispatch(setMyApprovalFilter(value));
    };

    const handleExport = () => {
        dispatch(
            appListExport({
                baseFilter: baseFilter,
                myApprovalFilter: myApprovalFilter,
                customFilter: filter,
                sort,
            })
        );
    };

    const handleClearFilter = () => {
        dispatch(clearFilter());
    };

    return (
        <>
            <CustomFilterForm
                show={showCustomFModal}
                onHide={() => setShowCustomFModal(false)}
            />
            <div className="align-items-center ps-1">
                {appData?.data?.applicationWrapperList?.length > 0 && (
                    <MyButton
                        className="float-start justify-self-start"
                        tooltip="Exportuje všechny stránky dle nastavých filtrů"
                        onClick={handleExport}
                    >
                        Exportovat
                    </MyButton>
                )}

                <div className="ms-auto d-flex justify-content-end align-items-center">
                    <MyButton
                        variant="outline-primary"
                        noYMargin
                        onClick={() => {
                            dispatch(resetPagination());
                        }}
                        className="me-3"
                        tooltip="Načíst znovu. Resetuje nastavené stránkování."
                    >
                        <ArrowClockwise />
                    </MyButton>

                    <ToggleButton
                        id="myApprovaltoggleButton"
                        className="me-3"
                        type="checkbox"
                        value="1"
                        disabled={
                            ApplicationBaseFilterTypeEnum.ACTION !== baseFilter
                        }
                        checked={myApprovalFilter}
                        onChange={handleMyApprovalFilterChange}
                        variant="outline-primary"
                    >
                        Moje úkoly
                    </ToggleButton>

                    <WithTooltip tooltip="Přednastavený filtr">
                        <Form.Select
                            data-clear-button="true"
                            className="rounded-0 rounded-start me-3 small-input"
                            defaultValue={baseFilter}
                            onChange={handleBaseFilterChange}
                        >
                            {Object.values(ApplicationBaseFilterTypeEnum).map(
                                (key) => (
                                    <option key={key} value={key}>
                                        {getBaseFilterText(key)}
                                    </option>
                                )
                            )}
                        </Form.Select>
                    </WithTooltip>

                    <ButtonGroup>
                        <MyButton
                            className={clsx(
                                'flex-shrink-0 rounded-0',
                                clean && 'rounded-end'
                            )}
                            tooltip={`Vlastní filtr: ${
                                clean ? 'PRÁZDNÝ' : 'NASTAVEN'
                            }`}
                            variant="outline-primary"
                            onClick={() => setShowCustomFModal(true)}
                        >
                            Vlastní filtr {!clean && '*'}
                        </MyButton>
                        {!clean && (
                            <MyButton
                                className="rounded-0 rounded-end"
                                tooltip="Vyčistit vlastní filtr"
                                variant="outline-warning"
                                onClick={handleClearFilter}
                            >
                                <XLg />
                            </MyButton>
                        )}
                    </ButtonGroup>
                </div>
            </div>
        </>
    );
}

export default ApplicationTableHeader;
