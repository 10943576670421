import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Pagination } from '../../../services/entities/Pagination';
import {
    PayoutsListFilter,
    PayoutsListFilterTypeEnum,
} from '../../../services/entities/PayoutsList';
import { Sort } from '../../../services/entities/Sort';

export const initialValues: PayoutsListFilter = {
    agreementNumber: '',
    id: null,
    state: [],
    recipientId: null,
    recipientName: '',
    amountFrom: null,
    amountTo: null,
    fromToDate: null,
    fromDate: null,
    toDate: null,
    dueDateFrom: null,
    dueDateTo: null,
    tribe: [],
    releaseDate: null,
    tasks: [],
};

export type PayoutsListFilterState = {
    baseFilter: PayoutsListFilterTypeEnum;
    filter: PayoutsListFilter;
    pagination: Pagination & { reset: boolean };
    sortOrder: 'ASC' | 'DESC';
    sortList: string[];
    clean: boolean;
};
const initialState: PayoutsListFilterState = {
    baseFilter: PayoutsListFilterTypeEnum.ACTION,
    filter: initialValues,
    sortOrder: 'DESC',
    sortList: ['id'],
    pagination: { page: 1, size: 10, reset: false },
    clean: true,
};

const payoutsListFilterSlice = createSlice({
    name: 'applicationListFilter',
    initialState,
    reducers: {
        setPayoutsFilter: (s, a: PayloadAction<PayoutsListFilter>) => {
            s.filter = a.payload;
            s.clean = false;
            s.pagination = {
                page: 1,
                size: s.pagination.size,
                reset: !s.pagination.reset,
            };
        },
        setPayoutsSort: (s, a: PayloadAction<Sort>) => {
            s.sortOrder = a.payload.sortOrder;
            s.sortList = a.payload.sortList;
            s.pagination = {
                page: 1,
                size: s.pagination.size,
                reset: !s.pagination.reset,
            };
        },
        setBasePayoutsFilter: (
            s,
            a: PayloadAction<PayoutsListFilterTypeEnum>
        ) => {
            s.baseFilter = a.payload;
            s.pagination = {
                page: 1,
                size: s.pagination.size,
                reset: !s.pagination.reset,
            };
        },
        clearPayoutsFilter: (s) => {
            s.filter = initialValues;
            s.clean = true;
            s.pagination = {
                page: 1,
                size: s.pagination.size,
                reset: !s.pagination.reset,
            };
        },
        setPayoutsPagination: (s, a: PayloadAction<Pagination>) => {
            s.pagination = { ...a.payload, reset: s.pagination.reset };
        },
        resetPayoutsPagination: (s) => {
            s.pagination = {
                page: 1,
                size: s.pagination.size,
                reset: !s.pagination.reset,
            };
        },
    },
});

export const {
    setPayoutsFilter,
    clearPayoutsFilter,
    setBasePayoutsFilter,
    setPayoutsPagination,
    resetPayoutsPagination,
    setPayoutsSort,
} = payoutsListFilterSlice.actions;
export default payoutsListFilterSlice.reducer;
