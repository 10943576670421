export enum TitleStructureEnum {
    DMP = 'DMP',
    STZ = 'STZ',
    USP = 'USP',
}

export type TitleRegisterFilter = {
    titleType: string;
    evidenceNumber: string;
    agreementNumber: string;
    agency: string;
    validFrom: string;
    validTo: string;
    paidOutFrom: number | string;
    paidOutTo: number | string;
    titleMaxValueFrom: number | string;
    titleMaxValueTo: number | string;
    broker: string;
    brokerCode: string;
    brokerType: string;
    leftToPay: boolean | string;
    depositAllowed: boolean | string;
    isEstimatedItem: boolean | string;
    incentiveEvent: string;
    typeOfEvent: string;
};

export type TitleRegisterSort = {
    sortOrder?: string;
    sortList?: string[];
};

export type TitleRegisterTitleFilter = {
    titleType: string;
    brokerCode?: string;
    name?: string;
    pageSize?: number;
};

export type PageTitleType = {
    recordCount: number;
    pageSize: number;
    pageNumber: number;
    titles: TitleRegisterTableType;
    mpTitles: Array<MpTitle>;
};

export type MpTitle = {
    id: number;
    titleType: string;
    evidenceNumber: string;
    agreementNumber: string;
    titleNumber: string;
    brokerCode: string;
    tribe: string;
    deposit: boolean;
};

export type TitleRegisterTableType = Array<TitleRegisterTableCols>;

export type TitleRegisterTableCols = {
    id: number;
    titleType: string;
    evidenceNumber: string;
    titleNumber: string;
    agreementNumber: string;
    brokerCode: string;
    broker: string;
    brokerType: string;
    tribe: string;
    agency: string;
    agencySap: string;
    validFrom: string;
    validTo: string;
    payoutR: number;
    payoutR1: number;
    payoutR2: number;
    titleMaxValue: number;
    deposit: boolean;
    expectedPayouts: number;
    isEstimatedItem: boolean;
    estimatedItem: number;
    paidOut: number;
    toBePaid: number;
    note: string;
    incentiveEvent: string;
    typeOfEvent: string;
    titleImportFile: {
        id: number;
        username: string;
        fileName: string;
        created: string;
        numberOfEntries: number;
    };
    inactive: boolean;
};

export type TitleRegisterListFetchInput = {
    type: string;
    data?: TitleRegisterFilter;
};

export let initialValues = {
    DMP: {
        titleType: 'DMP;ODM;MOP;DMM;BON',
        evidenceNumber: '',
        agreementNumber: '',
        agency: '',
        validFrom: '',
        validTo: '',
        paidOutFrom: '',
        paidOutTo: '',
        titleMaxValueFrom: '',
        titleMaxValueTo: '',
        broker: '',
        brokerCode: '',
        brokerType: '',
        leftToPay: null,
        depositAllowed: null,
        isEstimatedItem: null,
        incentiveEvent: '',
        typeOfEvent: '',
    },
    STZ: {
        titleType: 'STZ;MOT',
        evidenceNumber: '',
        agreementNumber: '',
        agency: '',
        validFrom: '',
        validTo: '',
        paidOutFrom: '',
        paidOutTo: '',
        titleMaxValueFrom: '',
        titleMaxValueTo: '',
        broker: '',
        brokerCode: '',
        brokerType: '',
        leftToPay: null,
        depositAllowed: null,
        isEstimatedItem: null,
        incentiveEvent: '',
        typeOfEvent: '',
    },
    USP: {
        titleType: 'USP',
        evidenceNumber: '',
        agreementNumber: '',
        agency: '',
        validFrom: '',
        validTo: '',
        paidOutFrom: '',
        paidOutTo: '',
        titleMaxValueFrom: '',
        titleMaxValueTo: '',
        broker: '',
        brokerCode: '',
        brokerType: '',
        leftToPay: null,
        depositAllowed: null,
        isEstimatedItem: null,
        incentiveEvent: '',
        typeOfEvent: '',
    },
};
