import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Variant} from 'react-bootstrap/esm/types';
import {generateId} from "../../../utils/generateId";

export interface NotificationsState {
    notifications: Notification[]
}

const initialState: NotificationsState = {
    notifications: []
}

export type Notification = {
    text: string,
    variant: Variant,
    timeout: number,
    timeoutId: number,
    id: number
}
export type AddNotificationInput = {
    text: string,
    variant?: Variant,
    timeout?: number
}
export const addNotification = createAsyncThunk<Notification, AddNotificationInput>(
    'notifications/addNotification',
    async ({text, variant = 'info', timeout = 10000}, thunkApi) => {
        const id = generateId()
        const timeoutId = window.setTimeout(() => {
            thunkApi.dispatch(clearNotification(id))
        }, timeout)
        return {id, text, variant, timeout, timeoutId}
    }
)
export const addDanger = (text: string) => {
    return addNotification({
        timeout: 10000,
        variant: 'danger',
        text
    })
}
export const addSuccess = (text: string) => {
    return addNotification({
        timeout: 10000,
        variant: 'success',
        text
    })
}
export const addInfo = (text: string) => {
    return addNotification({
        timeout: 10000,
        variant: 'info',
        text
    })
}

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        'clearNotification': (state, action: PayloadAction<number>) => {
            const index = state.notifications.findIndex(n => n.id === action.payload)
            if (index > -1) {
                const notif = state.notifications[index]
                window.clearTimeout(notif.timeoutId)
                state.notifications.splice(index, 1)
            }
        }
    },
    extraReducers: builder => {
        builder.addCase(addNotification.fulfilled, (s, a) => {
            const payload = a.payload
            s.notifications.push(payload)
        })
    }
})

export const {clearNotification} = notificationsSlice.actions
export default notificationsSlice.reducer