import React, {useCallback, useState} from "react";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import {InputProps} from "react-bootstrap-typeahead/types/types";

export type AsyncAutocompleteProps<T> = {
    id: string,
    labelKey?: string,
    onSearch: (query: string) => Promise<T[]>,
    placeholder?: string,
    inputProps: InputProps,
    className?: string,
    onChange: (value: T[]) => void,
    formatItem?: (item: any) => JSX.Element
}

function AsyncAutocomplete<T>(
    {
        id, labelKey, onSearch, placeholder, inputProps,
        className, formatItem, onChange
    }: AsyncAutocompleteProps<T>) {

    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState<T[]>([]);

    const handleSearch = (query: string) => {
        setIsLoading(true)
        onSearch(query).then((options) => {
            setOptions(options)
            setIsLoading(false)
        })
    }

    const changeWrapper = useCallback((selected: any[]) => {
        onChange(selected)
    }, [onChange])

    const inputProps2 = {...inputProps}
    delete inputProps2.onBlur
    delete inputProps2.onChange

    return (
        <AsyncTypeahead
            id={id}
            inputProps={inputProps2}
            onChange={changeWrapper}
            onBlur={inputProps.onBlur}
            className={className}
            filterBy={() => true}
            isLoading={isLoading}
            labelKey={labelKey}
            onSearch={handleSearch}
            options={options}
            placeholder={placeholder}
            renderMenuItemChildren={formatItem || undefined}
            emptyLabel="Nenalezen žádný záznam"
        />
    )
}

export default AsyncAutocomplete;