import React, {useEffect} from 'react';
import {Popover, Row} from "react-bootstrap";
import {LoadingIndicator} from '../../../components/loadingIndicator/LoadingIndicator';
import {TextItem} from '../../../components/textItem/TextItem';
import {formatDate} from '../../../utils/formatUtils';
import {useAppDispatch, useAppSelector} from '../../../store/store';
import {PayoutInternal} from '../../../services/entities/Payout';
import {brokerFetch} from '../../../store/slices/applicationDetail/brokerSlice';

const BrokerPopover = React.forwardRef((props: any, ref: any) => {

    const payout: PayoutInternal = props.payout
    const validityDate = props.validitydate
    const brokerData = useAppSelector(s => s.broker.broker)
    const broker = brokerData.data

    const dispatch = useAppDispatch()
    useEffect(() => {
        if (payout && payout.recipientId)
            dispatch(brokerFetch({kodzi: payout.recipientId, validityDate: validityDate || new Date()}))
    }, [dispatch, payout, validityDate])

    return (
        <Popover ref={ref} {...props}>
            <Popover.Header as="h3">Údaje o získateli</Popover.Header>
            <Popover.Body>
                {brokerData.pending && <LoadingIndicator />}

                {broker?.notFound ? (
                    <div>Nenalezeno</div>
                ) : (
                    <>
                        <Row>
                            <TextItem title="Název" value={broker?.name} />
                            <TextItem
                                title="Organizační jednotka"
                                value={broker?.unitCode}
                            />
                        </Row>

                        <Row>
                            <TextItem
                                title="Období platnosti"
                                value={
                                    broker &&
                                    `${formatDate(
                                        broker.validFrom
                                    )} - ${formatDate(broker.validTo)}`
                                }
                            />
                            <TextItem title="IČO" value={broker?.id} />
                        </Row>
                    </>
                )}
            </Popover.Body>
        </Popover>
    );
})

export default BrokerPopover;