import {EDITABLE_TASK_DEFS, TaskDefinitionKey} from '../../services/entities/ApplicationProcess';

export enum TaskStateEnum {
    UNCLAIMED_TASK,
    CLAIMED_TASK,
    CLAIMED_EDITABLE_TASK,
    NO_TASK
}

export const CLAIMED_TASKS = [
    TaskStateEnum.CLAIMED_TASK,
    TaskStateEnum.CLAIMED_EDITABLE_TASK
]

export const resolveTaskState = (login: string, taskAssignee: String, taskDefinitionKey: TaskDefinitionKey):TaskStateEnum => {

    if (!taskDefinitionKey || !login)
        return TaskStateEnum.NO_TASK

    if (taskAssignee === login) {
        const editable = EDITABLE_TASK_DEFS.includes(taskDefinitionKey)
        return editable ? TaskStateEnum.CLAIMED_EDITABLE_TASK : TaskStateEnum.CLAIMED_TASK
    }

    return TaskStateEnum.UNCLAIMED_TASK
}