// const BASE_URL = 'http://localhost:8010/proxy'

import {getTypeEnumForCompany} from "../utils/utils";

export const APP_ENV = process.env.REACT_APP_ENV;

export const COMPANIES  = {
    KOOP: 'KOOP',
    CPP: 'CPP'
}

export const COMPANY  = process.env.REACT_APP_COMPANY;

export const IS_KOOP = COMPANY === COMPANIES.KOOP
export const TYPE_ENUM_FOR_COMPANY = getTypeEnumForCompany()

export enum ENVS {
    DEV = 'DEV',
    TEST = 'TEST',
    PROD = 'PROD'
}

const BASE_URL = ''
const DATA_API_SUBPATH = process.env.REACT_APP_DATA_API_URL;
const SERVICE_API_SUBPATH = process.env.REACT_APP_SERVICE_API_URL;
const CUSTOM_SERVICE_API_SUBPATH = process.env.REACT_APP_SERVICE_CUSTOM_API_URL;
export const DATA_API_URL = `${BASE_URL}${DATA_API_SUBPATH}`;
export const SERVICE_API_URL = `${BASE_URL}${SERVICE_API_SUBPATH}`;
export const CUSTOM_SERVICE_API_URL = `${BASE_URL}${CUSTOM_SERVICE_API_SUBPATH}`;
