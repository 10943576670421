import {COMPANIES, COMPANY} from "../services/constants";
import {ApplicationTypeEnumCpp, ApplicationTypeEnumKoop} from "../services/entities/Application";

export function hasIntersection(arr1, arr2) {
    return arr1.some((el) => arr2.includes(el));
}

export const matchPattern = (arr: string[], pattern: string): boolean => {
    if (arr.some((hodnota) => hodnota.includes(pattern))) {
        return true;
    } else return false;
};

export function getTypeEnumForCompany() {
    if(COMPANY === COMPANIES.KOOP) return ApplicationTypeEnumKoop
    if(COMPANY === COMPANIES.CPP) return ApplicationTypeEnumCpp
}

export function arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.

    for (let i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
    }
    return true;
}

export function isPromise(p) {
    return p !== null &&
        typeof p === 'object' &&
        typeof p.then === 'function' &&
        typeof p.catch === 'function';
}

export function getLeaves<T>(root: T, childrenProp): T[] {

    if (root[childrenProp] && root[childrenProp].length) {
        const leaves = []
        const children = root[childrenProp]
        children.forEach(child => leaves.push(...getLeaves(child, childrenProp)))
        return leaves
    }

    return [root]
}

export function mergeFns(fn1: Function, fn2: Function) {
    return (input) => {
        fn1(input)
        return fn2(input)
    }
}