import React from 'react'
import { Form } from 'react-bootstrap'

export type RequiredLabelProps = {
    children: React.ReactNode
}
export function RequiredLabel({children}: RequiredLabelProps) {
    return (
        <Form.Label disabled>{children} <span className="text-danger">*</span></Form.Label>
    )
}