import './MyTabs.scss'
import {Badge, Nav} from 'react-bootstrap';
import {useContext} from 'react';
import {MyTabContainerContext} from './MyTabContainer';

export type MyTabItem = {
    label: string,
    count?: number,
    action?: () => void,
    disabled?: boolean
}
export type MyTabsProps = {
        items: MyTabItem[]
}

function Item({label, onClick, disabled, count, action}: MyTabItem & { onClick: (event) => void }) {

    if (action) {
        return <div className="tabCard position-relative my-2 ps-3 pe-1 py-0"
                    onClick={action}>
            {label}
        </div>
    }

    return (
        <Nav.Item className="d-flex align-items-center justify-content-between">
            <Nav.Link className="tabCard position-relative my-2 ps-3 pe-1 py-0"
                      disabled={disabled}
                      data-eventkey={label}
                      key={label} eventKey={label} onClick={onClick}>
                {label}
            </Nav.Link>

            {
                count >= 0 &&
                <Badge pill bg={count ? 'primary' : 'secondary'}>
                    {count}
                </Badge>
            }


        </Nav.Item>
    )
}

export function MyTabs({items}: MyTabsProps) {

    const [, setSelectedKey] = useContext(MyTabContainerContext)

    const handleClick = (event) => {
        const eventKey = event.target.getAttribute('data-eventkey')
        setSelectedKey(eventKey)
    }

    return (
        <Nav className="MyTabs outer flex-column pt-3 pb-3 h-100">
            {
                items.map((el, i) => (<Item key={i} {...el} onClick={handleClick}/>))
            }
        </Nav>
    )
}