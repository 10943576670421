import {Alert, Container} from "react-bootstrap";

type ValidationAlertProps = {
    show?: boolean
    onClose?: () => void
    variant?: string
    errors?: Array<string>
    dismissible?: boolean
}

const ValidationAlert = ({show, onClose, variant, errors, dismissible}: ValidationAlertProps) => {
    return (
        <Alert className='mb-0' show={show} variant={variant} onClose={onClose} dismissible={dismissible}>
            <Container className='overflow-hidden h-100'>
                <h6>Žádost nelze odeslat z důvodu následujících chyb ve formuláři:</h6>
                <div style={{maxHeight: '10rem', overflowY: 'auto'}}>
                <ul>
                    {errors?.map(err => {
                        return <li key={err.length + Math.random()}>{err}</li>
                    })}
                </ul>
                </div>
            </Container>
        </Alert>
    );
};

export default ValidationAlert;