import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ApplicationBaseFilterTypeEnum, ApplicationFilter} from '../../../services/entities/Application';
import {Pagination} from '../../../services/entities/Pagination';
import {Sort} from '../../../services/entities/Sort';

export const initialValues: ApplicationFilter = {
    creatorName: '',
    applicationID: '',
    applicationNumber: '',
    states: '',
    payoutStates: '',
    types: '',

    creationDateFrom: null,
    creationDateTo: null,
    golemReleaseDate: null,

    payoutAmountFrom: null,
    payoutAmountTo: null
}

export type ApplicationCustomFilterState = {
    filter: ApplicationFilter,
    baseFilter: ApplicationBaseFilterTypeEnum,
    myApprovalFilter: boolean,
    pagination: Pagination & {reset: boolean},
    sort: Sort,
    clean: boolean
}
const initialState: ApplicationCustomFilterState = {
    filter: initialValues,
    sort: {
        sortOrder: 'DESC',
        sortList: ['created']
    },
    myApprovalFilter: false,
    baseFilter: ApplicationBaseFilterTypeEnum.ACTION,
    pagination: {page: 1, size: 10, reset: false},
    clean: true
}

const applicationListFilterSlice = createSlice({
    name: 'applicationListFilter',
    initialState,
    reducers: {
        'setFilter': (s, a: PayloadAction<ApplicationFilter>) => {
            s.filter = a.payload
            s.clean = false
            s.pagination = {page: 1, size: s.pagination.size, reset: !s.pagination.reset}
        },
        'setSort': (s, a: PayloadAction<Sort>) => {
            s.sort = a.payload
            s.pagination = {page: 1, size: s.pagination.size, reset: !s.pagination.reset}
        },
        'setBaseFilter': (s, a: PayloadAction<ApplicationBaseFilterTypeEnum>) => {
            s.baseFilter = a.payload
            s.pagination = {page: 1, size: s.pagination.size, reset: !s.pagination.reset}
        },
        'setMyApprovalFilter': (s, a: PayloadAction<boolean>) => {
            s.myApprovalFilter = a.payload
            s.pagination = {page: 1, size: s.pagination.size, reset: !s.pagination.reset}
        },
        'clearFilter': (s) => {
            s.filter = initialValues
            s.clean = true
            s.pagination = {page: 1, size: s.pagination.size, reset: !s.pagination.reset}
        },
        'setPagination': (s,a: PayloadAction<Pagination>) => {
            s.pagination = {...a.payload, reset: s.pagination.reset}
        },
        'resetPagination': (s) => {
            s.pagination = {page: 1, size: s.pagination.size, reset: !s.pagination.reset}
        }
    }
})

export const {setFilter, clearFilter, setBaseFilter, setPagination, resetPagination, setSort, setMyApprovalFilter}
    = applicationListFilterSlice.actions
export default applicationListFilterSlice.reducer