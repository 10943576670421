import {DATA_API_URL} from '../constants';
import {ProcessInstanceModifyInput} from '../entities/ProcessInstanceModifyInput';
import {ApplicationId} from '../entities/Application';
import {ProcessDefinitionId, TaskId} from '../entities/ApplicationProcess';
import {fetchMethods} from "../fetchUtils";


export const modifyProcessInstances = (input: ProcessInstanceModifyInput,
                                       processDefinitionId: ProcessDefinitionId,  token: string):Promise<void> => {
    const url = `${DATA_API_URL}/camunda/modification/execute`;
    return fetchMethods.post(url,
        {...input, processDefinitionId: processDefinitionId},
        token)
}

export const migrateProcessInstances = (sourceDefinitionId: ProcessDefinitionId,
                                        targetDefinitionId: ProcessDefinitionId,
                                        token: string):Promise<void> => {
    const url = `${DATA_API_URL}/camunda/migration/execute`;
    const body = {
        migrationPlanGenerationDto: {
            sourceProcessDefinitionId: sourceDefinitionId,
            targetProcessDefinitionId: targetDefinitionId,
            updateEventTriggers: true
        },
        processInstanceQueryDto: {
            processDefinitionId:sourceDefinitionId
        }
    }
    return fetchMethods.post(url, body, token)
}

export const changeTaskAssignee = (
    applicationId: ApplicationId, taskId:TaskId, userId: string, token: string):Promise<void> => {
    const url = `${DATA_API_URL}/camunda/applications/${applicationId}/task/${taskId}/assignee`;
    const body = {
        userId
    }
    return fetchMethods.post(url, body, token)
}
