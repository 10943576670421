import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    initialValues,
    PageTitleType,
    TitleRegisterFilter,
    TitleRegisterListFetchInput,
    TitleRegisterSort,
    TitleRegisterTableCols,
    TitleStructureEnum,
} from '../../../services/entities/TitleRegister';
import { fetchTitleRegisterList } from '../../../services/api/titleRegisterApi';
import { getIdToken } from '../../../pages/common/authProvider/authProviderUtils';
import { store } from '../../store';

type TitleRegisterTypeState = {
    titleRegisterData: Array<TitleRegisterTableCols>;
    loading: boolean;
    recordCount: number;
    pagination: {
        pageSize: number;
        pageNumber: number;
    };
    sort: { sortOrder: 'ASC' | 'DESC'; sortList: string[] };
};

export type TitleRegisterState = {
    selectedTitleRegister: TitleStructureEnum;

    DMP: TitleRegisterTypeState;

    STZ: TitleRegisterTypeState;

    USP: TitleRegisterTypeState;
    mpTitlesData: TitleRegisterTypeState;
};

const initialState: TitleRegisterState = {
    selectedTitleRegister: TitleStructureEnum.DMP,

    DMP: {
        titleRegisterData: [],
        loading: false,
        recordCount: null,
        pagination: {
            pageSize: 30,
            pageNumber: 1,
        },
        sort: {
            sortOrder: 'ASC',
            sortList: [],
        },
    },

    STZ: {
        titleRegisterData: [],
        loading: false,
        recordCount: null,
        pagination: {
            pageSize: 30,
            pageNumber: 1,
        },
        sort: {
            sortOrder: 'ASC',
            sortList: [],
        },
    },

    USP: {
        titleRegisterData: [],
        loading: false,
        recordCount: null,
        pagination: {
            pageSize: 30,
            pageNumber: 1,
        },
        sort: {
            sortOrder: 'ASC',
            sortList: [],
        },
    },
    mpTitlesData: {
        titleRegisterData: [],
        loading: false,
        recordCount: null,
        pagination: {
            pageSize: 100,
            pageNumber: 1,
        },
        sort: {
            sortOrder: 'DESC',
            sortList: ['created'],
        },
    },
};

export const titleRegisterListFetch = createAsyncThunk<
    PageTitleType,
    TitleRegisterListFetchInput
>('titleRegister', async (input: TitleRegisterListFetchInput) => {
    const state = store.getState();
    const pagination = {
        pageSize: state.titleRegister[input.type].pagination.pageSize,
        pageNumber: state.titleRegister[input.type].pagination.pageNumber - 1,
    };
    const data: TitleRegisterFilter =
        input.data || initialValues[state.titleRegister.selectedTitleRegister];
    const sort: TitleRegisterSort = {
        sortOrder: state.titleRegister[input.type].sort.sortOrder,
        sortList: state.titleRegister[input.type].sort.sortList,
    };

    return await fetchTitleRegisterList(
        { ...data, ...pagination, ...sort },
        await getIdToken()
    );
});

const titleRegisterSlice = createSlice({
    name: 'titleRegister',
    initialState: initialState,
    reducers: {
        setSelectedTitleRegister: (state, action) => {
            state.selectedTitleRegister = TitleStructureEnum[action.payload];
        },
        setPagination: (state, action) => {
            state[action.payload.type].pagination = action.payload.data;
        },
        setTitleSort: (state, action) => {
            state[action.payload.type].sort = action.payload.data;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(titleRegisterListFetch.pending, (state, action) => {
            state[action.meta.arg.type].loading = true;
        });
        builder.addCase(titleRegisterListFetch.fulfilled, (state, action) => {
            state[action.meta.arg.type].titleRegisterData =
                action.payload.titles;
            state[action.meta.arg.type].recordCount =
                action.payload.recordCount;
            state[action.meta.arg.type].pagination = {
                pageSize: action.payload.pageSize,
                pageNumber: action.payload.pageNumber + 1,
            };
            state[action.meta.arg.type].loading = false;
        });
        builder.addCase(titleRegisterListFetch.rejected, (state, action) => {
            state[action.meta.arg.type].loading = false;
        });
    },
});

export const { setSelectedTitleRegister, setPagination, setTitleSort } =
    titleRegisterSlice.actions;
export default titleRegisterSlice.reducer;
