import { DATA_API_URL } from '../constants';
import { AuthToken } from './applicationApi';
import { Broker } from '../entities/Broker';
import { KODZI } from '../entities/Payout';
import { formatServerDate } from '../../utils/formatUtils';
import { fetchMethods } from '../fetchUtils';

export const fetchBroker = (
    kodzi: KODZI,
    validityDate: Date,
    token: AuthToken
): Promise<Broker> => {
    return fetchBrokers([kodzi], validityDate, token).then((data) => {
        return data && data.length ? data[0] : null;
    });
};

// will be deleted after test //

// return axios.get(url, apiConfig(token))
//     .then((response) => {
//             return response.data || {notFound: true};
//         },
//         (e) => {
//             if (e instanceof AxiosError
//                 && (e as AxiosError).response.status === 404) return {
//                 notFound: true
//             }
//             throw e
//         })

// will be deleted after test //

// return axios.get(url, apiConfig(token))
//     .then((response) => {
//             return response.data || {notFound: true};
//         },
//         (e) => {
//             if (e instanceof AxiosError
//                 && (e as AxiosError).response.status === 404) return {
//                 notFound: true
//             }
//             throw e
//         })

export const fetchBrokers = (
    kodzis: KODZI[],
    validityDate: Date,
    token: AuthToken
): Promise<Broker[]> => {
    kodzis = kodzis.map((k) => +k.toString());

    const url = `${DATA_API_URL}/rest/broker/commission/broker?date=${formatServerDate(
        validityDate
    )}`;
    return fetchMethods.post(url, kodzis, token);
};
