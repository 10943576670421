import React, {useCallback, useEffect, useMemo, useState} from 'react';
import './ClaimTask.scss'
import {getServerErrorMsg, RootState, useAppDispatch, useAppSelector} from '../../../store/store';
import {TaskStateEnum} from '../resolveTaskState';
import {ClipboardCheck, Info, Person, PersonFill, PersonPlus, XLg} from 'react-bootstrap-icons';
import {MyButton} from '../../../components/button/MyButton';
import {ApplicationTask, IdentityLink, TaskId} from '../../../services/entities/ApplicationProcess';
import {claimTask, fetchTaskIdentityLinks, unClaimTask} from '../../../services/api/applicationProcessApi';
import {addDanger, addInfo} from '../../../store/slices/common/notificationsSlice';
import {TEXTS} from '../../../constants/texts';
import {ApiData} from '../../../store/storeApiUtils';
import WithTooltip from '../../../components/withPopover/WithTooltip';
import {LoadingIndicator} from '../../../components/loadingIndicator/LoadingIndicator';
import {ButtonGroup, OverlayTrigger, Popover} from 'react-bootstrap';
import {formatCamundaUserId} from "../../../utils/formatUtils";
import {camundaUsersFetch} from "../../../store/slices/applicationDetail/camundaUsersSlice";
import {getIdToken} from "../authProvider/authProviderUtils";

export type ClaimTaskProps = {
    state: TaskStateEnum,
    task?: ApplicationTask,
    onAfterAction: (taskId: TaskId) => void
}

function ClaimTask({state, task, onAfterAction}: ClaimTaskProps) {
    const user = useAppSelector((s: RootState) => s.user)
    const camundaUsersMap = useAppSelector((s: RootState) => s.applicationDetail.camundaUsers).map
    const dispatch = useAppDispatch()

    const [pending, setPending] = useState(false)
    const [links, setLinks] = useState<ApiData<IdentityLink[]>>({data: [], pending: false, error: ''})

    useEffect(() => {
        if (task?.id && user) {
            setLinks({data: [], pending: true})

            getIdToken().then((idToken) => {
                fetchTaskIdentityLinks(task.id, idToken).then(res => {
                    setLinks({data: res, pending: false})
                    dispatch(camundaUsersFetch(res.map(el => el.userId)))
                }).catch(err => {
                    setLinks({data: [], pending: false, error: getServerErrorMsg(err)})
                })
            })
        } else {
            setLinks({data: [], pending: false})
        }
    }, [dispatch, task?.id, user])

    const handleClaim = async () => {
        setPending(true)
        claimTask(task.id, user.login, await getIdToken()).then(() => {
            setPending(false)
            onAfterAction(task.id)
        }).catch(err => {
            dispatch(addDanger(TEXTS.serverErr(getServerErrorMsg(err))))
        })
    }

    const handleUnclaim = async () => {
        setPending(true)
        unClaimTask(task.id, await getIdToken()).then(() => {
            setPending(false)
            onAfterAction(task.id)
        }).catch(err => {
            dispatch(addDanger(TEXTS.serverErr(getServerErrorMsg(err))))
        })
    }

    const copyTaskIdToClipboard = useCallback(() => {
        navigator.clipboard.writeText(task.id)
        dispatch(addInfo(`ID úkolu úspěšně zkopírováno do schránky \n ${task.id}`))
    }, [dispatch, task.id])

    const infoElement = useMemo(() => (
        <OverlayTrigger
            overlay={<Popover>
                <Popover.Header as="h3">Kandidáti</Popover.Header>
                <Popover.Body>
                    {
                        links.pending && <LoadingIndicator/>
                    }
                    {
                        !links.pending && !links.data.length &&
                        "Žádní kandidáti"
                    }
                    {
                        links.data.map(el => (
                            <div key={el.type + el.type + el.userId}>
                                {
                                    el.groupId &&
                                    <span>Skupina: <strong>{el.groupId}</strong>{' '}</span>
                                }
                                {
                                    el.userId &&
                                    <span>Uživatel: <strong>{formatCamundaUserId(el.userId, camundaUsersMap)}</strong></span>
                                }
                            </div>
                        ))
                    }
                </Popover.Body>
            </Popover>}
            placement="bottom"
            trigger="click"
            rootClose>
            <div className="d-flex">
                <MyButton variant="link" noYMargin
                          className="d-flex text-decoration-none align-items-center"
                          tooltipPlacement="top"
                          tooltip="Zobrazit kandidáty na řešení úkolu">
                    <Person/> <Info/>
                </MyButton>
            </div>
        </OverlayTrigger>), [camundaUsersMap, links.data, links.pending]
    )

    const taskIdElement = useMemo(() => (
        <MyButton variant="link" noYMargin disabled={pending}
                  onClick={copyTaskIdToClipboard}
                  tooltip={`Zkopírovat ID úkolu ${task.id} do schránky`}
                  className="d-flex align-items-center text-decoration-none">
            ID úkolu <ClipboardCheck className="ms-1"/>
        </MyButton>
    ), [copyTaskIdToClipboard, pending, task.id])

    return (
        <div className="ClaimTask position-relative d-flex align-items-center">
            <WithTooltip tooltip="Osoba, která má nárokován/přiřazen tento úkol">
                <PersonFill className="me-2"/>
            </WithTooltip>
            Přiřazeno: <strong className="ps-1">{formatCamundaUserId(task?.assignee, camundaUsersMap)}</strong>


            {
                task?.assignee ?
                    <ButtonGroup>
                        <MyButton disabled={pending} variant="link"
                                  className="d-flex align-items-center"
                                  tooltip="Zrušit přiřazení úkolu"
                                  onClick={handleUnclaim} noYMargin>
                            <XLg className="text-warning"/>
                        </MyButton>
                        {infoElement}
                        {taskIdElement}
                    </ButtonGroup> :
                    null
            }
            {
                state === TaskStateEnum.UNCLAIMED_TASK && !task?.assignee ?
                    <div className="d-flex">
                        <MyButton variant="link" disabled={pending || !task}
                                  tooltip="Přiřadit úkol na sebe"
                                  noYMargin
                                  onClick={handleClaim}>
                            <PersonPlus className="me-1"/>Nárokovat
                        </MyButton>
                        {infoElement}
                        {taskIdElement}
                    </div>
                    : null
            }
        </div>
    )
}

export default ClaimTask;
