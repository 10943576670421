import { ConditionalStyles, TableColumn } from 'react-data-table-component';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import {
    formatAmount,
    formatDate,
    TrueFalseFormat,
} from '../../../utils/formatUtils';
import TitleRegisterFilterButton, {
    TitleRegisterFilterButtonProps,
} from '../customFilter/TitleRegisterFilterButton';
import {
    TitleRegisterTableCols,
    TitleStructureEnum,
} from '../../../services/entities/TitleRegister';
import {
    setPagination,
    setTitleSort,
    titleRegisterListFetch,
} from '../../../store/slices/titleRegister/titleRegisterSlice';
import { LoadingIndicator } from '../../../components/loadingIndicator/LoadingIndicator';
import { TEXTS } from '../../../constants/texts';
import { MyTable } from '../../../components/table/MyTable';
import { arraysEqual } from '../../../utils/utils';

// fw-bold

const highlightCellStyles: ConditionalStyles<TitleRegisterTableCols>[] = [
    {
        'when': () => true,
        classNames: ['highlightedCell', 'fw-bold'],
    }
];

const TitleRegisterTable = ({
    setShowTitleFilterModal,
}: TitleRegisterFilterButtonProps) => {
    const dispatch = useAppDispatch();
    const filterData = useAppSelector(
        (state) => state.titleRegisterFilter.filterData
    );
    const { selectedTitleRegister } = useAppSelector(
        (state) => state.titleRegister
    );
    const { titleRegisterData, loading, recordCount, pagination, sort } =
        useAppSelector((state) => {
            return state.titleRegister[
                state.titleRegister.selectedTitleRegister
            ];
        });

    const columns: TableColumn<TitleRegisterTableCols>[] = [
        {
            id: 'titleType',
            name: 'Typ titulu',
            selector: (row) => row.titleType,
            sortable: true,
        },
        {
            id: 'agreementNumber',
            name: 'Číslo smlouvy',
            selector: (row) => row.agreementNumber,
            sortable: true,
        },
        {
            id: 'incentiveEvent',
            name: 'Název soutěže / motivační akce',
            width: '200px',
            selector: (row) => row.incentiveEvent,
            omit: selectedTitleRegister !== TitleStructureEnum.STZ,
            sortable: true,
        },

        {
            id: 'brokerCode',
            name: 'Číslo získatele',
            selector: (row) => row.brokerCode,
            omit: selectedTitleRegister !== TitleStructureEnum.DMP,
        },

        {
            id: 'broker',
            name: 'Název získatele',
            selector: (row) => row.broker,
            omit: selectedTitleRegister !== TitleStructureEnum.DMP,
            sortable: true,
        },
        {
            id: 'brokerType',
            name: 'Typ získatele',
            selector: (row) => row.brokerType,
            omit: selectedTitleRegister !== TitleStructureEnum.DMP,
        },
        {
            id: 'tribe',
            name: 'Kmen',
            selector: (row) => row.tribe,
            omit: selectedTitleRegister === TitleStructureEnum.USP,
            sortable: true,
        },
        {
            id: 'agency',
            name: 'Agentura (kód GOLEM)',
            selector: (row) => row.agency,
            sortable: true,
        },
        {
            id: 'agencySap',
            name: 'Agentura (kód SAP)',
            selector: (row) => row.agencySap,
            sortable: true,
        },
        {
            id: 'titleMaxValue',
            name: 'Maximální výše titulu',
            selector: (row) => formatAmount(row.titleMaxValue),
            sortable: true,
            conditionalCellStyles: highlightCellStyles,
        },
        {
            id: 'paidOut',
            name: 'Vyplaceno',
            selector: (row) => formatAmount(row.paidOut),
            sortable: true,
            conditionalCellStyles: highlightCellStyles,
        },
        {
            id: 'toBePaid',
            name: 'Zbývá',
            selector: (row) => formatAmount(row.toBePaid),
            sortable: true,
            conditionalCellStyles: highlightCellStyles,
        },
        {
            id: 'typeOfEvent',
            name: 'Typ soutěže',
            selector: (row) => row.typeOfEvent,
            omit: selectedTitleRegister !== TitleStructureEnum.STZ,
            sortable: true,
        },
        {
            id: 'validFrom',
            name: 'Platnost od',
            selector: (row) => formatDate(row.validFrom),
            sortable: true,
        },
        {
            id: 'validTo',
            name: 'Platnost do',
            selector: (row) => formatDate(row.validTo),
            sortable: true,
        },
        {
            id: 'payoutR',
            name: 'Výplata R',
            selector: (row) => formatAmount(row.payoutR),
            sortable: true,
        },
        {
            id: 'payoutR1',
            name: 'Výplata R+1',
            selector: (row) => formatAmount(row.payoutR1),
            omit: selectedTitleRegister === TitleStructureEnum.USP,
            sortable: true,
        },
        {
            id: 'payoutR2',
            name: 'Výplata R+2',
            selector: (row) => formatAmount(row.payoutR2),
            omit: selectedTitleRegister === TitleStructureEnum.USP,
            sortable: true,
        },

        {
            id: 'deposit',
            name: 'Zálohy',
            selector: (row) => TrueFalseFormat(row.deposit),
            omit: selectedTitleRegister !== TitleStructureEnum.DMP,
            sortable: true,
        },
        {
            id: 'expectedPayouts',
            name: 'Počet očekávaných výplat',
            selector: (row) => row.expectedPayouts,
            omit: selectedTitleRegister !== TitleStructureEnum.DMP,
            sortable: true,
        },
        {
            id: 'isEstimatedItem',
            name: 'Dohad',
            selector: (row) => TrueFalseFormat(row.isEstimatedItem),
            omit: selectedTitleRegister !== TitleStructureEnum.DMP,
            sortable: true,
        },
        {
            id: 'estimatedItem',
            name: 'Dohadná položka',
            selector: (row) => formatAmount(row.estimatedItem),
            omit: selectedTitleRegister !== TitleStructureEnum.DMP,
            sortable: true,
        },
        {
            id:
                selectedTitleRegister !== TitleStructureEnum.USP
                    ? 'ne'
                    : 'note',
            name: 'Poznámka',
            selector: (row) => row.note,
            width: '150px',
            omit: selectedTitleRegister !== TitleStructureEnum.USP,
            sortable: true,
        },
        {
            id: 'evidenceNumber',
            width: '150px',
            name: 'Titul ID (Evidenční číslo)',
            selector: (row) => row.evidenceNumber,
            sortable: true,
        },

        {
            id: 'titleNumber',
            name: 'Číslo titulu',
            selector: (row) => row.titleNumber,
            omit: selectedTitleRegister !== TitleStructureEnum.DMP,
            sortable: true,
        },

        {
            id:
                selectedTitleRegister === TitleStructureEnum.USP
                    ? 'ne'
                    : 'note',
            name: 'Poznámka',
            selector: (row) => row.note,
            width: '150px',
            omit: selectedTitleRegister === TitleStructureEnum.USP,
            sortable: true,
        },
    ];

    const handlePageChange = (page) => {
        if (page !== pagination.pageNumber) {
            dispatch(
                setPagination({
                    type: selectedTitleRegister,
                    data: { pageSize: pagination.pageSize, pageNumber: page },
                })
            );
            dispatch(
                titleRegisterListFetch({
                    type: selectedTitleRegister,
                    data: filterData[selectedTitleRegister],
                })
            );
        }
    };

    const handlePerRowsChange = async (newPageSize) => {
        if (newPageSize !== pagination.pageSize) {
            dispatch(
                setPagination({
                    type: selectedTitleRegister,
                    data: { pageSize: newPageSize, pageNumber: 1 },
                })
            );
            dispatch(
                titleRegisterListFetch({
                    type: selectedTitleRegister,
                    data: filterData[selectedTitleRegister],
                })
            );
        }
    };

    const handleSort = (selectedColumn, sortDirection: 'asc' | 'desc') => {
        if (selectedColumn.id) {
            const sortList = [selectedColumn.id];
            const direction = sortDirection.toUpperCase() as 'ASC' | 'DESC';

            if (
                !arraysEqual(sortList, sort.sortList) ||
                direction !== sort.sortOrder
            ) {
                dispatch(
                    setTitleSort({
                        type: selectedTitleRegister,
                        data: {
                            sortList: [selectedColumn.id],
                            sortOrder: sortDirection.toUpperCase() as
                                | 'ASC'
                                | 'DESC',
                        },
                    })
                );
            }

            dispatch(
                setPagination({
                    type: selectedTitleRegister,
                    data: { pageSize: pagination.pageSize, pageNumber: 1 },
                })
            );

            dispatch(
                titleRegisterListFetch({
                    type: selectedTitleRegister,
                    data: filterData[selectedTitleRegister],
                })
            );
        }
    };

    return (
        <div className="TitleRegisterTable">
            {loading && <LoadingIndicator />}
            <TitleRegisterFilterButton
                setShowTitleFilterModal={setShowTitleFilterModal}
            />
            <MyTable
                sortServer
                onSort={handleSort}
                columns={columns}
                data={titleRegisterData}
                noDataComponent={TEXTS.noData}
                pagination
                paginationServer
                paginationTotalRows={recordCount}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                paginationPerPage={pagination.pageSize}
                paginationDefaultPage={pagination.pageNumber}
            />
        </div>
    );
};

export default TitleRegisterTable;
