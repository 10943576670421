import React, {useContext, useState} from 'react';
import './Attachments.scss';
import {TableColumn} from 'react-data-table-component';
import {AttachmentCpp, AttachmentKoop} from '../../../services/entities/Attachment';
import {MyButton} from '../../../components/button/MyButton';
import AttachmentFormModalKoop from './AttachmentFormModalKoop';
import {Alert, Col, Row} from 'react-bootstrap';
import {TEXTS} from '../../../constants/texts';
import {RootState, useAppDispatch, useAppSelector,} from '../../../store/store';
import {
    AttachmentAny,
    attachmentDonwload,
    attachmentRemove,
    attachmentSave,
    AttachmentsState,
} from '../../../store/slices/applicationDetail/attachmentsSlice';
import {LoadingIndicator} from '../../../components/loadingIndicator/LoadingIndicator';
import {MyTable} from '../../../components/table/MyTable';
import {Download, Trash3} from 'react-bootstrap-icons';
import Confirmation from '../../../components/confirmation/Confirmation';
import {ApplicationId, ApplicationTypeEnumKoop,} from '../../../services/entities/Application';
import {addNotification} from '../../../store/slices/common/notificationsSlice';
import {AppFormContext} from '../application-detail';
import {useWatch} from 'react-hook-form';
import {IS_KOOP} from '../../../services/constants';
import AttachmentFormModalCpp from './AttachmentFormModalCpp';
import { processItemsAndPayouts } from '../../../services/entities/AttachmentsUtils';
import { titleRegisterListTitleFilterFetch } from '../../../store/slices/applicationDetail/applicationSlice';

export type AttachmentsProps = {
    disabled?: boolean;
    applicationId: ApplicationId;
};

function Attachments({ disabled, applicationId }: AttachmentsProps) {
    const attachments: AttachmentsState = useAppSelector(
        (s: RootState) => s.applicationDetail.attachments
    );

    const multiApplication = useAppSelector(
        (state) =>
            state?.applicationDetail?.application?.application?.data
                ?.application?.multiApplication
    );

    const { selectedType } = useAppSelector(
        (state) => state.applicationDetail.application
    );
    const currentTypeAttachments = attachments?.requiredAttachmentsRules.find(
        (att) => att.applicationType === selectedType
    );

    const columns: TableColumn<AttachmentAny>[] = [
        {
            name: TEXTS.filename,
            selector: (row) => row.fileName,
            sortable: true,
        },
        {
            name: TEXTS.docType,
            selector: (row) =>
                IS_KOOP
                    ? (row as AttachmentKoop).documentType
                    : (row as AttachmentCpp).dokuType,
            sortable: true,
        },
        {
            name: 'Titul',
            width: '300px',
            selector: (row) =>
                `${
                    (row as AttachmentKoop)?.title?.agreementNumber ===
                    undefined
                        ? ''
                        : (row as AttachmentKoop)?.title?.agreementNumber
                } ${
                    (row as AttachmentKoop)?.title?.evidenceNumber === undefined
                        ? ''
                        : `(${(row as AttachmentKoop)?.title?.evidenceNumber})`
                }
                `,
            sortable: true,
            omit: (IS_KOOP && !multiApplication) || !IS_KOOP,
        },
        {
            name: TEXTS.description,
            selector: (row) => row.description,
            cell: (row) => <div>{row.description}</div>,
            sortable: true,
        },
        {
            name: 'Akce',
            width: '160px',
            cell: (row) => (
                <div className="d-flex">
                    <MyButton
                        onClick={handleDownload}
                        title="Stáhnout přílohu"
                        noYMargin
                        data-id={row.id}
                        className="text-decoration-none"
                        variant="link"
                    >
                        <Download />
                    </MyButton>

                    {!disabled && (
                        <Confirmation
                            title="Smazat přílohu"
                            message="Opravdu si přejete smazat přílohu?"
                            confirmText="Smazat"
                            confirmParams={row.id}
                            onConfirm={handleRemove}
                        >
                            <MyButton
                                noYMargin
                                className="text-decoration-none"
                                variant="link"
                            >
                                <Trash3 />
                            </MyButton>
                        </Confirmation>
                    )}
                </div>
            ),
        },
    ];

    const [showModal, setShowModal] = useState(false);
    // const { brokerCodes } = useAppSelector(
    //     (s) => s.applicationDetail.application
    // );

    const dispatch = useAppDispatch();
    const payouts = useAppSelector(
        (state: RootState) => state.applicationDetail.payouts
    );

    const handleRemove = (id) => {
        const attachment = attachments.data.find((el) => el.id === id);
        dispatch(
            attachmentRemove({ applicationId: applicationId, attachment })
        );
    };

    const handleDownload = (event) => {
        const id = event.currentTarget.getAttribute('data-id');
        const attachment = attachments.data.find(
            (el) => el.id?.toString() === id
        );
        dispatch(
            attachmentDonwload({ applicationId: applicationId, attachment })
        );
    };
    const [titleData, setTitleData] = useState([]);

    const handleAddAttachment = () => {
        if (
            IS_KOOP &&
            multiApplication &&
            appType !== ApplicationTypeEnumKoop.OST
        ) {
            setTitleData([]);
            payouts?.data?.map((payout) => {
                return dispatch(
                    titleRegisterListTitleFilterFetch({
                        titleType: appType,
                        name: payout?.agreementNumber,
                    })
                ).then((val: any) => {
                    setTitleData((oldArray) => {
                        if (
                            !oldArray?.find(
                                (o) => o?.id === val?.payload?.mpTitles[0]?.id
                            )
                        ) {
                            return [...oldArray, val?.payload?.mpTitles[0]];
                        } else {
                            return oldArray;
                        }
                    });
                });
            });
        }
        setShowModal(true);
    };

    const handleSave = (attachment: AttachmentAny) => {
        setShowModal(false);
        return dispatch(attachmentSave({ attachment, applicationId }))
            .then(() => {
                setShowModal(false);
            })
            .then(null, (err) => {
                dispatch(
                    addNotification({
                        variant: 'danger',
                        timeout: 10000,
                        text: err.message,
                    })
                );
            });
    };

    const handleModalHide = () => {
        setShowModal(false);
    };
    const appFormLogic = useContext(AppFormContext);

    const appType: ApplicationTypeEnumKoop = useWatch({
        name: 'type',
        control: appFormLogic.control,
    }) as ApplicationTypeEnumKoop;

    return (
        <div className="Attachments position-relative">
            {attachments.pending ? <LoadingIndicator /> : null}
            {IS_KOOP ? (
                <AttachmentFormModalKoop
                    onSave={handleSave}
                    show={showModal}
                    onHide={handleModalHide}
                    titleData={titleData}
                />
            ) : (
                <AttachmentFormModalCpp
                    onSave={handleSave}
                    show={showModal}
                    onHide={handleModalHide}
                />
            )}
            {/*todo-upravit text podle dodaného*/}
            {!disabled &&
                !multiApplication &&
                IS_KOOP &&
                !appFormLogic.getValues('titleId') &&
                appType !== ApplicationTypeEnumKoop.OST && (
                    <Alert className="text-danger" variant="light">
                        Dokud nebude v žádosti zvolen konkrétní titul (sekce
                        "Parametry"), nebude možné přidávat přílohy.
                    </Alert>
                )}
            {!!multiApplication && IS_KOOP && payouts.data.length === 0 && (
                <Alert className="text-danger" variant="light">
                    Dokud nebude zadána alespoň jedna výplata, nebude možné
                    zadávat přílohy
                </Alert>
            )}
            {IS_KOOP &&
            !multiApplication &&
            (appFormLogic.getValues('titleId') ||
                appType === ApplicationTypeEnumKoop.OST) ? (
                <Alert variant="light">
                    {`Pro vybraný typ mimořádné provize by měly být nahrány následující typy dokumentů: ${currentTypeAttachments?.items
                        .map((doc) => doc.documentType)
                        .join(', ')}. 
                        ${
                            currentTypeAttachments?.items.some(
                                (file) => file.required
                            )
                                ? `Z toho jsou povinné dokumenty: ${currentTypeAttachments?.items
                                      .filter((doc) => doc.required)
                                      .map((file) => file.documentType)
                                      .join(', ')}.`
                                : ''
                        }`}
                </Alert>
            ) : (
                IS_KOOP &&
                !!multiApplication &&
                payouts.data.length > 0 && (
                    <Alert variant="light">
                        {`Pro vybraný typ mimořádné provize by měly být nahrány následující typy dokumentů: ${currentTypeAttachments?.items
                            .map((doc) => doc.documentType)
                            .join(', ')}.`}

                        <br />
                        {`Z toho jsou povinné dokumenty:`}
                        {processItemsAndPayouts(
                            currentTypeAttachments?.items,
                            payouts?.data
                        ).map((item, index) => (
                            <div key={index} className="document-item">
                                {index + 1}
                                {')'} {item}
                            </div>
                        ))}
                    </Alert>
                )
            )}
            <MyTable
                columns={columns}
                data={attachments.data}
                noDataComponent="Žádné záznamy"
                pagination
            />
            {!multiApplication &&
                (!IS_KOOP ||
                    appFormLogic.getValues('titleId') ||
                    appType === ApplicationTypeEnumKoop.OST) && (
                    <Row className="pt-3 pb-3">
                        <Col>
                            {disabled || (IS_KOOP && !selectedType) ? (
                                ' '
                            ) : (
                                <MyButton
                                    onClick={handleAddAttachment}
                                    noYMargin
                                >
                                    Přidat přílohu
                                </MyButton>
                            )}
                        </Col>
                    </Row>
                )}
            {IS_KOOP && !!multiApplication && (
                <Row className="pt-3 pb-3">
                    <Col>
                        {disabled ||
                        (IS_KOOP && !selectedType) ||
                        (!!multiApplication && payouts.data.length === 0) ? (
                            ' '
                        ) : (
                            <MyButton onClick={handleAddAttachment} noYMargin>
                                Přidat přílohu
                            </MyButton>
                        )}
                    </Col>
                </Row>
            )}
        </div>
    );
}

export default Attachments;
