import React, {useContext, useEffect, useMemo, useState} from 'react'
import './ApplicationForm.scss'
import {useWatch} from 'react-hook-form';
import Form from 'react-bootstrap/Form';
// import * as yup from 'yup';
import {
    ApplicationTypeEnumCpp,
    ApplicationTypeNames,
    ApplicationTypeValues,
    ApplicationWrapper
} from '../../../services/entities/Application';
import {RootState, useAppDispatch, useAppSelector} from '../../../store/store';
import {LoadingIndicator} from '../../../components/loadingIndicator/LoadingIndicator';
import {RequiredLabel} from '../../../components/form/RequiredLabel';
import {isAdminOrCaretaker, User} from '../../../services/entities/User';
import {ApiData} from '../../../store/storeApiUtils';
import {dateAfter, dateBefore, required, validateTextAppType} from '../../../components/form/formValidations';
import {FormError} from '../../../components/form/FormError';
import {AppFormContext} from '../application-detail';
import {allAppTypesList, getAppTypes4Roles} from '../../../services/entities/entityUtils';
import {setAppDirty} from "../../../store/slices/applicationDetail/applicationSlice";
import {TASK_DEF_APP_EDIT} from "../../../services/entities/ApplicationProcess";
import {PayoutsState} from "../../../store/slices/applicationDetail/payoutsSlice";

//https://www.npmjs.com/package/react-year-picker
//https://www.npmjs.com/package/react-month-picker

type ApplicationFormProps = { disabled?: boolean }

function ApplicationForm({disabled}: ApplicationFormProps) {
    const user: User = useAppSelector((s: RootState) => s.user)
    const application: ApiData<ApplicationWrapper> = useAppSelector(s => s.applicationDetail.application.application)
    const tasksData = useAppSelector(s => s.applicationDetail.application.tasks)
    const task = tasksData.data.length ? tasksData.data[0] : null
    const dispatch = useAppDispatch()

    const {
        register,
        reset,
        control,
        setValue,
        formState,
        trigger,
        resetField,
    } = useContext(AppFormContext);
    const {errors} = formState

    const fromDateWatch = useWatch({name: 'fromDate', control})
    const toDateWatch = useWatch({name: 'toDate', control})

    const typeWatch: string = useWatch({name: 'type', control})
    const immediatePayoutWatch: boolean = useWatch({name: 'immediatePayout', control})
    const [presetTypeValue, setPresetTypeValue] = useState({enabled: false, value: null})
    const userIsAdmin: boolean = user && isAdminOrCaretaker(user)
    const [datesChanged, setDatesChanged] = useState(false)
    const payouts: PayoutsState = useAppSelector((state: RootState) => state.applicationDetail.payouts)

    useEffect(() => {
        if (application.data)
            setPresetTypeValue(ApplicationTypeValues[application.data?.application?.type] || {
                enabled: false,
                value: null
            })
    }, [application])

    useEffect(() => {
        if (immediatePayoutWatch && userIsAdmin) {
            setValue('withoutPayment', false)
        } else if (typeWatch === ApplicationTypeEnumCpp.COMPETITION_REGION) {
            setValue('withoutPayment', true)
        }
    }, [immediatePayoutWatch, typeWatch, userIsAdmin, setValue])

    useEffect(() => {
        if (application.data?.application) {
            reset(application.data?.application, {keepDirty: true, keepErrors: true, keepIsValid: true})
        }
    }, [application.data, reset])

    const availableTypes: ApplicationTypeEnumCpp[] = useMemo(() => {
        return disabled ? allAppTypesList() : getAppTypes4Roles(user.roles)
    }, [disabled, user.roles])

    const handleTypeChange = (e) => {
        const value = e.target.value
        const pTypeValue = ApplicationTypeValues[value] || {enabled: false, value: null}
        setPresetTypeValue(pTypeValue)
        setValue('typeValue', pTypeValue.value, {shouldTouch: true, shouldDirty: true, shouldValidate: true})
    }

    const customTypeChange = (typeMetadata) => ({
        ...typeMetadata,
        'onChange': (e) => {
            typeMetadata.onChange(e)
            handleTypeChange(e)
        }
    })

    const hadnleFormChange = () => {
        dispatch(setAppDirty(true))
    }

    useEffect(() => {
        datesChanged && fromDateWatch && toDateWatch && trigger(['fromDate', 'toDate'])
    }, [trigger, fromDateWatch, toDateWatch, datesChanged])

    const onDateChange = () => {
        setDatesChanged(true)
    }

    return (
        <Form
            id="ApplicationForm"
            className="ApplicationForm"
            onChange={hadnleFormChange}
        >
            {(application.pending || payouts.kodzisPending) && (
                <LoadingIndicator />
            )}

            <Form.Group className="d-flex flex-row mb-3">
                <Form.Group className="me-3">
                    <RequiredLabel>Typ mimořádné provize</RequiredLabel>
                    <Form.Select
                        {...customTypeChange(
                            register('type', {
                                ...required(),
                                onChange() {
                                    resetField('immediatePayout');
                                    resetField('withoutPayment');
                                },
                            })
                        )}
                        disabled={disabled}
                    >
                        <option value="" hidden>
                            Vyberte typ provize
                        </option>
                        {availableTypes.map((type) => (
                            <option key={type} value={type}>
                                {ApplicationTypeNames[type]}
                            </option>
                        ))}
                    </Form.Select>
                    <FormError errors={errors} name="type" />
                </Form.Group>

                <Form.Group className="me-5">
                    <RequiredLabel>Označení odměny</RequiredLabel>
                    {presetTypeValue.enabled ? (
                        <>
                            <Form.Control
                                {...register('typeValue', {
                                    ...required(),
                                    validate: {
                                        ...validateTextAppType(),
                                    },
                                })}
                                disabled={disabled}
                                type="text"
                                className="x-small-input"
                                placeholder="Zadejte hodnotu"
                            />
                            <FormError
                                className="x-small-input"
                                errors={errors}
                                name="typeValue"
                            />
                        </>
                    ) : (
                        <h6 className="pt-2">
                            {presetTypeValue.value ||
                                application.data?.application?.typeValue ||
                                '--'}
                        </h6>
                    )}
                </Form.Group>
            </Form.Group>

            <Form.Group className="d-flex flex-row mb-3">
                <Form.Group className="me-3">
                    <RequiredLabel>Období od</RequiredLabel>
                    <Form.Control
                        type="date"
                        disabled={disabled}
                        placeholder="Zadejte měsíc"
                        max={toDateWatch?.toString()}
                        {...register('fromDate', {
                            ...required(),
                            onChange: onDateChange,
                            validate: {
                                ...dateBefore(
                                    toDateWatch,
                                    '"Období od" musí být dříve než "Období do".'
                                ),
                            },
                        })}
                    />
                    <FormError errors={errors} name="fromDate" />
                </Form.Group>

                <Form.Group className="me-5">
                    <RequiredLabel>Období do</RequiredLabel>
                    <Form.Control
                        type="date"
                        disabled={disabled}
                        placeholder="Zadejte datun"
                        min={fromDateWatch?.toString()}
                        {...register('toDate', {
                            ...required(),
                            onChange: onDateChange,
                            validate: {
                                ...dateAfter(
                                    fromDateWatch,
                                    '"Období do" musí být později než "Období od".'
                                ),
                            },
                        })}
                    />
                    <FormError errors={errors} name="toDate" />
                </Form.Group>

                {userIsAdmin ? (
                    <Form.Group className="me-5">
                        <RequiredLabel>Okamžitá výplata</RequiredLabel>
                        <Form.Check
                            {...register('immediatePayout')}
                            type="switch"
                            disabled={
                                disabled ||
                                (immediatePayoutWatch &&
                                    task.taskDefinitionKey ===
                                        TASK_DEF_APP_EDIT)
                            }
                        />
                        <FormError errors={errors} name="immediatePayout" />
                    </Form.Group>
                ) : null}

                {userIsAdmin ||
                typeWatch === ApplicationTypeEnumCpp.COMPETITION_REGION ? (
                    <Form.Group className="me-5">
                        <RequiredLabel>Bez výplaty</RequiredLabel>
                        <Form.Check
                            {...register('withoutPayment')}
                            type="switch"
                            disabled={
                                disabled ||
                                (immediatePayoutWatch && userIsAdmin) ||
                                (immediatePayoutWatch &&
                                    task.taskDefinitionKey ===
                                        TASK_DEF_APP_EDIT)
                            }
                        />
                        <FormError errors={errors} name="withoutPayment" />
                    </Form.Group>
                ) : null}
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Poznámka k žádosti</Form.Label>
                <Form.Control
                    as="textarea"
                    rows={5}
                    {...register('description')}
                    disabled={disabled}
                    placeholder="Zadejte poznámku"
                />
                <FormError errors={errors} name="description" />
            </Form.Group>
        </Form>
    );
}

export default ApplicationForm;
