import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    setDataFail,
    setDataPending,
    setDataSuccess,
} from "../../storeApiUtils";

import { ApplicationNewHistoryBody } from "../../../services/entities/Application";

import { getIdToken } from "../../../pages/common/authProvider/authProviderUtils";
import { fetchAppNewHistory } from "../../../services/api/newHistoryApi";
import { NewHistory } from "../../../services/entities/NewHistory";

export type NewHistoryState = {
    data: NewHistory[];
    pending: boolean;
    error: string;
};
const initialState: NewHistoryState = {
    data: [],
    pending: false,
    error: "",
};

export const appNewHistoryFetch = createAsyncThunk<
    NewHistory[],
    ApplicationNewHistoryBody
>("applicationNewHistory/appNewHistoryFetch", async (input) => {
    return await fetchAppNewHistory(input, await getIdToken());
});

const applicationNewHistorySlice = createSlice({
    name: "applicationNewHistory",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(appNewHistoryFetch.pending, (s, a) => {
            setDataPending(s, a);
        });
        builder.addCase(appNewHistoryFetch.fulfilled, (state, action) => {
            setDataSuccess(state, action);
        });
        builder.addCase(appNewHistoryFetch.rejected, (state, action) => {
            setDataFail(state, action);
        });
    },
});

export default applicationNewHistorySlice.reducer;
