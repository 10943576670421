import {DATA_API_URL} from '../constants';
import {ApplicationId} from '../entities/Application';
import {AuthToken} from './applicationApi';
import {Comment} from '../entities/Comment';
import {fetchMethods} from "../fetchUtils";

export const fetchComments = (appId: ApplicationId, token: AuthToken): Promise<Comment[]> => {
    const url = `${DATA_API_URL}/applications/${appId}/comments/`;
    return fetchMethods.get(url, token)
}

export const saveTaskComment = (appId: ApplicationId, comment: Comment, token: string): Promise<Comment[]> => {
    const url = `${DATA_API_URL}/applications/${appId}/comments/`;
    const body = [comment]
    return fetchMethods.put(url, body, token)
}
