import {Button, ButtonProps, OverlayTrigger, Tooltip} from 'react-bootstrap';
import clsx from 'clsx';
import {useNavigate} from 'react-router-dom';
import {Placement} from 'react-bootstrap/esm/types';

type ExtraProps = {
    noYMargin?: boolean,
    noXMargin?: boolean,
    to?: string,
    tooltip?: string,
    tooltipPlacement?: Placement
}
export function MyButton(props: ButtonProps & ExtraProps) {

    const btnProps = {...props}
    delete btnProps.noYMargin
    delete btnProps.to
    delete btnProps.tooltipPlacement

    const navigate = useNavigate();

    const handleOnClick = (event) => {
        props.onClick && props.onClick(event)
        props.to && navigate(props.to)
    }

    let btn = <Button {...btnProps} onClick={handleOnClick}
                     className={clsx(props.noYMargin || 'my-2' , btnProps.className)} />

    return props.tooltip ?
        <OverlayTrigger placement={props.tooltipPlacement || 'auto'} overlay={<Tooltip>{props.tooltip}</Tooltip>}>
            {btn}
        </OverlayTrigger> :
        btn
}