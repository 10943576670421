import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getIdToken } from '../../../pages/common/authProvider/authProviderUtils';
import {
    exportPayoutsList,
    fetchPayoutsList,
} from '../../../services/api/payoutsListApi';
import {
    PagedPayoutsList,
    PayoutsListFetchInput,
} from '../../../services/entities/PayoutsList';
import {
    ApiData,
    defaultApiData,
    setDataFail,
    setDataPending,
    setDataSuccess,
} from '../../storeApiUtils';

export type PayoutsListState = ApiData<PagedPayoutsList>;
const initialState: PayoutsListState = defaultApiData(null);

export const payoutsListFetch = createAsyncThunk<
    PagedPayoutsList,
    PayoutsListFetchInput
>('payoutsList/fetchPayoutsist', async (input) => {
    return fetchPayoutsList(input, await getIdToken());
});

export const payoutsListExport = createAsyncThunk<void, PayoutsListFetchInput>(
    'payoutsList/payoutsListExport',
    async (input) => {
        return await exportPayoutsList(input, await getIdToken());
    }
);

const payoutsListSlice = createSlice({
    name: 'payoutsListSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(payoutsListFetch.pending, (s) => setDataPending(s));
        builder.addCase(payoutsListFetch.fulfilled, (s, a) => {
            setDataSuccess(s, a);
        });
        builder.addCase(payoutsListFetch.rejected, setDataFail);
    },
});

export default payoutsListSlice.reducer;
