import {DATA_API_URL} from '../constants';
import {
    Application,
    ApplicationBaseFilterTypeEnum, ApplicationFilter,
    ApplicationId,
    ApplicationWrapper,
    PagedApplications, ValidationResult
} from '../entities/Application';
import {formatDateTimeStamp} from '../../utils/formatUtils';
import {Sort} from '../entities/Sort';
import {Payout, PayoutInternal} from "../entities/Payout";
import {payoutInternal2external, payoutWrappers2internal} from "./payoutApi";
import {fetchMethods} from "../fetchUtils";

const pagedAppEx2In = (input: PagedApplications): PagedApplications => {
    if (input.applicationWrapperList)
        input.applicationWrapperList = input.applicationWrapperList.map(appWrapperEx2In)
    else
        input.applicationWrapperList = []

    return input
}
const appWrapperEx2In = (input:any): ApplicationWrapper => {
    return {
        ...input,
        application: external2internal(input.application),
        payouts: payoutWrappers2internal(input.payouts)
    }
}
const external2internal = (input:any): Application => {
    input.typeObject = input.type;
    input.type = input.type ? input.type.name : ''
    input.id = input.id.toString()
    return input
}

const internal2external = (input:Application): any   => {
    return {
        ...input,
        // toDate: month2DateStr(input.toDate?.toString()),
        // fromDate: month2DateStr(input.fromDate?.toString()),
        type: input.type || null
    }
}

export type AuthToken = string
export type AppListFetchInput = {
    baseFilter: ApplicationBaseFilterTypeEnum,
    myApprovalFilter: boolean,
    customFilter: ApplicationFilter,
    pageSize?: number,
    pageNumber?: number,
    sort: Sort
}

const asString = (val?: any): string =>  val ? val.toString() : ''
const createQueryString = (input: AppListFetchInput) => {
    const searchParams = new URLSearchParams();
    searchParams.append("baseFilter", input.baseFilter);

    searchParams.append("myTasksOnly", input.myApprovalFilter.toString());

    input.sort.sortList.forEach(si => {
        searchParams.append("sortList", si)
    })
    searchParams.append("sortOrder", input.sort.sortOrder);

    Object.entries(input.customFilter).forEach(entry => {
        if (entry[1]) {
            searchParams.append(entry[0], asString(entry[1]));
        }
    })

    searchParams.append("pageSize", asString(input.pageSize));
    searchParams.append("pageNumber", asString(input.pageNumber));

    return searchParams.toString()
}

export const fetchAppList = (input:AppListFetchInput ,
                             token: AuthToken): Promise<PagedApplications> => {

    const queryString = createQueryString(input)
    const url = `${DATA_API_URL}/applications/?${queryString}`;
    return fetchMethods.get(url, token)
        .then((response) => {
            return pagedAppEx2In(response)
        });
}

export const exportAppList = (input:AppListFetchInput ,
                               token: AuthToken): Promise<any> => {

    const queryString = createQueryString(input)
    const url = `${DATA_API_URL}/applications/csv?${queryString}`;

    return fetchMethods.get(url, token).then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // the filename you want
            a.download = `Zadosti_${formatDateTimeStamp(new Date())}.csv`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a)
        })
}

export const fetchApplication = (id: ApplicationId, token: AuthToken): Promise<ApplicationWrapper> => {
    const url = `${DATA_API_URL}/applications/${id}`;
    return fetchMethods.get(url, token)
        .then((response) => {
            return appWrapperEx2In(response);
        });
}

export const saveAppAndPayouts = (application: Application, payouts: PayoutInternal[], token: AuthToken) => {
    const url = `${DATA_API_URL}/applications/${application.id}/common/save`;

    const body = {
        application: internal2external(application),
        payouts: payouts.map(payoutInternal2external)
    }

    return fetchMethods.put(url, body, token)
        .then((response) => {
            return appWrapperEx2In(response)
        });
}

export const finalSaveAppAndPayouts = (application: Application, payouts: Payout[], token: AuthToken) => {
    const url = `${DATA_API_URL}/applications/${application.id}/common`;
    const body = {
        application,
        payouts: payouts.map(payoutInternal2external),
    };
    if (body.application.type === 'OST') {
        body.application.titleId = null;
        body.application.title = null;
    }

    return fetchMethods.put(url, body, token).then((response) => {
        if (response.result === ValidationResult.SUCCESS)
            return appWrapperEx2In(response);
        if (response.result === ValidationResult.ERROR) return response;
    });
}