import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import {Outlet, useOutletContext} from "react-router-dom";
import {HistoryWrapper, useListenHistory} from './components/history/useListenHistory';
import Notifications from './pages/common/notifications/Notifications';
import AuthProvider from './pages/common/authProvider/AuthProvider';
import ErrorBoundary from './components/errorBoundary/ErrorBoundary';
import clsx from 'clsx';
import {IS_KOOP} from './services/constants';


function App() {
    const history = useListenHistory()

    return (
        <div className={clsx(IS_KOOP && 'koop-mode', !IS_KOOP && 'cpp-mode')}>
            <Notifications/>
            <AuthProvider>
                <div className="position-relative">
                    <ErrorBoundary>
                        <Outlet context={history}/>
                    </ErrorBoundary>
                </div>
            </AuthProvider>
        </div>
    );
}

export const useHistoryContext = () => {
    return useOutletContext<HistoryWrapper>();
}

export default App;

