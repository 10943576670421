import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {ApiData, defaultApiData, setDataFail, setDataPending, setDataSuccess} from '../../storeApiUtils';
import {ApplicationLog} from '../../../services/entities/ApplicationLog';
import {fetchLogs} from '../../../services/api/logsApi';
import {ApplicationId} from '../../../services/entities/Application';
import {camundaUsersFetch} from "./camundaUsersSlice";
import {getIdToken} from "../../../pages/common/authProvider/authProviderUtils";


export type ApplicationLogsState = ApiData<ApplicationLog[]>

const initialState = defaultApiData([])

export const appLogsFetch = createAsyncThunk<ApplicationLog[], ApplicationId>(
    'applicationLogs/appLogsFetch',
    async (appId, thunkApi) => {
        const result =  await fetchLogs(appId, await getIdToken())
        thunkApi.dispatch(camundaUsersFetch(result.map(el => el.username)))
        return result
    }
)

const applicationLogsSlice = createSlice({
    name: 'applicationLogs',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(appLogsFetch.pending, (s,a) => {
            setDataPending(s, a)
        })
        builder.addCase(appLogsFetch.fulfilled, (state, action) => {
            setDataSuccess(state, action)
        })
        builder.addCase(appLogsFetch.rejected, (state, action) => {
            setDataFail(state, action)
        })
    }
})

export default applicationLogsSlice.reducer