import React, { useContext, useEffect, useMemo, useState } from 'react';
import './ApplicationForm.scss';
import { useWatch } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
// import * as yup from 'yup';
import {
    ApplicationTypeEnumKoop,
    ApplicationTypeNames,
    ApplicationTypeValues,
    ApplicationWrapper,
} from '../../../services/entities/Application';
import {
    RootState,
    useAppDispatch,
    useAppSelector,
} from '../../../store/store';
import { LoadingIndicator } from '../../../components/loadingIndicator/LoadingIndicator';
import { RequiredLabel } from '../../../components/form/RequiredLabel';
import { isAdminOrCaretaker, User } from '../../../services/entities/User';
import { ApiData } from '../../../store/storeApiUtils';
import {
    dateAfter,
    dateBefore,
    minLength,
    required,
} from '../../../components/form/formValidations';
import { FormError } from '../../../components/form/FormError';
import { AppFormContext } from '../application-detail';
import {
    allAppTypesList,
    getAppTypes4Role,
} from '../../../services/entities/entityUtils';
import {
    setAppDirty,
    setTribe,
    setBrokerCodes,
    setSelectedType,
} from '../../../store/slices/applicationDetail/applicationSlice';
import { validateDate } from '../../../utils/validateDate';
import { TASK_DEF_APP_EDIT } from '../../../services/entities/ApplicationProcess';
import { titleRegisterListTitleFilterFetch } from '../../../store/slices/applicationDetail/applicationSlice';
import {
    PayoutsState,
    removePayout,
} from '../../../store/slices/applicationDetail/payoutsSlice';
import PayoutsExistMessage from '../payouts/PayoutsExistMessage';
import { attachmentUpdate } from '../../../store/slices/applicationDetail/attachmentsSlice';
import { getDokuType } from '../../../utils/getDokuType';

//https://www.npmjs.com/package/react-year-picker
//https://www.npmjs.com/package/react-month-picker

type ApplicationFormProps = { disabled?: boolean };

function ApplicationFormKoop({ disabled }: ApplicationFormProps) {
    const user: User = useAppSelector((s: RootState) => s.user);

    const application: ApiData<ApplicationWrapper> = useAppSelector(
        (s) => s.applicationDetail.application.application
    );
    const applicationId = useAppSelector(
        (s) =>
            s.applicationDetail?.application?.application?.data?.application?.id
    );
    const tasksData = useAppSelector(
        (s) => s.applicationDetail.application.tasks
    );
    const task = tasksData.data.length ? tasksData.data[0] : null;
    const dispatch = useAppDispatch();

    const {
        register,
        reset,
        resetField,
        control,
        setValue,
        formState,
        trigger,
        getFieldState,
        getValues,
    } = useContext(AppFormContext);
    const { errors } = formState;

    const fromDateWatch = useWatch({ name: 'fromDate', control });
    const toDateWatch = useWatch({ name: 'toDate', control });

    const typeWatch: string = useWatch({ name: 'type', control });
    const immediatePayoutWatch: boolean = useWatch({
        name: 'immediatePayout',
        control,
    });
    const userIsAdmin: boolean = user && isAdminOrCaretaker(user);
    const [datesChanged, setDatesChanged] = useState(false);

    const { titleRegisterDataFilter, recordCount, loading, pagination } =
        useAppSelector(
            (state) => state.applicationDetail.application.mpTitlesData
        );
    const deposit = useWatch({ name: 'deposit', control });
    const titleIdWatch = +useWatch({ name: 'titleId', control });
    const filterByCodeWatch = useWatch({ name: 'filterByCode', control });
    const filterByNameWatch = useWatch({ name: 'filterByName', control });
    const selectedTitle = titleRegisterDataFilter?.find(
        (title) => title.id === titleIdWatch
    );

const applicationTitle = application?.data?.application?.title;

const multiApplication = application?.data?.application.multiApplication;

const titleSelectInputDefault =
    (!recordCount || recordCount === 0) && typeWatch
        ? 'Nenalezeny žádné tituly'
        : !typeWatch
        ? 'Nejprve vyberte typ provize'
        : 'Vyberte titul';
const titleSelectInputStored = `${applicationTitle?.agreementNumber} (${applicationTitle?.evidenceNumber}`;
const typeFieldState = getFieldState('type', formState);
const titleFieldState = getFieldState('titleId', formState);
const filterByCodeFieldState = getFieldState('filterByCode', formState);
const filterByNameFieldState = getFieldState('filterByName', formState);
const dirtyFieldsCheck =
    typeFieldState.isDirty ||
    filterByCodeFieldState.isDirty ||
    filterByNameFieldState.isDirty;
const payouts: PayoutsState = useAppSelector(
    (state: RootState) => state.applicationDetail.payouts
);
const [openExistPayouts, setOpenExistPayouts] = useState(false);
const attachment = useAppSelector((s) => s.applicationDetail.attachments.data);

useEffect(() => {
    setValue(
        'typeValue',
        deposit ? 'PX_DMZ' : ApplicationTypeValues[typeWatch]?.typeValue
    );
}, [deposit, setValue, typeWatch]);

useEffect(() => {
    if (immediatePayoutWatch && userIsAdmin) {
        setValue('withoutPayment', false);
    }
    // else if (typeWatch === ApplicationTypeEnum.ODM) {
    //     setValue('withoutPayment', true);
    // }
}, [immediatePayoutWatch, typeWatch, userIsAdmin, setValue]);

useEffect(() => {
    if (application.data?.application) {
        reset(application.data?.application, {
            keepDirty: true,
            keepErrors: true,
            keepIsValid: true,
        });
        if (application?.data?.application?.title) {
            setValue('titleId', applicationTitle?.id?.toString());
        }
    }
}, [application.data, reset, setValue, applicationTitle?.id]);

useEffect(() => {
    if (titleFieldState.isDirty || typeFieldState.isDirty) {
        setValue('deposit', false);
    }
}, [setValue, titleFieldState.isDirty, typeFieldState.isDirty]);

const availableTypes: ApplicationTypeEnumKoop[] = useMemo(() => {
    return disabled ? allAppTypesList() : getAppTypes4Role(user.roles);
}, [disabled, user.roles]);

const handleTypeChange = (e) => {
    const value = e.target.value;
    const pTypeValue = ApplicationTypeValues[value] || {
        enabled: false,
        value: null,
        typeValue: null,
    };
    setValue('typeValue', deposit ? 'PX_DMZ' : pTypeValue.typeValue, {
        shouldTouch: true,
        shouldDirty: true,
        shouldValidate: true,
    });
};

useEffect(() => {
    if (typeWatch !== ApplicationTypeEnumKoop.OST && typeWatch)
        dispatch(titleRegisterListTitleFilterFetch({ titleType: typeWatch }));
}, [dispatch, typeWatch]);

const customTypeChange = (typeMetadata) => ({
    ...typeMetadata,
    onChange: (e) => {
        typeMetadata.onChange(e);
        handleTypeChange(e);
    },
});

const handleFormChange = () => {
    dispatch(setAppDirty(true));
};

useEffect(() => {
    datesChanged &&
        fromDateWatch &&
        toDateWatch &&
        trigger(['fromDate', 'toDate']);
}, [trigger, fromDateWatch, toDateWatch, datesChanged]);

useEffect(() => {
    dispatch(setBrokerCodes(selectedTitle?.brokerCode));
    dispatch(setTribe(selectedTitle?.tribe));
}, [dispatch, selectedTitle?.brokerCode, typeWatch, selectedTitle?.tribe]);

    useEffect(() => {
        dispatch(setSelectedType(typeWatch));
    }, [dispatch, typeWatch]);

    const onDateChange = () => {
        setDatesChanged(true);
    };

    const handleFilterByCode = (e) => {
        setValue('filterByCode', e.target.value);
        setTimeout(() => {
            if (typeWatch && !errors.filterByCode) {
                dispatch(
                    titleRegisterListTitleFilterFetch({
                        titleType: typeWatch,
                        brokerCode: e.target.value,
                        name: filterByNameWatch,
                    })
                );
            }
        }, 500);
    };

    const handleRemovePayouts = () => {
        payouts.data.forEach((payout) => {
            dispatch(removePayout(payout.id));
        });
    };

    const handleFilterByName = (e) => {
        setValue('filterByName', e.target.value);
        setTimeout(() => {
            if (typeWatch) {
                dispatch(
                    titleRegisterListTitleFilterFetch({
                        titleType: typeWatch,
                        brokerCode: filterByCodeWatch,
                        name: e.target.value,
                    })
                );
            }
        }, 500);
    };

    const attachments = useAppSelector(
        (s: RootState) => s.applicationDetail.attachments
    );

    const currentTypeAttachments = attachments.requiredAttachmentsRules.find(
        (att) => att.applicationType === typeWatch
    );

    const handleAttachmentUpdate = () => {
        const title = titleRegisterDataFilter?.find(
            (title) => title.id === +getValues('titleId')
        );
        attachment.forEach((attachment) => {
            currentTypeAttachments?.items.forEach((i) => {
                if (attachment.documentType === i.documentType) {
                    const newDokuType = getDokuType(i, title.tribe);
                    dispatch(
                        attachmentUpdate({
                            attachment,
                            applicationId,
                            newDokuType,
                        })
                    );
                }
            });
            // dispatch(attachmentUpdate({ attachment, applicationId }));
        });
    };

    const titlesAll = recordCount;
    const titlesShown = Math.min(pagination.pageSize, recordCount);

return (
    <div>
        <PayoutsExistMessage
            open={openExistPayouts}
            setOpen={setOpenExistPayouts}
            handleRemovePayouts={handleRemovePayouts}
            multiApplication={multiApplication}
        />

        <Form
            id="ApplicationForm"
            className="ApplicationForm"
            onChange={handleFormChange}
        >
            {application.pending || loading ? <LoadingIndicator /> : null}
            <Form.Group className="d-flex flex-row mb-3">
                <Form.Group className="me-3">
                    <RequiredLabel>Typ mimořádné provize</RequiredLabel>
                    <Form.Select
                        {...customTypeChange(
                            register('type', {
                                ...required(),
                                onChange() {
                                    resetField('filterByCode');
                                    resetField('filterByName');
                                    resetField('immediatePayout');
                                    resetField('withoutPayment');
                                    resetField('businessDecision');
             
                                    setValue('deposit', false);

                                    resetField('titleId');
                                    payouts.data?.length !== 0 &&
                                        setOpenExistPayouts(true);
                                    handleAttachmentUpdate();
                                },
                            })
                        )}
                        disabled={disabled}
                    >
                        <option value="" hidden>
                            Vyberte typ provize
                        </option>
                        {!multiApplication
                            ? availableTypes.map((type) => (
                                  <option key={type} value={type}>
                                      {ApplicationTypeNames[type]} ({type})
                                  </option>
                              ))
                            : allAppTypesList().map((type) => {
                                  return (
                                      <option key={type} value={type}>
                                          {ApplicationTypeNames[type]} ({type})
                                      </option>
                                  );
                              })}
                    </Form.Select>
                    <FormError errors={errors} name="type" />
                </Form.Group>
            </Form.Group>

            {!multiApplication && (
                <Form.Group className="d-flex gap-3 mb-3">
                    {typeWatch !== ApplicationTypeEnumKoop.OST && (
                        <div>
                            <Form.Group>
                                <RequiredLabel>Titul</RequiredLabel>
                                <Form.Label className="ms-2">
                                    {!disabled && typeWatch && recordCount
                                        ? `(Zobrazeno: ${titlesShown} z celkem: ${titlesAll})`
                                        : ''}
                                </Form.Label>
                                <Form.Select
                                    {...register('titleId', {
                                        ...required(true),
                                        onChange: () => {
                                            setValue('deposit', false);

                                            payouts.data?.length !== 0 &&
                                                setOpenExistPayouts(true);
                                            handleAttachmentUpdate();
                                        },
                                    })}
                                    defaultValue=""
                                    disabled={
                                        disabled || !recordCount || !typeWatch
                                    }
                                >
                                    <option
                                        hidden
                                        value={
                                            applicationTitle &&
                                            !dirtyFieldsCheck
                                                ? applicationTitle?.id?.toString()
                                                : ''
                                        }
                                    >
                                        {applicationTitle && !dirtyFieldsCheck
                                            ? titleSelectInputStored
                                            : titleSelectInputDefault}
                                    </option>
                                    {typeWatch &&
                                        titleRegisterDataFilter?.map(
                                            (title, i) => {
                                                if (
                                                    i >= 0 &&
                                                    i < pagination.pageSize
                                                ) {
                                                    return (
                                                        <option
                                                            key={title.id}
                                                            value={title.id}
                                                        >{`${title.agreementNumber} (${title.evidenceNumber})`}</option>
                                                    );
                                                } else if (
                                                    i === pagination.pageSize
                                                ) {
                                                    return (
                                                        <option
                                                            disabled
                                                        >{`+ dalších ${
                                                            recordCount -
                                                            pagination.pageSize
                                                        } titulů`}</option>
                                                    );
                                                }
                                                return null;
                                            }
                                        )}
                                </Form.Select>
                                <FormError errors={errors} name="titleId" />
                            </Form.Group>
                        </div>
                    )}
                    {!disabled && typeWatch !== ApplicationTypeEnumKoop.OST && (
                        <div className="ms-3">
                            <Form.Label>Filtrovat tituly podle:</Form.Label>
                            <div className="d-flex">
                                {typeWatch !== ApplicationTypeEnumKoop.STZ &&
                                    typeWatch !==
                                        ApplicationTypeEnumKoop.USP && (
                                        <Form.Group className="pb-2">
                                            <Form.Control
                                                placeholder="Čísla získatele"
                                                type="text"
                                                {...register('filterByCode', {
                                                    ...required(false),
                                                    onChange: (event) => {
                                                        handleFilterByCode(
                                                            event
                                                        );
                                                    },
                                                    ...minLength(3),
                                                })}
                                                disabled={disabled}
                                            />
                                            <FormError
                                                errors={errors}
                                                name="filterByCode"
                                            />
                                        </Form.Group>
                                    )}
                                <Form.Group className="pb-2 ms-1">
                                    <Form.Control
                                        placeholder="Čísla smlouvy (Titulu ID)"
                                        type="text"
                                        {...register('filterByName', {
                                            onChange: (event) => {
                                                handleFilterByName(event);
                                            },
                                        })}
                                        disabled={disabled}
                                    />
                                </Form.Group>
                            </div>
                        </div>
                    )}
                </Form.Group>
            )}
            <Form.Group className="d-flex flex-row mb-3">
                <Form.Group className="me-3">
                    <RequiredLabel>Období od</RequiredLabel>
                    <Form.Control
                        type="date"
                        disabled={disabled}
                        placeholder="Zadejte měsíc"
                        max={toDateWatch?.toString()}
                        {...register('fromDate', {
                            ...required(),
                            onChange: onDateChange,
                            validate: {
                                ...dateBefore(
                                    toDateWatch,
                                    '"Období od" musí být dříve než "Období do".'
                                ),
                                validateDate,
                            },
                        })}
                    />
                    <FormError errors={errors} name="fromDate" />
                </Form.Group>

                <Form.Group className="me-5">
                    <RequiredLabel>Období do</RequiredLabel>
                    <Form.Control
                        type="date"
                        disabled={disabled}
                        placeholder="Zadejte datun"
                        min={fromDateWatch?.toString()}
                        {...register('toDate', {
                            ...required(),
                            onChange: onDateChange,
                            validate: {
                                ...dateAfter(
                                    fromDateWatch,
                                    '"Období do" musí být později než "Období od".'
                                ),
                                validateDate,
                            },
                        })}
                    />
                    <FormError errors={errors} name="toDate" />
                </Form.Group>

                {userIsAdmin || disabled ? (
                    <Form.Group className="me-5">
                        <RequiredLabel>Okamžitá výplata</RequiredLabel>
                        <Form.Check
                            {...register('immediatePayout')}
                            type="switch"
                            disabled={
                                disabled ||
                                (immediatePayoutWatch &&
                                    task.taskDefinitionKey ===
                                        TASK_DEF_APP_EDIT)
                            }
                        />
                        <FormError errors={errors} name="immediatePayout" />
                    </Form.Group>
                ) : null}

                {userIsAdmin || disabled ? (
                    <Form.Group className="me-5">
                        <RequiredLabel>Bez výplaty</RequiredLabel>
                        <Form.Check
                            {...register('withoutPayment')}
                            type="switch"
                            disabled={
                                disabled ||
                                (immediatePayoutWatch && userIsAdmin) ||
                                (immediatePayoutWatch &&
                                    task.taskDefinitionKey ===
                                        TASK_DEF_APP_EDIT) ||
                                multiApplication
                            }
                        />
                        <FormError errors={errors} name="withoutPayment" />
                    </Form.Group>
                ) : null}

                {(disabled ||
                    typeWatch === ApplicationTypeEnumKoop.DMP ||
                    typeWatch === ApplicationTypeEnumKoop.DMM) && (
                    <Form.Group className="me-5">
                        <Form.Label>Obchodní rozhodnutí</Form.Label>
                        <Form.Check
                            {...register('businessDecision')}
                            type="switch"
                            disabled={disabled}
                        />
                    </Form.Group>
                )}

                {(disabled || selectedTitle?.deposit) && (
                    <Form.Group className="me-5">
                        <Form.Label>Záloha</Form.Label>
                        <Form.Check
                            {...register('deposit')}
                            type="switch"
                            disabled={disabled || multiApplication}
                        />
                    </Form.Group>
                )}
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Poznámka k žádosti</Form.Label>
                <Form.Control
                    as="textarea"
                    rows={5}
                    {...register('description')}
                    disabled={disabled}
                    placeholder={!disabled && 'Zadejte poznámku'}
                />
                <FormError errors={errors} name="description" />
            </Form.Group>
        </Form>
    </div>
);
}

export default ApplicationFormKoop;
