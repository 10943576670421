import { Col, Form, Modal, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { PAYOUTS_FILTER_TEXTS, TEXTS } from '../../../constants/texts';
import { MyButton } from '../../../components/button/MyButton';
import {
    RootState,
    useAppDispatch,
    useAppSelector,
} from '../../../store/store';

import {
    formatPayoutState,
    formatPayoutTasks,
} from '../../../utils/formatUtils';

import { PayoutStateEnum } from '../../../services/entities/Payout';
import {
    PayoutsListFilter,
    PayoutsTasks,
} from '../../../services/entities/PayoutsList';
import { tribesFetch } from '../../../store/slices/applicationDetail/payoutsSlice';
import {
    clearPayoutsFilter,
    initialValues,
    setPayoutsFilter,
} from '../../../store/slices/payoutsList/payoutsListFilterSlice';

type PayoutListFilterProps = {
    show: boolean;
    onHide: () => void;
};

const PayoutListFilter = ({ show, onHide }: PayoutListFilterProps) => {
    const dispatch = useAppDispatch();
    const filter = useAppSelector((s: RootState) => s.payoutsListFilter.filter);
    const { register, handleSubmit, reset, formState } =
        useForm<PayoutsListFilter>({
            defaultValues: initialValues,
        });


    useEffect(() => {
        if (filter) reset(filter);
    }, [reset, filter]);

    const onSubmit = (values: PayoutsListFilter) => {
        const data = {
            ...values,
            states: fromArray(values.state),
        };

        dispatch(setPayoutsFilter(data));
        onHide();
    };

    const handleClear = () => {
        dispatch(clearPayoutsFilter());
    };

    const fromArray = (val) => (Array.isArray(val) ? val.join(',') : val);

    const { tribes } = useAppSelector(
        (state) => state.applicationDetail.payouts
    );

    useEffect(() => {
        dispatch(tribesFetch());
    }, [dispatch]);

    return (
        <Modal show={show} onHide={onHide} dialogClassName="CustomFilterForm">
            <Modal.Header closeButton>
                <Modal.Title as="h5">Vlastní filtr</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="payoutListFilter" onSubmit={handleSubmit(onSubmit)}>
                    <Row className="mb-2">
                        <Col sm={12} md={6} lg={4}>
                            <Form.Group className="pb-2">
                                <Form.Label>
                                    {PAYOUTS_FILTER_TEXTS.title}
                                </Form.Label>
                                <Form.Control
                                    {...register('agreementNumber')}
                                />
                            </Form.Group>
                            <Form.Group className="pb-2">
                                <Form.Label>
                                    {PAYOUTS_FILTER_TEXTS.applicationID}
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    {...register('id')}
                                />
                            </Form.Group>
                            <Form.Group className="pb-2">
                                <Form.Label>
                                    {PAYOUTS_FILTER_TEXTS.state}
                                </Form.Label>
                                <Form.Select
                                    {...register('state')}
                                    multiple
                                    htmlSize={5}
                                >
                                    {Object.keys(PayoutStateEnum).map((key) => (
                                        <option key={key} value={key}>
                                            {formatPayoutState(
                                                PayoutStateEnum[key]
                                            )}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                            <Form.Group className="pb-2">
                                <Form.Label>
                                    {PAYOUTS_FILTER_TEXTS.brokerCode}
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    {...register('recipientId')}
                                />
                            </Form.Group>
                            <Form.Group className="pb-2">
                                <Form.Label>
                                    {PAYOUTS_FILTER_TEXTS.broker}
                                </Form.Label>
                                <Form.Control {...register('recipientName')} />
                            </Form.Group>
                            <Form.Group as={Row} className="pb-2">
                                <Form.Group as={Col} className="pb-2">
                                    <Form.Label>
                                        {PAYOUTS_FILTER_TEXTS.amountFrom}
                                    </Form.Label>
                                    <Form.Control
                                        type="number"
                                        {...register('amountFrom')}
                                    />
                                </Form.Group>

                                <Form.Group as={Col} className="pb-2">
                                    <Form.Label>
                                        {PAYOUTS_FILTER_TEXTS.amountTo}
                                    </Form.Label>
                                    <Form.Control
                                        type="number"
                                        {...register('amountTo')}
                                    />
                                </Form.Group>
                            </Form.Group>
                            <Form.Group as={Row} className="pb-2">
                                <Form.Group as={Col}>
                                    <Form.Label>
                                        {PAYOUTS_FILTER_TEXTS.fromToDate}
                                    </Form.Label>
                                    <Form.Control
                                        type="date"
                                        {...register('fromToDate')}
                                    />
                                </Form.Group>
                            </Form.Group>
                            <Form.Group as={Row} className="pb-2">
                                <Form.Group as={Col}>
                                    <Form.Label>
                                        {
                                            PAYOUTS_FILTER_TEXTS.NotPayoutBeforeFrom
                                        }
                                    </Form.Label>
                                    <Form.Control
                                        type="date"
                                        {...register('dueDateFrom')}
                                    />
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Label>
                                        {PAYOUTS_FILTER_TEXTS.NotPayoutBeforeTo}
                                    </Form.Label>
                                    <Form.Control
                                        type="date"
                                        {...register('dueDateTo')}
                                    />
                                </Form.Group>
                            </Form.Group>
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                            <Form.Group className="pb-2">
                                <Form.Label>
                                    {PAYOUTS_FILTER_TEXTS.tribe}
                                </Form.Label>
                                <Form.Select
                                    {...register('tribe')}
                                    multiple
                                    htmlSize={6}
                                >
                                    {tribes.map((entry) => (
                                        <option
                                            key={entry.code}
                                            value={entry.code}
                                        >
                                            {entry.code} = {entry.description}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label>
                                    Zaznamenání termínu uvolnění výplat k
                                    vyplacení
                                </Form.Label>
                                <Form.Control
                                    type="date"
                                    {...register('releaseDate')}
                                />
                            </Form.Group>
                            <Form.Group className="pb-2">
                                <Form.Label>Úkol</Form.Label>
                                <Form.Select
                                    {...register('tasks')}
                                    multiple
                                    htmlSize={1}
                                >
                                    {Object.keys(PayoutsTasks).map((key) => (
                                        <option key={key} value={key}>
                                            {formatPayoutTasks(
                                                PayoutsTasks[key]
                                            )}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <MyButton
                    variant="warning"
                    className="me-3"
                    onClick={handleClear}
                >
                    {TEXTS.clear}
                </MyButton>
                <MyButton variant="secondary" onClick={onHide}>
                    {TEXTS.close}
                </MyButton>
                <MyButton
                    type="submit"
                    form="payoutListFilter"
                    disabled={!formState.isDirty}
                    variant="primary"
                >
                    Filtrovat
                </MyButton>
            </Modal.Footer>
        </Modal>
    );
};

export default PayoutListFilter;
