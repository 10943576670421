import React from 'react';
import {TableColumn} from 'react-data-table-component';
import {ApiData} from '../../../store/storeApiUtils';
import {Comment} from '../../../services/entities/Comment';
import {formatCamundaUserId, formatDateTime} from '../../../utils/formatUtils';
import {LoadingIndicator} from '../../../components/loadingIndicator/LoadingIndicator';
import {MyTable} from '../../../components/table/MyTable';
import {CamundaUser, UserId} from "../../../services/entities/User";

export type CommentsProps = {
    comments: ApiData<Comment[]>,
    camundaUsersMap: {[key: UserId]: CamundaUser}
}

function CommentsTable({comments, camundaUsersMap}: CommentsProps) {
    const columns: TableColumn<Comment>[] = [
        {
            name: 'Procesní krok',
            selector: row => row.taskName,
            sortable: true
        },
        {
            name: 'Komentář',
            selector: (row: Comment) => row.text,
            cell: row => (
                <div>
                    {row.text}
                </div>
            ),
            sortable: true
        },
        {
            name: 'Jméno',
            selector: (row: Comment) => row.userName,
            format: row => formatCamundaUserId(row.userName, camundaUsersMap),
            sortable: true
        },
        {
            name: 'Čas',
            selector: (row: Comment) => formatDateTime(row.created),
            sortable: true
        }
    ];

    return (
        <div className="position-relative">

            {
                comments.pending && <LoadingIndicator/>
            }

            <MyTable columns={columns}
                     data={comments.data}
                     noDataComponent="Žádné záznamy"
                     pagination/>
        </div>
    )
}

export default CommentsTable;
