import {DATA_API_URL} from '../constants';
import {ApplicationId} from '../entities/Application';
import {AuthToken} from './applicationApi';
import {ApplicationLog} from '../entities/ApplicationLog';
import {fetchMethods} from "../fetchUtils";

export const fetchLogs = (appId: ApplicationId, token: AuthToken): Promise<ApplicationLog[]> => {
    const url = `${DATA_API_URL}/applications/${appId}/logs/`;

    return fetchMethods.get(url, token)
}

export const saveLogs = (appId: ApplicationId, token: AuthToken): Promise<ApplicationLog[]> => {
    const url = `${DATA_API_URL}/applications/${appId}/logs/`;

    return fetchMethods.get(url, token)
}
