import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {ApiData, defaultApiData, setDataFail, setDataPending, setDataSuccess} from '../../storeApiUtils';
import {ApplicationId} from '../../../services/entities/Application';
import {Comment} from '../../../services/entities/Comment';
import {fetchComments, saveTaskComment} from '../../../services/api/commentApi';
import {camundaUsersFetch} from "./camundaUsersSlice";
import {getIdToken} from "../../../pages/common/authProvider/authProviderUtils";

export type CommentsState = ApiData<Comment[]> & {applicationId: ApplicationId}
const initialState: CommentsState = {
    ...defaultApiData([]),
    applicationId: null
}

export type CommentSaveInput = {
    comment: Comment,
    appId: ApplicationId
}
export const commentSave = createAsyncThunk<Comment[], CommentSaveInput>(
    'comments/commentSave',
    async (input: CommentSaveInput) => {
        return await saveTaskComment(input.appId, input.comment, await getIdToken())
    }
)

export const commentsFetch = createAsyncThunk<Comment[], ApplicationId>(
    'comments/commentsFetch',
    async (appId: ApplicationId, thunkApi) => {
        const result =  await fetchComments(appId, await getIdToken())
        const userIds = result.map(el => el.userName)
        thunkApi.dispatch(camundaUsersFetch(userIds))
        return result
    }
)

const commentsSlice = createSlice({
    name: 'comments',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(commentsFetch.pending, s => setDataPending(s))
        builder.addCase(commentsFetch.fulfilled, setDataSuccess)
        builder.addCase(commentsFetch.rejected, setDataFail)

        builder.addCase(commentSave.pending, s => setDataPending(s))
        builder.addCase(commentSave.fulfilled, setDataSuccess)
        builder.addCase(commentSave.rejected, setDataFail)
    }
})

export default commentsSlice.reducer