import { COMPANIES, COMPANY } from '../services/constants';

export const TEXTS = {
    close: 'Zavřít',
    clear: 'Vyčistit',
    confirm: 'Potvrdit',
    save: 'Uložit',
    send: 'Odeslat',
    cancel: 'Zrušit',
    yes: 'Ano',
    no: 'Ne',
    back: 'Zpět',
    loadingData: 'Načítám data...',
    errorLoadingData: 'Chyba při načítání dat',
    noData: 'Žádné záznamy',
    comment: 'Komentář',
    filename: 'Název',
    docType: 'Typ dokumentu',
    description: 'Popis',
    actions: 'Akce',
    select: 'Vybrat',
    serverErr: (err: string) => `Došlo k chybě při volání serveru: ${err}`,
    leaveAppDetailConfirmation:
        'Stiskem tlačítka „Nová žádost“ jste spustil(a) novou procesní instanci žádosti ' +
        'o mimořádnou provizi, v níž Vám byl přiřazen úkol na vyplnění žádosti. Opuštěním tohoto úkolu (této obrazovky) ' +
        'nedojde k ukončení procesní instance! Pro ukončení procesní instance zavřete toto dialogové okno a klikněte ' +
        'na tlačítko „Zrušit“. Všechny procesní instance s nedokončeným úkolem na vyplnění žádosti zůstávají v ' +
        'přehledu všech žádostí, u kterých máte přiřazen nějaký úkol.Přejete si opustit úkol na vyplnění žádosti?',
};

export const APP_TEXTS = {
    number: 'Číslo žádosti',
    state: 'Stav',
    payoutState: 'Stav výplat žádosti',
    type: 'Typ mimořádné provize',
    creationDateFrom: 'Vytvořeno od',
    creationDateTo: 'Vytvořeno do',
    golemReleaseDate: 'Termín uvolnění výplat do GOLEM',
    recipientName: 'Název získatele',
    realPayoutSum: 'Suma všech výplat',
    payoutAmountFrom: 'Suma výplat od',
    payoutAmountTo: 'Suma výplat do',
    creatorName: 'Zadavatel',
    approver: 'Schvalovatel',
    applicationID: 'DB ID',
};

export const TITLE_FILTER_TEXTS = {
    titleType: 'Typ titulu',
    evidenceNumber: 'Titul ID (Evidenční číslo)',
    agreementNumber: 'Číslo smlouvy',
    broker: 'Název získatele',
    brokerCode: 'Číslo získatele',
    brokerType: 'Typ získatele',
    agency: 'Agentura (Kód GOLEM)',
    validFrom: 'Platnost od',
    validTo: 'Platnost do',
    paidOutFrom: 'Vyplaceno od',
    paidOutTo: 'Vyplaceno do',
    titleMaxValueFrom: 'Maximální výše titulu od',
    titleMaxValueTo: 'Maximální výše titulu do',
    depositAllowed: 'Zálohy',
    isEstimatedItem: 'Dohad',
    leftToPay: 'Zbývá',
    incentiveEvent: 'Název soutěže / motivační akce',
    typeOfEvent: 'Typ soutěže',
};

export const PAYOUTS_FILTER_TEXTS = {
    title: `${
        COMPANY === COMPANIES.CPP
            ? 'Číslo žádosti'
            : COMPANY === COMPANIES.KOOP && 'Titul (číslo smlouvy)'
    }`,
    applicationID: 'DB ID',
    state: 'Stav',
    brokerCode: 'Získatelské číslo',
    broker: 'Název získatele',
    amountFrom: 'Částka od',
    amountTo: 'Částka do',
    fromToDate: 'Období',
    NotPayoutBeforeFrom: 'Nevyplatit před od',
    NotPayoutBeforeTo: 'Nevyplatit před do',
    tribe: 'Kmen',
    releaseDate: 'Termín uvolnění výplat do GOLEM / SAP HR',
    task: 'Úkol',
};
