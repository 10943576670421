import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {DiagramData} from '../../../services/entities/ApplicationProcess';
import {ApiData, defaultApiData, setDataFail, setDataPending, setDataSuccess} from '../../storeApiUtils';
import {fetchAppProcessDiagram, fetchAppProcessDiagrams} from '../../../services/api/applicationProcessApi';
import {getIdToken} from "../../../pages/common/authProvider/authProviderUtils";

export type DiagramState  = {
    diagram: ApiData<DiagramData>
    pending?: boolean
}

const initialState: DiagramState = {
    diagram: defaultApiData(null),
    pending: false
}

export const diagramFetch = createAsyncThunk<DiagramData, void>(
    'diagram/diagramFetch',
    async () => {
        return await fetchAppProcessDiagram(await getIdToken())
    }
)

export const diagramsFetch = createAsyncThunk<DiagramData, string>(
    'diagram/diagramsFetch',
    async (input) => {
        return await fetchAppProcessDiagrams(input, await getIdToken())
    }
)

const diagramSlice = createSlice({
    name: 'diagram',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(diagramFetch.pending, s => {
            setDataPending(s.diagram)
        })
        builder.addCase(diagramFetch.fulfilled, (s, a) => {
            setDataSuccess(s.diagram, a)
        })
        builder.addCase(diagramFetch.rejected, (s, a) => {
            setDataFail(s.diagram, a)
        })

        builder.addCase(diagramsFetch.pending, s => {
            setDataPending(s.diagram)
            s.pending = true
        })
        builder.addCase(diagramsFetch.fulfilled, (s, a) => {
            setDataSuccess(s.diagram, a)
            s.pending = false
        })
        builder.addCase(diagramsFetch.rejected, (s, a) => {
            setDataFail(s.diagram, a)
            s.pending = false
        })
    }
})

// export const {} = diagramSlice.actions
export default diagramSlice.reducer