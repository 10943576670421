import {ApplicationTypeEnumCpp, ApplicationTypeEnumKoop} from './Application';
import { UserRole } from './User';
import {TYPE_ENUM_FOR_COMPANY, IS_KOOP} from "../constants";

export const allAppTypesList = () => {
    return Object.keys(TYPE_ENUM_FOR_COMPANY).map(
        (key) => TYPE_ENUM_FOR_COMPANY[key]
    );
};

export const getAppTypes4Roles = (roles: UserRole[] = []):ApplicationTypeEnumCpp[] => {
    const result = new Set<ApplicationTypeEnumCpp>()
    roles.forEach(r => {
        const types4Role = getAppTypes4Role(r);
        types4Role.forEach(r => result.add(r))
    })
    return Array.from(result.values());
}

export const getAppTypes4Role = (roles: string[] | string): ApplicationTypeEnumKoop[] | ApplicationTypeEnumCpp[] => {
   if(IS_KOOP) {
       let tmp_roles = [];
       if (
           roles.includes(UserRole.administrator) ||
           roles.includes(UserRole.spravce)
       ) {
           return allAppTypesList();
       }
       if (roles.includes(UserRole.zadavatel_dmp)) {
           tmp_roles.push(ApplicationTypeEnumKoop.DMP);
       }
       if (roles.includes(UserRole.zadavatel_mop)) {
           tmp_roles.push(ApplicationTypeEnumKoop.MOP);
       }
       if (roles.includes(UserRole.zadavatel_stz)) {
           tmp_roles.push(ApplicationTypeEnumKoop.STZ);
       }
       if (roles.includes(UserRole.zadavatel_stz)) {
           tmp_roles.push(ApplicationTypeEnumKoop.MOT);
       } //todo možná se bude upravovat
       if (roles.includes(UserRole.zadavatel_odm)) {
           tmp_roles.push(ApplicationTypeEnumKoop.ODM);
       }
       if (roles.includes(UserRole.zadavatel_bon)) {
           tmp_roles.push(ApplicationTypeEnumKoop.BON);
       }
       if (roles.includes(UserRole.zadavatel_dmm)) {
           tmp_roles.push(ApplicationTypeEnumKoop.DMM);
       }
       if (roles.includes(UserRole.zadavatel_usp)) {
           tmp_roles.push(ApplicationTypeEnumKoop.USP);
       }
       if (roles.includes(UserRole.zadavatel_ost)) {
           tmp_roles.push(ApplicationTypeEnumKoop.OST);
       }

       return tmp_roles.length > 0 ? tmp_roles : [];
   } else {
           if (roles.includes(UserRole.ZADAVATEL_PK)) return [ApplicationTypeEnumCpp.INSURANCE_OFFICE]
           if (roles.includes(UserRole.ZADAVATEL_MT)) return [ApplicationTypeEnumCpp.OUT_OF_TERM]
           if (roles.includes(UserRole.ZADAVATEL_STMGR))  return [ApplicationTypeEnumCpp.COMPETITION_HEADQUATERS]
           if (roles.includes(UserRole.ZADAVATEL_STMREG))  return [ApplicationTypeEnumCpp.COMPETITION_REGION]
           if (roles.includes(UserRole.ZADAVATEL_SP))  return [ApplicationTypeEnumCpp.SUPER_COMISSION]
           if (roles.includes(UserRole.spravce) || roles.includes(UserRole.ADMINISTRATOR)) return allAppTypesList()
           return []
       }
};
