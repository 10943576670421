import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    Application,
    ApplicationId,
    ApplicationWrapper,
    ValidationErrors,
    ValidationResult,
} from '../../../services/entities/Application';
import {
    ApplicationProcess,
    ApplicationTask,
    ProcessInstanceId,
    TaskId,
} from '../../../services/entities/ApplicationProcess';
import {
    createApplication,
    finishEscalation,
    finishTask,
    getApplicationTasks,
} from '../../../services/api/applicationProcessApi';
import {
    fetchApplication,
    finalSaveAppAndPayouts,
    saveAppAndPayouts,
} from '../../../services/api/applicationApi';
import { NavigateFunction } from 'react-router-dom';
import { NAV } from '../../../RouteList';
import {
    ApiData,
    defaultApiData,
    setCustomData,
    setDataFail,
    setDataPending,
    setDataSuccess,
} from '../../storeApiUtils';
import { RootState, store } from '../../store';
import { camundaUsersFetch } from './camundaUsersSlice';
import { getIdToken } from '../../../pages/common/authProvider/authProviderUtils';
import {
    MpTitle,
    PageTitleType,
    TitleRegisterTableCols,
    TitleRegisterTitleFilter,
} from '../../../services/entities/TitleRegister';
import { fetchTitleRegisterListFromTitleFilter } from '../../../services/api/titleRegisterApi';


//TODO create process slice
type TitleOtherType = {
    titleRegisterData: Array<TitleRegisterTableCols>;
    titleRegisterDataFilter: Array<MpTitle>;
    loading: boolean;
    recordCount: number;
    pagination: {
        pageSize: number;
        pageNumber: number;
    };
};

type ImportTitleOtherType = {
    importTitleRegisterData: Array<TitleRegisterTableCols>;
    importTitleRegisterDataFilter: Array<MpTitle>;
    loading: boolean;
    recordCount: number;
    pagination: {
        pageSize: number;
        pageNumber: number;
    };
};

export interface ApplicationState {
    new: boolean;
    process: ApiData<ApplicationProcess>;
    application: ApiData<ApplicationWrapper>;
    tasks: ApiData<ApplicationTask[]>;
    finishingApp: ApiData<null>;
    expectedPayoutSumValid: boolean;
    attachments: [];
    dirty: boolean;
    brokerCodes: string;
    tribe: string;
    selectedType: string;
    mpTitlesData: TitleOtherType;
    importMpTitlesData: ImportTitleOtherType;
    validationErrors: ValidationErrors;
}

const initialState: ApplicationState = {
    new: false,
    application: defaultApiData(),
    process: defaultApiData(),
    tasks: defaultApiData([]),
    finishingApp: defaultApiData(),
    expectedPayoutSumValid: true,
    attachments: [],
    dirty: false,
    brokerCodes: '',
    tribe: '',
    selectedType: '',
    mpTitlesData: {
        titleRegisterData: [],
        titleRegisterDataFilter: [],
        loading: false,
        recordCount: null,
        pagination: {
            pageSize: 100,
            pageNumber: 1,
        },
    },

    importMpTitlesData: {
        importTitleRegisterData: [],
        importTitleRegisterDataFilter: [],
        loading: false,
        recordCount: null,
        pagination: {
            pageSize: 100,
            pageNumber: 1,
        },
    },
    validationErrors: {
        result: '',
        errors: [],
        showErrorAlert: false,
        errorMsgId: '',
    },
};

type NewApplicationStart = {
    navigate: NavigateFunction;
    multiApplication: boolean;
};

export const newApplicationStart = createAsyncThunk<
    ApplicationProcess,
    NewApplicationStart
>('application/newApplicationStart', async (payload) => {
    const process = await createApplication(
        await getIdToken(),
        payload.multiApplication
    );
    payload.navigate(NAV.APPLICATION_TASK(process.businessKey));

    return process;
});

export const applicationTaskFetch = createAsyncThunk<
    ApplicationTask[],
    ProcessInstanceId
>(
    'application/applicationTaskFetc',
    async (processInstanceId: ProcessInstanceId, thunkApi) => {
        const result = await getApplicationTasks(
            processInstanceId,
            await getIdToken()
        );
        thunkApi.dispatch(camundaUsersFetch(result.map((el) => el.assignee)));
        return result;
    }
);

export const applicationFetch = createAsyncThunk<
    ApplicationWrapper,
    ApplicationId
>('application/applicationFetch', async (appId: ApplicationId) => {
    return await fetchApplication(appId, await getIdToken());
});

export const applicationAndPayoutsSave = createAsyncThunk<
    ApplicationWrapper,
    Application
>('application/applicationAndPayoutsSave', async (application, thunkApi) => {
    const payouts = ((await thunkApi.getState()) as RootState).applicationDetail
        .payouts.data;
    return await saveAppAndPayouts(application, payouts, await getIdToken());
});

export const applicationAndPayoutSaveFinal = createAsyncThunk<
    ApplicationWrapper,
    Application
>(
    'application/applicationAndPayoutSaveFinal',
    async (application, thunkApi) => {
        const payouts = ((await thunkApi.getState()) as RootState)
            .applicationDetail.payouts.data;
        return await finalSaveAppAndPayouts(
            application,
            payouts,
            await getIdToken()
        );
    }
);

export type TaskFinishInput = {
    taskId: TaskId;
    escalationCode: string;
};
export const taskFinish = createAsyncThunk<void, TaskFinishInput>(
    'application/taskfinish',
    async (input: TaskFinishInput) => {
        if (input.escalationCode)
            await finishEscalation(
                input.taskId,
                input.escalationCode,
                await getIdToken()
            );
        else await finishTask(input.taskId, await getIdToken());
    }
);

export const titleRegisterListTitleFilterFetch = createAsyncThunk<
    PageTitleType,
    TitleRegisterTitleFilter
>('titleRegisterTitleFilter', async (data: TitleRegisterTitleFilter) => {
    const state = store.getState();
    const pagination = {
        pageSize: state.titleRegister['mpTitlesData'].pagination.pageSize,
        pageNumber:
            state.titleRegister['mpTitlesData'].pagination.pageNumber - 1,
    };

    return await fetchTitleRegisterListFromTitleFilter(
        { ...data, ...pagination },
        await getIdToken()
    );
});

export const importTitleRegisterListTitleFilterFetch = createAsyncThunk<
    PageTitleType,
    TitleRegisterTitleFilter
>('importTitleRegisterTitleFilter', async (data: TitleRegisterTitleFilter) => {
    const state = store.getState();
    const pagination = {
        pageNumber:
            state.titleRegister['mpTitlesData'].pagination.pageNumber - 1,
    };

    return await fetchTitleRegisterListFromTitleFilter(
        { ...data, ...pagination },
        await getIdToken()
    );
});

const applicationSlice = createSlice({
    name: 'applicationDetail',
    initialState,
    reducers: {
        clearApplication: (state) => {
            setCustomData(state.application, null);
            setCustomData(state.tasks, []);
        },
        setPayoutSumValid: (state, action: PayloadAction<boolean>) => {
            state.expectedPayoutSumValid = action.payload;
        },
        setAppDirty: (s, a: PayloadAction<boolean>) => {
            s.dirty = a.payload;
        },
        setBrokerCodes: (s, a) => {
            s.brokerCodes = a.payload;
        },

        setTribe: (s, a) => {
            s.tribe = a.payload;
        },
        setSelectedType: (s, a) => {
            s.selectedType = a.payload;
        },
        showErrorAlert: (s, a) => {
            s.validationErrors.showErrorAlert = true;
            s.validationErrors.errorMsgId = a.payload;
        },
        hideErrorAlert: (s) => {
            s.validationErrors.showErrorAlert = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(newApplicationStart.pending, (state) => {
            setDataPending(state.process);
        });
        builder.addCase(
            newApplicationStart.fulfilled,
            (state, action: PayloadAction<ApplicationProcess>) => {
                setDataSuccess(state.process, action);
                state.new = true; //TODO descide by application parameters or process presence
            }
        );
        builder.addCase(newApplicationStart.rejected, (state, action) =>
            setDataFail(state.process, action)
        );

        builder.addCase(applicationFetch.pending, (s) =>
            setDataPending(s.application)
        );
        builder.addCase(applicationFetch.fulfilled, (s, a) =>
            setDataSuccess(s.application, a)
        );
        builder.addCase(applicationFetch.rejected, (s, a) => {
            setDataFail(s.application, a);
        });

        builder.addCase(applicationAndPayoutsSave.pending, (state) =>
            setDataPending(state.application)
        );
        builder.addCase(
            applicationAndPayoutsSave.fulfilled,
            (state, action) => {
                setDataSuccess(state.application, action);
            }
        );
        builder.addCase(applicationAndPayoutsSave.rejected, (state, action) =>
            setDataFail(state.application, action)
        );

        builder.addCase(applicationAndPayoutSaveFinal.pending, (state) =>
            setDataPending(state.application)
        );
        builder.addCase(
            applicationAndPayoutSaveFinal.fulfilled,
            (state, action) => {
                if (action.payload.result === ValidationResult.SUCCESS) {
                    setDataSuccess(state.application, action);
                } else if (action.payload.result === ValidationResult.ERROR) {
                    state.application.pending = false;
                    state.validationErrors.result = action.payload.result;
                    state.validationErrors.errors = action.payload.errors;
                    state.validationErrors.showErrorAlert = true;
                }
            }
        );
        builder.addCase(
            applicationAndPayoutSaveFinal.rejected,
            (state, action) => setDataFail(state.application, action)
        );

        builder.addCase(taskFinish.pending, (s) =>
            setDataPending(s.finishingApp)
        );
        builder.addCase(
            taskFinish.fulfilled,
            (state, action: PayloadAction) => {
                setDataSuccess(state.finishingApp, action);
            }
        );
        builder.addCase(taskFinish.rejected, (state, action) =>
            setDataFail(state.finishingApp, action)
        );

        builder.addCase(applicationTaskFetch.pending, (state, action) => {
            setDataPending(state.tasks, action);
        });
        builder.addCase(applicationTaskFetch.fulfilled, (state, action) => {
            setDataSuccess(state.tasks, action);
        });
        builder.addCase(applicationTaskFetch.rejected, (state, action) =>
            setDataFail(state.tasks, action)
        );
        builder.addCase(titleRegisterListTitleFilterFetch.pending, (state) => {
            state.mpTitlesData.loading = true;
        });
        builder.addCase(
            titleRegisterListTitleFilterFetch.fulfilled,
            (state, action) => {
                state['mpTitlesData'].titleRegisterDataFilter =
                    action.payload.mpTitles;
                state['mpTitlesData'].recordCount = action.payload.recordCount;
                state['mpTitlesData'].pagination = {
                    pageSize: action.payload.pageSize,
                    pageNumber: action.payload.pageNumber + 1,
                };
                state['mpTitlesData'].loading = false;
            }
        );
        builder.addCase(titleRegisterListTitleFilterFetch.rejected, (state) => {
            state['mpTitlesData'].loading = false;
        });

        builder.addCase(
            importTitleRegisterListTitleFilterFetch.pending,
            (state) => {
                state.mpTitlesData.loading = true;
            }
        );
        builder.addCase(
            importTitleRegisterListTitleFilterFetch.fulfilled,
            (state, action) => {
                state['importMpTitlesData'].importTitleRegisterDataFilter =
                    action.payload.mpTitles;
                state['importMpTitlesData'].recordCount =
                    action.payload.recordCount;
                state['importMpTitlesData'].pagination = {
                    pageSize: action.payload.pageSize,
                    pageNumber: action.payload.pageNumber + 1,
                };
                state['importMpTitlesData'].loading = false;
            }
        );
        builder.addCase(
            importTitleRegisterListTitleFilterFetch.rejected,
            (state) => {
                state['importMpTitlesData'].loading = false;
            }
        );
    },
});

export const {
    clearApplication,
    setPayoutSumValid,
    setAppDirty,
    setBrokerCodes,
    setTribe,
    setSelectedType,
    showErrorAlert,
    hideErrorAlert,
} = applicationSlice.actions;
export default applicationSlice.reducer;
