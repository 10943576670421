import {OidcRpClient, OidcRpLoginSuccess} from '@idport/oidc-rp-sdk';
import {OidcRpRefreshSuccess} from '@idport/oidc-rp-sdk/lib/oidc-rp-responses';
import jwt from "jwt-decode";

//const ISSUER = 'https://idp-t.vig.cz/idport/koop/fs/oidc/oidc';
// const ISSUER = '/idport/koop/fs/oidc/oidc';
// const ISSUER = 'https://idp-t.vig.cz/mep/fs/fl';
// const ISSUER = process.env.REACT_APP_AUTH_URL || '/idport/koop/fs/oidc/oidc';

const ISSUER = process.env.REACT_APP_AUTH_URL || 'https://idp-t.vig.cz/idport/koop/fs/oidc/oidc';

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID || 'camunda-dev';
const SCOPE = process.env.REACT_APP_AUTH_SCOPE

const oidcRpClient = OidcRpClient.createClient({
    clientId: CLIENT_ID,
    issuer: ISSUER,
    responseType: 'code',
    disableClearUrl: true,
    disableIssuerValidation: true,
    checkSession: false,

    'callback': (e) => {
        switch (e.type) {
            case 'session_changed':
                console.log('Session changed.');
                break;
            case 'session_error':
                console.log('Session error occured.');
                break;
        }
    }
});

export const getInfoFromUrl = (): [string, string, string] => {
    const hash = window.location.hash.substring(1);
    const params = new URLSearchParams(hash)
    const state = params.get('state');
    return [hash, params.get('code'), state ? decodeURIComponent(state) : null]
}

export type Claims = {
    exp: number,
    email: string,
    username: string
}

export const login = (state: string) => {
    return oidcRpClient
        .loginWithRedirect({
            redirectUri: process.env.NODE_ENV === 'development'
                ? 'http://localhost:3000'
                : (window.location.origin + '/'),
            scope: SCOPE,
            state: state,
        })
        .then((resp) => {
            return resp
        })
        .catch((e) => {
            console.error(e);
        });
}

export const logout = async (state: string): Promise<void> => {

    try {
        await oidcRpClient.revokeToken()
    } catch (e) {
        console.error(e)
    }

    try {
        return oidcRpClient.logout({
            postLogoutRedirectUri: process.env.NODE_ENV === 'development'
                ? 'http://localhost:3000'
                : (window.location.origin + '/'),
            state: state,
        })
    } catch (e) {
        return null
    }

}

export const refreshTokens = (refreshToken?: string): Promise<OidcRpRefreshSuccess> => {
    return oidcRpClient.refreshTokens(refreshToken ? {refreshToken} : null)
}


const STORAGE = sessionStorage
const STORED_TOKENS_KEY = 'storedTokens'
const DANGER_PERIOD = 5000

export type StoredTokens = {
    idToken: string,
    idTokenExpiration: number,
    refreshToken: string,
    refreshTokenExpiration: number
}

export const removeTokens = () => {
    STORAGE.removeItem(STORED_TOKENS_KEY)
    setTokenMocked(null)
}
const storeTokens = (tokens: StoredTokens) => {
    if (!tokens)
        STORAGE.removeItem(STORED_TOKENS_KEY)
    else
        STORAGE.setItem(STORED_TOKENS_KEY, JSON.stringify(tokens))
}
export const hasStoredTokens = () => {
    return !!STORAGE.getItem(STORED_TOKENS_KEY)
}
const retrieveStoredTokens = (): StoredTokens => {
    const stringTokens = STORAGE.getItem(STORED_TOKENS_KEY);
    return stringTokens ? JSON.parse(stringTokens) : null
}

const errorListeners: { [key: string]: (error: TokenError) => void } = {}
const mocked = {value: null}

export enum TokenError {
    REFRESH_EXPIRED,
    CANT_GET_TOKENS,
    NO_ID_TOKEN,
    NO_REFRESH_TOKEN,
    NO_USERNAME,
    NO_ID_EXP,
    NO_REFRESH_EXP,
    WRONG_STORED_FORMAT
}

export const addTokenErrorListener = (key: string, listener: (error: TokenError) => void) => {
    errorListeners[key] = listener
}

export const setTokenMocked = (value) => {
    mocked.value = value
}

const validateIdTokenResponse = (data: OidcRpLoginSuccess): TokenError => {
    if (!data.idToken) return TokenError.NO_ID_TOKEN;
    if (!data.refreshToken) return TokenError.NO_REFRESH_TOKEN;
    // if (!accessExp) return TokenError.NO_ID_EXP
    // if (!refreshExp) return TokenError.NO_REFRESH_EXP
    if (!(data.idToken.claims as Claims).username)
        return TokenError.NO_USERNAME

    return null
}

const extractAndStoreTokens = (result: OidcRpRefreshSuccess | OidcRpLoginSuccess): StoredTokens => {
    const tokenErr = validateIdTokenResponse(result)
    if (tokenErr) {
        notifyListeners(tokenErr)
        return null
    }

    const accessDecoded: any = jwt(result.idToken.raw)
    const refreshDecoded: any = jwt(result.refreshToken)

    const tokens = {
        idToken: result.idToken.raw,
        idTokenExpiration: accessDecoded.exp,
        refreshToken: result.refreshToken,
        refreshTokenExpiration: refreshDecoded.exp
    };
    storeTokens(tokens)

    return tokens
}

export const fetchTokensAndLogin = async (hash: string): Promise<string> => {
    const url = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : (window.location.origin + '/')

    let data = null
    try {
        data = await oidcRpClient.handleLoginWithRedirectCallback(`${url}#${hash}`)
    } catch (error) {
        notifyListeners(TokenError.CANT_GET_TOKENS)
        return null
    }

    if (data.type === 'error') {
        notifyListeners(TokenError.CANT_GET_TOKENS)
        return null
    } else {
        const result = data as OidcRpLoginSuccess
        const tokens = extractAndStoreTokens(result)
        return tokens ? (result.idToken.claims as Claims).username
                      : Promise.reject('Could not get/parse tokens from id port.')
    }
}

const notifyListeners = (error: TokenError) => {
    Object.entries(errorListeners).forEach(e => {
        e[1](error)
    })
}

const getTimeFromJwtTime = (jwtTime) => {
    if (!jwtTime)
        return NaN

    const d = new Date(0)
    d.setUTCSeconds(jwtTime)
    return d.getTime()
}

const validateStoredTokens = (tokens: StoredTokens):TokenError => {
    if (!tokens.idToken
        || !tokens.idTokenExpiration
        || !tokens.refreshToken)
        return TokenError.WRONG_STORED_FORMAT

    return null
}

const getIdTokenInternal = async (): Promise<StoredTokens> => {
    const tokens = retrieveStoredTokens()

    const err = validateStoredTokens(tokens)

    if (err) {
        notifyListeners(err)
        return null
    }

    if (!tokens)
        return Promise.reject('Nelze získat přístupové údaje.')

    const now = new Date().getTime()

    if (getTimeFromJwtTime(tokens.idTokenExpiration) - now < DANGER_PERIOD) {
        if (getTimeFromJwtTime(tokens.refreshTokenExpiration) - now < DANGER_PERIOD) {
            notifyListeners(TokenError.REFRESH_EXPIRED)
            return null
        }

        let res: OidcRpRefreshSuccess = null
        try {
            res = await refreshTokens(tokens.refreshToken)
        } catch (error) {
            notifyListeners(TokenError.CANT_GET_TOKENS)
            return null
        }

        return extractAndStoreTokens(res)
    }

    return tokens
}
export const getIdToken = async (): Promise<string> => {
    if (mocked.value)
        return mocked.value

    const result = await getIdTokenInternal();
    return result && result.idToken
}
export const extractUsername = (accessToken: string): string => {
    return (jwt(accessToken) as any).username
}





