import React from 'react';
import {Modal} from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import {MyButton} from '../../../components/button/MyButton';
import {useAppDispatch, useAppSelector} from '../../../store/store';
import './title-register-import-error-alert.scss';
import {titleRegisterImportConfirmFetch} from '../../../store/slices/titleRegister/titleRegisterImportSlice';
import {DataImportConfirmType} from '../../../services/api/titleRegisterImportApi';
import {LoadingIndicator} from '../../../components/loadingIndicator/LoadingIndicator';

type ImportErrorAlertProps = {
    variant: string;
    show: boolean;
    title: string;
    content: Array<string>;
    setShowAlert: React.Dispatch<React.SetStateAction<boolean>>;
    file?: File;
};

const TitleRegisterImportErrorAlert = ({variant, title, content, show, setShowAlert}: ImportErrorAlertProps) => {
    const dispatch = useAppDispatch();
    const {loading, data} = useAppSelector((state) => state.titleRegisterImport);
    if (loading) return <LoadingIndicator/>;
    return (
        <Modal
            show={show}
            size="lg"
            className="TitleRegisterImportErrorAlertPage"
        >
            <Modal.Header
                className={`${
                    variant === 'danger'
                        ? 'alertHeaderError'
                        : 'alertHeaderWarning'
                }`}
            >
                {title}
            </Modal.Header>

            <Modal.Body>
                <Alert variant={variant} className="longText">
                    <ul>
                        {content.map((p, index) => {
                            return <li key={index}>{p}</li>;
                        })}
                    </ul>
                    {variant === 'warning' &&
                        'Přejete si přesto pokračovat s importem?'}
                </Alert>
            </Modal.Body>
            <Modal.Footer>
                {variant === 'warning' && (
                    <MyButton
                        variant="primary"
                        className="mx-1"
                        onClick={() => {
                            setShowAlert(!show);
                            const payload: DataImportConfirmType = {
                                referenceUUID: data.referenceUUID
                            };
                            dispatch(titleRegisterImportConfirmFetch(payload));
                        }}
                    >
                        Pokračovat
                    </MyButton>
                )}
                <MyButton
                    variant="secondary"
                    onClick={() => {
                        setShowAlert(!show);
                    }}
                >
                    {variant === 'warning' ? 'Zrušit' : 'Zavřít'}
                </MyButton>
            </Modal.Footer>
        </Modal>
    );
};

export default TitleRegisterImportErrorAlert;
