import {Form} from "react-bootstrap"
import {DeepRequired, FieldErrorsImpl} from "react-hook-form"
import clsx from 'clsx';

export type FormErrorProps<T> = {
    errors: FieldErrorsImpl<DeepRequired<T>>,
    name: string,
    ignoreRequired?: boolean,
    className?: string
}

export function FormError<T>({name, errors, className, ignoreRequired}: FormErrorProps<T>) {

    const err = errors[name]

    if (!err)
        return

    if (ignoreRequired && err.type === 'required')
        return

    return err.message
        && <div className={clsx(className)}>
            <Form.Text className="text-danger">{err.message}<br/></Form.Text>
        </div>
}