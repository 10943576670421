import {DATA_API_URL} from '../constants';
import {ApplicationId} from '../entities/Application';
import {PayoutId} from "../entities/Payout";
import {fetchMethods} from "../fetchUtils";
import {PayoutDetailWrapper} from '../entities/PayoutDetailWrapper';
import {AuthToken} from './applicationApi';

export const fetchPayoutDetailData = (applicationId: ApplicationId, payoutId: PayoutId, token: AuthToken)
    : Promise<PayoutDetailWrapper> => {
    const url = `${DATA_API_URL}/payouts/${applicationId}/${payoutId}`;
    return fetchMethods.get(url, token);
}

