import { store } from "../store/store";
import {addDanger} from "../store/slices/common/notificationsSlice";
import {apiConfig} from "./api/apiUtils";

export const processResponse = async (response) => {
    // response only can be ok in range of 2XX
    if (response.ok) {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then(data => {
                return data
            })
        } else if (contentType?.includes('text/csv') || contentType?.includes('application/octet-stream')) {
            return response.blob()
        } else {
            return response.text().then(text => {
                return text
            })
        }
    } else {
        //handle errors
        if (response.status >= 400 && response.status <= 599) {

            let res =  await response.text()
            if (!res)
                res = response.statusText || response.status.toString()

            store.dispatch(addDanger(
                `Došlo k chybě, obraťte se na administrátora aplikace. \nDetail: ${res}`))

            throw new Error(`Error fetching response: ${response.url}, status: ${response.status}`)
        }
    }
}

export function secureFetch(url, data) {
    return fetch(url, data).then(processResponse)
}

const defaultsHeadersPost = {'Content-Type': 'application/json', 'accept': 'application/json'}

export const fetchMethods = {

    get(url: string, token: string, otherHeaders?: object, other?: object) {
        return secureFetch(url, {
            method: 'GET',
            ...apiConfig(token, otherHeaders, other)
        })
    },

    post(url: string, data: object | string, token: string, noDefaultBody?: boolean, noDefaultHeaders?: boolean, otherHeaders?: object, other?: object) {
        let defaultHeaders = noDefaultHeaders ? null : defaultsHeadersPost
        return secureFetch(url, {
            method: 'POST',
            body: noDefaultBody || data === '' ? data : JSON.stringify(data),
            ...apiConfig(token, {...defaultHeaders, ...otherHeaders}, other)
        })
    },

    put(url: string, data: object | string, token: string, otherHeaders?: object, other?: object) {
        const defaultHeaders = {'Content-Type': 'application/json'}
        return secureFetch(url, {
            method: 'PUT',
            body: JSON.stringify(data),
            ...apiConfig(token, {...defaultHeaders, ...otherHeaders}, other)
        })
    },

    delete(url: string, token: string, otherHeaders?: object, other?: object) {
        return secureFetch(url, {
            method: 'DELETE',
            ...apiConfig(token, otherHeaders, other)
        })
    },
}