import React from 'react';
import {Col, Container, Form, Row} from 'react-bootstrap';
import Header from '../../common/header/Header';
import {ArrowsMove, Backspace} from 'react-bootstrap-icons';
import MainContent from '../../common/content/MainContent';
import {TEXTS} from '../../../constants/texts';
import {NAV} from '../../../RouteList';
import {RequiredLabel} from '../../../components/form/RequiredLabel';
import {FormError} from '../../../components/form/FormError';
import {useForm} from 'react-hook-form';
import {MyButton} from '../../../components/button/MyButton';
import {required} from '../../../components/form/formValidations';
import {RootState, useAppDispatch, useAppSelector} from '../../../store/store';
import {processInstancesMigrate} from '../../../store/slices/administration/administrationSlice';
import {LoadingIndicator} from '../../../components/loadingIndicator/LoadingIndicator';
import {ProcessDefinitionId} from '../../../services/entities/ApplicationProcess';
import {addNotification} from '../../../store/slices/common/notificationsSlice';

type ProcessInstanceMigrationFormData = {
    sourceId: ProcessDefinitionId,
    targetId: ProcessDefinitionId
}

const defaultFormData = {
    sourceId: "",
    targetId: ""
}
function ProcessInstanceMigrationPage() {
    const dispatch = useAppDispatch()
    const modifyState = useAppSelector((s: RootState) => s.administration.modify)
    const {register, reset, handleSubmit, formState: {errors}} = useForm<ProcessInstanceMigrationFormData>({
        defaultValues: defaultFormData
    });

    const onSubmit = (values: ProcessInstanceMigrationFormData) => {
        dispatch(processInstancesMigrate({
            sourceDefinitionId: values.sourceId,
            targetDefinitionId: values.targetId,
        })).then(() => {
            reset(defaultFormData)
            dispatch(addNotification({
                text: 'Migrace procesních instancí úspěšně provedena.',
                variant: 'success',
                timeout: 10000
            }))
        }, () => {
            dispatch(addNotification({
                text: 'Vyskytl se problém při migraci procesních instancí.',
                variant: 'danger',
                timeout: 10000
            }))
        })
    }

    return (
        <div>
            <Header className="mb-3"
                    actions={[
                        {
                            title: TEXTS.back,
                            icon: <Backspace/>,
                            to: NAV.INDEX
                        }
                    ]}/>

            <MainContent className="pt-5">

                {
                    modifyState.pending && <LoadingIndicator />
                }

                <Container fluid="xxl">
                    <Form className="ApplicationForm" onSubmit={handleSubmit(onSubmit)}>
                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <RequiredLabel>ID zdrojové procesní definice</RequiredLabel>
                                <Form.Control  {...register("sourceId", {...required()})}
                                               className="mw-100"
                                               placeholder="Zadejte id zdrojové procesní definice"/>
                                <FormError errors={errors} name="sourceId" />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <RequiredLabel>ID cílové procesní definice</RequiredLabel>
                                <Form.Control  {...register("targetId", {...required()})}
                                               className="mw-100"
                                               placeholder="Zadejte id zdrojové procesní definice"/>
                                <FormError errors={errors} name="targetId" />
                            </Form.Group>
                        </Row>

                        <Row>
                            <Col align="end">
                                <MyButton type="submit">
                                    <ArrowsMove className="me-2"/>Migrovat
                                </MyButton>
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </MainContent>
        </div>
    );
}

export default ProcessInstanceMigrationPage;
