export function processItemsAndPayouts(items, payouts) {
    const output = [];
    function getTitleFromPayout(payout) {
        if (payout?.agreementNumber) {
            return payout?.agreementNumber;
        } else if (payout?.title && payout?.title?.id) {
            return payout?.title?.id;
        }
        return null;
    }
    items?.forEach((item) => {
        if (item?.required) {
            if (item?.forTitle) {
                const titles = payouts?.map(getTitleFromPayout);

                const titleMap = {};
                titles?.forEach((title) => {
                    if (title) {
                        titleMap[title] = true;
                    }
                });
                const uniqueTitleCount = Object?.keys(titleMap)?.length;

                output?.push(
                    `${item?.documentType} (vztahuje se k titulu, kterých se v žádosti nachází unikátně ${uniqueTitleCount}x. Nahrajte příslušnou přílohu individuálně pro
                              každý titul)`
                );
            } else {
                output?.push(
                    `${item?.documentType} (vztahuje se k žádosti. Nahrajte přílohu pouze 1x)`
                );
            }
        }
    });

    return output;
}

