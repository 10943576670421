import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    ApiData,
    defaultApiData,
    setDataFail,
    setDataPending,
    setDataSuccess,
} from '../../storeApiUtils';
import { KODZI } from '../../../services/entities/Payout';
import { Broker } from '../../../services/entities/Broker';
import { fetchBroker } from '../../../services/api/golemApi';
import { getIdToken } from '../../../pages/common/authProvider/authProviderUtils';

export type BrokerState = {
    broker: ApiData<Broker>;
};
const initialState: BrokerState = {
    broker: defaultApiData(),
};
export type BrokerFetchInput = {
    kodzi: KODZI;
    validityDate: Date;
};
export const brokerFetch = createAsyncThunk<Broker, BrokerFetchInput>(
    'broker/brokerFetch',
    async (input) => {
        return await fetchBroker(
            input.kodzi,
            input.validityDate,
            await getIdToken()
        );
    }
);

const brokerSlice = createSlice({
    name: 'broker',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(brokerFetch.pending, (s) => {
            setDataPending(s.broker);
            s.broker.data = null;
        });
        builder.addCase(brokerFetch.fulfilled, (s, a) =>
            setDataSuccess(s.broker, a)
        );
        builder.addCase(brokerFetch.rejected, (s, a) => {
            setDataFail(s.broker, a);
            s.broker.data = null;
        });
    },
});

export default brokerSlice.reducer;
