import { Sort } from './Sort';

export type PayoutsListFilter = {
    agreementNumber: string;
    id: number;
    state: string[];
    recipientId: number;
    recipientName: string;
    amountFrom: number;
    amountTo: number;
    fromToDate: number;
    fromDate: Date;
    toDate: Date;
    dueDateFrom: Date;
    dueDateTo: Date;
    tribe: string[];
    releaseDate: Date;
    tasks: string[];
    pageNumber?: 0;
    pageSize?: 0;
    sortOrder?: string;
    sortList?: [string];
};
export enum PayoutsListFilterTypeEnum {
    ACTION = 'ACTION',
    ALL = 'ALL',
}

export enum PayoutTypeEnum {
    noPayout = 'Bez výplaty',
    golem = 'GOLEM',
    sapHr = 'SAP HR',
}

export enum PayoutsTasks {
    ERROR_RESOLVED = 'ERROR_RESOLVED',
}
export type PayoutsListFetchInput = {
    baseFilter?: PayoutsListFilterTypeEnum;
    agreementNumber: string;
    id: number;
    state: string[];
    recipientId: number;
    recipientName: string;
    amountFrom: number;
    amountTo: number;
    fromDate: Date;
    toDate: Date;
    dueDateFrom: Date;
    dueDateTo: Date;
    tribe: string[];
    releaseDate: Date;
    tasks: string[];
    pageSize?: number;
    pageNumber?: number;
    sort?: Sort;
    sortOrder?: 'ASC' | 'DESC';
    sortList?: string[];
};

export type PagedPayoutsList = {
    payoutListWrapper: PayoutsListWrapper[];
    pageNumber: number;
    pageSize: number;
    recordCount: number;
};
export type PayoutListTask = {
    description: string;
    executionId: string;
    id: string;
    name: string;
    processDefinitionId: string;
    processInstanceId: string;
    suspended: boolean;
    taskDefinitionKey: string;
};
export type PayoutsList = {
    title: string;
    dbId: number;
    applicationID: number;
    state: string[];
    recipientNumber: number;
    recipientName: string;
    amount: number;
    tribe: string[];
    fromDate: Date;
    toDate: Date;
    periodChangeFrom: Date;
    periodChangeTo: Date;
    notPayout: number;
    payoutType: PayoutTypeEnum;
    tasks: PayoutListTask[];
};

export type PayoutsListWrapper = {
    payouts: PayoutsList;
    errorPayout: boolean;
    tasks: PayoutListTask[];
    errors?: Array<string>;
};
