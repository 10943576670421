import React, {useContext} from 'react';
import {Container, Nav, Navbar, NavDropdown} from 'react-bootstrap';
import './Header.scss';
import { Link, useNavigate } from 'react-router-dom';
import { MyButton } from '../../../components/button/MyButton';
import { RootState, useAppSelector } from '../../../store/store';
import { AuthContext, AuthContextValue } from '../authProvider/AuthProvider';
import {
    ADMINISTRATION_ROLES,
    ET_ROLES,
    isAdmin,
    MP_APPLICATION_ROLES,
    PAYOUTS_LIST_ROLES,
    seeAdministrationMp,
} from '../../../services/entities/User';
import { Variant } from 'react-bootstrap/esm/types';
import {
    ArrowRightCircle,
    ArrowsMove,
    Person,
    PersonCheck,
    QuestionCircle,
} from 'react-bootstrap-icons';
import { NAV } from '../../../RouteList';
import clsx from 'clsx';
import { APP_ENV, ENVS, IS_KOOP } from '../../../services/constants';
import { hasIntersection } from '../../../utils/utils';
import { TEXTS } from '../../../constants/texts';
import Confirmation from '../../../components/confirmation/Confirmation';

export type ActionInput = {
    icon?: React.ReactNode;
    className?: string;
    componentOverride?: React.ReactElement;
    params?: any;
    title: string;
    error?: string;
    to?: string;
    variant?: Variant;
    description?: string;
    asDropdown?: boolean;
    action?: (any?) => void;
};

function NavAction({
    icon,
    className,
    title,
    action,
    to,
    asDropdown,
    componentOverride,
}: ActionInput) {
    if (componentOverride) return componentOverride;

    const buttonEl = (
        <MyButton noYMargin onClick={action && action} className={className}>
            <div className="d-flex align-items-center">
                {icon && icon}
                {icon && <>&nbsp;</>}
                {title}
            </div>
        </MyButton>
    );

    const MainEl = asDropdown ? NavDropdown.Item : Nav.Item;

    return (
        <MainEl>
            {to ? (
                <Link to={to} className="me-2">
                    {buttonEl}
                </Link>
            ) : (
                buttonEl
            )}
        </MainEl>
    );
}

export type HeaderProps = {
    className?: string;
    title?: string;
    actions?: ActionInput[];
    useConfirmForBack?: boolean;
};

function Header({
    className,
    title = 'Mimořádné provize',
    actions = [],
    useConfirmForBack,
}: HeaderProps) {
    const user = useAppSelector((s: RootState) => s.user);
    const auth: AuthContextValue = useContext(AuthContext);
    const isNotProd = APP_ENV !== ENVS.PROD;
    const navigate = useNavigate();

    return (
        <Navbar
            className={clsx('Header', className)}
            bg="primary"
            variant="dark"
            expand="lg"
        >
            <Container fluid="xxl">
                {useConfirmForBack ? (
                    <Confirmation
                        className="large-confirmation"
                        title="Opustit žádost"
                        confirmText={TEXTS.yes}
                        cancelText={TEXTS.no}
                        message={TEXTS.leaveAppDetailConfirmation}
                        disabled={!useConfirmForBack}
                        onConfirm={() => navigate(NAV.INDEX)}
                    >
                        <Navbar.Brand className="pe-3 pt-0 pb-0 text-center pointer">
                            <img
                                alt="Logo-Home"
                                height={'48px'}
                                src={
                                    IS_KOOP
                                        ? '/assets/logo-koop2.png'
                                        : '/assets/logo_cpp_bile_rgb.svg'
                                }
                                className="me-3"
                            />

                            <span className="h6">
                                {isNotProd && (
                                    <strong className="text-danger">
                                        ! TEST -{' '}
                                    </strong>
                                )}
                                {title}
                                {isNotProd && (
                                    <strong className="text-danger">
                                        {' '}
                                        - TEST !
                                    </strong>
                                )}
                            </span>
                        </Navbar.Brand>
                    </Confirmation>
                ) : (
                    <Navbar.Brand
                        as={Link}
                        to={NAV.INDEX}
                        className="pe-3 pt-0 pb-0 text-center"
                    >
                        <img
                            alt="Logo-Home"
                            height={'48px'}
                            src={
                                IS_KOOP
                                    ? '/assets/logo-koop2.png'
                                    : '/assets/logo_cpp_bile_rgb.svg'
                            }
                            className="me-3"
                        />

                        <span className="h6">
                            {isNotProd && (
                                <strong className="text-danger">
                                    ! TEST -{' '}
                                </strong>
                            )}
                            {title}
                            {isNotProd && (
                                <strong className="text-danger">
                                    {' '}
                                    - TEST !
                                </strong>
                            )}
                        </span>
                    </Navbar.Brand>
                )}
                <Navbar.Toggle />
                <Navbar.Collapse>
                    <Nav>
                        {actions?.map((el, i) => (
                            <NavAction
                                key={i}
                                {...el}
                                className="button-action"
                            />
                        ))}

                        {IS_KOOP &&
                            hasIntersection(user.roles, ET_ROLES) &&
                            (useConfirmForBack ? (
                                <Confirmation
                                    className="large-confirmation"
                                    title="Opustit žádost"
                                    confirmText={TEXTS.yes}
                                    cancelText={TEXTS.no}
                                    message={TEXTS.leaveAppDetailConfirmation}
                                    disabled={!useConfirmForBack}
                                    onConfirm={() =>
                                        navigate(NAV.TITLE_REGISTER)
                                    }
                                >
                                    <Nav.Link className="ms-3">Tituly</Nav.Link>
                                </Confirmation>
                            ) : (
                                <Nav.Link
                                    as={Link}
                                    className="ms-3"
                                    to={NAV.TITLE_REGISTER}
                                >
                                    Tituly
                                </Nav.Link>
                            ))}

                        {hasIntersection(user.roles, MP_APPLICATION_ROLES) &&
                            (useConfirmForBack ? (
                                <Confirmation
                                    className="large-confirmation"
                                    title="Opustit žádost"
                                    confirmText={TEXTS.yes}
                                    cancelText={TEXTS.no}
                                    message={TEXTS.leaveAppDetailConfirmation}
                                    disabled={!useConfirmForBack}
                                    onConfirm={() => navigate(NAV.INDEX)}
                                >
                                    <Nav.Link>Žádosti</Nav.Link>
                                </Confirmation>
                            ) : (
                                <Nav.Link as={Link} to={NAV.INDEX}>
                                    Žádosti
                                </Nav.Link>
                            ))}
                        {hasIntersection(user.roles, PAYOUTS_LIST_ROLES) &&
                            (useConfirmForBack ? (
                                <Confirmation
                                    className="large-confirmation"
                                    title="Opustit žádost"
                                    confirmText={TEXTS.yes}
                                    cancelText={TEXTS.no}
                                    message={TEXTS.leaveAppDetailConfirmation}
                                    disabled={!useConfirmForBack}
                                    onConfirm={() => navigate(NAV.PAYOUTS)}
                                >
                                    <Nav.Link className="ms-3">
                                        Výplaty
                                    </Nav.Link>
                                </Confirmation>
                            ) : (
                                <Nav.Link
                                    as={Link}
                                    className="ms-3"
                                    to={NAV.PAYOUTS}
                                >
                                    Výplaty
                                </Nav.Link>
                            ))}

                        {hasIntersection(user.roles, ADMINISTRATION_ROLES) && (
                            <NavDropdown title="Administrace" className="ms-3">
                                {isAdmin(user) &&
                                    (useConfirmForBack ? (
                                        <Confirmation
                                            className="large-confirmation"
                                            title="Opustit žádost"
                                            confirmText={TEXTS.yes}
                                            cancelText={TEXTS.no}
                                            message={
                                                TEXTS.leaveAppDetailConfirmation
                                            }
                                            disabled={!useConfirmForBack}
                                            onConfirm={() =>
                                                navigate(NAV.PROCESS_MOD)
                                            }
                                        >
                                            <NavDropdown.Item>
                                                <ArrowRightCircle /> Modifikace
                                                procesních instancí
                                            </NavDropdown.Item>
                                        </Confirmation>
                                    ) : (
                                        <NavDropdown.Item
                                            as={Link}
                                            to={NAV.PROCESS_MOD}
                                        >
                                            <ArrowRightCircle /> Modifikace
                                            procesních instancí
                                        </NavDropdown.Item>
                                    ))}
                                {seeAdministrationMp(user) &&
                                    (useConfirmForBack ? (
                                        <Confirmation
                                            className="large-confirmation"
                                            title="Opustit žádost"
                                            confirmText={TEXTS.yes}
                                            cancelText={TEXTS.no}
                                            message={
                                                TEXTS.leaveAppDetailConfirmation
                                            }
                                            disabled={!useConfirmForBack}
                                            onConfirm={() =>
                                                navigate(NAV.TASK_ASSIGN)
                                            }
                                        >
                                            <NavDropdown.Item>
                                                <PersonCheck /> Změna přiřazení
                                                uživatelských úkolů
                                            </NavDropdown.Item>
                                        </Confirmation>
                                    ) : (
                                        <NavDropdown.Item
                                            as={Link}
                                            to={NAV.TASK_ASSIGN}
                                        >
                                            <PersonCheck /> Změna přiřazení
                                            uživatelských úkolů
                                        </NavDropdown.Item>
                                    ))}
                                {seeAdministrationMp(user) &&
                                    isAdmin(user) &&
                                    (useConfirmForBack ? (
                                        <Confirmation
                                            className="large-confirmation"
                                            title="Opustit žádost"
                                            confirmText={TEXTS.yes}
                                            cancelText={TEXTS.no}
                                            message={
                                                TEXTS.leaveAppDetailConfirmation
                                            }
                                            disabled={!useConfirmForBack}
                                            onConfirm={() =>
                                                navigate(NAV.PROCESS_MIGRATE)
                                            }
                                        >
                                            <NavDropdown.Item>
                                                <ArrowsMove /> Migrace
                                                procesních instancí
                                            </NavDropdown.Item>
                                        </Confirmation>
                                    ) : (
                                        <NavDropdown.Item
                                            as={Link}
                                            to={NAV.PROCESS_MIGRATE}
                                        >
                                            <ArrowsMove /> Migrace procesních
                                            instancí
                                        </NavDropdown.Item>
                                    ))}
                            </NavDropdown>
                        )}
                    </Nav>
                </Navbar.Collapse>

                <Navbar.Brand as="div">
                    <MyButton
                        noYMargin
                        className="button-action me-1 big-icon-btn"
                        tooltip="Stáhnout uživatelskou příručku"
                    >
                        <a href={NAV.GUIDE} target="blank">
                            <QuestionCircle color="white" />
                        </a>
                    </MyButton>

                    <div className="h6 d-inline-block">
                        <Person className="me-1" />
                        {user?.name}
                    </div>
                    {user?.name ? (
                        <MyButton
                            className="ms-1 text-light"
                            noYMargin
                            variant="link"
                            onClick={() => auth.logout()}
                        >
                            Odhlásit
                        </MyButton>
                    ) : null}
                </Navbar.Brand>
            </Container>
        </Navbar>
    );
}

export default Header;
