import React, {useCallback, useEffect} from 'react';
import {Form, Modal} from 'react-bootstrap';
import {RequiredLabel} from '../../../components/form/RequiredLabel';
import {FormError} from '../../../components/form/FormError';
import {useForm} from 'react-hook-form';
import {MyButton} from '../../../components/button/MyButton';
import {TEXTS} from '../../../constants/texts';
import {required} from '../../../components/form/formValidations';
import {XLg} from 'react-bootstrap-icons';
import AsyncAutocomplete from "../../../components/form/AsyncAutocomplete";
import {fetchCamundaUserByLastname} from "../../../services/api/applicationProcessApi";
import {CamundaUser} from "../../../services/entities/User";
import {formatCamundaUser} from "../../../utils/formatUtils";
import {LoadingIndicator} from "../../../components/loadingIndicator/LoadingIndicator";
import {getIdToken} from "../../common/authProvider/authProviderUtils";

type TaskAssignModalFormProps = {
    assignee: string,
    assigneeLabel: string,
    onHide: () => void,
    open: boolean,
    pending: boolean,
    onAssigneeConfirm: (assignee: string) => void
}

function TaskAssignModalForm({assignee, assigneeLabel, onHide, open, onAssigneeConfirm, pending}: TaskAssignModalFormProps) {

    const {register, trigger, handleSubmit, reset, formState: {errors}, setValue} = useForm()

    useEffect(() => {
        reset({assignee})
    }, [reset, assignee])

    const onSubmit = (values) => {
        onAssigneeConfirm(values.assignee)
    }

    const onDelete = () => {
        reset({assignee: ''})
        onAssigneeConfirm('')
    }

    const handleSearchUsers = useCallback(async (query: string) => {
        return fetchCamundaUserByLastname(`*${query}*`, await getIdToken())
    }, []);

    const FormatUser = (user: CamundaUser) => {
        return (
            <span>{formatCamundaUser(user, '', true)}</span>
        )
    };

    const handleUserChanged = useCallback((users: CamundaUser[]) => {
        const user = users.length ? users[0].id : null;
        setValue('assignee', user)
        trigger()
    }, [setValue, trigger]);

    return (
        <Modal show={open} onHide={onHide}>

            <Modal.Header closeButton>
                <Modal.Title as="h5">Změna přiřazení</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {pending && <LoadingIndicator />}

                <Form id="TaskAssignModalForm" onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group>
                        <RequiredLabel>Příjmení řešitele</RequiredLabel>
                        <AsyncAutocomplete
                            id="taskAssignUserInput"
                            onChange={handleUserChanged}
                            className="mw-100"
                            onSearch={handleSearchUsers}
                            labelKey="label"
                            placeholder={assigneeLabel || 'Zadejte znaky příjmení a vyberte řešitele'}
                            formatItem={FormatUser}
                            inputProps={register("assignee", {...required()})}/>
                        <FormError errors={errors} name="assignee"/>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <MyButton variant="secondary" onClick={onHide} className="me-5">
                    {TEXTS.close}
                </MyButton>
                <MyButton variant="warning" onClick={onDelete}  disabled={pending}>
                    Vymazat <XLg className="text-light ms-2"/>
                </MyButton>
                <MyButton type="submit" form="TaskAssignModalForm"
                          disabled={pending}
                          variant="primary">
                    {TEXTS.save}
                </MyButton>
            </Modal.Footer>
        </Modal>
    );
}

export default TaskAssignModalForm;
