import { ApplicationId } from './Application';

export type ProcessInstanceId = string;
export type ProcessDefinitionId = string;
export type TaskDefinitionKey = string;
export type TaskId = string;

export interface ApplicationProcess {
    links?: { href; [otherOptions: string]: unknown };
    id?: ProcessInstanceId;
    definitionId?: ProcessDefinitionId;
    businessKey: ApplicationId;
    ended?: boolean;
    suspended?: false;
    tenantId?: string;
    multiApplication: boolean;
}

/*
assignee: null
camundaFormRef: null
caseDefinitionId: null
caseExecutionId: null
caseInstanceId: null
created: "2022-08-05T08:04:04.736+0000"
delegationState: null
description: null
due: null
executionId: "2c7b3d71-1495-11ed-bc90-0242ac140002"
followUp: null
formKey: null
id: "2c7c27d3-1495-11ed-bc90-0242ac140002"
name: "Vytvoření žádosti"
owner: null
parentTaskId: null
priority: 50
processDefinitionId: "mp-application:1:d20e17aa-143e-11ed-bc90-0242ac140002"
processInstanceId: "2ba9205d-1495-11ed-bc90-0242ac140002"
suspended: false
taskDefinitionKey: "Activity_0k19to5"
tenantId: null
*/

//TODO move in separate definition
export interface ApplicationTask {
    id: TaskId,
    name: string,
    owner?: string,
    assignee?: string,
    processInstanceId: ProcessInstanceId,
    processDefinitionId: ProcessDefinitionId,
    taskDefinitionKey: TaskDefinitionKey
}

export const TASK_DEFS_USE_CONFIRM_LEAVE = [
    'applicationCreate'
]

export const EDITABLE_TASK_DEFS = [
    'applicationCreate',
    'applicationEdit',
    'payout-error'
]

export const TASK_DEF_APP_EDIT = 'applicationEdit'

export const APPROVE_TASK_DEFS = [
    'approve_application_task'
]

export const REQUIRED_COMMENT_ESCALATION_CODES = [
    "application-return"
]

export interface TaskEscalation {
    extensionElements: {
        actionLabel?: string,
        actionDescription?: string
    },
    escalation: {
        boundaryName: string,
        escalationId: string,
        escalationCode: string,
        escalationName: string
    },
    documentation: string //TODO use
}

export type DiagramData = {
    id: string,
    bpmn20Xml: string
}

export type IdentityLink = {
    userId: string,
    groupId: string,
    type: "string"
}

export type RequiredCommentData = {
    taskDefName: string,
    escalationCode: string
}
const REQUIRED_COMMENTS_ESCALATIONS: RequiredCommentData[] = [
    {
        taskDefName: 'Activity_userTask_schvaleniZadosti',
        escalationCode: 'application-return',
    },
    {
        taskDefName: 'Activity_userTask_schvaleniZadosti',
        escalationCode: 'application-disapproved',
    },
    {
        taskDefName: 'UserTask_ZajisteniVraceniVyplaceneProvize',
        escalationCode: null,
    },
    { taskDefName: 'payout-error', escalationCode: null },
];
export const isCommentRequired = (input: RequiredCommentData): boolean => {
    if (!input) return false
    return !!REQUIRED_COMMENTS_ESCALATIONS.find(el => el.escalationCode === input.escalationCode &&
                                                      el.taskDefName === input.taskDefName)
}

export const TASK_DEF_NOT_USE_COMMENT = ["applicationCreate"]