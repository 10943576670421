import {AsyncThunkAction} from "@reduxjs/toolkit";
import {useEffect, useRef, useState} from "react"
import {useAppDispatch} from '../../../store/store';

export type UseLazyLoadingInput<PARAM, RETURN> = {
    param?: PARAM,
    noParam?: boolean,
    actionFn: (PARAM) => AsyncThunkAction<RETURN, PARAM, any>,
    cancel?: boolean,
    errFn?: (err) => void
}
export type UseLazyLoadingResult = {
    refresh: () => void
}

export function useLazyLoading<PARAM, RETURN>(input: UseLazyLoadingInput<PARAM, RETURN>): UseLazyLoadingResult {
    const pending = useRef(null)
    const dispatch = useAppDispatch()
    const [refresh, setRefresh] = useState(false)

    useEffect(() => {
        if (!input.cancel && ((input.noParam && !pending.current)
            || (input.param && pending.current !== input.param))) {
            pending.current = input.noParam ? true : input.param
            const result = dispatch(input.actionFn(input.param))
            if (input.errFn)
                result.catch(input.errFn)
        }
    }, [input, dispatch, refresh])

    return {
        refresh: () => {
            pending.current = null
            setRefresh(r => !r)
        }
    }
}