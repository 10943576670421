import { hasIntersection } from '../../utils/utils';

export type UserId = string;

export interface CamundaUser {
    id: UserId;
    firstName: string;
    lastName: string;
    label?: string;
    email: string;
}

export interface User {
    login: UserId;
    name: string;
    token?: string;
    roles: UserRole[];
}

export enum UserRole {
    // KOOP
    // uzivatelaplikace = 'uzivatelaplikace',
    administrator = 'administrator',
    spravce_et = 'spravce_et',
    ctenar_vse_et = 'ctenar_vse_et',
    ctenar_dmp_et = 'ctenar_dmp_et',
    ctenar_usp_et = 'ctenar_usp_et',
    ctenar_stz_et = 'ctenar_stz_et',
    ctenar_usp_oj_et = 'ctenar_usp_oj_et',
    ctenar_stz_oj_et = 'ctenar_stz_oj_et',
    spravce = 'spravce',
    spravcevyplaty = 'spravcevyplaty',
    ctenar_vse = 'ctenar_vse',
    zadavatel_dmp = 'zadavatel_dmp',
    zadavatel_mop = 'zadavatel_mop',
    zadavatel_bon = 'zadavatel_bon',
    zadavatel_stz = 'zadavatel_stz',
    zadavatel_odm = 'zadavatel_odm',
    zadavatel_dmm = 'zadavatel_dmm',
    zadavatel_usp = 'zadavatel_usp',
    zadavatel_ost = 'zadavatel_ost',
    ctenar_dmp = 'ctenar_dmp',
    ctenar_mop = 'ctenar_mop',
    ctenar_bon = 'ctenar_bon',
    ctenar_stz = 'ctenar_stz',
    ctenar_odm = 'ctenar_odm',
    ctenar_dmm = 'ctenar_dmm',
    ctenar_usp = 'ctenar_usp',
    ctenar_ost = 'ctenar_ost',
    ctenar_dmp_oj = 'ctenar_dmp_oj',
    ctenar_mop_oj = 'ctenar_mop_oj',
    ctenar_bon_oj = 'ctenar_bon_oj',
    ctenar_odm_oj = 'ctenar_odm_oj',
    ctenar_dmm_oj = 'ctenar_dmm_oj',
    ctenar_usp_oj = 'ctenar_usp_oj',
    ctenar_ost_oj = 'ctenar_ost_oj',
    ctenar_stz_oj = 'ctenar_stz_oj',
    schvalovatel_upmv = 'schvalovatel_upmv',
    schvalovatel_upmo = 'schvalovatel_upmo',
    schvalovatel_uphr = 'schvalovatel_uphr',
    schvalovatel_upo = 'schvalovatel_upo',
    schvalovatel_urio = 'schvalovatel_urio',
    schvalovatel_ureo = 'schvalovatel_ureo',
    schvalovatel_uban = 'schvalovatel_uban',
    schvalovatel_udio = 'schvalovatel_udio',
    schvalovatel_uppo = 'schvalovatel_uppo',
    schvalovatel_oco = 'schvalovatel_oco',
    schvalovatel_predstobch = 'schvalovatel_predstobch',
    schvalovatel_vedouci_oco = 'schvalovatel_vedouci_oco',
    spravcevyplaty_saphr = 'spravcevyplaty_saphr',
    schvalovatel_age_pha = 'schvalovatel_age_pha',
    schvalovatel_age_jcv = 'schvalovatel_age_jcv',
    schvalovatel_age_zac = 'schvalovatel_age_zac',
    schvalovatel_age_sec = 'schvalovatel_age_sec',
    schvalovatel_age_vyc = 'schvalovatel_age_vyc',
    schvalovatel_age_stc = 'schvalovatel_age_stc',
    schvalovatel_age_jim = 'schvalovatel_age_jim',
    schvalovatel_age_sem = 'schvalovatel_age_sem',
    schvalovatel_stmreg = 'schvalovatel_stmreg',
    ctenar_stmreg_vse = 'ctenar_stmreg_vse',
    schvalovatel_stmgr_upo = 'schvalovatel_stmgr_upo',
    schvalovatel_stmgr_uo = 'schvalovatel_stmgr_uo',

    zadavatel_hromzad = 'zadavatel_hromzad',
    // CPP
    ZADAVATEL_PK = 'zadavatel_pk',
    CTENAR_PK = 'ctenar_pk',
    Schvalovatel_STM_REG = 'schvalovatel_stm_reg',
    CTENAR_MT = 'ctenar_mt',
    ZADAVATEL_MT = 'zadavatel_mt',
    Schvalovatel_MT_SP = 'schvalovatel_mt_sp',
    ZADAVATEL_SP = 'zadavatel_sp',
    CTENAR_SP = 'ctenar_sp',
    ZADAVATEL_STMGR = 'zadavatel_stmgr',
    CTENAR_STMGR = 'ctenar_stmgr',
    ZADAVATEL_STMREG = 'zadavatel_stmreg',
    CTENAR_STMREG = 'ctenar_stmreg',
    Schvalovatel_1_PK = 'schvalovatel_1_pk',
    SPRAVCEVYPLATY = 'spravcevyplaty',
    Schvalovatel_2_PK = 'schvalovatel_2_pk',
    ADMINISTRATOR = 'administrator',
    Schvalovatel_3_PK = 'schvalovatel_3_pk',
    UZIVATELAPLIKACE = 'uzivatelaplikace',
    SCHVALOVATEL_STMGR_UIO = 'Schvalovatel_stmgr_uio',
    SCHVALOVATEL_STMGR_UPR = 'schvalovatel_stmgr_upr',
    SCHVALOVATEL_STMGR_UES = 'schvalovatel_stmgr_ues',
}

export const ET_ROLES = [
    UserRole.administrator,
    UserRole.spravce_et,
    UserRole.ctenar_vse_et,
    UserRole.ctenar_dmp_et,
    UserRole.ctenar_usp_et,
    UserRole.ctenar_stz_et,
    UserRole.ctenar_usp_oj_et,
    UserRole.ctenar_stz_oj_et,
];

export const MP_APPLICATION_ROLES = [
    UserRole.administrator,
    UserRole.spravce,
    UserRole.zadavatel_hromzad,
    UserRole.zadavatel_dmp,
    UserRole.zadavatel_mop,
    UserRole.zadavatel_bon,
    UserRole.zadavatel_stz,
    UserRole.zadavatel_odm,
    UserRole.zadavatel_dmm,
    UserRole.zadavatel_usp,
    UserRole.zadavatel_ost,
    UserRole.ctenar_vse,
    UserRole.ctenar_dmp,
    UserRole.ctenar_mop,
    UserRole.ctenar_bon,
    UserRole.ctenar_stz,
    UserRole.ctenar_odm,
    UserRole.ctenar_dmm,
    UserRole.ctenar_usp,
    UserRole.ctenar_ost,
    UserRole.ctenar_dmp_oj,
    UserRole.ctenar_mop_oj,
    UserRole.ctenar_bon_oj,
    UserRole.ctenar_odm_oj,
    UserRole.ctenar_dmm_oj,
    UserRole.ctenar_usp_oj,
    UserRole.ctenar_ost_oj,
    UserRole.ctenar_stz_oj,
    UserRole.schvalovatel_oco,
    UserRole.schvalovatel_uppo,
    UserRole.schvalovatel_predstobch,
    UserRole.schvalovatel_uban,
    UserRole.schvalovatel_udio,
    UserRole.schvalovatel_uphr,
    UserRole.schvalovatel_upmo,
    UserRole.schvalovatel_upmv,
    UserRole.schvalovatel_upo,
    UserRole.schvalovatel_urio,
    UserRole.schvalovatel_ureo,
    UserRole.schvalovatel_vedouci_oco,
    UserRole.schvalovatel_age_pha,
    UserRole.schvalovatel_age_jcv,
    UserRole.schvalovatel_age_zac,
    UserRole.schvalovatel_age_sec,
    UserRole.schvalovatel_age_vyc,
    UserRole.schvalovatel_age_stc,
    UserRole.schvalovatel_age_jim,
    UserRole.schvalovatel_age_sem,
    UserRole.schvalovatel_stmreg,
    UserRole.ctenar_stmreg_vse,
    UserRole.schvalovatel_stmgr_upo,
    UserRole.schvalovatel_stmgr_uo,

    UserRole.ZADAVATEL_PK,
    UserRole.CTENAR_PK,
    UserRole.Schvalovatel_STM_REG,
    UserRole.CTENAR_MT,
    UserRole.ZADAVATEL_MT,
    UserRole.Schvalovatel_MT_SP,
    UserRole.ZADAVATEL_SP,
    UserRole.CTENAR_SP,
    UserRole.ZADAVATEL_STMGR,
    UserRole.CTENAR_STMGR,
    UserRole.ZADAVATEL_STMREG,
    UserRole.CTENAR_STMREG,
    UserRole.Schvalovatel_1_PK,
    UserRole.Schvalovatel_2_PK,
    UserRole.ADMINISTRATOR,
    UserRole.Schvalovatel_3_PK,
    UserRole.SCHVALOVATEL_STMGR_UIO,
    UserRole.SCHVALOVATEL_STMGR_UPR,
    UserRole.SCHVALOVATEL_STMGR_UES,
];

export const APPLICANT_ROLES = [
    UserRole.administrator,
    UserRole.spravce,
    UserRole.zadavatel_dmp,
    UserRole.zadavatel_mop,
    UserRole.zadavatel_bon,
    UserRole.zadavatel_stz,
    UserRole.zadavatel_odm,
    UserRole.zadavatel_dmm,
    UserRole.zadavatel_usp,
    UserRole.zadavatel_ost,
    UserRole.zadavatel_hromzad,
    UserRole.ZADAVATEL_PK,
    UserRole.ZADAVATEL_MT,
    UserRole.ZADAVATEL_SP,
    UserRole.ZADAVATEL_STMGR,
    UserRole.ZADAVATEL_STMREG,
];
export const ADMINISTRATION_ROLES = [UserRole.administrator, UserRole.spravce];
export const PAYOUTS_LIST_ROLES = [
    UserRole.administrator,
    UserRole.spravce,
    UserRole.spravcevyplaty,
    UserRole.spravcevyplaty_saphr,
];

export const isManaging = (user) => user.roles.map(r => r.toLowerCase()).includes(UserRole.spravce)
export const seeAdministrationMp = (user) =>
    hasIntersection(user.roles, [UserRole.spravce, UserRole.administrator, UserRole.spravce, UserRole.ADMINISTRATOR]);
export const isAdmin = (user) =>
    user.roles.map((r) => r.toLowerCase()).includes(UserRole.administrator || UserRole.ADMINISTRATOR);

export const isAdminOrCaretaker = (user) =>
    hasIntersection(user.roles, [UserRole.spravce, UserRole.administrator, UserRole.spravce, UserRole.ADMINISTRATOR]);

export const seesPayoutErrorOnApplications = (user) => {
    return (
        user.roles.includes(UserRole.administrator) ||
        user.roles.includes(UserRole.spravce) ||
        user.roles.includes(UserRole.SPRAVCEVYPLATY)
    );
};

export type UserProfile = {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
};
