import {Badge, Col, ColProps} from "react-bootstrap";
import {Variant} from "react-bootstrap/esm/types";
import {Link} from 'react-router-dom';

export type TextLabelProps = {
    title: string,
    value: string | number,
    colProps?: ColProps,
    to?: string,
    bg?: Variant
}

export function TextItem({title, value, to, colProps = {}}: TextLabelProps) {
    const valueEl = to ?
        <Link to={to}>
            <strong className="d-block">{value || '--'}</strong>
        </Link> : <strong className="d-block">{value || '--'}</strong>
    return (
        <Col {...colProps}>
            <>
                <small className="d-block">{title}</small>
                {valueEl}
            </>
        </Col>
    );
}

export function BadgeItem({title, value, bg}: TextLabelProps) {
    return (
        <Col>
            <small className="d-block">{title}</small>
            <Badge pill bg={bg}>{value || '--'}</Badge>
        </Col>
    );
}