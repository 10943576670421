import {MyButton} from "../../../components/button/MyButton";
import clsx from "clsx";
import {XLg} from "react-bootstrap-icons";
import {useCallback} from "react";
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {clearTitleRegisterFilter} from "../../../store/slices/titleRegister/titleRegisterFilterSlice";
import {titleRegisterListFetch} from "../../../store/slices/titleRegister/titleRegisterSlice";

export type TitleRegisterFilterButtonProps = {
    setShowTitleFilterModal: (p: boolean) => void
}

const TitleRegisterFilterButton = ({setShowTitleFilterModal}: TitleRegisterFilterButtonProps) => {
    const selectedTab = useAppSelector(state => state.titleRegister.selectedTitleRegister)
    const {currentFiltersTypeSet} = useAppSelector(state => state.titleRegisterFilter)
    const dispatch = useAppDispatch()

    const checkCurrentFilterSet = useCallback(() => {
        return currentFiltersTypeSet.includes(selectedTab)
    }, [selectedTab, currentFiltersTypeSet])

    const handleClearFilter = () => {
        dispatch(clearTitleRegisterFilter(selectedTab))
        dispatch(titleRegisterListFetch({type: selectedTab}))
    }

    return <div className="d-flex justify-content-end">
        <MyButton className={clsx('flex-shrink-0 rounded-0', !checkCurrentFilterSet() && 'rounded-1')}
                  tooltip={`Vlastní filtr: ${!checkCurrentFilterSet() ? 'PRÁZDNÝ' : 'NASTAVEN'}`}
                  variant="outline-primary"
                  onClick={() => setShowTitleFilterModal(true)}>
            Vlastní filtr {checkCurrentFilterSet() && '*'}
        </MyButton>
        {
            checkCurrentFilterSet() &&
            <MyButton className="rounded-0 rounded-end"
                      tooltip='Vyčistit vlastní filtr'
                      variant="outline-warning"
                      onClick={handleClearFilter}>
                <XLg/>
            </MyButton>
        }
    </div>
}

export default TitleRegisterFilterButton