import './AppOverview.scss';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { LoadingIndicator } from '../../../components/loadingIndicator/LoadingIndicator';
import {
    appPayoutStateBg,
    appStateBg,
    formatAmount,
    formatAppPayoutState,
    formatAppState,
    formatDate
} from '../../../utils/formatUtils';
import clsx from 'clsx';
import { ApplicationWrapper } from '../../../services/entities/Application';
import { ApplicationTask } from '../../../services/entities/ApplicationProcess';
import { BadgeItem, TextItem } from '../../../components/textItem/TextItem';
import { NAV } from '../../../RouteList';
import { APP_TEXTS } from '../../../constants/texts';
import { ApplicationWrapperLite }
    from '../../../services/entities/PayoutDetailWrapper';

type AppOverviewProps = {
    className: string;
    readonly?: boolean;
    pending?: boolean;
    useLink?: boolean;
    application?: ApplicationWrapper | ApplicationWrapperLite;
    title?: string;
    task?: ApplicationTask;
};
function AppOverview({
    className,
    readonly,
    pending,
    application,
    task,
    title,
    useLink,
}: AppOverviewProps) {
    const app = application?.application;
    const multiApplication = app?.multiApplication;

return (
    <Container
        fluid="xxl"
        className={clsx('AppOverview position-relative', className)}
    >
        {/*{(application.pending || task.pending) && <LoadingIndicator/>}*/}

        {pending && <LoadingIndicator />}
        {application && (
            <>
                <Row>
                    {!readonly && (
                        <Col>
                            <h5>{title || task?.name || ''}</h5>
                        </Col>
                    )}
                    {!task?.name && title === undefined && (
                        <Col>
                            <h5>{''}</h5>
                        </Col>
                    )}
                    <TextItem
                        title="Číslo žádosti"
                        value={app?.number}
                        to={
                            app?.number &&
                            useLink &&
                            NAV.APPLICATION_DETAIL(app.id)
                        }
                    />
                    <TextItem title="Zadavatel" value={app?.creatorName} />
                    <TextItem
                        title={APP_TEXTS.recipientName}
                        value={app?.recipientName}
                    />
                    <BadgeItem
                        title="Stav žádosti"
                        value={formatAppState(app?.state)}
                        bg={appStateBg(app?.state)}
                    />

                    {!task?.name && title === undefined && <Col></Col>}
                </Row>
                <Row>
                    <Col>
                        <Col>
                            <small className="d-block">Typ žádosti</small>
                        </Col>
                        <Col>
                            <>
                                <strong className="d-block text-danger">
                                    {multiApplication
                                        ? 'Hromadná'
                                        : 'Individuální'}
                                </strong>
                            </>
                        </Col>
                    </Col>
                    {/* <Col></Col> */}
                    {readonly && task?.name && <Col></Col>}

                    <TextItem
                        title="Datum vytvoření"
                        value={formatDate(app?.created)}
                    />
                    <TextItem title="DB ID žádosti" value={app?.id} />
                    <TextItem
                        title={APP_TEXTS.realPayoutSum}
                        value={formatAmount(application.realPayoutSum)}
                    />
                    <BadgeItem
                        title="Stav výplat žádosti"
                        value={formatAppPayoutState(app?.payoutState)}
                        bg={appPayoutStateBg(app?.payoutState)}
                    />
                    {readonly && <Col></Col>}
                </Row>
            </>
        )}
    </Container>
);
}

export default AppOverview;
