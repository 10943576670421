import React from 'react';
import './TableAction.scss'
import clsx from 'clsx';
import WithTooltip from '../withPopover/WithTooltip';

type TableActionProps = {
    tooltip?: string,
    children: React.ReactNode | React.ReactNode[],
    onClick?: React.MouseEventHandler<HTMLDivElement>,
    large?: boolean,
    second?: boolean,
    innerProps?: object,
    disabled?: boolean
}

function TableAction(props: TableActionProps) {

    const {
        innerProps = {}, onClick, tooltip,
        children, large, second,
        disabled
    } = props

    if (disabled) {
        return (
            <div {...innerProps} className={clsx("d-flex align-items-center", innerProps['className'])}>
                {children}
            </div>
        )
    }

    const main = <div role="button"
                      onClick={onClick}
                      {...innerProps}
                      className={clsx(
                          "TableAction btn-link text-decoration-none d-flex align-items-center p-0",
                          large && 'btn',
                          second && 'ms-2',
                          innerProps['className'])}>
        {children}
    </div>

    return tooltip ? <WithTooltip tooltip={props.tooltip}>{main}</WithTooltip> : main
}

export default TableAction;