import DataTable, {IDataTableProps} from 'react-data-table-component';
import {TEXTS} from '../../constants/texts';

export type MyTableProps<DATA> = IDataTableProps<DATA>

export function MyTable<DATA>(props: MyTableProps<DATA>) {

    const paginationComponentOptions = {
        rowsPerPageText: 'Řádků na stránku',
        rangeSeparatorText: 'do'
    };

    return (
        <DataTable {...props}
                   progressComponent={<p>{TEXTS.loadingData}</p>}
                   paginationComponentOptions={paginationComponentOptions}
                   noDataComponent="Žádné záznamy" />
    )
}