import React from 'react';
import './AppComments.scss'
import {RootState, useAppSelector} from '../../../../store/store';
import CommentsTable from '../CommentsTable';

function AppComments() {
    const comments = useAppSelector((s: RootState) => s.applicationDetail.comments)
    const camundaUsersMap = useAppSelector(
        (s: RootState) => s.applicationDetail.camundaUsers.map
    );
    return (
        <CommentsTable comments={comments} camundaUsersMap={camundaUsersMap} />
    )
}

export default AppComments;
