import {createSlice} from "@reduxjs/toolkit";
import {initialState} from "../../../pages/TitleRegister/customFilter/TitleRegisterFilterForm";
import {initialValues, TitleRegisterFilter, TitleStructureEnum} from "../../../services/entities/TitleRegister";

export type TitleRegisterFilterState = {
    filterData: {
        [TitleStructureEnum.DMP]: TitleRegisterFilter,
        [TitleStructureEnum.USP]: TitleRegisterFilter
        [TitleStructureEnum.STZ]: TitleRegisterFilter
    }
    currentFiltersTypeSet: Array<string>
    clean: boolean
}

const titleRegisterFilterSlice = createSlice({
    name: 'titleRegisterFilter',
    initialState,
    reducers: {
        'setTitleRegisterFilter': (state, action) => {
            state.filterData[action.payload.titleStructureType] = action.payload.data
            state.currentFiltersTypeSet.push(action.payload.titleStructureType)
            state.clean = false
        },
        'clearTitleRegisterFilter': (state, action) => {
            state.filterData[action.payload] = initialValues[action.payload]
            state.currentFiltersTypeSet = state.currentFiltersTypeSet.filter((e) => e !== action.payload)
            state.clean = true
        }
    }
})

export const {
    setTitleRegisterFilter,
    clearTitleRegisterFilter
} = titleRegisterFilterSlice.actions
export default titleRegisterFilterSlice.reducer