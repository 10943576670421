import React from 'react';
import {MyTable} from '../../../components/table/MyTable';
import {TableColumn} from 'react-data-table-component';
import {ApplicationLog} from '../../../services/entities/ApplicationLog';
import {formatCamundaUserId, formatDateTime} from '../../../utils/formatUtils';
import {RootState, useAppSelector} from '../../../store/store';

function ApplicationLogs() {
    const logsData = useAppSelector((s: RootState) => s.applicationDetail.logs)
    const userMap = useAppSelector((s: RootState) => s.applicationDetail.camundaUsers.map)

    const columns: TableColumn<ApplicationLog>[] = [
        {
            name: 'Čas',
            selector: row => row.created?.toString(),
            format: row => row.created ? formatDateTime(row.created) : '--',
            sortable: true
        },
        {
            name: 'Jméno',
            selector: row => row.username?.toString(),
            format: row => formatCamundaUserId(row.username, userMap),
            sortable: true
        },
        {
            name: 'Popis',
            selector: row => row.description,
            sortable: true
        }
    ]

    return (
        <div>
            <MyTable columns={columns}
                     data={logsData.data}
                     progressPending={logsData.pending}
                     defaultSortFieldId="created"
                     pagination />
        </div>
    )
}

export default ApplicationLogs;
