import React, {createContext, useEffect, useMemo, useRef} from "react"
import {useNavigate} from "react-router-dom";
import {
    addTokenErrorListener,
    extractUsername,
    fetchTokensAndLogin,
    getIdToken,
    getInfoFromUrl,
    hasStoredTokens,
    logout,
    removeTokens,
    TokenError
} from './authProviderUtils';
import {RootState, useAppDispatch, useAppSelector} from '../../../store/store';
import {clearUser, setTokenError, setUserAndLoadData} from '../../../store/slices/common/userSlice';
import {NAV} from "../../../RouteList";
import {LoadingIndicator} from '../../../components/loadingIndicator/LoadingIndicator';
import {addNotification} from '../../../store/slices/common/notificationsSlice';

export type AuthContextValue = {
    logout: () => void
}
export const AuthContext = createContext<AuthContextValue>(null)

export type AuthProviderProps = {
    children: React.ReactNode
}

//https://www.npmjs.com/package/@idport/oidc-rp-sdk
export function AuthProvider({children}: AuthProviderProps) {
    const navigate = useNavigate()

    const dispatch = useAppDispatch()
    const user = useAppSelector((s: RootState) => s.user)
    const pending = useRef(false)

    useEffect(() => {
        addTokenErrorListener('AuthProvider', (err: TokenError) => {
            dispatch(setTokenError(err))
            removeTokens()
        })
    })

    //decision-making
    useEffect(() => {
        const [hash, codeFromParam, statePathname] = getInfoFromUrl()

        //if error occurs and I am not already on login
        if (user.tokenError && !window.location.pathname.startsWith(NAV.LOGIN_START)) {
            removeTokens()
            navigate(NAV.LOGIN(window.location.pathname))
            return
        }

        //has code in url - returned from idport
        if (codeFromParam && !user.login && !pending.current) {
            pending.current = true
            fetchTokensAndLogin(hash).then(login => {
                dispatch(setUserAndLoadData({
                    login: login
                })).then(() => {
                    navigate(statePathname || '/')
                })
                pending.current = false
            }, () => {
                pending.current = false
            })
            return
        }

        //opened from scratch with refresh token possibly
        if (!pending.current && !user.login && hasStoredTokens()) {
            pending.current = true
            getIdToken().then(at => {
                if (at) {
                    dispatch(setUserAndLoadData({
                        login: extractUsername(at)
                    }))
                }
                pending.current = false
            })
            return
        }


        if (!pending.current && !window.location.pathname.startsWith(NAV.LOGIN_START) && !user.login) {
            navigate(NAV.LOGIN(window.location.pathname))
            return
        }

    }, [dispatch, navigate, user])

    const value: AuthContextValue = useMemo(() => ({
        logout: () => {
            removeTokens()
            dispatch(setTokenError(null))
            dispatch(clearUser())
            if (!user.mocked) {
                logout(window.location.pathname).then(() => dispatch(addNotification({
                    text: 'Odhlášení proběhlo úspěšně.'
                })))
            }
            navigate(NAV.LOGIN('/'))
        }
    }), [user, navigate, dispatch])


    return (
        <AuthContext.Provider value={value}>
            {
                user.pendingData ? <LoadingIndicator/> : null
            }

            {
                ((user.login && !user.tokenError)
                  || window.location.pathname.startsWith(NAV.LOGIN_START))
                    ? children : null
            }
        </AuthContext.Provider>
    )
}

export default AuthProvider