import './Notifications.scss'
import React from 'react'
import {Alert} from 'react-bootstrap'
import {RootState, useAppDispatch, useAppSelector} from '../../../store/store';
import {clearNotification} from '../../../store/slices/common/notificationsSlice';


function Notifications() {

    const dispatch = useAppDispatch()
    const notifications = useAppSelector((s: RootState) => s.notifications.notifications)

    const handleClose = (id: number) => {
        dispatch(clearNotification(id))
    }

    return (
        <div className="Notifications position-fixed end-0 top-0 p-3 mw-50">
            {
                notifications.map(n => (
                    <Alert className="text-wrap"
                           key={n.id}
                           variant={n.variant} onClose={() => handleClose(n.id)} dismissible>
                        {n.text.split('\n').map((line, i) => <div key={i}>{line}</div>)}
                    </Alert>
                ))
            }
        </div>
    )
}

export default Notifications;
