import React, {useEffect} from 'react';
import {RootState, useAppDispatch, useAppSelector} from '../../../../store/store';
import ProcessHistoryTable from '../ProcessHistoryTable';
import {Container, Row, Tab} from 'react-bootstrap';
import PayoutComments from '../../comments/payouts/PayoutComments';
import MyTabContainer from '../../../../components/tabContainer/MyTabContainer';
import {TextItem} from '../../../../components/textItem/TextItem';
import {payoutHistoryFetch} from '../../../../store/slices/payoutDetail/payoutDetailSlice';
import {ApplicationId} from "../../../../services/entities/Application";
import {PayoutId} from "../../../../services/entities/Payout";

type PayoutHistoryAndCommentsProps = {
    applicationId: ApplicationId,
    payoutId: PayoutId,
    disabled: boolean
}
function PayoutHistoryAndComments({applicationId, payoutId, disabled}: PayoutHistoryAndCommentsProps) {
    const dispatch = useAppDispatch()
    const selectedPayout = useAppSelector((s: RootState) => s.payouDetail.payoutDetailData?.data?.payoutWrapperLite?.payoutLite)
    const historyData = useAppSelector((s: RootState) => s.payouDetail.history)
    const camundaUsersMap = useAppSelector((s: RootState) => s.applicationDetail.camundaUsers.map)

    useEffect(() => {
        if (!disabled && applicationId && payoutId)
            dispatch(payoutHistoryFetch({payoutId: payoutId, applicationId: applicationId}))
    }, [applicationId, dispatch, payoutId, disabled])

    return (
        <div className="position-relative">
            <MyTabContainer
                items={[
                    {label: "Komentáře"},
                    {label: "Historie"}
                ]}
                className="mb-3">
                <Tab.Pane eventKey="Komentáře" title="Komentáře">
                    <PayoutComments disabled={disabled} payoutId={payoutId} payout={selectedPayout} applicationId={applicationId} />
                </Tab.Pane>
                {
                    <Tab.Pane eventKey="Historie" title="Historie">
                        <Container fluid="xxl" className="mb-3">
                                <Row>
                                    <TextItem title="ID procesní instance" value={selectedPayout?.payoutProcessInstanceId?.toString()} />
                                </Row>
                        </Container>
                        <ProcessHistoryTable data={historyData.data} pending={historyData.pending} camundaUsersMap={camundaUsersMap} />
                    </Tab.Pane>
                }
            </MyTabContainer>
        </div>
    )
}

export default PayoutHistoryAndComments;
