import clsx from 'clsx';
import React from 'react';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Placement } from 'react-bootstrap/esm/types';

type WithTooltipProps = {
    tooltip: string,
    inline?: boolean,
    divProps?: any,
    placement?: Placement,
    children: React.ReactNode
}

function WithTooltip({children, placement, tooltip, divProps, inline}: WithTooltipProps) {
    return <OverlayTrigger placement={placement} overlay={<Tooltip>{tooltip}</Tooltip>}>
        <div {...divProps} className={clsx(divProps?.className, inline && 'd-inline-block')}>
            {children}
        </div>
    </OverlayTrigger>
}

export default WithTooltip;