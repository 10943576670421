import {DATA_API_URL} from '../constants';
import { fetchMethods } from '../fetchUtils';

export type FetchTitleRegisterImportType = {
    titleStructureType: string;
    status: string;
    message: string;
    rows: Array<any>;
    numberOfRowsInDB: number;
    numberOfRowsInFile: number;
    referenceUUID: string
};

export type DataImportType = {
    file: File;
};

export type DataImportConfirmType = {
    referenceUUID: string
}

export const fetchTitleRegisterImport = (
    data: DataImportType,
    token: string
): Promise<FetchTitleRegisterImportType> => {
    const url = `${DATA_API_URL}/titles/import`;
    const formData = new FormData();
    formData.append('file', data.file[0]);
    return fetchMethods.post(url, formData, token, true, true, {
        Headers: {'Content-type': 'multipart/form-data'},
    });
};

export const fetchTitleRegisterImportConfirm = (
    data: DataImportConfirmType,
    token: string
): Promise<FetchTitleRegisterImportType> => {
    const url = `${DATA_API_URL}/titles/confirm`;
    return fetchMethods.post(url, data, token);
};
