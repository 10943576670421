import {AuthToken} from "./applicationApi";
import {PageTitleType, TitleRegisterFilter, TitleRegisterTitleFilter} from "../entities/TitleRegister";
import { fetchMethods } from '../fetchUtils';
import {DATA_API_URL} from '../constants';

export const fetchTitleRegisterList = (data: TitleRegisterFilter, token: AuthToken): Promise<PageTitleType> => {
    const url = `${DATA_API_URL}/titles/`
    return fetchMethods.post(url, data, token)
}

export const fetchTitleRegisterListFromTitleFilter = (
    data: TitleRegisterTitleFilter, token: AuthToken): Promise<PageTitleType> => {
const url = `${DATA_API_URL}/titles/mp`
    return fetchMethods.post(url, data, token)
}