import { FetchTitleRegisterImportType } from '../services/api/titleRegisterImportApi';

export default function parseErrors(
    data: FetchTitleRegisterImportType
): Array<string> {
    let errors = [];
    if (!data.rows || !data.rows.length || data.message) {
        return [data.message];
    } else {
        data.rows.forEach((r) => {
            r.errors.forEach((e: any) => {
                errors.push(`${e.message} Řádek: ${r.rowNumber}`);
            });
        });
    }

    return errors;
}
