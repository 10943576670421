import {ProcessDefinitionId, ProcessInstanceId} from './ApplicationProcess';

export type ActivityType = "userTask" | string
export interface ProcessHistory {
    "id": string,
    "parentActivityInstanceId": string,
    "activityId": string,
    "activityName": string,
    "activityType": ActivityType,
    "processDefinitionKey": string,
    "processDefinitionId": ProcessDefinitionId,
    "processInstanceId": ProcessInstanceId,
    "executionId": string,
    "taskId": string,
    "calledProcessInstanceId": ProcessInstanceId,
    "calledCaseInstanceId": string,
    "assignee": string,
    "startTime": Date,
    "endTime": Date,
    "durationInMillis": number,
    "canceled": boolean,
    "completeScope": boolean,
    "tenantId": string,
    "removalTime": null,
    "rootProcessInstanceId": ProcessInstanceId
}


export const USER_TASK_ACTIVITY_TYPE: ActivityType = "userTask"