import React from "react"
import {Form, Modal} from "react-bootstrap";
import {User} from '../../../services/entities/User';
import {LoadingIndicator} from '../../../components/loadingIndicator/LoadingIndicator';

const DEV_USERS = [] //TODO mock in database or on server
export type MockAuthProviderModalProps = {
    show: boolean,
    onHide: () => void,
    pending?: boolean,
    onMockLogin: (user: User) => void
}
export function MockAuthProviderModal({show, onHide, pending, onMockLogin}: MockAuthProviderModalProps) {

    const handleSelectUser = (event) => {
        const login = event.target.value
        const user = DEV_USERS.find(el => el.login === login)
        onMockLogin(user)
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title as="h5">Mock přihlášení</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                {
                    pending && <LoadingIndicator />
                }

                <Form.Group>
                    <Form.Label>Vybrat testovacího uživatele</Form.Label>
                    <Form.Select className="small-input" onChange={handleSelectUser}>
                        <option key="vybrat" value="">Vybrat</option>
                        {
                            DEV_USERS.map(el => (
                                <option key={el.login} value={el.login}
                                        title={`${el.login}: ${el.roles.join(", ")}`}>
                                    {el.name}
                                </option>
                            ))
                        }
                    </Form.Select>
                </Form.Group>
            </Modal.Body>
        </Modal>
    )
}

export default MockAuthProviderModal
