import React, { useContext, useState } from 'react';
import './Payouts.scss';
import { MyButton } from '../../../components/button/MyButton';
import { Alert, Form, FormGroup, Modal } from 'react-bootstrap';
import { TEXTS } from '../../../constants/texts';
import { RequiredLabel } from '../../../components/form/RequiredLabel';
import Confirmation from '../../../components/confirmation/Confirmation';
import { importPayouts } from './importPayouts';
import { addNotification } from '../../../store/slices/common/notificationsSlice';
import {
    putPayouts,
    removeAllPayouts,
} from '../../../store/slices/applicationDetail/payoutsSlice';
import { generateId } from '../../../utils/generateId';
import {
    Application,
    ApplicationTypeEnumKoop,
} from '../../../services/entities/Application';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { separateExtension } from '../../../utils/fileUtils';
import {
    importTitleRegisterListTitleFilterFetch,
    titleRegisterListTitleFilterFetch,
} from '../../../store/slices/applicationDetail/applicationSlice';
import { AppFormContext } from '../application-detail';
import { useWatch } from 'react-hook-form';
import { IS_KOOP } from '../../../services/constants';

export type ImportPayoutsFormProps = {
    open: boolean;
    setOpen: (boolean) => void;
    application: Application;
    fromDate?: Date;
};

function ImportPayoutsForm({
    open,
    fromDate,
    setOpen,
    application,
}: ImportPayoutsFormProps) {
    const [selectedFile, setSelectedFile] = useState(null);
    const [errors, setErrors] = useState([]);
    const dispatch = useAppDispatch();
    const multiApplication = useAppSelector(
        (state) =>
            state?.applicationDetail?.application?.application?.data
                ?.application?.multiApplication
    );
    const { recordCount } = useAppSelector(
        (state) => state.applicationDetail.application.mpTitlesData
    );

    const appFormLogic = useContext(AppFormContext);
    const appType: ApplicationTypeEnumKoop = useWatch({
        name: 'type',
        control: appFormLogic.control,
    }) as ApplicationTypeEnumKoop;
    const handleHide = () => {
        setErrors([]);
        setSelectedFile(null);
        setOpen(false);
    };

    const handleFileChange = (event) => {
        setErrors([]);
        setSelectedFile(event.target.files[0]);
    };

    const handleFileImport = async () => {
        const parts = separateExtension(selectedFile.name);

        if (parts[1] !== 'csv') {
            setErrors(['Importovaný soubor musí mít příponu .csv .']);
            return;
        }
        let titleRegisterDataFilter;
        if (
            IS_KOOP &&
            multiApplication &&
            appType !== ApplicationTypeEnumKoop.OST
        ) {
            await dispatch(
                importTitleRegisterListTitleFilterFetch({
                    titleType: appType,
                    pageSize: recordCount,
                })
            ).then((val: any) => {
                titleRegisterDataFilter = val?.payload?.mpTitles;
            });
        }

        await importPayouts(
            selectedFile,
            application.created,
            tribe,
            selectedType,
            tribesCodes,
            brokerCodes,
            multiApplication,
            titleRegisterDataFilter
        ).then(
            (importResult) => {
                if (importResult.errors.length) {
                    setErrors(importResult.errors);
                    return;
                }

                if (
                    importResult.data.length >=
                    parseInt(process.env.REACT_APP_MAX_PAYOUTS)
                ) {
                    dispatch(
                        addNotification({
                            text: 'S ohledem na vysoké množství importovaných výplat není možné ověřit existenci importovaných čísel získatelů.',
                            variant: 'info',
                            timeout: 10000,
                        })
                    );
                }
                setErrors([]);
                handleHide();

                dispatch(removeAllPayouts());
                dispatch(
                    putPayouts({
                        validityDate: fromDate || application.created,
                        data: importResult.data.map((p) => ({
                            ...p,
                            id: generateId(),
                        })),
                    })
                );
            },

            (importError) => {
                setErrors([importError.message]);
            }
        );
        if (
            IS_KOOP &&
            multiApplication &&
            appType !== ApplicationTypeEnumKoop.OST
        ) {
            await dispatch(
                titleRegisterListTitleFilterFetch({
                    titleType: appType,
                })
            );
        }
    };
    const { selectedType, tribe, brokerCodes } = useAppSelector(
        (state) => state.applicationDetail.application
    );
    const tribesCodes = useAppSelector(
        (state) => state.applicationDetail.payouts.tribes
    );
    return (
        <div className="ImportPayoutForm">
            <Modal show={open} onHide={handleHide} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title as="h5">Import seznamu výplat</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormGroup>
                        <RequiredLabel>CSV soubor seznamu</RequiredLabel>
                        <Form.Control
                            type="file"
                            accept=".csv"
                            onChange={handleFileChange}
                        />
                    </FormGroup>
                </Modal.Body>
                <Modal.Footer className="justify-content-end">
                    {errors.length > 0 && (
                        <Alert variant="danger" className="w-100 p-2">
                            <Alert.Heading>
                                Chyby při načítání/zpracování souboru.
                            </Alert.Heading>
                            <p>
                                <strong>
                                    Dokud nedojde k opravě těchto chyb, tak
                                    nebude umožněn import CSV souboru.
                                </strong>
                            </p>
                            {errors.map((err, i) => (
                                <div key={i} className="mb-1">
                                    {err}
                                </div>
                            ))}
                        </Alert>
                    )}
                    <div className="d-flex">
                        <MyButton
                            variant="secondary"
                            onClick={handleHide}
                            className="me-2"
                        >
                            {TEXTS.close}
                        </MyButton>

                        <Confirmation
                            onConfirm={handleFileImport}
                            message="Import výplat smaže stávající výplaty. Přejete si pokračovat?"
                            title="Upozornění"
                            confirmText="Pokračovat"
                        >
                            <MyButton
                                variant="primary"
                                disabled={!selectedFile}
                            >
                                Import
                            </MyButton>
                        </Confirmation>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ImportPayoutsForm;
