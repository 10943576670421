import clsx from 'clsx';
import React, { useState } from 'react';
import { ButtonGroup, Form } from 'react-bootstrap';
import { ArrowClockwise, XLg } from 'react-bootstrap-icons';
import { MyButton } from '../../../components/button/MyButton';
import WithTooltip from '../../../components/withPopover/WithTooltip';
import { PayoutsListFilterTypeEnum } from '../../../services/entities/PayoutsList';
import { payoutsListExport } from '../../../store/slices/payoutsList/payoutsListSlice';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import PayoutListFilter from '../payoutListFilter/PayoutListFilter';
import {
    clearPayoutsFilter,
    resetPayoutsPagination,
    setBasePayoutsFilter,
} from '../../../store/slices/payoutsList/payoutsListFilterSlice';

const PayoutsTableHeader = () => {
    const dispatch = useAppDispatch();
    const [showFilterModal, setShowFilterModal] = useState(false);
    const { filter, clean } = useAppSelector(
        (state) => state.payoutsListFilter
    );

    const {data, pending} = useAppSelector(s => s.payoutsList)
    const recordCount = data?.recordCount

    const { sortOrder, sortList, baseFilter } = useAppSelector(
        (s) => s.payoutsListFilter
    );

    const getPayoutsFilterText = (type: PayoutsListFilterTypeEnum): string => {
        switch (type) {
            case PayoutsListFilterTypeEnum.ACTION:
                return 'Úkoly';
            case PayoutsListFilterTypeEnum.ALL:
                return 'Všechny výplaty';
        }
    };

    const handleExport = () => {
        dispatch(
            payoutsListExport({
                baseFilter: baseFilter,
                ...filter,
                pageNumber: 0,
                pageSize: recordCount,
                sortOrder: sortOrder,
                sortList: sortList,
            })
        );
    };

    const handleBaseFilterChange = (event) => {
        const value = event.target.value;

        dispatch(setBasePayoutsFilter(value));
    };
    const handleClearFilter = () => {
        dispatch(clearPayoutsFilter());
    };
    return (
        <>
            <PayoutListFilter
                show={showFilterModal}
                onHide={() => setShowFilterModal(false)}
            />

            <div className="align-items-center ps-1">
                {data?.payoutListWrapper?.length > 0 && (
                    <MyButton
                        disabled={pending}
                        className="float-start justify-self-start"
                        tooltip="Exportuje všechny stránky dle nastavých filtrů"
                        onClick={handleExport}
                    >
                        Exportovat
                    </MyButton>
                )}

                <div className="ms-auto d-flex justify-content-end align-items-center">
                    <MyButton
                        variant="outline-primary"
                        noYMargin
                        className="me-3"
                        tooltip="Načíst znovu. Resetuje nastavené stránkování."
                        onClick={() => {
                            dispatch(resetPayoutsPagination());
                        }}
                    >
                        <ArrowClockwise />
                    </MyButton>

                    <WithTooltip tooltip="Přednastavený filtr">
                        <Form.Select
                            data-clear-button="true"
                            className="rounded-0 rounded-start me-3 small-input"
                            onChange={handleBaseFilterChange}
                            value={baseFilter}
                        >
                            {Object.values(PayoutsListFilterTypeEnum).map(
                                (key) => (
                                    <option key={key} value={key}>
                                        {getPayoutsFilterText(key)}
                                    </option>
                                )
                            )}
                        </Form.Select>
                    </WithTooltip>

                    <ButtonGroup>
                        <MyButton
                            className={clsx(
                                'flex-shrink-0 rounded-0',
                                clean && 'rounded-end'
                            )}
                            tooltip={`Vlastní filtr: ${
                                clean ? 'PRÁZDNÝ' : 'NASTAVEN'
                            }`}
                            variant="outline-primary"
                            onClick={() => setShowFilterModal(true)}
                        >
                            Vlastní filtr {!clean && '*'}
                        </MyButton>
                        {!clean && (
                            <MyButton
                                className="rounded-0 rounded-end"
                                tooltip="Vyčistit vlastní filtr"
                                variant="outline-warning"
                                onClick={handleClearFilter}
                            >
                                <XLg />
                            </MyButton>
                        )}
                    </ButtonGroup>
                </div>
            </div>
        </>
    );
};

export default PayoutsTableHeader;
