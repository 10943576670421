import React, { useEffect } from 'react';
import { Search } from 'react-bootstrap-icons';

import { MyTable } from '../../../components/table/MyTable';
import { TEXTS } from '../../../constants/texts';
import { IS_KOOP } from '../../../services/constants';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { formatAmount, formatDate } from '../../../utils/formatUtils';
import PayoutsTableHeader from './PayoutsTableHeader';
import { payoutsListFetch } from '../../../store/slices/payoutsList/payoutsListSlice';
import { ApiData } from '../../../store/storeApiUtils';
import { PagedPayoutsList } from '../../../services/entities/PayoutsList';
import { setPayoutsPagination, setPayoutsSort } from '../../../store/slices/payoutsList/payoutsListFilterSlice';
import { arraysEqual } from '../../../utils/utils';
import { LoadingIndicator } from '../../../components/loadingIndicator/LoadingIndicator';
import TableAction from '../../../components/table/TableAction';
import { Link } from 'react-router-dom';
import { NAV } from '../../../RouteList';
import { MyButton } from '../../../components/button/MyButton';
import { setFromPayout } from '../../../store/slices/payoutDetail/payoutDetailSlice';

const PayoutsTable = () => {
    const dispatch = useAppDispatch();
    const payoutsData: ApiData<PagedPayoutsList> = useAppSelector(
        (state) => state.payoutsList
    );
    const payoutsListFilter = useAppSelector((s) => s.payoutsListFilter);
    const totalRows = payoutsData?.data?.recordCount;
    const pagination = useAppSelector((s) => s.payoutsListFilter.pagination);
    const {sortOrder, sortList} = useAppSelector((s) => s.payoutsListFilter);
    useEffect(() => {
        dispatch(
            payoutsListFetch({
                baseFilter: payoutsListFilter.baseFilter,
                ...payoutsListFilter.filter,
                pageNumber: payoutsListFilter.pagination.page - 1,
                pageSize: payoutsListFilter.pagination.size,
                sortOrder: payoutsListFilter.sortOrder,
                sortList: payoutsListFilter.sortList,
            })
        );
    }, [payoutsListFilter, dispatch]);

    const handleSort = (selectedColumn, sortDirection: 'asc' | 'desc') => {
        if (selectedColumn.id) {
            const sortPayoutsList = [selectedColumn.id];
            const direction = sortDirection.toUpperCase() as 'ASC' | 'DESC';

            if (
                !arraysEqual(sortPayoutsList, sortList) ||
                direction !== sortOrder
            ) {
                dispatch(
                    setPayoutsSort({
                        sortList: [selectedColumn.id],
                        sortOrder: sortDirection.toUpperCase() as
                            | 'ASC'
                            | 'DESC',
                    })
                );
            }
        }
    };

    const handlePageChange = (page) => {
        if (page !== pagination.page)
            dispatch(setPayoutsPagination({size: pagination.size, page}));
    };

    const handlePerRowsChange = async (newPageSize) => {
        if (newPageSize !== pagination.size)
            dispatch(setPayoutsPagination({size: newPageSize, page: 1}));
    };

    const columns = [
        {
            id: 'Detail',
            name: 'Detail',
            width: '45px',
            selector: (row) => (row.tasks ? row.tasks.length : 0),
            cell: (row) =>
                !row.isNew && (
                    <TableAction tooltip="Zobrazit detail výplaty" large>
                        <Link
                            to={NAV.PAYOUT_DETAIL(row.applicationID, row.dbId)}
                        >
                            <Search />
                        </Link>
                    </TableAction>
                ),
        },
        {
            id: 'agreementNumber',
            name: `${IS_KOOP ? 'Titul (číslo smlouvy)' : 'DB ID žádosti'}`,
            selector: (row) =>
                IS_KOOP ? row.agreementNumber : row.applicationID,
        },
        {
            width: '80px',
            id: 'id',
            name: 'DB ID výplaty',
            selector: (row) => row.dbId,
            sortable: true,
        },
        {
            id: 'state',
            name: 'Stav',
            selector: (row) => row.state,
        },
        {
            id: 'recipientId',
            name: 'Získatelské číslo',
            selector: (row) => row.recipientId,
            sortable: true,
        },
        {
            id: 'recipientName',
            name: 'Název získatele',
            selector: (row) => row.recipientName,
            sortable: true,
        },
        {
            id: 'amount',
            name: 'Částka',
            selector: (row) => formatAmount(row.amount),
            sortable: true,
        },
        {
            id: 'productType',
            width: '60px',
            name: 'Kmen',
            selector: (row) => row.productType,
            sortable: true,
        },
        {
            id: 'applicationDate',
            name: 'Období',
            selector: (row) =>
                `${formatDate(row.applicationFromDate)} - ${formatDate(
                    row.applicationTomDate
                )}`,
            format: (row) =>
                `${
                    row.applicationFromDate !== null
                        ? formatDate(row.applicationFromDate)
                        : ''
                } - ${
                    row.applicationToDate !== null
                        ? formatDate(row.applicationToDate)
                        : ''
                }`,
        },
        {
            //  id: 'payoutFromDate',
            name: 'Změna období',
            width: '100px',

            selector: (row) =>
                `${formatDate(row.payoutFromDate)} - ${formatDate(
                    row.payoutToDate
                )}`,
            format: (row) =>
                `${
                    row.payoutFromDate !== null
                        ? formatDate(row.payoutFromDate)
                        : ''
                } - ${
                    row.payoutToDate !== null
                        ? formatDate(row.payoutToDate)
                        : ''
                }`,
        },
        {
            id: 'dueDate',
            width: '90px',

            name: 'Nevyplatit před',
            selector: (row) => row.dueDate.toString(),
            format: (row) => formatDate(row.dueDate),
            sortable: true,
        },
        {
            id: 'paymentType',
            width: '90px',

            name: 'Způsob výplaty',
            selector: (row) => row.paymentType,
            sortable: true,
        },
        {
            id: 'tasks',
            name: 'Úkoly',
            grow: 1,
            compact: true,
            sortable: false,
            selector: (row) => row.tasks,
            cell: (row) => (
                <div className="position-relative">
                    {row.tasks
                        ? row?.tasks?.map((task, index) => (
                              <MyButton
                                  onClick={() => {
                                      dispatch(setFromPayout(true));
                                  }}
                                  key={index}
                                  size="sm"
                                  to={NAV.PAYOUT_DETAIL(
                                      row.applicationID,
                                      row.dbId
                                  )}
                                  noYMargin
                                  className="table-button  my-1 p-1 text-truncate"
                                  variant="outline-primary"
                              >
                                  {task.name}
                              </MyButton>
                          ))
                        : null}
                </div>
            ),
        },
    ];

    return (
        <div>
            {payoutsData.pending ? <LoadingIndicator/> : null}

            <PayoutsTableHeader/>
            <MyTable
                columns={columns}
                data={payoutsData?.data?.payoutListWrapper}
                noDataComponent={TEXTS.noData}
                sortServer
                onSort={handleSort}
                paginationResetDefaultPage={pagination.reset}
                pagination
                paginationServer
                onChangePage={handlePageChange}
                paginationPerPage={pagination.size}
                onChangeRowsPerPage={handlePerRowsChange}
                paginationTotalRows={totalRows}
                defaultSortFieldId={sortList?.length ? sortList[0] : null}
                paginationDefaultPage={pagination.page}
                defaultSortAsc={sortOrder === 'ASC'}
            />
        </div>
    );
};

export default PayoutsTable;
