// export const max = (max: number, msg: string) => (value: number) => {
//     return value > max ? `Hodnota nesmí být větší než ${max}` : true
// }

import dayjs from 'dayjs';

export const required = (required: boolean = true) =>
    required
        ? {
              required: 'Pole je povinné',
          }
        : {};

export const max = (value: number) => ({
    max: {
        value,
        message: `Hodnota nesmí být větší než ${value}`,
    },
});

export const validatePayoutAmount = () => ({
    validatePayoutAmount: (val) => {
        if (!/^\d*(,\d+)?$/.test(val))
            return 'Částka výplaty smí obsahovat pouze celé nezáporné číslo. Podporovaný oddělovač je pouze čárka.'

        if (parseInt(val) < 1)
            return 'Není zadána žádná částka, zadejte prosím hodnotu výplaty.';
    },
});

export const min = (value: number, message = null) => ({
    min: {
        value,
        message: message || `Hodnota nesmí být menší než ${value}`,
    },
});

export const validateKodzi = () => ({
    kodzi: (val) =>
        /^[0-9]{1,10}$/.test(val) ||
        'Získatelské číslo musí obsahovat minimálně 1 a maximálně 10 číslic',
});

export const validateTextAppType = () => ({
    applicationType: (val) =>
        /^[a-z\d]{3}$/i.test(val) ||
        'Označení odměny musí obsahovat právě tři znaky a to pouze čísla a písmena bez diakritiky.',
});

export const maxLength = (value: number) => ({
    maxLength: {
        value,
        message: `Maximálně ${value} znaků`,
    },
});

export const minLength = (value: number) => ({
    minLength: {
        value,
        message: `Minimálně ${value} znaky`,
    },
});

export const pattern = (value: RegExp, message?: string) => ({
    pattern: {
        value,
        message: message || `Musí splňovat formát: ${value}`,
    },
});

export const dateAfterCheck = (val: string | Date, date: string | Date) => {
    if (!val || !date) return true;
    return !dayjs(val).isBefore(dayjs(date));
};

export const dateAfter = (date: string | Date, message: string) => ({
    dateAfter: (val: string | Date) => {
        if (!val || !date) return;

        if (dayjs(val).isBefore(dayjs(date))) return message;
    },
});

export const dateBefore = (date: string | Date, message: string) => ({
    dateBefore: (val: string | Date) => {
        if (!val || !date) return;

        if (dayjs(val).isAfter(dayjs(date))) return message;
    },
});
