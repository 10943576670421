import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
    ApiData,
    setCustomData,
    setDataFail,
    setDataPending,
    setDataSuccess,
} from '../../storeApiUtils';
import {
    KODZI,
    Payout,
    PayoutId,
    PayoutInternal,
    PayoutStateEnum,
    TribesData,
} from '../../../services/entities/Payout';
import {fetchPayouts, fetchTribes} from '../../../services/api/payoutApi';
import {
    ApplicationId,
    ValidationResult,
} from '../../../services/entities/Application';
import { fetchBrokers } from '../../../services/api/golemApi';
import { Broker } from '../../../services/entities/Broker';
import { PaginationWithReset } from '../../../services/entities/Pagination';
import { Sort } from '../../../services/entities/Sort';
import {
    applicationAndPayoutSaveFinal,
    applicationAndPayoutsSave,
    setAppDirty,
} from './applicationSlice';
import { getIdToken } from '../../../pages/common/authProvider/authProviderUtils';

export type KodzisInput<T> = {
    data: T;
    validityDate: Date;
};

export type PayoutsPaginationAndSort = {
    sort: Sort;
    pagination: PaginationWithReset;
    applicationId: ApplicationId;
};

export type PayoutsState = ApiData<PayoutInternal[]> & {
    applicationId: ApplicationId;
    paginationAndSort: PayoutsPaginationAndSort;
    kodzisPending: boolean;
    tribes: TribesData[];
};

const initialState: PayoutsState = {
    data: [],
    tribes: [],
    pending: false,
    kodzisPending: false,
    error: '',
    actionRequestId: null,
    applicationId: null,
    paginationAndSort: {
        applicationId: null,
        pagination: { page: 1, size: 10, reset: false },
        sort: {
            sortOrder: 'DESC',
            sortList: [],
        },
    },
};

export const payoutsFetch = createAsyncThunk<
    Payout[],
    KodzisInput<ApplicationId>
>('payouts/payoutsFetch', async (input) => {
    return await fetchPayouts(input.data, await getIdToken());
});

export const tribesFetch = createAsyncThunk<TribesData[], void>(
    'payouts/tribesFetch',
    async () => {
        return fetchTribes(await getIdToken());
    }
);

type PayoutKodzi = {
    payoutId: PayoutId;
    kodzi: KODZI;
};
export type ValdiateKodziIn = {
    payoutKodzis: PayoutKodzi[];
    validityDate: Date;
};
export const validateKodzis = createAsyncThunk<Broker[], ValdiateKodziIn>(
    'payouts/valdiateKodzis',
    async (input) => {
        return fetchBrokers(
            input.payoutKodzis.map((el) => el.kodzi),
            input.validityDate,
            await getIdToken()
        );
    }
);

export const putPayout = createAsyncThunk<void, KodzisInput<Payout>>(
    'payouts/putPayout',
    async (input, thunkApi) => {
        thunkApi.dispatch(
            validateKodzis({
                payoutKodzis: [
                    {
                        kodzi: input.data.recipientId,
                        payoutId: input.data.id,
                    },
                ],
                validityDate: input.validityDate,
            })
        );

        thunkApi.dispatch(setAppDirty(true));
    }
);

export const putPayouts = createAsyncThunk<void, KodzisInput<Payout[]>>(
    'payouts/putPayouts',
    async (input, thunkApi) => {
        if (input.data.length < parseInt(process.env.REACT_APP_MAX_PAYOUTS)) {
            await thunkApi.dispatch(
                validateKodzis({
                    payoutKodzis: input.data.map((el) => ({
                        payoutId: el.id,
                        kodzi: el.recipientId,
                    })),
                    validityDate: input.validityDate,
                })
            );
        }

        thunkApi.dispatch(setAppDirty(true));
    }
);

export const removePayout = createAsyncThunk<void, PayoutId>(
    'payouts/removePayout',
    async (input, thunkApi) => {
        thunkApi.dispatch(setAppDirty(true));
    }
);
export const removeAllPayouts = createAsyncThunk<void, void>(
    'payouts/removeAllPayouts',
    async (input, thunkApi) => {
        thunkApi.dispatch(setAppDirty(true));
    }
);

const putPayoutInternal = (state: PayoutsState, payout: PayoutInternal) => {
    const index = state.data.findIndex((el) => el.id === payout.id);
    if (index > -1) {
        state.data.splice(index, 1, payout);
    } else {
        state.data.push({
            ...payout,
            isNew: true,
            state: PayoutStateEnum.PENDING,
        });
    }
};

const payoutsSlice = createSlice({
    name: 'payouts',
    initialState,
    reducers: {
        refreshPayouts: (state) => {
            state.data = [];
            state.paginationAndSort = {
                ...initialState.paginationAndSort,
                applicationId: state.paginationAndSort.applicationId,
            };
        },
        setPayoutsPaginationSort: (
            state,
            action: PayloadAction<PayoutsPaginationAndSort>
        ) => {
            state.paginationAndSort = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(removePayout.pending, (state, action) => {
            state.data.splice(
                state.data.findIndex((el) => el.id === action.meta.arg),
                1
            );
            state.paginationAndSort = {
                ...initialState.paginationAndSort,
                applicationId: state.paginationAndSort.applicationId,
            };
        });

        builder.addCase(removeAllPayouts.pending, (state) => {
            state.data = [];
            state.paginationAndSort = {
                ...initialState.paginationAndSort,
                applicationId: state.paginationAndSort.applicationId,
            };
        });

        builder.addCase(putPayout.pending, (state, action) => {
            const payout = action.meta.arg.data;
            putPayoutInternal(state, payout);
            state.paginationAndSort = {
                ...initialState.paginationAndSort,
                applicationId: state.paginationAndSort.applicationId,
            };
        });

        builder.addCase(putPayouts.pending, (state, action) => {
            action.meta.arg.data.forEach((p) => putPayoutInternal(state, p));
            state.paginationAndSort = {
                ...initialState.paginationAndSort,
                applicationId: state.paginationAndSort.applicationId,
            };
        });

        builder.addCase(payoutsFetch.pending, (state, action) => {
            setDataPending(state, action);

            // if (action.meta.arg !== state.paginationAndSort.applicationId) {
            //     state.paginationAndSort = {
            //         ...initialState.paginationAndSort,
            //         applicationId: action.meta.arg
            //     }
            // }
        });
        builder.addCase(payoutsFetch.fulfilled, (s, a) =>
            setDataSuccess(s, a, true)
        );
        builder.addCase(payoutsFetch.rejected, setDataFail);

        builder.addCase(applicationAndPayoutsSave.pending, (s) =>
            setDataPending(s)
        );
        builder.addCase(applicationAndPayoutsSave.fulfilled, (s, a) => {
            if (a.payload.result !== 'ERROR') {
                setCustomData(s, a.payload.payouts);
            } else {
                s.pending = false;
            }
           
        });
        builder.addCase(applicationAndPayoutsSave.rejected, setDataFail);

        builder.addCase(applicationAndPayoutSaveFinal.pending, (s) =>
            setDataPending(s)
        );
        builder.addCase(applicationAndPayoutSaveFinal.fulfilled, (s, a) => {
            if (a.payload.result === ValidationResult.SUCCESS) {
                setCustomData(s, a.payload.payouts);
            } else if (a.payload.result === ValidationResult.ERROR) {
                s.pending = false;
            }
        });
        builder.addCase(applicationAndPayoutSaveFinal.rejected, setDataFail);

        builder.addCase(validateKodzis.pending, (s) => {
            s.kodzisPending = true;
        });
        builder.addCase(validateKodzis.fulfilled, (s, a) => {
            const brokerByIdMap = {};
            a.payload.forEach((el) => (brokerByIdMap[el.code] = el));
            const requestedKodziMap = {};
            a.meta.arg.payoutKodzis.forEach(
                (el) => (requestedKodziMap[el.payoutId] = el.kodzi)
            );

            const newData = s.data?.map((payout) => {
                if (requestedKodziMap[payout.id]) {
                    const broker: Broker = brokerByIdMap[payout.recipientId];
                    const extendedPayout = {
                        broker: broker,
                        brokerExists: !!broker,
                        brokerFetchError: false,
                        brokerValid: broker?.valid,
                        paymentType: broker?.paymentType,
                        businessType: broker?.businessType,
                        businessDescription: broker?.businessDescription,
                        recipientName: broker?.name,
                    };

                    return { ...payout, ...extendedPayout };
                }

                return payout;
            });

            return {
                ...s,
                data: newData,
                kodzisPending: false,
            };
        });
        builder.addCase(validateKodzis.rejected, (s, a) => {
            const requestedKodziMap = {};
            a.meta.arg.payoutKodzis.forEach(
                (el) => (requestedKodziMap[el.payoutId] = el.kodzi)
            );
            const newData = s.data?.map((payout) => {
                if (requestedKodziMap[payout.id]) {
                    const extendedPayout = {
                        brokerFetchError: true,
                        brokerExists: false,
                        brokerValid: false,
                    };

                    return { ...payout, ...extendedPayout };
                }

                return payout;
            });
            return {
                ...s,
                data: newData,
                kodzisPending: false,
            };
        });
        builder.addCase(tribesFetch.fulfilled, (s, a) => {
            s.tribes = a.payload;
        });
        builder.addCase(tribesFetch.rejected, setDataFail);

        builder.addCase(tribesFetch.pending, (s) => setDataPending(s));
    },
});

export const {setPayoutsPaginationSort}
    = payoutsSlice.actions
export default payoutsSlice.reducer