import React, {useCallback, useEffect, useMemo} from 'react'
import './ApplicationTable.scss'
import {ApplicationWrapper, PagedApplications,} from '../../../services/entities/Application';
import {TableColumn} from 'react-data-table-component';
import {formatAmount, formatAppPayoutState, formatAppState, formatDate} from '../../../utils/formatUtils';
import {TEXTS} from '../../../constants/texts';
import {useAppDispatch, useAppSelector} from '../../../store/store';
import {ApiData} from '../../../store/storeApiUtils';
import {NAV} from '../../../RouteList';
import {MyButton} from '../../../components/button/MyButton';
import {ExclamationTriangle, Search} from 'react-bootstrap-icons';
import {MyTable} from '../../../components/table/MyTable';
import {appListFetch} from "../../../store/slices/applicationList/applicationListSlice";
import {taskColumn} from '../../common/taskColumn/taskColumn';
import ApplicationTableHeader from './ApplicationTableHeader';
import {LoadingIndicator} from '../../../components/loadingIndicator/LoadingIndicator';
import {seesPayoutErrorOnApplications} from '../../../services/entities/User';
import {clearApplication} from '../../../store/slices/applicationDetail/applicationSlice';
import {setPagination, setSort} from '../../../store/slices/applicationList/applicationListFilterSlice';
import {arraysEqual} from "../../../utils/utils";

function ApplicationTable() {
    const dispatch = useAppDispatch()
    const appData: ApiData<PagedApplications> = useAppSelector(state => state.applicationList)
    const user = useAppSelector(state => state.user)
    const applicationListFilter = useAppSelector(s => s.applicationListFilter)
    const totalRows = appData.data?.recordCount
    const pagination = useAppSelector(s => s.applicationListFilter.pagination)
    const sort = useAppSelector(s => s.applicationListFilter.sort)

    useEffect(() => {
        dispatch(appListFetch({
            baseFilter: applicationListFilter.baseFilter,
            myApprovalFilter: applicationListFilter.myApprovalFilter,
            customFilter: applicationListFilter.filter,
            pageNumber: applicationListFilter.pagination.page-1,
            pageSize: applicationListFilter.pagination.size,
            sort: applicationListFilter.sort
        }))
    }, [applicationListFilter, dispatch])

    const handleOpenTask = useCallback(() => {
        dispatch(clearApplication())
    }, [dispatch])

    const showPayoutErrorAction = useCallback((row: ApplicationWrapper): boolean => {
        return row.errorPayout && seesPayoutErrorOnApplications(user)
    }, [user])

    const columns: TableColumn<ApplicationWrapper>[] = useMemo(
        () => [
            {
                name: 'Detail',
                selector: (row) => row.tasks?.length || 0,
                width: '45px',
                compact: true,
                sortable: false,
                cell: (row) => (
                    <MyButton
                        to={NAV.APPLICATION_DETAIL(row.application.id)}
                        title="Prohlížet žádost"
                        onClick={handleOpenTask}
                        noYMargin
                        data-id={row.application.id}
                        className="text-decoration-none"
                        variant="link"
                    >
                        <Search />
                    </MyButton>
                ),
            },
            {
                id: 'state',
                name: 'Stav žádosti',
                compact: true,
                width: '90px',
                selector: (row) => row.application.state,
                format: (row) => formatAppState(row.application.state),
                sortable: false,
            },
            {
                id: 'payoutState',
                name: 'Stav výplat žádosti',
                grow: 0.7,
                compact: true,
                selector: (row) => row.application.payoutState,
                cell: (row) =>
                    showPayoutErrorAction(row) ? (
                        <MyButton
                            className="my-1 p-1 ms-0 ps-0 text-truncate mw-100 text-decoration-none text-wrap text-start"
                            tooltip="Vyřešit chybu výplaty"
                            noYMargin
                            size="sm"
                            to={NAV.APPLICATION_DETAIL_PAYOUTS(
                                row.application.id
                            )}
                            variant="link"
                        >
                            <ExclamationTriangle className="text-danger" />{' '}
                            {formatAppPayoutState(row.application.payoutState)}
                        </MyButton>
                    ) : (
                        formatAppPayoutState(row.application.payoutState)
                    ),
                sortable: false,
            },
            {
                id: 'recipientName',
                name: 'Název získatele',
                grow: 0.7,
                compact: true,
                selector: (row) => row.application.recipientName,
                sortable: true,
            },
            {
                name: 'Role schvalovatele',
                id: 'approvers',
                grow: 0.7,
                compact: true,
                //width: '115px',
                selector: (row) => row.application.approvers,
                sortable: true,
            },
            {
                name: 'Jméno schvalovatele',
                id: 'lastApproverName',
                grow: 0.7,
                compact: true,
                selector: (row) => row.application.lastApproverName,
                sortable: true,
            },
            {
                name: 'Zadavatel',
                id: 'creatorName',
                grow: 0.7,
                compact: true,
                //width: '115px',
                selector: (row) => row.application.creatorName || '',
                sortable: true,
            },
            {
                name: 'Typ provize',
                id: 'type',
                grow: 0.7,
                compact: true,
                selector: (row) => row.application.typeObject?.description,

                sortable: false,
            },
            {
                name: 'Výše odměny',
                id: 'realPayoutSum',
                grow: 0.7,
                compact: true,
                selector: (row) => row.realPayoutSum?.toString(),
                format: (row) => formatAmount(row.realPayoutSum),
                sortable: true,
            },
            {
                name: 'Číslo žádosti',
                id: 'number',
                grow: 0.7,
                compact: true,
                selector: (row) => row.application.number,
                sortable: true,
            },
            {
                name: 'Datum vytvoření',
                id: 'created',
                width: '85px',
                compact: true,
                selector: (row) => row.application.created.toString(),
                format: (row) => formatDate(row.application.created),
                sortable: true,
            },
            {
                name: 'DB ID',
                id: 'id',
                width: '60px',
                compact: true,
                selector: (row) => Number(row.application.id),
                sortable: true,
            },
            taskColumn<ApplicationWrapper>({
                name: 'Úkoly',
                genIds: (row, task) => ({
                    'data-id': row.application.id,
                    'data-taskid': task.id,
                }),
                handleOpenTask,
                to: (row, taskId) => NAV.APPLICATION_DETAIL_WITH_TASK(row.application.id, taskId),
                otherCellContent: (row) =>
                    showPayoutErrorAction(row) && (
                        <MyButton
                            to={NAV.APPLICATION_DETAIL_PAYOUTS(
                                row.application.id
                            )}
                            title="Vyřešit chybu výplaty"
                            noYMargin
                            size="sm"
                            className="table-button my-1 p-1 text-truncate"
                            variant="outline-primary"
                            onClick={() => dispatch(clearApplication())}
                        >
                            Chyba výplaty
                        </MyButton>
                    ),
            }),
        ],
        [dispatch, handleOpenTask, showPayoutErrorAction]
    );

    const handleSort = (selectedColumn, sortDirection: 'asc' | 'desc') => {
        if (selectedColumn.id) {
            const sortList = [selectedColumn.id];
            const direction = sortDirection.toUpperCase() as 'ASC' | 'DESC';

            if (
                !arraysEqual(sortList, sort.sortList) ||
                direction !== sort.sortOrder
            ) {
                dispatch(
                    setSort({
                        sortList: [selectedColumn.id],
                        sortOrder: sortDirection.toUpperCase() as
                            | 'ASC'
                            | 'DESC',
                    })
                );
            }
        }
    };

    const handlePageChange = (page) => {
        if (page !== pagination.page)
            dispatch(setPagination({ size: pagination.size, page }));
    };

    const handlePerRowsChange = async (newPageSize) => {
        if (newPageSize !== pagination.size)
            dispatch(setPagination({ size: newPageSize, page: 1 }));
    };


    return (
        <div className="ApplicationTable position-relative">

            {
                appData.pending ? <LoadingIndicator /> : null
            }

            {
                appData.error ?
                    <h6 className="text-danger">{TEXTS.errorLoadingData}: {appData.error}</h6> :

                    <>
                        <ApplicationTableHeader/>
                        <MyTable
                            columns={columns}
                            sortServer
                            onSort={handleSort}
                            data={appData.data?.applicationWrapperList}
                            paginationResetDefaultPage={pagination.reset}
                            noDataComponent={TEXTS.noData}
                            pagination paginationServer
                            onChangePage={handlePageChange}
                            paginationPerPage={pagination.size}
                            onChangeRowsPerPage={handlePerRowsChange}
                            paginationTotalRows={totalRows}

                            defaultSortFieldId={sort.sortList?.length ? sort.sortList[0] : null}
                            paginationDefaultPage={pagination.page}
                            defaultSortAsc={sort.sortOrder === 'ASC'}
                        />
                    </>
            }
        </div>
    )
}

export default ApplicationTable;
