import React, {useEffect, useRef} from 'react';
import {RootState, useAppDispatch, useAppSelector} from '../../../../store/store';
import {ApplicationId} from '../../../../services/entities/Application';
import CommentsTable from '../CommentsTable';
import {PayoutId, PayoutInternal} from '../../../../services/entities/Payout';
import {payoutsCommentsFetch} from '../../../../store/slices/payoutDetail/payoutDetailSlice';
import {PayoutDetailDataPayout} from '../../../../services/entities/PayoutDetailWrapper';

export type CommentsProps = {
    applicationId: ApplicationId,
    payout: PayoutInternal | PayoutDetailDataPayout,
    payoutId: PayoutId,
    disabled: boolean
}

function PayoutComments({applicationId, payoutId, payout, disabled}: CommentsProps) {
    const dispatch = useAppDispatch()
    const lastParams = useRef<[ApplicationId, PayoutId]>(null)

    //TOOD fix condition  && lastParams.current !== [applicationId, payoutId]
    useEffect(() => {
        if (!disabled
            && applicationId && payoutId
            && !payout?.isNew) {
            lastParams.current = [applicationId, payoutId]
            dispatch(payoutsCommentsFetch({applicationId, payoutId: payoutId}))
        }
    }, [dispatch, payoutId, applicationId, payout?.isNew, disabled])

    const comments = useAppSelector((s: RootState) => s.payouDetail.comments)
    const camundaUsersMap = useAppSelector((s: RootState) => s.applicationDetail.camundaUsers.map)

    return (
        <CommentsTable comments={comments} camundaUsersMap={camundaUsersMap} />
    )
}

export default PayoutComments;
