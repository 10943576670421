import {DATA_API_URL, IS_KOOP} from '../constants';
import {ApplicationId} from '../entities/Application';
import {AuthToken} from './applicationApi';
import {RequiredAttachmentsForTypes, AttachmentKoop, AttachmentCpp} from '../entities/Attachment';
import {fetchMethods} from "../fetchUtils";
import {AttachmentAny} from '../../store/slices/applicationDetail/attachmentsSlice';

export const saveAttachment = (appId: ApplicationId, attachment: AttachmentKoop | AttachmentCpp, token: AuthToken): Promise<any> => {
    const url = `${DATA_API_URL}/applications/${appId}/attachments/`;
    const formData = new FormData();
    formData.append('file', attachment.file);
    formData.append('fileName', attachment.fileName);
    formData.append('mimeType', attachment.mimeType);
    formData.append('description', attachment.description);

    if (IS_KOOP) {
        formData.append(
            'documentType',
            (attachment as AttachmentKoop).documentType
        );
        formData.append('dokuType', (attachment as AttachmentKoop).dokuType);
        formData.append('titleID', (attachment as AttachmentKoop)?.titleID);
    } else {
        formData.append('dokuType', (attachment as AttachmentCpp).dokuType);
    }

    return fetchMethods.post(url, formData, token, true, true)
}

export const removeAttachment = (
    appId: ApplicationId,
    attachment: AttachmentKoop | AttachmentCpp,
    token: string
): Promise<AttachmentKoop[]> => {
    const url = `${DATA_API_URL}/applications/${appId}/attachments/${attachment.id}`;
    return fetchMethods.delete(url, token);
};

export const downloadAttachment = (
    appId: ApplicationId,
    attachment: AttachmentKoop | AttachmentCpp,
    token: string
): Promise<any> => {
    const url = `${DATA_API_URL}/applications/${appId}/attachments/${attachment.id}/download`;
    return fetchMethods.get(url, token).then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want
        a.download = attachment.fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    });
};

export const fetchAttachments = (
    appId: ApplicationId,
    token: string
): Promise<AttachmentAny[]> => {
    const url = `${DATA_API_URL}/applications/${appId}/attachments/`;
    return fetchMethods.get(url, token);
};

export const fetchRequiredAttachmentsRules = (
    token: string
): Promise<RequiredAttachmentsForTypes> => {
    const url = `${DATA_API_URL}/required-documents/`;
    return fetchMethods.get(url, token);
};

export const updateAttachments = (
    appId: ApplicationId,
    attachment: AttachmentKoop,
    token: AuthToken,
    newDokuType: string
): Promise<any> => {
    const url = `${DATA_API_URL}/applications/${appId}/attachments/${
        attachment.id
    }/update?dokuType=${newDokuType ? newDokuType : attachment.dokuType}`;
    return fetchMethods.post(url, attachment, token);
};