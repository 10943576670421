import {UserProfile, UserRole} from '../entities/User';
import {SERVICE_API_URL} from '../constants';
import { fetchMethods } from '../fetchUtils';

export const fetchUserProfile = (login: string, token: string):Promise<UserProfile> => {
    const url = `${SERVICE_API_URL}/user/${login}/profile`;
    return fetchMethods.get(url, token)
}

// type UserGroup = {
//     id: string,
//     name: string,
//     type: string
// }
export const fetchUserRoles = (login: string, token: string):Promise<UserRole[]> => {
    const url = `${SERVICE_API_URL}/group?member=${login}`;
    return fetchMethods.get(url, token).then((response) => {
        return response?.map(el => el.id.toLowerCase()).map(id => id.replace(/^(mpcpp_|mpkoop_)?(.*?)(_test)?$/, "$2"))
    });
}
