import { DATA_API_URL } from '../constants';
import {
    Payout,
    PayoutId,
    PayoutInternal,
    PayoutWrapper,
    TribesData,
} from '../entities/Payout';
import { ApplicationId } from '../entities/Application';
import { AuthToken } from './applicationApi';
import { fetchMethods } from '../fetchUtils';

export const payoutWrappers2internal = (
    wrappers: PayoutWrapper[]
): PayoutInternal[] => {
    return wrappers && wrappers.map(wrapper2internal);
};

export const payoutInternal2external = (payout: PayoutInternal): Payout => {
    let { isNew, hasDuplicate, duplicates, tasks, ...rest } = payout;
    if (isNew) rest.id = undefined;
    // rest.fromDate = month2DateStr(payout.fromDate)
    // rest.toDate = month2DateStr(payout.toDate)
    return rest;
};

const wrapper2internal = (w: PayoutWrapper): PayoutInternal => {
    return {
        ...w.payout,
        hasDuplicate: w.hasDuplicate,
        duplicates: w.duplicates,
        tasks: w.tasks,
        golemError: w.golemError,
        // fromDate: date2month(w.payout.fromDate),
        // toDate: date2month(w.payout.toDate)
    };
};

export const fetchPayouts = (
    appId: ApplicationId,
    token: AuthToken
): Promise<PayoutInternal[]> => {
    const url = `${DATA_API_URL}/applications/${appId}/payouts/`;
    return fetchMethods.get(url, token).then((response) => {
        return payoutWrappers2internal(response);
    });
};

export const fetchPayout = (
    appId: ApplicationId,
    payoutId: PayoutId,
    token: AuthToken
): Promise<PayoutInternal> => {
    const url = `${DATA_API_URL}/applications/${appId}/payouts/${payoutId}`;
    return fetchMethods.get(url, token).then((response) => {
        return wrapper2internal(response);
    });
};

export const savePayoutDates = (
    appId: ApplicationId,
    payoutId: PayoutId,
    fromDate: Date | string,
    toDate: Date | string,
    token: string
): Promise<void> => {
    const url = `${DATA_API_URL}/applications/${appId}/payouts/${payoutId}/dates`;
    const body = {
        fromDate,
        toDate,
    };
    return fetchMethods.put(url, body, token);
};

export const fetchTribes = (token: AuthToken): Promise<TribesData[]> => {
    const url = `${DATA_API_URL}/tribes/`;

    return fetchMethods.get(url, token);
};
