import {Form} from 'react-bootstrap';
import {MyButton} from '../../components/button/MyButton';
import {login} from '../common/authProvider/authProviderUtils';


function TryLoginPage() {
    //https://www.npmjs.com/package/@idport/oidc-rp-sdk

    const handleLogin = () => {
        login(window.location.pathname)
    }

    const handleGetToken= () => {

    }

    const handleRefresh = () => {

    }

    return (
        <div>
            <Form.Control/>

            <MyButton className="pb-3" onClick={() => handleLogin()}>
                Login
            </MyButton>

            <MyButton className="pb-3" onClick={() => handleGetToken()}>
                Get Token
            </MyButton>

            <MyButton className="pb-3" onClick={() => handleRefresh()}>
                Refresh Token
            </MyButton>

        </div>
    );
}

export default TryLoginPage;
