import { Container, Tab, Tabs } from 'react-bootstrap';
import Header from '../common/header/Header';
import { useAppDispatch, useAppSelector } from '../../store/store';
import {
    setSelectedTitleRegister,
    titleRegisterListFetch,
} from '../../store/slices/titleRegister/titleRegisterSlice';
import { useEffect, useState } from 'react';
import TitleRegisterFilterForm from './customFilter/TitleRegisterFilterForm';
import ImportFormModal from './import/import-form-modal';
import { CloudArrowUp } from 'react-bootstrap-icons';
import TitleRegisterTable from './tables/TitleRegisterTable';
import {
    initialValues,
    TitleStructureEnum,
} from '../../services/entities/TitleRegister';
import { hasIntersection } from '../../utils/utils';
import { UserRole } from '../../services/entities/User';

export const TitleRegisterPage = () => {
    const [showTitleFilterModal, setShowTitleFilterModal] = useState(false);
    const selectedTab = useAppSelector(
        (state) => state.titleRegister.selectedTitleRegister
    );
    const dispatch = useAppDispatch();
    const [showModal, setShowModal] = useState(false);
    const { roles } = useAppSelector((state) => state.user);

    //DMP, STZ a USP

    useEffect(() => {
        dispatch(
            titleRegisterListFetch({
                type: TitleStructureEnum.DMP,
                data: { ...initialValues[TitleStructureEnum.DMP] },
            })
        );
        dispatch(
            titleRegisterListFetch({
                type: TitleStructureEnum.STZ,
                data: { ...initialValues[TitleStructureEnum.STZ] },
            })
        );
        dispatch(
            titleRegisterListFetch({
                type: TitleStructureEnum.USP,
                data: { ...initialValues[TitleStructureEnum.USP] },
            })
        );
    }, [dispatch]);

    const handleModalHide = () => {
        setShowModal(false);
    };

    return (
        <div className="TitleRegisterPage">
            <ImportFormModal show={showModal} onHide={handleModalHide} />

            <TitleRegisterFilterForm
                show={showTitleFilterModal}
                onHide={() => setShowTitleFilterModal(false)}
                selectedTable={TitleStructureEnum[selectedTab]}
            />
            {hasIntersection(
                [UserRole.spravce_et, UserRole.administrator],
                roles
            ) ? (
                <Header
                    className="mb-3"
                    actions={[
                        {
                            title: 'Import titulů',
                            icon: <CloudArrowUp />,
                            action: () => {
                                setShowModal(true);
                            },
                        },
                    ]}
                />
            ) : (
                <Header className="mb-3" />
            )}

            <Container fluid="xxl">
                <h3 className="mb-3">Evidence titulů</h3>

                <Tabs
                    onSelect={(k) => {
                        dispatch(setSelectedTitleRegister(k));
                    }}
                    activeKey={selectedTab}
                    className="mb-3"
                >
                    <Tab eventKey="DMP" title="Mimořádné provize">
                        <div>
                            <TitleRegisterTable
                                setShowTitleFilterModal={
                                    setShowTitleFilterModal
                                }
                            />
                        </div>
                    </Tab>
                    <Tab eventKey="STZ" title="Soutěže">
                        <div>
                            <TitleRegisterTable
                                setShowTitleFilterModal={
                                    setShowTitleFilterModal
                                }
                            />
                        </div>
                    </Tab>
                    <Tab eventKey="USP" title="Úspory">
                        <div>
                            <TitleRegisterTable
                                setShowTitleFilterModal={
                                    setShowTitleFilterModal
                                }
                            />
                        </div>
                    </Tab>
                </Tabs>
            </Container>
        </div>
    );
};
