import React from 'react';
import {MyButton} from '../../../components/button/MyButton';
import {ApplicationTask, TaskId} from '../../../services/entities/ApplicationProcess';

export type TaskColumnProps<R> = {
    columnProps?: object,
    name: string,
    to: (row: R, taskId: TaskId) => string,
    selector?: (row: R) => any,
    genIds: (row: R, task: ApplicationTask) => object,
    handleOpenTask?: (any) => void,
    otherCellContent?: (row: R) => React.ReactNode
}

export function taskColumn<R extends { tasks?: ApplicationTask[] }>(
    {columnProps, name, selector, genIds, handleOpenTask, to, otherCellContent}: TaskColumnProps<R>) {
    return ({
        name,
        grow: 1,
        compact: true,
        sortable: false,
        selector: selector || (row => row.tasks?.map(el => el.name).join(",") || ' '),
        cell: row => <div className="position-relative">
            {
                (row.tasks || []).map(task => (
                    <MyButton size="sm"
                              to={to(row, task.id)}
                              key={task.id}
                              onClick={handleOpenTask}
                              title={task.name}
                              noYMargin
                              {...genIds(row, task)}
                              className="table-button my-1 p-1 text-truncate"
                              variant="outline-primary">
                        {task.name}
                    </MyButton>
                ))
            }
            {
                otherCellContent && otherCellContent(row)
            }
        </div>,
        ...(columnProps || {})
    })
}